import React, { Component } from 'react';
import ModalWrapper from './ModalWrapper';
import sad from "../../../assests/img/sadd.svg";
import { withTranslation } from 'react-i18next';

class WarningModal extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            show:true
         }
    }

 
    render() { 
        const {t,closeModal}=this.props
        return ( 
            <ModalWrapper show={this.props.show}>
                <div 
                    className="p-4 bg-light mx-auto text-center py-md-5 py-4" 
                    style={{width:'35rem'}}>
                    <img 
                        className="p-md-0 p-3"
                        height="100"
                        src={sad} alt="" /> 
                    <h3 className='text-muted m-0 py-md-3 py-0'>
                    {t("You don’t have enough points to complete your purchase")}
                    </h3> 
                    {/* <p>{t("")}</p> */}
                    <button className="btn btn-secondary mt-3 mx-2"
                        onClick={()=>{closeModal()}}>
                        <span className="fw-bold primary-color">{t("Close")}</span></button>
                </div>
            </ModalWrapper>
         );
    }
}

export default  withTranslation()(WarningModal)