import axios from "axios";
import Loader from "components/shared/Loader";
import MainLoginModel from "components/shared/Modals/MainLoginModel";
import RedeemConfirmationModal from "components/shared/Modals/RedeemConfirmationModal";
import RedeemSuccesfulForLogged from "components/shared/Modals/RedeemSuccesfulForLogged";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { checkTokenValidity } from "Redux/auth/authActions";
import { fetchPointBalance } from "Redux/point/pointActions";
import { REACT_APP_baseUrl } from "utils/app-constants";
import secretKeyGenerator from "utils/serectKeyGenerator";
import Pointsredeem from "./Pointsredeem";
import PointsTransaction from "./PointsTransaction";
const PointsHistory = (props) => {
  const [redeemDetailsForLoggedUser, setRedeemDetails] = useState({
    showPopup: false,
    redeemSuccess: false,
    message: "",
    showSpinner: false,
    giftCardNumber: "",
    apiLoading:false,
    successfulRedeem:undefined
  });

  const [checkBalanceLoading,setCheckBalanceLoading] = useState(false)
  const [showConfirmModal, setConfirmModal] = useState(false);
  const [giftCardNumber,setGiftCardNumber] = useState()
  const [rewardsToken, setRewardsToken] = useState("");
  const [showLoginModal,setShowLoginModal] = useState(false)

  const dispatch = useDispatch();
  const loginInfo = useSelector(state => state.auth)

  // Check is user logged in

  useEffect(()=>{
    dispatch(checkTokenValidity())
  },[])

  useEffect(() => {
    if(!loginInfo.valid){
      setShowLoginModal(true)
    }
  },[loginInfo.valid])

  const redeemPointsForLoggedUser = (giftCardNumber) => {
    setRedeemDetails(prevValue => ({...prevValue,apiLoading:true,successfulRedeem:false}))
    let authToken = localStorage.getItem("userToken");
    axios
      .post(
        `${REACT_APP_baseUrl}/giftcards/add_mylist_card`,
        {
          mylist_card: {
            card_number: rewardsToken,
          },
        },
        { headers: { Authorization: `Bearer ${authToken}` } }
      )
      .then((res) => {
        setConfirmModal(false)
        setRewardsToken('')
        if (res.data.code === 200) {
          dispatch(fetchPointBalance(localStorage.getItem("userToken")));

          setRedeemDetails({
            showPopup: true,
            redeemSuccess: true,
            showSpinner: false,
            giftCardNumber: giftCardNumber,
            apiLoading:false,
            successfulRedeem:true
          });
        } else if (res.data.code === 400) {
          setRedeemDetails({
            showPopup: true,
            redeemSuccess: false,
            showSpinner: false,
            giftCardNumber: giftCardNumber,
            responseMessage: res.data?.message,
            successfulRedeem:false
          });
        }
      })
      .catch((err) =>{
        setRewardsToken('')
        setConfirmModal(false);
        console.error(err)
      });
  };

  const checkRedemptionPoints = () => {
    let authToken = localStorage.getItem("userToken");
    setCheckBalanceLoading(true);
    axios
      .get(`${REACT_APP_baseUrl}/giftcards/${rewardsToken}/check_balance`, {
        headers: { Authorization: `Bearer ${authToken}` },
        params: {
          secret_key: secretKeyGenerator({ id: rewardsToken }),
        },
      })
      .then((res) => {
        setCheckBalanceLoading(false)
        if (res.data.code === 200) {
          if (res.data.data.giftcard.remaining_value > 0) {
            setConfirmModal(true);
          } else {
            setRedeemDetails({
              showPopup: true,
              redeemSuccess: false,
              showSpinner: false,
            });
          }
        } else {
          setRedeemDetails({
            showPopup: true,
            redeemSuccess: false,
            showSpinner: false,
            responseMessage: res.data?.message,
          });
        }
      })
      .catch((err) => {
        setCheckBalanceLoading(false)
        setRewardsToken('')
        console.error(err);
      });
  };
  if(loginInfo.tokenLoading){
    return <Loader/>
  }
  if(!loginInfo.valid){
    return <MainLoginModel show={showLoginModal} loginFun={()=>setShowLoginModal(false)} lang={props.lang} toogleLang={this.props.toogleLang}/>
  }
  return (
    <>
    <div className="d-flex justify-content-center align-items-center mt-3">
    <Pointsredeem rewardsToken={rewardsToken} setRewardsToken={setRewardsToken} checkRedemptionPoints={checkRedemptionPoints}
    checkBalanceLoading={checkBalanceLoading}
    />

      <RedeemConfirmationModal
        onProceed={()=>redeemPointsForLoggedUser(giftCardNumber)}
        closeModal={() => setConfirmModal(false)}
        show={showConfirmModal}
        loading={redeemDetailsForLoggedUser.apiLoading}
      />
      <RedeemSuccesfulForLogged
        show={redeemDetailsForLoggedUser.showPopup}
        giftCardNo={redeemDetailsForLoggedUser.giftCardNumber}
        redeemSuccess={redeemDetailsForLoggedUser.redeemSuccess}
        responseMessage={redeemDetailsForLoggedUser.responseMessage}
        closeModal={() =>
          setRedeemDetails((prevState) => ({ ...prevState, showPopup: false }))
        }
      />
    </div>
    <PointsTransaction successfulRedeem={redeemDetailsForLoggedUser.successfulRedeem}/>
    </>
  );
};

export default PointsHistory;
