import React, { Component } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, EffectCoverflow } from "swiper";
import "swiper/swiper-bundle.css";
import { Link } from 'react-router-dom';
import {ReactComponent as GiftcardImg} from '../../assests/img/module-icons-white/icon-module-gift-cards.svg'
import {ReactComponent as OffersImg} from '../../assests/img/module-icons-white/icon-module-hassad-offers.svg'
import {ReactComponent as GiftsImage}  from '../../assests/img/module-icons-white/icon-module-hassad-gifts.svg'
import {ReactComponent as MagazineImage} from '../../assests/img/module-icons-white/icon-module-e-magazines.svg'
import {ReactComponent as ExpImage} from '../../assests/img/module-icons-white/icon-module-experiences.svg'
import {ReactComponent as GamesImage} from '../../assests/img/module-icons-white/icon-module-games.svg'
import {ReactComponent as SoftwaresImage} from '../../assests/img/module-icons-white/icon-module-softwares.svg'
import {Button} from 'react-bootstrap'
import Card from "react-bootstrap/Card";
import { withTranslation } from "react-i18next";


SwiperCore.use([Navigation, Pagination, EffectCoverflow]);

class MobileGoalModal extends Component {
  render() {
    const {t} = this.props
    return (
      <div className='d-md-none d-block' >
        <div className='text-center color-blue fw-bold mt-3'>
          <h3>{t("Choose a module to set goal item")}</h3>
        </div>
       <div style={{marginTop:"4rem"}}>
     
     
        <Swiper
          navigation
          pagination={{ clickable: true }}
          effect="coverflow"
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }}
          slidesPerView={2}
          centeredSlides
          style={{ height: "50vh" }}
          observer
          observeParents
        >
          <SwiperSlide >
            <Link to='/giftcards'>
              <div className='container modal-card rounded d-flex justify-content-center flex-column' style={{ width:"15rem",backgroundColor:'#8E75AA'}}>
                <div className='d-flex justify-content-center'>
                <GiftcardImg  style={{width:"50%"}}  />
                </div>
                <div className='text-white d-flex justify-content-center'>
                  <h3>{t("Gift Cards")}</h3>
                  </div>
                </div>
            </Link>
          </SwiperSlide>
          <SwiperSlide >
            <Link to='/physicalgiftcards'>
              <div className='container modal-card rounded d-flex justify-content-center flex-column' style={{ width:"15rem",backgroundColor:'#8E75AA'}}>
                <div className='d-flex justify-content-center'>
                <GiftcardImg  style={{width:"50%"}}  />
                </div>
                <div className='text-white d-flex justify-content-center'>
                  <h3>{t("Physical Gift Cards")}</h3>
                  </div>
                </div>
            </Link>
          </SwiperSlide>
          <SwiperSlide >
            <Link to='/gifts'>
              <div className='container rounded d-flex justify-content-center flex-column modal-card' style={{width:"15rem",backgroundColor:'#89ABE3'}}>
                <div className='d-flex justify-content-center'>
          <GiftsImage  style={{width:"50%"}} alt="" />
                </div>
                <div className='text-white d-flex justify-content-center'>
                  <h3>{t("Merchandise")}</h3>
                  </div>
                </div>
            </Link>
          </SwiperSlide>
          <SwiperSlide
          >
            <Link to='/offers'>
            <div className='container modal-card rounded d-flex justify-content-center flex-column' style={{ width:"15rem",backgroundColor:'#279989'}}>
                <div className='d-flex justify-content-center'>
          <OffersImg  style={{width:"50%"}} alt="" />
                </div>
                <div className='text-white d-flex justify-content-center'>
                  <h3>{t("Offers")}</h3>
                  </div>
                </div>
            </Link>
          </SwiperSlide>
          <SwiperSlide
          >
           <Link to='/e-magazines'>
           <div className='container modal-card rounded d-flex justify-content-center flex-column' style={{ width:"15rem",backgroundColor:'#FF6D70'}}>
                <div className='d-flex justify-content-center'>
          <MagazineImage  style={{width:"50%"}} alt="" />
                </div>
                <div className='text-white d-flex justify-content-center'>
                  <h3>{t("E-Magazines")}</h3>
                  </div>
                </div>
            </Link>
          </SwiperSlide>
          <SwiperSlide
          >
             <Link to='/experiences'>
             <div className='container modal-card rounded d-flex justify-content-center flex-column' style={{ width:"15rem",backgroundColor:'#77C5D5'}}>
                <div className='d-flex justify-content-center'>
          <ExpImage  style={{width:"50%"}} alt="" />
                </div>
                <div className='text-white d-flex justify-content-center'>
                  <h3>{t("Experiences")}</h3>
                  </div>
                </div>
            </Link>
          </SwiperSlide>
          <SwiperSlide
           
          >
            <Link to='/softwares&games'>
            <div className='container modal-card rounded d-flex justify-content-center flex-column' style={{ width:"15rem",backgroundColor:'#C09C83'}}>
                <div className='d-flex justify-content-center'>
          <GamesImage  style={{width:"50%"}} alt="" />
                </div>
                <div className='text-white d-flex justify-content-center'>
                  <h3>{t("Softwares and Games")}</h3>
                  </div>
                </div>
            </Link>
          </SwiperSlide>
          {/* <SwiperSlide
           
          >
             <Link to='/softwares'>
             <div className='container modal-card rounded d-flex justify-content-center flex-column' style={{ width:"15rem",backgroundColor:'#683465'}}>
                <div className='d-flex justify-content-center'>
          <SoftwaresImage  style={{width:"50%"}} alt="" />
                </div>
                <div className='text-white d-flex justify-content-center'>
                  <h3>{t("Softwares")}</h3>
                  </div>
                </div>
            </Link>
          </SwiperSlide> */}
        </Swiper>
      </div>
      </div>
    );
  }
}

export default withTranslation()(MobileGoalModal);
