/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ProductGiftsCard from '../shared/ProductGiftsCard';
import { Dropdown } from 'react-bootstrap';
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import Breadcrumbs from '../shared/Breadcrumbs';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { sortMerchandiseCards } from '../../Redux/Merchandise/merchandiseActions';
import { connect } from 'react-redux';
import { pointsToCurrancy } from '../../utils/pointsConversion';
// import iconCategorie from '../../assests/img/Footer/icon-categories.png'
import filterIcon from '../../assests/img/header/icon-filter-categories.png'



class GiftsPage extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            categories:[],
            confirmOrder:false,
            filteredCards:false,
            orders:{},
            card:{},
            data:this.props.products,
            fromPoint:null,
            toPoint:null,
            pointsFilteredCard:{},
            pointsOnlyFilter:false,
            categoryName:false,
            establisdhments:['Casual Dining','Food Court'],
         }
    }

    // fetchProductHandler = () => {
    //     const payload = {
    //         "merchant_product": {
    //            "currency": this.props.countryId
    //         }
    //      }
   
    //      this.props.fetchProductsList(payload)
    // }
    //fetchProductsList
    componentDidMount(){
      
        // if(this.props.countrySelected!= 2){
        //     this.props.history.replace("/404")
        // }
        // if( this.props.products &&  this.props.products.length=== 0 ){
        //     this.props.history.replace("/404")  
        // }
        if( !this.props.loading && this.props.products &&  this.props.products.length=== 0){
            this.props.history.push({
                pathname:'/404',
                redirectionURL:'/gifts'
            })         
        }
        
    }

    componentDidUpdate(prevProps,prevState){
        let href = this.props.location.pathname.split('/');
        if((prevProps.currancyCode !== this.props.currancyCode) ){

        }
        // if(this.props.products.length===0 ){    
        //  this.props.history.replace("/404")
        // }
        if(prevProps.products !== this.props.products){
            this.setState({
                categories: this.props.categories,
                data: this.props.products?.map(val => val.products)
            })
        }
        if( !this.props.loading && this.props.products &&  this.props.products.length=== 0){
            this.props.history.push({
                pathname:'/404',
                redirectionURL:'/gifts'
            })         
        }
        
        // if(href.length>2 && typeof href.split('/')[2] != 'number'){
        // }
    }
    setFromPoint=(val)=>{
        this.setState({
            fromPoint:val
        })
    }

    setToPoint=(val)=>{
        this.setState({
            toPoint:val
        })
    }

    // fetchBrandHandler = (id) => {
    //     const payload = {
    //         "brands": {
    //             "id":id,
    //             "currency": this.props.countrySelected
    //         }
    //     }
    //     this.props.fetchBrand(payload)
    // }

  
    addActiveClass(elem) {
        let div = document.getElementsByClassName('category')[0]
        div.classList.remove('color-skyblue','category')
        elem.classList.add('category','color-skyblue');
    }
    render() { 
        if(this.props.filteredCards.length>0 && !this.state.filteredCards){
            this.setState({filteredCards:true})
        }
        const {currentCategory,brands,establisdhments} = this.state
        const{t}=this.props
        const {categories} = this.state
        const breadcrumbs =[
            {name:t('Home'),url:'/',active:true},
            {name:t('Gifts'),url:'/gifts',active:false},
            // {name: this.state.categoryName && this.state.categoryName,url:'',active:false}
        ] 
        let data = this.props.products;
       
        if(this.state.filteredCards){
            data = this.props.filteredCards   
        }
        
        
        
        const desktopView = (
            <div className="d-md-block d-none container-fluid p-0 pb-5">
                <div className="d-flex">
                    <div className="w-100">
                
                
                <Breadcrumbs crumbs={breadcrumbs}/>
                <div className="d-flex p-5 gap-5">
                    <div className='col-2 p-0'>
                        <div className='border-bottom fw-bold '>
                            <span className='fs-5 headings fw-bold'>{t("Categories")}</span>
                            <div className='text-muted d-flex flex-column gap-2 py-3'>
                                
                                
                                <Link to="/gifts"  className='category color-skyblue' onClick={()=>{
                                        this.props.sortMerchandiseCards(this.props.categories,"Default","All",true);this.setState({filteredCards:true,categoryName:false});
                                    }}>{t("All Products")}</Link>
                                {
                                   this.props.categories?.map(category => <Link
                                      to={`/gifts/${category.name}`}
                                        onClick={(event)=>{
                                        this.props.sortMerchandiseCards(this.props.categories,"Default",category.name,true);this.setState({filteredCards:true,categoryName:category.name});
                                        this.addActiveClass(event.target);
                                        
                                    }
                                        
                                    }>{t(category.name)}</Link>) 
                                }
                            </div>
                        </div>
                        <div className='border-bottom fw-bold py-4'>
                            <span className='fs-5 headings fw-bold'>{`${t("Points")}`}</span>
                            <div className='text-muted d-flex gap-2 py-3 align-items-center justify-content-between'>
                                <input 
                                onChange={(e)=>{this.setFromPoint(e.target.value)}}
                                value={this.state.fromPoint}
                                onWheelCapture={e => {e.target.blur()}}
                                type="number" min='0' className='p-2 border' style={{width:'5rem'}}/>
                                    {t("to")}
                                <input
                                onChange={(e)=>{this.setToPoint(e.target.value)}}
                                value={this.state.toPoint}
                                min="1"
                                onWheelCapture={e => {e.target.blur()}}
                                type="number" className='p-2 border' style={{width:'5rem'}}/>
                                <button  onClick={()=>{
                                        this.props.sortMerchandiseCards(this.state.filteredCards? this.props.filteredCards : this.props.products,"Default",this.props.categoryName,false,{from:(this.state.fromPoint),to:(this.state.toPoint)});this.setState({filteredCards:true});
                                    
                                    }} type="button" class="btn primary-btn fw-bold">{t("Go")}</button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{minWidth:'70vw'}} className='d-flex justify-content-between'>
                            <div className='pb-4'>
                                <span className='fs-3 headings fw-bold'>{t("Merchandise")}</span>
                                <span className='fs-5 text-muted'> - {t("All")}</span>
                            </div>
                            <div >
                                
                                <Dropdown className='d-inline' >
                                    <DropdownToggle style={{width:'8rem'}} className='text-light rounded-0 mx-3 py-2 px-0 bg-white no-outline'>
                                        <span className='headings fw-bold float-left px-3'>{t(this.props.sortBy)}</span>
                                        <span className='arrow-down color-skyblue float-right'></span>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={()=>{this.props.sortMerchandiseCards(this.props.products,"Default",this.props.categoryName,false);this.setState({filteredCards:true})}}>{t("Default")}</DropdownItem>
                                        <DropdownItem onClick={()=>{this.props.sortMerchandiseCards(this.state.filteredCards? this.props.filteredCards : this.props.products,"Points",this.props.categoryName,false);this.setState({filteredCards:true})}}>{t("Points")}</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="d-grid grid-cols-md-4 grid-cols-2 gap-md-3 gap-2 py-3">
                            {
                              data  &&  data.map( (card,id)=>(
                                
                                    <ProductGiftsCard  card={card} key={id} lang={this.props.lang}/>
                                ))
                            }
                       </div>
                    </div>
                </div>
                    </div>
              </div>
                    </div>
        )
        
        const mobileView=(
            <div className="d-block d-md-none container-fluid px-0 py-3 mb-2"  style={{bottom:'4rem'}}>
                <div className="d-flex">
                    <div className="w-100">

                    <div className='pb-4 px-4'>
                             <span className='fs-3 headings fw-bold'>{t("Merchandise")}</span>
                             <span className='fs-5 text-muted'> - {t("All")}</span>
                               <div  className='text-right'>
                              
                               {/* <span>
                               <span className="mr-5">Sort By</span><br/>
                                
                               </span> */}
                             <div className="d-flex text-right justify-content-end">


                             <div className="py-3">
               <div style={{maxWidth:"50px", maxHeight:"50px"}} className={this.props.lang !== 'ar' ? 'mr-3' : 'ml-3'}  id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
               <img src={filterIcon} style={{height:"2.7rem"}} alt="categorie" className="img-fluid"/>
               </div>
               <div className="dropdown-menu " aria-labelledby="dropdownMenuButton">   
               {
                                   this.props.categories?.map(category => <Link
                                        to={`/gifts/${category.name}`}
                                        className=" dropdown-item" onClick={()=>{
                                        this.props.sortMerchandiseCards(this.props.categories,"Default",category.name,true);this.setState({filteredCards:true});
                                    
                                    }                                  
                                    }>{t(category.name)}</Link>) 
                                }          
                  </div>
                </div>
                             <div className='text-muted d-flex flex-column gap-3 py-3'>
                            
                             <Dropdown className='d-inline' >
                                    <DropdownToggle style={{width:'8rem'}} className='text-light rounded-0  py-2 px-0 bg-white'>
                                        <span className='headings fw-bold float-left px-3'>{t(this.props.sortBy)}</span>
                                        <span className='arrow-down color-skyblue float-right'></span>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                    <DropdownItem onClick={()=>{this.props.sortMerchandiseCards(this.props.products,"Default",this.props.categoryName,false);this.setState({filteredCards:true})}}>{t("Default")}</DropdownItem>
                                        <DropdownItem onClick={()=>{this.props.sortMerchandiseCards(this.state.filteredCards? this.props.filteredCards : this.props.products,"Points",this.props.categoryName,false);this.setState({filteredCards:true})}}>{t("Points")}</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                             </div>
                            </div>
                            </div>                  
                     </div>
                     
                     {/* <div className='border-bottom fw-bold '>
                            <span className='fs-5 headings fw-bold filter-green'>
                            <img src="https://img.icons8.com/ios/24/000000/drag-list-down.png"/>
                            </span>
                            <div className='text-muted d-flex flex-column gap-2 py-3'>
                               
                            </div>
                        </div> */}

                    
                    <div className="py-md-4 pt-3 d-grid grid-cols-md-4 grid-cols-2 gap-md-5 gap-2 px-3 content-box">
                    {
                              data &&  data.map( (card,id)=>(
                                
                                    <ProductGiftsCard card={card} key={id} lang={this.props.lang} />
                                ))
                            }
                </div>

                    </div>
                </div>
            </div>
        )
        const filterModal=(
            <div className="d-md-none d-block position-fixed bg-light w-100 " style={{zIndex:'1500',bottom:'4rem'}}>
            { this.state.showNav &&
             <div className="bg-light overflow-auto py-2" style={{zIndex:'1500',maxHeight:'calc(100vh - 6rem)'}}>
                 <div className="py-5  d-flex justify-content-start">
                     {/* <button className="btn" onClick={()=>{this.toggleNav(false)}}
                     >
                         {/* <svg height="20" width="20" className="bg-secondary">
                             <line x1="5" y1="5" x2="15" y2="15" stroke="#eee" strokeWidth="2" strokeLinecap="round"/>
                             <line x1="15" y1="5" x2="5" y2="15" stroke="#eee" strokeWidth="2" strokeLinecap="round"/>
                         </svg> */}
                     {/* </button> */} 
                     <div className="col-2 fs-3 cursor-pointer color-skyblue text-right" onClick={()=>{this.toggleNav(false)}} style={{transform:'rotate(180deg)'}}>
                        <section >➜</section>
                    </div>
                 </div>           
                          <div className='text-muted d-flex flex-column gap-3 border-bottom py-4  px-3 mt-0 pt-0'>
                          <span className='fs-5 headings fw-bold px-3'>{t("Categories")}</span>
                                {
                                    this.props.categories?.map(category => <Link className="pl-5" onClick={()=>{
                                        this.props.sortMerchandiseCards(this.props.categories,"Default",category.name,true);this.setState({filteredCards:true});
                                    
                                    }
                                        
                                    }><h6>{category.name}</h6></Link>) 
                                }
                            </div>
                            <div className='border-bottom fw-bold  px-3 py-4 '>
                            <span className='fs-5 headings fw-bold px-3'>{t("Brands")}</span>
                            <div className='text-muted d-flex flex-column gap-3 py-3 position-relative'>
                                {
                                    brands.map(brand => (
                                        <div className='d-flex align-items-center gap-2 pl-4'>
                                            <input className='d-inline' type="checkbox" />
                                            <span className=''>{t(brand)}</span>
                                        </div>
                                    )) 
                                }
                            </div>
                         </div>
                         <div className='border-bottom fw-bold py-4 px-3'>
                            <span className='fs-5 headings fw-bold px-3'>{t("Price")}(SAR)</span>
                            <div className='text-muted d-flex gap-2 py-3 align-items-center px-3 '>
                                <input type="number" className='p-2 border' style={{width:'5rem'}}/>
                                    {t("to")}
                                <input type="number" className='p-2 border' style={{width:'5rem'}}/>
                                <span className="btn primary-btn fw-bold">{t("Go")}</span>
                            </div>
                        </div>
                        <div className='border-bottom fw-bold px-3 py-4 '>
                            <span className='fs-5 headings fw-bold px-3'>Establishment Type</span>
                            <div className='text-muted d-flex flex-column gap-3 py-3 position-relative'>
                                {
                                    establisdhments.map(establisdhment => (
                                        <div className='d-flex align-items-center gap-2 px-3'>
                                            <input className='d-inline' type="checkbox" />
                                            <span className=''>{establisdhment}</span>
                                        </div>
                                    )) 
                                }
                            </div>
                        </div>
                           
                            <div className="px-5 py-3">
                            <button type="button" className="btn btn-block btn-primary rounded-0 text-center"onClick={()=>{this.toggleNav(false)}}  >{t("Apply Filters")}</button>
                            </div>
                </div>
            }
            
            </div>
        )
        return ( 
            <>
             { this.props.loading ? 
                <section>
                    <div class="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </section> :
                <>
                 {desktopView}  
                {mobileView}
                {/* {filterModal} */}
                </>
            }
                {/* {desktopView} 
                {mobileView} */}
            </>            
         );
    }
}

const mapStateToProps = state => {
    return {
        loading:state.merchandise.loading,
        sortBy:state.merchandise.sortBy,
        filteredCards: state.merchandise.filteredCards,
        countrySelected:state.countries.countryId,
        categoryName:state.merchandise.categoryName,
        products:state.merchandise.products,
        categories:state.merchandise.categories,
        currancyCode:state.countries.convertionRate.currency_code,
        countryId: state.countries.countryId,
        fetchError:state.merchandise.error
    }
}


const mapDispatchToProps = dispatch => {
    return {
        // fetchProductsList : (payload) => dispatch(fetchProductsList(payload)),
        sortMerchandiseCards:(data,sortBy,categoryName,onlyCategory,onlyPointsFilter) => dispatch(sortMerchandiseCards(data,sortBy,categoryName,onlyCategory,onlyPointsFilter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GiftsPage));
