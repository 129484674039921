import React, { Component } from 'react'
import { Link } from "react-router-dom";
import GoalItemModuleModal from "./GoalItemModal";
import { ReactComponent as RemindIcon } from "../../assests/img/goalitems/icon-remind-me-later.svg";
import {ReactComponent as ChangeIcon} from '../../assests/img/goalitems/icon-change-goal-item-green.svg'

import { connect } from "react-redux";
import { currancyToPoints } from "../../utils/pointsConversion";
import GoalItemRemindModal from "./GoalItemRemindModal";
import RemoveGoalItemModal from "./RemoveGoalItemModal";
import axios from "axios";
import secretKeyGenerator from "../../utils/serectKeyGenerator";
import store from "../../Redux/store";
import { fetchGoalItems } from '../../Redux/goalItems/goalItemsActions';
import { withTranslation } from 'react-i18next';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';  
import 'react-circular-progressbar/dist/styles.css';
import calendarIcon from '../../assests/img/experiences/icon-calendar-white.svg'
import UpdateGoalItemDateModal from './UpdateGoalItemDateModal';
import { REACT_APP_baseUrl } from 'utils/app-constants';


class GoalItemsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      remindShow: false,
      removemodal: false,
      processing: false,
      msg: "",
      deletStatus: false,
      confirmModal:false,
      failedModal: false,
      updateDate:{
        showModal:false,
        selectDate:new Date(),
        apiLoading:false
      }
    };
  }
  setModalView = () => {
    this.setState({
      show: false,
      removemodal: false,
      confirmModal:false,
      failedModal: false
    });
  };
  showModal =()=>{
    this.setState ({
      show:true
    })
  }
  setRemindView = (props) => {
    this.setState({
      remindShow: false,
    });
  };
  removeGoalItem = () => {
    this.setState({
      processing: true,
    });
    let payload = {
      customer_goal_item: [
        {
          product_id: this.props.goalItemData.product_id,
          product_type: this.props.goalItemData.product_type,
          product_price: this.props.goalItemData.product_price,
          goal_item: false,
        },
      ],
    };
    axios.post(`${REACT_APP_baseUrl}/customer_goal_items`, payload, {
      headers: {
        Authorization: `Bearer ${store.getState().auth.authToken}`,
      },
      params: {
        secret_key: secretKeyGenerator(payload),
      },
    })
    .then((response)=>{
        if(response?.data?.code === 200){
            const msg = response?.data?.message
            this.setState ({
              msg:msg ,
                processing: false,
                confirmModal: false,
                deletStatus:true,
            })
              this.props.fetchGoalItem()
        }
        else {
          this.setState ({
            failedModal:true,
            msg:response?.data?.message ,
            processing: false,
            confirmModal:false,

          })
       }      
    })
    .catch(error => {
      const errorMsg = "Something went wrong !"
      this.setState ({
        msg:errorMsg ,
        processing: false,
        failedModal:true,
        confirmModal:false,

      })
  })
  };

  moduleRedirectAPI = () => {
    switch(this.props.goalItemData?.child_product_type){
      case 'GIFTCARD': return 'selectedgiftcard';
      case 'PHYSICAL_GIFT_CARD': return 'physicalgiftcard';
      case 'OFFERS': return 'offer';
      case 'OTHERS': return 'gift';
      case 'EXPERIENCES': return 'experience';
      case 'EMAGAZINE': return 'e-magazines';
      case "SOFTWARE_AND_GAME" : 
      if( this.props.goalItemData.type  == 'SOFTWARE'){return 'softwares'}
      else if(this.props.goalItemData.type  == 'GAME'){return 'games'};
      break
      default: return ''
      
    }
  }

  myWalletRedirection(){
    switch(this.props.goalItemData?.child_product_type){
      case 'GIFTCARD': return 'giftCards';
      case 'PHYSICAL_GIFT_CARD': return 'physicalGiftcards';
      case 'OFFERS': return 'offers';
      case 'OTHERS': return 'merchandise';
      case 'EXPERIENCES': return 'experience';
      case 'EMAGAZINE': return 'eMagazines';
      case "SOFTWARE_AND_GAME" : 
      if( this.props.goalItemData.type  == 'SOFTWARE'){return 'software'}
      else if(this.props.goalItemData.type  == 'GAME'){return 'games'};
      break
      default: return ''
    }
  }

  componentDidMount(){
    this.props.fetchGoalItem()
  }

  updateGoalDate=(date) =>{
    this.setState({updateDate:{
      showModal:true,
      selectDate:date
    }})
  }

  

  handleUpdateGoalDate = () => {
    this.setState({updateDate: {...this.state.updateDate,apiLoading:true}})
    const {goalItemData} = this.props
    let payload = {
      "customer_goal_items": {
          "product_id": goalItemData.product_id,
          "product_type": goalItemData.product_type,
          "product_price": goalItemData.product_price,
          "remind_me_later_date": new Date(this.state.updateDate.selectDate)?.toISOString()?.slice(0,10)
      }
  }   

    if(goalItemData.product_type === 'MERCHANT_PRODUCT'){
      
      payload = {
        "customer_goal_items":{
          ...payload.customer_goal_items,
          "product_color":goalItemData.product_color,
          "product_storage":goalItemData.product_storage,
        }
      }
    }
    
    axios({
      method: 'POST',
      url:`${REACT_APP_baseUrl}/customer_goal_items/update_customer_goal`,
      data:payload,
      headers:{"Authorization":`Bearer ${store.getState().auth.authToken}`},
      params:{"secret_key":secretKeyGenerator(payload)}
  })
    .then((response)=>{
      this.props.fetchGoalItem()
      this.setState({updateDate: {...this.state.updateDate,apiLoading:false,showModal:false}})
    })
    .catch(error => {
      this.setState({updateDate: {...this.state.updateDate,apiLoading:false,showModal:false}})
    })
  }

  render() {
    const { bannerFlag, goalItemData,enableGoalItem, t ,currancyCode,pointBalance ,goalItemLoading } = this.props;
    const {updateDate} = this.state;
    const Decimal = Number(( pointBalance / (goalItemData?.child_product_type === "PHYSICAL_GIFT_CARD" ? goalItemData?.product_point_price : currancyToPoints(goalItemData?.product_price)))).toFixed(2)
   const calcPercentage = (Decimal *100)?.toFixed(0)
   const targetDate = goalItemData.target_date
  let  date= new Date().toISOString().slice(0, 10)
  const diffInMs   = new Date(targetDate) - new Date(date)
  const remaindays = diffInMs / (1000 * 60 * 60 * 24);
    return (
          <>
           <div className="px-md-5 px-md-3 py-3 mx-auto">
            <UpdateGoalItemDateModal t={t} show={updateDate.showModal}
             changeDate={this.updateGoalDate}
             currentDate={updateDate.selectDate}
             onSubmit={this.handleUpdateGoalDate}
            />
          <GoalItemModuleModal
            show={this.state.show}
            setModalView={this.setModalView}
            displayModal={this.state.displayModal}
          />
          <GoalItemRemindModal
            show={this.state.remindShow}
            setModalView={this.setRemindView}
          />
          <RemoveGoalItemModal
            show={this.state.removemodal}
            lang={this.props.lang}
            setModalView={this.setModalView}
            card={this.props.goalItemData}
            removeGoalItem={this.removeGoalItem}
            processing={this.state.processing}
            msg = {this.state.msg}
            deletStatus={this.state.deletStatus}
            confirmModal={this.state.confirmModal}
            showModal={this.showModal}
            failedModal={this.state.failedModal}
            currancyCode={this.props.currancyCode}
          />
          {goalItemLoading ?
          <section>
                <div class="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4 ">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
        </section>
          :
          <>
          {bannerFlag && goalItemData?.length === 0 && enableGoalItem &&  (
            <>
              <div
                onClick={() => this.setState({ remindShow: true })}
                className="btn py-1 color-green fw-bold d-flex justify-content-end"
                style={{alignItems:"center"}}
              >
                <RemindIcon style={{ height: "15" }} className="remind-icon " />{" "}
                <div className="px-1">{t("Remind me later")}</div>
              </div>
              <div className="d-flex bg-color-blue px-3 d-flex goal-banner" >
                <div 
               className={`goalItemBanner d-flex justify-content-center align-items-center col-6 col-lg-6 ${this.props.lang !== 'ar' ? '' : 'goalItemBannerArabic'}`}
                >
                  <div 
                   className={`py-5 mb-5 text-center fs-md-5 fs-7 ml-5 ${this.props.lang !== 'ar' ? '' : 'goaltexts'}`}
                  >
                    {t("You haven't chosen a")}<br />
                    <b> {t("Goal item yet")}</b>
                  </div>
                </div>  
                <div className="d-flex flex-column justify-content-center my-3 py-3 col-6 col-lg-6">
                  <div className="goalItemBorder d-flex align-self-start mt-1"></div>
                  <div className="d-flex align-items-center p-3 mx-md-2 text-center">
                    <p>
                     {t("Set an item from different categories as your goal item and track your progress against it")}
                    </p>
                  </div>
                  <div className="goalItemBorder d-flex align-self-end my-1"></div>
                  <button
                    className="btn primary-btn align-self-center d-none d-md-block no-outline"
                    onClick={() => this.setState({ show: true })}
                  >
                  {t("Set as Goal Item")}
                  </button>
                  <Link
                    to="goal-item"
                    className="align-self-center d-md-none d-block mt-2"
                  >
                    <button
                      className="btn primary-btn no-outline"
                      onClick={() => this.setState({ show: true })}
                    >
                     {t("Set as Goal Item")}
                    </button>
                  </Link>
                </div>
              </div>
            </>
          )}
          {goalItemData?.length !== 0 && enableGoalItem && (
            <>
              <div className="btn py-1 color-green fw-bold d-flex justify-content-end" style={{alignItems:"center"}}>
                <ChangeIcon style={{ height: "15" }} className="remind-icon " />{" "}
                <div
                  className="px-1"
                  onClick={() => this.setState({ removemodal: true , confirmModal: true})}
                >
                 {t("Change goal Item")}
                </div>
              </div>
              <div className="d-flex goal-banner">
                <div className="d-flex flex-column bg-color-blue justify-content-center py-3 col-6 col-lg-6 ">
                  <div className="goalItemBorder d-flex align-self-start mt-1"></div>
                  <div className=" align-items-center p-3 mx-md-2 text-center">
                    {
                      pointBalance >= (goalItemData?.child_product_type === "PHYSICAL_GIFT_CARD" ? goalItemData?.product_point_price : currancyToPoints(goalItemData?.product_price)) && !goalItemData.purchased_flag && <>
                        <div>
                          <h5>{t("You can now purchase this item for")}{" "}</h5>
                          <span className="fs-5">
                            {goalItemData?.child_product_type === "PHYSICAL_GIFT_CARD" ? goalItemData?.product_point_price : currancyToPoints(goalItemData?.product_price)}
                          </span>{" "}<h5 className='d-inline-block'>{t("Points")}{" "}</h5>
                        </div>
                    <br />
                    <h5>{t("Congratulations!")}</h5>
                      </>
                    }
                    {
                     pointBalance < (goalItemData?.child_product_type === "PHYSICAL_GIFT_CARD" ? goalItemData?.product_point_price : currancyToPoints(goalItemData?.product_price)) && !goalItemData.purchased_flag && <>
<div className=' d-flex justify-content-center' >
                      <div style={{ width: 200, height: 120 }}>

                   
          <CircularProgressbar className=' text-center' value={calcPercentage} text={`${calcPercentage}% `}  circleRatio={0.6} 
           styles ={{
            trail: {
              strokeLinecap:'butt',
              transform: 'rotate(-108deg)',
              transformOrigin: 'center center',
            },
            path: {
              strokeLinecap:'butt',
              transform: 'rotate(-108deg)',
              transformOrigin: 'center center',
              pathColor:"red",
              stroke: "#00c2ab"
            },
            text: {
              fill: '#00c2ab',
              fontSize:"1em"
            }

          }}
          />
             </div>
                  </div>
                        <div className='mt-3'>
                          <h5>{t("You need")}{" "}</h5>
                          <span className="fs-5">
                            {((goalItemData?.child_product_type === "PHYSICAL_GIFT_CARD" ? goalItemData?.product_point_price : currancyToPoints(goalItemData?.product_price)) - pointBalance)?.toFixed(2)}
                          </span>{" "}<h5 className='d-inline-block'>{t("more points to purchase your goal item")}{" "}</h5>
                        </div>
                       
                    <br />
                    <div className="text-right ">
                    <p className='mb-0' style={{opacity:"0.8"}}> <span className='mr-1'>
                      <img src={calendarIcon} alt="" width="18" /></span> {remaindays == 0 ? <span className='cursor-pointer' onClick={() => {this.setState({updateDate:{...this.state.updateDate,showModal:true}})}}>{t("Update")}</span> : `${t("Days Remaining")} ${remaindays}`}</p>
                    </div>
                      </>
                    }
                    {
                      goalItemData.purchased_flag && <>
                      <div>
                        <h5>{t("You have purchased this item for")}{" "}</h5>
                        <span className="fs-5">
                          {currancyToPoints(goalItemData?.product_price)}
                        </span>{" "}<h5 className='d-inline-block'>{t("Points")}{" "}</h5>
                      </div>
                  <br />
                  <h5>{t("Congratulations!")}</h5>
                    </>
                    }
                  </div>
                  <div className="goalItemBorder d-flex align-self-end my-1"></div>
                </div>
                <div className="col-md-6 col-6 border no-gutters pl-0 text-md-left text-center " >
                  <div className=" justify-content-center">
                  <div className="d-md-flex d-block px-md-5 pt-5 pb-2 justify-content-center top-50 mt-5">
                    <div>
                      <img
                        src={
                          goalItemData?.images?.color?.small_rectangle ||
                          goalItemData?.images?.small_rectangle
                        }
                        width="150"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="mt-3 px-3">
                      <h5>{this.props.lang !== 'ar' ? goalItemData?.product_name :goalItemData?.product_name_arabic}</h5>
                      {t("Card Value")} :{" "}
                      <span className="fw-bold">
                        {t(goalItemData?.child_product_type === "PHYSICAL_GIFT_CARD" ? goalItemData?.currency :currancyCode)} {goalItemData?.product_price} ({" "}
                        {goalItemData?.child_product_type === "PHYSICAL_GIFT_CARD" ? goalItemData?.product_point_price :currancyToPoints(goalItemData?.product_price)} {t("Points")} )
                      </span>
                    </div>
                  </div>
                  <div className="justify-content-center d-md-flex pb-3">
                    <Link
                      to={!goalItemData.purchased_flag ? `${this.moduleRedirectAPI()}/${goalItemData.product_id}` : `mywallet/${this.myWalletRedirection()}`}
                      className="align-self-center mt-2"
                    >
                      <button className="btn primary-btn no-outline">
                        {t("View Goal Item")}
                      </button>
                    </Link>
                  </div>
                  </div>
                </div>
              </div>
            </>
          )}
          </>
  }
        </div>
          </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    bannerFlag: state.goalItem.goalItemData?.promotional_banner_flag,
    goalItemData: state.goalItem.goalItemData?.goal_items?.[0] || [],
    enableGoalItem: state.countries.convertionRate?.enable_goal_item_feature,
    currancyCode:state.countries.convertionRate.currency_code,
    pointBalance:Number(state.point.pointBalance),
    goalItemLoading:state.goalItem?.fetchLoading
  };
};
const mapDispatchToProps = dispatch => {
    return {
        fetchGoalItem:() => dispatch(fetchGoalItems())
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(GoalItemsSection));
