import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal'
import {ReactComponent as GiftcardImg} from '../../assests/img/module-icons-white/icon-module-gift-cards.svg'
import {ReactComponent as OffersImg} from '../../assests/img/module-icons-white/icon-module-hassad-offers.svg'
import {ReactComponent as GiftsImage}  from '../../assests/img/module-icons-white/icon-module-hassad-gifts.svg'
import {ReactComponent as MagazineImage} from '../../assests/img/module-icons-white/icon-module-e-magazines.svg'
import {ReactComponent as ExpImage} from '../../assests/img/module-icons-white/icon-module-experiences.svg'
import {ReactComponent as GamesImage} from '../../assests/img/module-icons-white/icon-module-games.svg'
import {ReactComponent as SoftwaresImage} from '../../assests/img/module-icons-white/icon-module-softwares.svg'

class GoalItemModuleModal extends Component {
    
    render() { 
        const {t}=this.props;
        return ( 
            <Modal show={this.props.show} size="lg" centered onHide={this.props.setModalView}>
                <div 
                    className="p-4 bg-light d-none d-md-block">
                    <div className='color-blue d-inline-block fw-bold'><h5>{t("Choose a module to set goal item")}</h5></div>
                    <div class="container">
                        <div class="row my-2">
                            <div class="col">
                                <Link to='/giftcards'>
                                <div className="d-flex flex-column align-items-center bg-color-green p-4 rounded text-light">
                                <GiftcardImg style={{width:'30%'}} height="50" />
                                <h5>{t("Digital Gift Cards")}</h5>
                                </div>
                                </Link>
                            </div>
                            <div class="col">
                                <Link to='/physicalgiftcards'>
                                <div className="d-flex flex-column align-items-center bg-color-green p-4 rounded text-light">
                                <GiftcardImg style={{width:'30%'}} height="50" />
                                <h5>{t("Physical Gift Cards")}</h5>
                                </div>
                                </Link>
                            </div>
                            <div class="col">
                                <Link to='/offers'>
                                <div className="d-flex flex-column align-items-center bg-color-green p-4 rounded text-light">
                                <OffersImg style={{width:'30%'}}height="50" />
                                <h5>{t("Offers")}</h5>
                                </div>
                                </Link>
                            </div>
                        </div>
                        <div class="row my-2">
                        <div class="col">
                                <Link to='/gifts'>
                                <div className="d-flex flex-column align-items-center bg-color-green p-4 rounded text-light">
                                <GiftsImage style={{width:'30%'}}  alt={GiftsImage} height="50" />
                                <h5>{t("Merchandise")}</h5>
                                </div>
                                </Link>
                            </div>
                            <div class="col">
                                <Link to='/e-magazines'>
                                <div className="d-flex flex-column align-items-center bg-color-green p-4 rounded text-light">
                                <MagazineImage style={{width:'30%'}}  alt={MagazineImage}height="50" />
                                <h5>{t("E-Magazines")}</h5>
                                </div>
                                </Link>
                            </div>
                            <div class="col">
                                <Link to='/experiences'>
                                <div className="d-flex flex-column align-items-center bg-color-green p-4 rounded text-light">
                                <ExpImage style={{width:'30%'}} alt={ExpImage}height="50"  />
                                <h5>{t("Experiences")}</h5>
                                </div>
                                </Link>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <Link to='/softwares'>
                                <div className="d-flex flex-column align-items-center bg-color-green p-4 rounded text-light">
                                <SoftwaresImage style={{width:'30%'}} alt={SoftwaresImage}  height="50"/>
                                <h5>{t("Softwares and Games")}</h5>
                                </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
         );
    }
}

export default withTranslation() (withTranslation() (GoalItemModuleModal));