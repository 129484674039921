import React, { Component } from 'react';
import  cardImg from '../../../assests/img/card.svg'
import { logoHandler } from "utils/Theme/Logo-handler";
import ModalWrapper from './ModalWrapper';
import { Redirect, withRouter } from 'react-router-dom';
import {fetchOrderDetailsSuccess, postOrderCreation, removeOrder} from '../../../Redux/order/orderActions'
import { connect } from 'react-redux';
import axios from 'axios';
import secretKeyGenerator from '../../../utils/serectKeyGenerator';
import FailMessageModal from './FailMessageModal';
import { withTranslation } from 'react-i18next';
import MasterCard from "../../../assests/img/MasterCard.png";
import Visa from "../../../assests/img/Visa.png";
import removeIcon from '../../../assests/img/delete.svg'
import {fetchCurrencyConversion} from '../../../Redux/conversion/conversionAction'
import { REACT_APP_baseUrl } from 'utils/app-constants';
import { currancyToPoints, otherPointsToCurrancy } from 'utils/pointsConversion';
import ShippingAddressPage from 'components/PaymentFlow/ShippingAddressPage';


 
class PhysicalCardConfirmantionModal extends Component {
    constructor(props) {
        super(props);
        this.state={
            orderDetails:{
                total:this.props.orders.amount,
                payable_amount:this.props.orders.amount
            },
            redirectToPayment:false,
            checkoutBtn:true,
            paymentFail:false,
            errorMsg:" ",
            loading:false,
            convertedCurrency:this.props.orders.amount,
            selectedCurrency:"AED",
            code:[],
            pointsCurrancy:null,
            convertedPointsBalance:null,
            showShippingPage:true,
            orders:{},
        }
    }
    
    postOrderHandler(){
        const { pointBalance, giftcard_points,cardPayment } = this.props;
        const {orders,selectedCurrency,pointsCurrancy} = this.state
        if(pointBalance || cardPayment){
            const missingPoints = giftcard_points - pointBalance; 
            if (missingPoints > 0 || cardPayment) {
                orders["stripe_payment_currency"] = selectedCurrency;
                orders["amount"] = pointsCurrancy
                this.setState({ redirectToPayment: true })
                this.setState({ loading: true })
            }
            else{
            const payload ={"orders":orders}

            axios.post(`${REACT_APP_baseUrl}/merchant_product_orders/create_physical_giftcard_order`,
            payload,{
                headers:{
                    "Authorization":`Bearer ${this.props.authToken}`
                },
                params:{
                    "secret_key":secretKeyGenerator(payload)
                }
            })
                .then(response  => {
                
                    if(response.data.code === 200){
                        const orderDetails = response.data.data.order;
                        this.props.history.push(`/payment/merchandise-orders-summary/${orderDetails.id}`)
                        
                        
                    }
                    else{
                    this.setState({
                        paymentFail:true,
                        errorMsg:response.data?.message
                    
                    })
                    }
                })
                .catch(error => {
                    this.setState({
                        paymentFail:true,
                        errorMsg:"Something went wrong!! Please try again!!"
                    })
                })
                this.setState({loading:true})
        }
        }
        else{
            this.setState({
                paymentFail:true,
                errorMsg:"Points Redemption Failed"
            })
        }
        
    }
    setCheckoutBtn(val){
        this.setState(old=>({
            checkoutBtn:!old.checkoutBtn
        }))
    }
    removeOrderhandler=()=>{
        this.props.remove(false)
        this.props.removeOrder()
    }
    setShippingPage = (dataFromChild) => {
        this.setState(prevState => ({
            showShippingPage:false,
            orders:{
                ...this.props.orders,
                  "first_name": dataFromChild.firstName,
                  "last_name": dataFromChild.lastName,
                  "email": dataFromChild.email,
                  "address_country": dataFromChild.country,
                  "address_state": dataFromChild.state,
                  "address_city": dataFromChild.city,
                  "zip_code": dataFromChild.zip,
                  "address_street1": dataFromChild.address,
                  "phone_number": dataFromChild.phNumber,
            }
        }))
    }
    // numberWithCommas = (x) => {
    //     let num = +x;
    //     let y = num.toFixed(0)
    //     return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // }
    numberWithCommas = (x) => {
        return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
    numberWithCom = (x) => {
        let num = +x;
        let y = num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    currencyConversion =(event)=>{
        let payload = {
            "giftcard_units": {
                "currency": event.target.value
                 }
        }
            this.props.fetchCurrencyConversion(payload)
            this.setState({selectedCurrency:event.target.value})
    }

    componentDidMount(){

            let payload

            if(Object.values(this.props.acceptedCurrencies).some(e => e.unit_name_short === this.props.currancyCode)){
                payload = {
                    "giftcard_units": {
                        "currency": this.props.currancyCode
                    }
                }
                this.setState({selectedCurrency:this.props.currancyCode})
            }else{
                payload = {
                    "giftcard_units": {
                        "currency": "AED"
                    }
                }           
            }
            
            this.props.fetchCurrencyConversion(payload)
            // if(Object.values(this.props.acceptedCurrencies).some(e => e.unit_name_short === this.props.currancyCode)){
            //     this.setState({convertedCurrency:this.props.orders?.giftcard_value})
            // }else{
            //     this.setState({convertCur:true,convertCur2:false})
            // }
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.currencyConversonLoading !== this.props.currencyConversonLoading){

            let total = this.props.giftcard_points;
            let missingPoints = (this.props.giftcard_points - this.props.pointBalance).toFixed(2)
            let convertedPointsBalance = (this.props.pointBalance / this.props.convertedCurrency?.currency_exchange_rate).toFixed(2);
            if(missingPoints > 0 && !this.props.cardPayment) {
                total = otherPointsToCurrancy(missingPoints,true);
            }else if(this.props.cardPayment) total = otherPointsToCurrancy(total,true);
            if(this.state.selectedCurrency === this.props.currancyCode){
                // For Available Currencies
                const payableAmount = total
                if(this.props.minimum_payable_amount > payableAmount){
                    this.setState({pointsCurrancy:this.props.minimum_payable_amount,convertedPointsBalance:convertedPointsBalance})
                }else{
                    this.setState({pointsCurrancy:payableAmount,convertedPointsBalance:convertedPointsBalance})
                }
            }else{
                // For other currencies which is not avaible
                const payableAmount = total
                const markUp = (payableAmount * 0.05) + Number(payableAmount)
                if(this.props.minimum_payable_amount > markUp){
                    this.setState({pointsCurrancy:this.props.minimum_payable_amount,convertedPointsBalance:convertedPointsBalance})
                }else{
                    this.setState({pointsCurrancy:markUp.toFixed(2),convertedPointsBalance:convertedPointsBalance})
                }
            }
        }
    }
    render() { 
       
        const {t,card,orders,currancyCode,pointBalance,acceptedCurrencies, GiftCardMobile,GiftCardMobileCode, GiftCardEmail, GiftCardQuantity, GiftCardMessage, url, cardPayment, physicalCard} = this.props;
        
        const {checkoutBtn,paymentFail, errorMsg,loading,convertedCurrency,selectedCurrency,pointsCurrancy,convertedPointsBalance,showShippingPage} = this.state;
        const total = this.state.orderDetails.total;
        const missingPoints = (this.props.giftcard_points - pointBalance).toFixed(2)
        const desktopOrderSummaryCard=(
            <div className="d-md-block d-none">
                <FailMessageModal show={paymentFail} lang={this.props.lang} Message={errorMsg}/>
                <div 
                    className="card mb-4 rounded-0"
                    style={{width:'18rem'}}>
                    <div className="card-body">
                    <div className="fs-4 fw-bold py-md-0 py-3 m-0 mt-md-0 mt-5  headings">{t("Order Summary")}:</div>
                    {
                        (cardPayment || missingPoints > 0) &&
                    <div className='d-flex flex-row justify-content-between'>
                    {
                    (this.props.cardPayment || missingPoints > 0) ?
                   <>
                     <label htmlFor="accCurrency" className='text-black-50 fw-bold col-8 m-0 p-0'>{t("Select Payment Currency")}:</label>
                    <select id='accCurrency' onChange={this.currencyConversion} className='dist border col-4 p-0 m-0 text-center form-control arrow-indicator no-outline'>
                                {acceptedCurrencies?.map(e => <option name={e.unit_name_short} selected={e.unit_name_short === this.props.currancyCode} value={e.unit_name_short}>{e.unit_name_short}</option>)}
                    </select>
                   </>: null
                }
                    </div>
                    }
                        
                        <div className="d-flex flex-row justify-content-between py-2">
                            <span className="text-black-50 fw-bold">{t("Subtotal")}:</span>
                            {
                                this.props.currencyConversonLoading &&
                                <div class="spinner">
                                                    <div class="bounce1"></div>
                                            <div class="bounce2"></div>
                                                     <div class="bounce3"></div>
                                                            </div>

                            }
                            { 
                               
                               this.props.lang!=='ar' && cardPayment?
                               <span className=" product_value fs-large" > {this.numberWithCommas(total)} <small className="fw-normal ">{t(currancyCode)}</small></span>
                               :
                               this.props.lang==='ar' && cardPayment?
                               <span className="product_value fs-large">&nbsp;{t(currancyCode)} {this.numberWithCommas(total)} </span>
                               :
                               this.props.lang==='ar' && !cardPayment?
                               <span className="product_value fs-large">&nbsp;{t("Points")} {this.numberWithCommas(this.props.giftcard_points)} </span>
                               :
                               this.props.lang!=='ar' && !cardPayment?
                               <span className=" product_value fs-large" > {this.numberWithCommas(this.props.giftcard_points)} <small className="fw-normal ">{t("Points")}</small></span>
                               :null

                            }
                        </div>
                        {/* disabled Points Fucntionalities */}
                        <div className="d-flex flex-row justify-content-between py-2">
                            <span className="text-black-50 fw-bold">{t("Available")}:</span>
                            {this.props.lang!=='ar'?
                            <h5 className="text-danger fw-bold m-0 fs-regular">{this.numberWithCommas(pointBalance)}<small> {t("Points")}</small></h5>
                            :
                            <h5 className="text-danger fw-bold m-0 fs-regular"><small> {t("Points")}</small> {this.numberWithCommas(pointBalance)}</h5>
                        }
                        </div>
                        
                         {
                            missingPoints>0 && !this.props.cardPayment &&
                            <>
                            
                            <div className="d-flex flex-row justify-content-between py-2">
                                <span className="text-black-50 fw-bold">{t("Missing Points")}:</span>
                                {
                                   this.props.lang!=='ar'?
                                   <h5 className="text-danger fw-bold m-0 fs-regular">{this.numberWithCommas(missingPoints)} <small>{t("Points")}</small></h5> 
                                    :
                                    <h5 className="text-danger fw-bold m-0 fs-regular"><small>{t("Points")}</small> {this.numberWithCommas(missingPoints)} </h5>
                               }
                            </div>
                            </>
                            
                        } 
                        {/* /* Showing Only SAR and Credit Card Image */}
                        {
                            (this.props.cardPayment || missingPoints > 0) &&  <div className="d-flex flex-row py-3">
                            <div className="p-0 col-2">
                                <img src={cardImg} alt="" className="img-fluid verticle-center"/>
                            </div>
                            <div className="px-1 col-10">
                            <h5 className="primary-color  fw-bold m-0">{this.numberWithCommas(pointsCurrancy)} {t(selectedCurrency)}</h5>
                                <small className="text-muted fw-bold">
                                    {t("to be paid by Credit Card")}
                                </small><br/>
                                <img src={MasterCard} alt='maser-card'/>
                                <img src={Visa} alt='visa'/>
                            </div>
                        </div>
                        }
                        
                        <hr className="hr-line"/>
                        <div className="d-flex flex-row justify-content-between pt-1 pb-3">
                            <h5 className="text-dark fw-bold m-0">{t("Total")}</h5>
                            {
                                 this.props.lang!=='ar' && (cardPayment || missingPoints > 0)?
                                 <span className=" product_value fs-large" > 
                                 { this.numberWithCommas(pointsCurrancy)} <small>{t(selectedCurrency)}</small></span>
                                 :
                                 this.props.lang==='ar' && (cardPayment || missingPoints > 0)?
                                 <span className="product_value fs-large">&nbsp;{t(selectedCurrency)} {this.numberWithCommas(pointsCurrancy)} </span>
                                 :
                                 this.props.lang==='ar' && !cardPayment?
                                 <span className="product_value fs-large">&nbsp;{t("Points")} {this.numberWithCommas(this.props.giftcard_points)} </span>
                                 :
                                 this.props.lang!=='ar' && !cardPayment?
                                 <span className=" product_value fs-large" > {this.numberWithCommas(this.props.giftcard_points)} <small className="fw-normal ">{t("Points")}</small></span>
                                 :null

                            }
                        </div>
                        {/* <div className="d-flex gap-2">
                            <input 
                                type="checkbox"
                                className="flex-shrink-0"
                                onClick={()=>{this.setCheckoutBtn()}} />
                                <label className="" >{t("I have read and accepted the Terms & Conditions")}.</label>
                        </div> */}
                        <br/>
                        <button 
                            className="btn btn-block primary-btn text-light rounded-0 text-center" 
                            onClick={()=>{this.postOrderHandler()}} 
                            disabled={this.props.currencyConversonLoading}>
                            {/* {t("Checkout Now")} */}
                            { loading ? <span > <div class="spinner-border spinner-border-sm " role="status">
                            <span className="sr-only" >Loading...</span>
                        </div>
                        <span  className='px-2' >{t("Checking")} . . .</span>
                        </span> : <span>{(this.props.cardPayment || missingPoints > 0) ? t("Buy with Credit Card") : t("Checkout Now")}</span>}
                        </button>
                    </div>
                </div>
            </div>
        )
        const mobileOrderSummaryCard=(
            <div className="d-md-none d-block flex-shrink-0">
                <FailMessageModal show={paymentFail} lang={this.props.lang} Message={errorMsg}/>
                <div className="fs-5 fw-bold text-muted py-2">{t("Order Summary")}</div>
                <div className="border px-3">
                    {
                        (this.props.cardPayment || missingPoints > 0) && 
                <div className="d-flex flex-row justify-content-between align-items-center py-3 border-bottom">    
                     <label htmlFor="accCurrency" className='text-black-50 fw-bold col-8 m-0 p-0'>{t("Select Payment Currency")}:</label>
                    <select id='accCurrency' onChange={this.currencyConversion} className='dist border col-4 p-0 m-0 text-center form-control arrow-indicator no-outline' name="">
                                {acceptedCurrencies?.map(e => <option selected={e.unit_name_short === this.props.currancyCode} value={e.unit_name_short}>{e.unit_name_short}</option>)}
                    </select>
                   </div>
                }
                    <div className="d-flex flex-row justify-content-between py-3 border-bottom">
                        <span className="text-black-50 fw-bold">{t("Subtotal")}:</span>
                        {
                                this.props.currencyConversonLoading &&
                                <div class="spinner">
                                                    <div class="bounce1"></div>
                                            <div class="bounce2"></div>
                                                     <div class="bounce3"></div>
                                                            </div>

                            }
                        {
                            this.props.lang!=='ar'?
                            <div className="text-dark fw-bold m-0 fs-5 fw-bold  fs-regular">{this.numberWithCommas(this.props.giftcard_points)}<small> {t("Points")}</small></div>
                                :
                                <div className="text-dark fw-bold m-0 fs-5 fw-bold  fs-regular"><small> {t("Points")}</small>&nbsp;{this.numberWithCommas(this.props.giftcard_points)}</div>

                        }
                    </div>
    
                    {/* PointsFunctionalities Disabled */}
                        <div className="d-flex flex-row justify-content-between py-2">
                            <span className="text-black-50 fw-bold">{t("Available")}:</span>
                            {this.props.lang!=='ar'?
                            <h5 className="text-danger fw-bold m-0 fs-regular">{this.numberWithCommas(pointBalance)}<small> {t("Points")}</small></h5>
                            :
                            <h5 className="text-danger fw-bold m-0 fs-regular"><small> {t("Points")}</small> {this.numberWithCommas(pointBalance)}</h5>
                        }
                        </div>
                    {
                        missingPoints>0 && !this.props.cardPayment &&
                        <>
                        
                        <div className="d-flex flex-row justify-content-between  py-3 border-bottom">
                            <span className="text-black-50 fw-bold">{t("Missing Points")}:</span>
                            {
                                this.props.lang!=='ar'?
                                <div className="text-danger fw-bold m-0 fs-5 fw-bold  fs-regular">{this.numberWithCommas(missingPoints)}<small>{t(currancyCode)}</small></div>
                                    :
                                    <div className="text-danger fw-bold m-0 fs-5 fw-bold  fs-regular"><small>{t(currancyCode)}</small>&nbsp;{this.numberWithCommas(missingPoints)}</div>

                            }
                        </div>
                        </>
                        
                    }
                    
                    <div className="d-flex flex-row justify-content-between py-3">
                        <div className="text-dark fw-bold m-0">{t("Total")}</div>
                        {
                             this.props.lang!=='ar' && (cardPayment || missingPoints>0)?
                             <span className=" product_value fs-large" > {this.numberWithCommas(pointsCurrancy)} <small>{t(selectedCurrency)}</small></span>
                             :
                             this.props.lang==='ar' && (cardPayment || missingPoints>0) ?
                             <span className="product_value fs-large">&nbsp;{t(selectedCurrency)} {this.numberWithCommas(pointsCurrancy)} </span>
                             :
                             this.props.lang==='ar' && !cardPayment?
                             <span className="product_value fs-large">&nbsp;{t("Points")} {this.numberWithCommas(this.props.giftcard_points)} </span>
                             :
                             this.props.lang!=='ar' && !cardPayment?
                             <span className=" product_value fs-large" > {this.numberWithCommas(this.props.giftcard_points)} <small className="fw-normal ">{t("Points")}</small></span>
                             :null

                        }
                    </div>

                    {
                        (this.props.cardPayment || missingPoints>0 ) && <div className="d-flex flex-row py-3 border-bottom">
                        <div className="p-0 col-1">
                            <img src={cardImg} alt="" className="img-fluid verticle-center"/>
                        </div>
                        <div className="px-3 col-10">
                        <div className=" primary-color fw-bold m-0 fs-5 fw-bold">{this.numberWithCommas(convertedCurrency)} {t(selectedCurrency)} </div>
                            <small className="text-muted fw-bold">
                                {t("to be paid by Credit Card")}
                            </small><br/>
                                    <img src={MasterCard} alt="master card"/>
                            <img src={Visa} alt="visa"/>
                        </div>
                    </div>
                    }
                </div>
                <div className=" w-100 bg-light py-5 ">
                {/* <div className="d-flex">
                            <input 
                                type="checkbox"
                                className="flex-shrink-0"
                                onClick={()=>{this.setCheckoutBtn()}} />
                                <label className="" style={{paddingLeft: "1rem",paddingRight:"1rem"}}>{t("I have read and accepted the Terms & Conditions")}.</label>
                        </div> */}
                        <br/>
                    <button 
                        onClick={()=>{this.postOrderHandler()}}
                        disabled={this.props.currencyConversonLoading}
                        className="btn btn-block primary-btn w-100 rounded-0 p-2 text-white">
                       {/* {t("Checkout Now")} */}
                       { loading ? <span > <div class="spinner-border spinner-border-sm " role="status">
                            <span className="sr-only" >Loading...</span>
                        </div>
                        <span  className='px-2' >{t("Checking")} . . .</span>
                        </span> : <span>{(this.props.cardPayment || missingPoints>0) ? t("Buy with Credit Card") : t("Checkout Now")}</span>}
                    </button>
                </div>
                <div className="d-md-none d-block m-4 p-2" />
            </div>
        )     
        return (
            <>
                {
                    showShippingPage && physicalCard ?  
                        <ShippingAddressPage lang={this.props.lang} setShippingPage={this.setShippingPage} order={orders} cardPayment={cardPayment} physicalCard={physicalCard} giftcardPoints={this.props.giftcard_points} giftcardCurrency={this.props.currancyCode}/>
                    :
                    <ModalWrapper show={this.props.show}>
                        <section className="bg-white w-100 h-100" >
                            <header className="d-flex py-md-4 py-3 px-md-5 px-3 gap-md-4 gap-3 border shadow-sm align-items-center">
                                <div>
                                    <img className="img-fluid d-md-block d-none" style={{width:'13rem'}} src={logoHandler()} alt="logo"/>
                                    <img className="img-fluid d-md-none d-block mt-2" id="lg-fix" src={logoHandler()} alt="logo"/>
                                
                                </div>
                                <div className="pt-3 fs-md-2 fs-5 fw-bold">
                                    <span className="headings">{t("Order Confirmation")} </span> - <span className="text-black-50">({orders.quantity} {t("item")})</span> 
                                </div>
                            </header>
                            <div className="d-flex flex-md-row flex-column p-md-5 p-3 gap-md-5 bg-white">
                                <div className="flex-grow-1 d-flex align-self-start border-md-1 border-none p-md-3 p-0">
                                    <div className="col-3 p-md-2 p-0" style={{minWidth:"8rem"}}>
                                        <img  className="img-fluid" src={card.image && card.image?.medium_rectangle} alt="giftCard"/>
                                    </div>
                                    <div className="text-black-50 px-3 flex-grow-1 ">
                                        <div className=" product_title fs-md-3 fs-5 ">{this.props.lang!=='ar'?card.name: card.name_arabic}</div>
                                    
                                        <div className="pb-2 fw-bold">
                                        {

                                            this.props.lang!=='ar' && cardPayment?
                                            <>
                                            {/* <span className=" product_value fs-large" > {this.numberWithCommas(total)} <small className="fw-normal ">{t(currancyCode)}</small></span> */}
                                            <span className=" product_value fs-large" > {this.numberWithCommas(this.props.giftcard_points)} <small className="fw-normal ">{t("Points")}</small></span>
                                            </>
                                            :
                                            this.props.lang==='ar' && cardPayment? <>
                                            <span className="product_value fs-large">&nbsp;{t(currancyCode)} {this.numberWithCommas(total)} </span>
                                            <span className="product_value fs-large">&nbsp;{t("Points")} {this.numberWithCommas(this.props.giftcard_points)} </span>
                                            </>

                                            :
                                            this.props.lang==='ar' && !cardPayment?
                                            <span className="product_value fs-large">&nbsp;{t("Points")} {this.numberWithCommas(this.props.giftcard_points)} </span>
                                            :
                                            this.props.lang!=='ar' && !cardPayment?
                                            <span className=" product_value fs-large" > {this.numberWithCommas(this.props.giftcard_points)} <small className="fw-normal ">{t("Points")}</small></span>
                                            :null
                                        }
                                        <div className="pt-2 fw-bold">
                                                    <img src="" alt=''/>
                                                {
                                                    this.props.lang!=='ar'?
                                                    <span className="primary-color fs-regular">{currancyCode}  {this.props?.GiftCardValue} * {this.props.orders?.quantity} {t("Quantity")} </span>
                                                    :
                                                    <span className="primary-color fs-regular">{t(currancyCode)}  {this.props?.GiftCardValue} * {t("Quantity")} {this.props.orders?.quantity}  </span>
                                                }
                                                </div>
                                        </div>
                                        {/* Order details desktop view */}
                                        <div className="py-2 d-none">
                                                    <hr className="hr-line" />
                                                    {(GiftCardMessage !== "") ?
                                                        <div>

                                                            {(GiftCardEmail !== "") ?
                                                                <div className="d-flex py-2">
                                                                    <div className="w-100 d-flex justify-content-start">
                                                                        <span className="fs-regular d-inline">{t("Recipient's email address")}:</span>
                                                                    </div>
                                                                    <div className="w-100 d-flex justify-content-start">
                                                                        <span className="fs-regular d-inline">{GiftCardEmail}</span>
                                                                    </div></div> : null
                                                            }
                                                            {(GiftCardMobile !== "") ?
                                                                <div className="d-flex py-2">
                                                                    <div className="w-100 d-flex justify-content-start">
                                                                        <span className="fs-regular d-inline">{t("Recipient's mobile number")}:</span>
                                                                    </div>
                                                                    <div className="w-100 d-flex justify-content-start">
                                                                        <span className="fs-regular d-inline">{GiftCardMobileCode}{GiftCardMobile}</span>
                                                                    </div></div> : null
                                                            }

                                                            <div className="d-flex py-2">
                                                                <div className="w-100 d-flex justify-content-start">
                                                                    <span className="fs-regular d-inline">{t("Amount")}:</span>
                                                                </div>
                                                                <div className="w-100 d-flex justify-content-start">
                                                                    <span className="color-blue fs-regular">{total} {t("point")}</span>
                                                                </div>
                                                            </div>

                                                            <div className="d-flex py-2">
                                                                <div className="w-100 d-flex justify-content-start">
                                                                    <span className="fs-regular d-inline">{t("Quantity")}:</span>
                                                                </div>
                                                                <div className="w-100 d-flex justify-content-start">
                                                                    <span className="fs-regular d-inline">{GiftCardQuantity}</span>
                                                                </div>
                                                            </div>

                                                            <div className="d-flex py-2">
                                                                <div className="w-100 d-flex justify-content-start">
                                                                    <span className="fs-regular d-inline">{t("Message")}:</span>
                                                                </div>
                                                                <div className="w-100 d-flex justify-content-start">
                                                                    <span className="fs-regular d-inline"></span>
                                                                </div>
                                                            </div>

                                                            <div className="d-flex py-2 py-4 mt-2 giftcardedit">
                                                                <div className="col-4 p-md-2 p-0 py-4 mx-4" >
                                                                    <img style={{ width: '100%', height: 'auto' }} src={url} alt="" />
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                    <span className="fs-regular">{GiftCardMessage}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null}

                                                </div>
                                        {/* Points Functionalities are disabled */}
                                        {/* {
                                            missingPoints >0 && !this.props.cardPayment &&
                                            <>
                                                <div className="pb-2 ">
                                                {t("Missing Points")}: 
                                                    {
                                                        this.props.lang==='en'?
                                                        <span className="text-danger fs-md-3 fs-regular"> {this.numberWithCommas(missingPoints)} {t("Points")}</span>
                                                            :
                                                            <span className="text-danger fs-md-3 fs-regular">&nbsp; {t("Points")} {this.numberWithCommas(missingPoints)}</span>

                                                    }
                                                </div>
                                                <div className="pb-2 fw-bold">
                                                    <img src="" alt=''/>
                                                    <span className="color-blue fs-regular"> {this.numberWithCommas(missingPoints)} {t("points")} ({this.numberWithCom(pointsToCurrancy(missingPoints))} {t(currancyCode)})</span>
                                                </div>
                                                <div>
                                                    <b>{t("to be paid by  Credit Card/MADA")}</b> 
                                                    <span className="color-skyblue">{t(" Credit Card/MADA")}</span>
                                                </div>
                                            </>
                                            
                                        } */}
                                        {/* {
                                            this.props.lang==='ar' && !cardPayment &&
                                            <span className="product_value fs-large">&nbsp;{t("Points")} {(this.numberWithCommas(total))} </span>
                                            
                                        }
                                        {
                                            this.props.lang==='en' && !cardPayment &&
                                            <span className=" product_value fs-large" > {(this.numberWithCommas(total))} <small className="fw-normal ">{t("Points")}</small></span>
                                        } */}
                                    <div className="py-3 d-flex justify-content-end">
                                            <button className="border-0 bg-white text-decoration-none color-skyblue fw-bold" onClick={()=>this.removeOrderhandler()} >
                                            <span className="mx-1"><img className=''  src={removeIcon} alt="remove icon"/></span>{t("Remove")}</button>
                                        </div>
                                    </div>
                                </div>
                                {desktopOrderSummaryCard}
                                {mobileOrderSummaryCard}
                            </div>
                        </section>
                        {/* when missing points > 0 it will redirect to card payment page  */}
                        {/* {
                            this.state.redirectToPayment && (
                            (missingPoints>0 || this.props.cardPayment) &&
                            <Redirect
                                    push to={{
                                    pathname:'/payment/card-details',
                                    state:{
                                        order:orders,orderDetails,
                                        cardPayment:this.props.cardPayment,
                                        orderCreationURL:'orders',
                                        summaryPageURL:'/payment/orders-summary'
                                    
                                    }
                            }}/>

                            )
                            
                        } */}
                        {
                            this.state.redirectToPayment && (
                            (missingPoints>0 || this.props.cardPayment) &&
                            <Redirect
                                    push to={{
                                    pathname:'/payment/card-details-merchandise',
                                    state:{
                                        order:{"orders":this.state.orders},
                                        cardPayment:this.props.cardPayment,
                                        orderCreationURL:'merchant_product_orders/create_physical_giftcard_order',
                                        summaryPageURL:'/payment/merchandise-orders-summary',
                                        currancyCode:selectedCurrency,
                                        convertedCurrency:pointsCurrancy,
                                        splitPayment: (missingPoints > 0 && !this.props.cardPayment) ? {value: Number(convertedPointsBalance) + Number(pointsCurrancy)} : false
                                    }
                            }}/>


                            )
                            
                        }
                        
                    </ModalWrapper> 
                }
            </>
         );
    }
}
const mapStateToProps = state => {
    return {
        orderDetails:state.order.orderDetails,
        orderPosted:!state.order.loading,
        pointBalance:state.point.pointBalance,
        errorFlag:state.order.errorFlag,
        authToken:state.auth.authToken,
        convertedCurrency: state.conversion.convertedValue,
        acceptedCurrencies: state.conversion.paymentCurrencies,
        currencyConversonLoading: state.conversion.loading,
        minimum_payable_amount: state.conversion?.convertedValue?.minimum_payable_amount
    }
}

const mapDispatchToProps = dispatch => {
    return {
        postOrderCreation : (payload) => dispatch(postOrderCreation(payload)),
        removeOrder : () => dispatch(removeOrder()),
        fetchOrderDetailsSuccess : data => dispatch(fetchOrderDetailsSuccess(data)),
        fetchCurrencyConversion: (payload) => dispatch(fetchCurrencyConversion(payload))
    }
}

 
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(withTranslation()(PhysicalCardConfirmantionModal)));