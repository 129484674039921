import axios from "axios"
import secretKeyGenerator from "../../utils/serectKeyGenerator"
import { FETCH_COUNTRYCODE_FAILURE, FETCH_COUNTRYCODE_REQUEST, FETCH_COUNTRYCODE_SUCCESS,
    FETCH_PHONEFORMAT_FAILURE, FETCH_PHONEFORMAT_REQUEST, FETCH_PHONEFORMAT_SUCCESS 
} from "./CountryCodeTypes"

import { REACT_APP_baseUrl } from 'utils/app-constants';


export const fetchCountyCodeRequest = () => {
    return {
        type:FETCH_COUNTRYCODE_REQUEST,
    }
}

export const fetchCountyCodeSuccess = data => {
    return {
        type:FETCH_COUNTRYCODE_SUCCESS,
        payload:data
    }
}

export const fetchCountyCodeFailure = error =>{
    return {
        type:FETCH_COUNTRYCODE_FAILURE,
        payload:error
    }
}

export const fetchPhoneFormatRequest = () => {
    return {
        type:FETCH_PHONEFORMAT_REQUEST,
    }
}

export const fetchPhoneFormatSuccess = data => {
    return {
        type:FETCH_PHONEFORMAT_SUCCESS,
        payload:data
    }
}

export const fetchPhoneFormatFailure = error =>{
    return {
        type:FETCH_PHONEFORMAT_FAILURE,
        payload:error
    }
}

const cacheHeaders = () => {
    return {
        headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Expires': '0'
        }
    }
  }

export const fetchCountyCode = () => {
    return (dispatch)=>{
        dispatch(fetchCountyCodeRequest);
        axios.get(`${REACT_APP_baseUrl}/countries`,cacheHeaders())
            .then(response  => {
                let countryCode = response.data.data.countries;
                // categories.forEach(item => {
                //     item.brands.forEach(card => {
                //         countyCode.push(card)
                //     });
                // })
                dispatch(fetchCountyCodeSuccess(countryCode))
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
                dispatch(fetchCountyCodeFailure("failed"))
            })
    }
}

export const fetchPhoneFormat = (payload,setPhoneCode) => {
    return (dispatch)=>{
        dispatch(fetchPhoneFormatRequest);
        axios.post(`${REACT_APP_baseUrl}/countries/set_phone_attributes`,
            payload,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache, no-store, must-revalidate',
                    'Expires': '0'
                },
                params:{
                    "secret_key":secretKeyGenerator(payload)
                }
            })
            .then(response  => {
                const PhoneFormat = response.data.data
                if(typeof setPhoneCode === "function"){
                    setPhoneCode(PhoneFormat?.country_code)    
                }
                dispatch(fetchPhoneFormatSuccess(PhoneFormat))
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
                dispatch(fetchPhoneFormatFailure("failed"))
            })
    }
}