import axios from 'axios';
import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {ReactComponent as AlertSvg} from '../../assests/img/alert.svg'
import { fetchGoalItems, setGoalItem } from '../../Redux/goalItems/goalItemsActions';
import store from '../../Redux/store';
import { REACT_APP_baseUrl } from 'utils/app-constants';


class GoalItemRemindModal extends Component {
    constructor(props){
        super(props);
        this.state={
            loading:true,
            msg:'We will remind you in 15 days'
        }
    }
    remindMe = () => {
        this.setState({loading:false,msg:'Processing Request'})
        axios({
            method:'POST',
            url:`${REACT_APP_baseUrl}/customer_goal_items/update_customer_goal`,
            headers:{"Authorization":`Bearer ${store.getState().auth.authToken}`}
        })
        .then((response)=>{
            if(response.data?.code === 200){
                this.props.fetchGoalItems()
            }
            this.setState({msg:response.data?.message})
        })
        .catch(error => {
            this.setState({msg:'Something went wrong'})
        })
    }

    render() {
        const {show,t,setModalView,goalLoading} = this.props;
        const {msg,loading} = this.state
        return (
            <Modal 
            backdrop="static"
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onExited={()=>this.setState({loading:true,msg:'We will remind you in 15 days'})}
            >
                <div 
                    className="p-4 bg-light mx-auto text-center py-md-5 py-4">
                    <AlertSvg className="p-md-0 p-3" height="100"/>
                    <h3 className='text-muted m-0 py-md-3 py-0'>
                    {t(msg)}
                    </h3> 
                    {
                        msg !== 'Processing Request' && <button 
                        className="btn  border-color-blue mt-3 mx-2"
                        onClick={()=>{setModalView()}}>
                        <span className="fw-bold color-blue">{loading ? t("Cancel") : t("Close")}</span>
                    </button>
                    }
                    {
                        loading && <button 
                        className="btn primary-btn text-light border-color-green rounded mx-2 mt-3"
                        onClick={()=>{this.remindMe()}}>
                        {t("Confirm")}
                    </button>
                    }
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
       goalItemData:state.goalItem.goalItemData?.brand,
       goalLoading:state.goalItem.loading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setGoalItem: (payload,productAPI,status) => dispatch(setGoalItem(payload,productAPI,status)),
        fetchGoalItems:()=> dispatch(fetchGoalItems())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(GoalItemRemindModal))
