import React, { Component } from 'react'

export default class TermsAndConditions extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }
  render() {
    const arabicTermsAndConditions = (
      <div className="container-fluid p-3">
        <div class="text-black-50 fw-bold mt-3 " >
          <h3 className='color-skyblue'>الشروط والأحكام</h3>
          <p>فيما يلي الشروط والأحكام المتعلقة ببرنامج حصاد للمكافآت المقدم من بنك الرياض والمتفق مع الأنظمة واللوائح والتعليمات المعمول بها في المملكة العربية السعودية.</p>
          <ol>
            <li>
              <h5>العميل المؤهل:</h5>
              <p>أ. يستطيع عميل بنك الرياض التسجيل في برنامج حصاد للمكافآت من خلال أون لاين الرياض وموبايل الرياض أو عن طريق التواصل مع فريق خدمة العملاء في الهاتف المصرفي.</p>
              <p>ب. الحاصلين على بطاقات بنك الرياض الائتمانية* يتم تسجيلهم تلقائياً في برنامج حصاد للمكافآت.</p>
            </li>
            <li>
              <h5>العمليات المؤهلة</h5>
              <p>تمنح نقاط حصاد لكل عملية مؤهله تتم داخل وخارج المملكة سواء عن طريق استخدام البطاقة الائتمانية او اجراء العمليات البنكية الأخرى من خلال قنوات بنك الرياض الرقمية أو نقاط البيع. عند إتمام العمليات بعملة أخرى غير الريال السعودي، فأنه سيتم احتساب النقاط المكتسبة عن طريق تحويل قيمة المشتريات إلى الريال السعودي حسب سعر الصرف الحالي في بنك الرياض ويتم تحديث العمليات المؤهلة للحصول على نقاط حصاد في موقع بنك الرياض الرسمي بشكل دوري.</p>
            </li>
            <li>
              <h5>اكتساب النقاط:</h5>
              <p>يتم احتساب النقاط حسب الجدول أدناه، ويحتفظ بنك الرياض لنفسه الحق في تعديل نسبة النقاط المكتسبة دون سابق إشعار وسيتم الإعلان عن طريق موقع بنك الرياض الرسمي وتعد نقاط برنامج حصاد المكتسبة غير قابلة للتحويل المالي.</p><br />
              <h4 className='color-skyblue' >كيفية احتساب نقاط حصاد للخدمات والمنتجات:</h4>
              <table class="text-black-50 table table-bordered table-responsive-md text-center">
                <thead className='thead-light'>
                  <tr>
                    <th class="text-center">نوع البطاقة الائتمانية</th>
                    <th class="text-center">استخدام العمليات</th>
                    <th class="text-center">عدد النقاط المكتسبة</th>
                    <th class="text-center">مقدار الصرف الدولي</th>
                    <th class="text-center">عدد النقاط المكتسبة</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>ماستركارد وورلد إيليت</td>
                    <td>لكل 8 ريال تم إنفاقها</td>
                    <td rowspan="5" style={{ verticalAlign: 'middle' }} >نقطة واحدة</td>
                    <td>لكل 6 ريال تم إنفاقها</td>
                    <td rowspan="5" style={{ verticalAlign: 'middle' }}>نقطة واحدة</td>
                  </tr>
                  <tr>
                    <td>ماستركارد وورلد</td>
                    <td>لكل 8 ريال تم إنفاقها</td>
                    <td>كل 6 ريال تم إنفاقها</td>
                  </tr>
                  <tr>
                    <td>فيزا سيجنتشر</td>
                    <td>لكل 10 ريال تم إنفاقها</td>
                    <td>لكل 8 ريال تم إنفاقها</td>
                  </tr>
                  <tr>
                    <td>فيزا/ ماستركارد بلاتينيوم</td>
                    <td>لكل 10 ريال تم إنفاقها</td>
                    <td>لكل 8 ريال تم إنفاقها</td>
                  </tr>
                  <tr>
                    <td>ماستركارد بلاتينيوم</td>
                    <td>لكل 15 ريال تم إنفاقها</td>
                    <td>لكل 10 ريال تم إنفاقها</td>
                  </tr>
                </tbody>
              </table>
              <br />
              <h5>نقاط حصاد لأول 3 أشهر من استخدام البطاقات الائتمانية:</h5>
              <table class="text-black-50 table table-bordered table-responsive-md text-center">
                <thead className='thead-light'>
                  <tr>
                    <th class="text-center">نوع البطاقة الائتمانية</th>
                    <th class="text-center">مقدار الانفاق في أول ثلاثة أشهر</th>
                    <th class="text-center">النقاط المكتسبة</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>ماستركارد وورلد إيليت</td>
                    <td>40,000 ريال</td>
                    <td>4,000 نقطة</td>
                  </tr>
                  <tr>
                    <td>ماستركارد وورلد</td>
                    <td>25,000 ريال</td>
                    <td>1,600 نقطة</td>
                  </tr>
                  <tr>
                    <td>فيزا سيجنتشر</td>
                    <td>10,000 ريال</td>
                    <td>1,200 نقطة</td>
                  </tr>
                  <tr>
                    <td>فيزا/ ماستركارد بلاتينيوم/td&gt;
                    </td><td>8,000 ريال</td>
                    <td>800 نقطة</td>
                  </tr>
                  <tr>
                    <td>ماستركارد بلاتينيوم</td>
                    <td>5,000 ريال</td>
                    <td>400 نقطة</td>
                  </tr>
                </tbody>
              </table>
              <br />
              <h5>النقاط المكتسبة لتنشيط البطاقة الائتمانية:</h5>
              <table class="text-black-50 table table-bordered table-responsive-md text-center">
                <thead>
                  <tr>
                    <th class="text-center">نوع البطاقة الائتمانية</th>
                    <th class="text-center">النقاط المكتسبة</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>ماستركارد وورلد إيليت</td>
                    <td>100 نقطة</td>
                  </tr>
                  <tr>
                    <td>ماستركارد وورلد</td>
                    <td>100 نقطة</td>
                  </tr>
                  <tr>
                    <td>فيزا سيجنتشر</td>
                    <td>50 نقطة</td>
                  </tr>
                  <tr>
                    <td>فيزا/ ماستركارد بلاتينيوم</td>
                    <td>50 نقطة</td>
                  </tr>
                  <tr>
                    <td>ماستركارد بلاتينيوم</td>
                    <td>20 نقطة</td>
                  </tr>
                </tbody>
              </table>
              <br />
              <h5>المنتجات والخدمات:</h5>
              <table class="text-black-50 table table-bordered table-responsive-md text-center">
                <thead className='thead-light'>
                  <tr>
                    <th class="text-center">نوع الخدمة</th>
                    <th class="text-center">النقاط المكتسبة</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>دفع فواتير سداد عبر أون لاين الرياض أو موبايل الرياض<br />)لا يشمل الدفع عن طريق البطاقات الائتمانية(</td>
                    <td>4.5 نقطة<br />الحد الأقصى 100 ريال</td>
                  </tr>
                  <tr>
                    <td>دفع فواتير سداد عبر لاين الرياض (الهاتف المصرفي)<br />)لا يشمل الدفع عن طريق البطاقات الائتمانية(</td>
                    <td>2.8 نقطة<br />الحد الأقصى 100 ريال</td>
                  </tr>
                  <tr>
                    <td>تحويل الراتب</td>
                    <td>100 نقطة</td>
                  </tr>
                  <tr>
                    <td>التسجيل في أون لاين الرياض</td>
                    <td>20 نقطة</td>
                  </tr>
                  <tr>
                    <td>السحب من الصراف الآلي</td>
                    <td>نقطة واحدة لكل 500 ريال (قيمة تراكمية)</td>
                  </tr>
                  <tr>
                    <td>بطاقة مدى</td>
                    <td>نقطة واحدة لكل 125 ريال<br />8  نقاط بحد أقصى للعمليات الأكثر من 1000 ريال  (قيمة تراكمية)</td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>
              <h5>صلاحية النقاط:</h5>
              <p>تنتهي صلاحية نقاط حصاد بعد 12 شهر من اكتسابها حيث لا يمكن استرجاعها بعد فترة الانتهاء.</p>
            </li>
            <li>
              <h5>استبدال نقاط برنامج حصاد:</h5>
              <p>يمكن استبدال نقاط برنامج حصاد للمكافآت بقسائم إلكترونية من خلال حصاد مول الإلكتروني حسبما ما هو موضح في موقع بنك الرياض الإلكتروني (صفحة برنامج حصاد للمكافآت) ويحتفظ البنك لنفسه حق تعديل/تغير المنتجات التي سبق طرحها في موقع بنك الرياض وحصاد مول دون إشعار مسبق*. إذ أن المنتجات والخدمات المطروحة متعلقة بشروط وأحكام التجار المعتمدين لدى بنك الرياض.</p>
            </li>
            <li>
              <h5>الاسترداد الشخصي:</h5>
              <p>للمشتركين في برنامج حصاد الحاصلين على القسيمة المستبدلة الحق في طلب مكافاتهم من التاجر عن طريق استخدام القسيمة المالية لدى التاجر، وينبغي إبراز الهوية للتاجر للحصول على المكافأة.</p>
            </li>
            <li>
              <h5>غير قابل للتحويل أو الاسترداد:</h5>
              <p>قسائم برنامج حصاد غير قابلة للتحويل أو الاسترداد طالما تم إصدارها أو اعتمادها، كما لا يمكن استبدال النقاط بمبلغ نقدي آجل أو استخدامها لدفع أي رسوم يستحق سدادها مالم يكون قد تم تحديد ذلك مسبقاً في الموقع الإلكتروني أو تم الإعلان عن ذلك من قبل بنك الرياض، إضافة إلى ذلك نقاط برنامج حصاد غير قابلة للتحويل والاستخدام في حال وفاة العميل لا سمح الله.</p>
            </li>
            <li>
              <h5>الحد الأدنى لاستبدال النقاط:</h5>
              <p>ينبغي على المشتركين في برنامج حصاد، اكتساب 1,000 نقطة كحد أدنى لاستبدال النقاط، حيث ان الحد الأدنى للقسيمة هو 100 ريال</p>
            </li>
            <li>
              <h5>تحويل النقاط:</h5>
              <p>بإمكان مشترك حصاد تحويل النقاط المكتسبة لمشترك آخر عن طريق أون لاين الرياض أو موبايل الرياض بشرط اكتساب الحد الأدنى من النقاط</p>
            </li>
            <li>
              <h5>الوضع في حالة حظر الاسترداد:</h5>
              <p>لا يمكن استبدال نقاط حصاد في حال تزوير القسائم، حالات العجز عن السداد الكلي على البطاقات الائتمانية وحظر او إقفال الحساب من قبل بنك الرياض.</p>
            </li>
            <li>
              <h5>المسؤولية:</h5>
              <p>لا يمنح بنك الرياض أي ضمانات على أي نوعية من المنتجات أو الخدمات التي تقدم بواسطة طرف ثالث سواء كان شريك (اتاجر) في برنامج حصاد أو مقدم خدمة، فبنك الرياض لا يعتبر مسؤولاً أمام المشتركين في برنامج حصاد أو أي طرف ثالث عن أي فقد، خسائر او مطالبات تنتج عن أو ذات علاقة بمنتجات، بضائع، خدمات تقدم بواسطة الشركاء في برنامج حصاد/ حصاد مول الإلكتروني أو نتيجة لأي منتج خدمة لم يقم الشركاء في البرنامج بتوفيرها, كما ان الشروط والضمانات سواء تم التعبير عنها صراحةً أو ضمناً أو ظهرت من خلال أي نظام أو لائحة ذات علاقة بالتناسب, نوعية أو سلامة مكافاة حصاد المقدمة يعتد بها نظاماً وقانوناً. وأي مسؤولية لا يمكن استبعادها تعد محدودة، حيث يتم السماح باستبدال أو إصلاح أو اعتماد قيمة المكافأة تحت تقدير بنك الرياض.</p>
            </li>
            <li>
              <h5>الشروط العامة السرية:</h5>
              <p>يلتزم بنك الرياض بعدم الإفصاح عن اسم العميل، عنوانه أو تفاصيل الاتصال به للشركاء (التجار) ويكتفي فقط بملخص موجز الإفصاح لذكره في القسمية وقت الاسترداد، علما بأن جميع المعلومات التي يتم تحصليها سيتم استخدامها لأغراض تروجيه ترويجية فقط. وسيتم الاحتفاظ بها في مستوى سرية عالية وفقاً لسياسات البنك.</p>
            </li>
            <li>
              <h5>تسجيل الدخول في حصاد مول:</h5>
              <p>سيتم مشاركة بيانات المشترك المتطلبة في برنامج حصاد مع مزودي خدمة حصاد مول الإلكتروني. وهم شركة مايليست السعودية للخدمات التجارية، شركة الاتصالات السعودية وشركة حلول التقنية المثالية المصرحة من قبل هيئة الاتصالات والمعلومات وذلك لغرض إرسال القسيمة الإلكترونية المستبدلة عبر الرسائل القصيرة والبريد الإلكتروني التي تم تزويده من قبل العميل. علماً بأن مزودي الخدمة لن يقوموا بتخزين أو استخدام البيانات ادناه لأي غرض اخر.</p>
              <span>- رقم عضوية حصاد</span><br />
              <span>- البريد الإلكتروني</span><br />
              <span>- رقم الجوال.</span>
            </li>
            <li>
              <h5>معلومات التواصل:</h5>
              <p>يجب على المشترك التأكد من تزويد البنك بمعلومات التواصل الصحيحة حتى يستطيع استبدال نقاطه والحصول على القسيمة وفي حال تقدم المشترك بمعلومات تواصل خاطئة لا يحق للعميل المطالبة بالقسائم المستبدلة، وفي حال رغب العميل في إرسال القسيمة كاهديه لشخص اخر لا يتحمل بنك الرياض نتيجة المدخلات الخاطئة لبيانات التواصل من قبل العميل ولا يمكن المطالبة بالقسيمة في حال ضياعها ولا يلتزم البنك بأي مسؤولية ناتجه عن ذلك الخطأ.</p>
            </li>
          </ol>
          <div class="container-fluid mb-5">
            <h5 className='color-skyblue' >للشكاوى والاستفسارات</h5>
            <p>يرجى التواصل مع فريق خدمة العملاء داخل وخارج المملكة: (+966) 920002470</p>
            <h5 className='color-skyblue'>خدمة عملاء المصرفية الخاصة</h5>
            <p>يرجى الاتصال على: 800-122-6666</p>
            <p>إذا كنت تتصل من خارج المملكة: (+966) 920002470</p>
            <h5 className='color-skyblue'>خدمة عملاء المصرفية الماسية</h5>
            <p>يرجى الاتصال على: 800-610-0010</p>
            <p>إذا كنت تتصل من خارج المملكة: (+966) 920002470</p>
            <h5 className='color-skyblue'>خدمة عملاء المصرفية الذهبية</h5>
            <p>يرجى الاتصال على: 800-441-0000</p>
            <p>إذا كنت تتصل من خارج المملكة: (+966) 920002470</p>
          </div>
        </div>
      </div>
    )

    const englishTermsAndConditions = (
      <div className="container-fluid p-3">
        <div className="text-black-50 fw-bold mt-3 ">
          <h3 className='color-skyblue' >Terms & Conditions</h3>
          <h4 className='mt-5'>CHSC - Customer Loyalty Program </h4>
          <h5 className='mt-3'>Capital Health Screening Centre Loyalty Program Terms and Conditions</h5>
          <p >
            By  joining  the  Capital  Health  Screening  Centre  (“CHSC”)  Loyalty  Program  (the  “Loyalty
            Program”) you agree to be bound by these terms and conditions:

            A single CHSC Loyalty Card will be issued to each corporate customer (the “Member”). The
            Member  shall  nominate  one  of  their  employees  who  shall  represent  the  Member  (the
            “Representative”).
          </p>
          <ol className=' no-gutters'>

            <li>
              <p>CHSC Loyalty Program membership is not transferrable and is issued to the Member for
                their sole use. </p>

            </li>

            <li>
              <p>The Loyalty Program is only applicable to visa screening services at all CHSC’s branches,
                including mobile units. </p>
            </li>
            <li>
              <p>To earn, redeem points or benefits under the Loyalty Program, the Representative must
                present the Loyalty Program card, nomination proof and proof of identity at the time of the
                transaction. The redemption gift shall be expected to be given to the Representative within
                a week of redeeming the points. </p>
            </li>
            <li>
              <p>The  Member  is  responsible  for  updating  their  preferences  and  contact  details  of  their
                Representative by emailing CHSC Loyalty Program team at
                loyaltyprogram@capitlahealht.ae. </p>
            </li>
            <li>
              <p>A minimum of 100 loyalty points are required to claim rewards. </p>
            </li>
            <li><p>

              Loyalty points expire at the end of the calendar year.
            </p></li>
            <li>
              <p>To replace a lost or stolen card please call on 02 496 9666 or contact the Loyalty Program
                team at loyaltyprogram@capitalhealth.ae.  </p>
            </li>
            <li>
              <p>CHSC, at its sole discretion, reserves the right to terminate member’s membership if there
                has been a mis-use of the Loyalty Program in a manner which is deemed as a breach of
                these Terms and Conditions. </p>
            </li>
            <li>
              <p>A Member whose membership is terminated will forfeit all points in their account. </p>
            </li>
            <li>
              <p>CHSC  may  terminate  the  Loyalty  Program  at  any  stage  by  providing  CHSC’s  Loyalty
                Program  Members  a  30  days’  notice  via  email.  From  the  date  of  such  notice  until  the
                termination of the Loyalty Program, Members may redeem but not accrue points. </p>
            </li>
            <li>
              <p>CHSC  may  deduct  any  points  credited  in  error  to  a  Member’s  account  and  any  points
                relating to a transaction which is cancelled or reversed or where a refund is given.  </p>
            </li>
            <li>
              <p>CHSC reserves the right at any time and without prior notice to change or add to these
                Terms and Conditions and the rewards schedule. </p>
            </li>
            <li>
              <p>In  case  of  the  Member  entered  into  a  Credit  Agreement  with  CHSC,  the  Member  shall
                settle in full any outstanding amounts as a prerequisite condition for redeeming the points. </p>
            </li>
            <li>
              <p>It  is  the  responsibility  of  the  Member  to  inform  CHSC  in  case  of  the  change  of  the
                Representative. </p>
            </li>
            <li>
              <p>CHSC  may  collect  information  about  Members  including  information  contained  in  the
                application form and information as to transactions resulting in point’s credits and credits
                which  will  be  held  in  the  Membership  data  base.  The  Membership  data  base  contains
                Representative’s information such as name, email, address, telephone number, date of
                birth and P.O. Box. </p>
            </li>
            <li>
              <p>Loyalty points are not transferrable, exchangeable and are not cash equivalent.  </p>
            </li>
            <li>
              <p>Loyalty points can only be redeemed for rewards that are part of the reward catalogue. </p>
            </li>
            <li>
              <p>A  monthly  statement  of  accounts  will  be  sent  to  the  email  address  provided  by  the
                Member.</p>
            </li>
            <li>
              <p>These Terms & Conditions are governed by the laws of the Emirate of Abu Dhabi and the
                federal laws of the United Arab Emirates. In the event of any dispute, Members agree to
                submit to the exclusive jurisdiction of the courts of the Emirate of Abu Dhabi.</p>
            </li>
          </ol>

    </div>
    </div>

    )
    return (
      this.props.lang !== 'ar' ? englishTermsAndConditions : arabicTermsAndConditions
    )
  }
}
