import React, { Component } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import './Buygift.css'



import SwiperCore, {
    Navigation, EffectCoverflow,Pagination
  } from 'swiper';
import { withTranslation } from 'react-i18next';
  
  SwiperCore.use([Navigation, EffectCoverflow,Pagination]);

class SelectOccassion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disable: (this.props.GiftCardSelect!==undefined)?false:true,
            selected:{
                id: this.props.GiftCardSelect
            },
            sliderIndex: this.props.GiftSliderIndex
        }
    }


    disableHandler = (name, index)=>{
        this.setState({
            disable: false,
            selected:{
                id: name,
            },
            sliderIndex: index
        })
    }



    render() {
        const { nextStep,cardSelectHandler, t, images, loading, lang } = this.props;
        console.log('slider index', this.state.sliderIndex);
        const desktopView = (
            <div className="d-md-block d-none flex-shrink-0 mt-4 mb-5 px-3">
            <p className="color-blue mt-4 pl-1 mainheadselect">{t("Select Occassion")}</p>
                      <Swiper slidesPerView={5} spaceBetween={50} pagination={{
                            "clickable": true
                            }}
                            navigation
                            onSwiper={(swiper) => {
                                if (this.state.selected.id!== undefined) {
                                    swiper.activeIndex = this.state.sliderIndex - 2;
                                    swiper.slideNext();
                                }
                            }}
                            className="mySwiper">
                            {images.map((image, index)=>{
                                return <SwiperSlide onClick={()=>{
                                        this.disableHandler(image.id, index);
                                    }}>
                                    <img className={(this.state.selected.id === image.id)?"selectedborder hoverpointer":"hoverpointer"} src={image?.images?.image.medium_rectangle} alt="occassion images" />
                                    </SwiperSlide>
                            })}
                            
                           
                            </Swiper>

                            <div className="w-100 text-center mt-5">
                                <button 
                                    className="btn btn-primary w-50"
                                    disabled={this.state.disable}
                                    onClick={()=> {
                                        cardSelectHandler(this.state.selected.id, this.state.sliderIndex);
                                        nextStep();
                                    }}>
                                    {t("Proceed")}  
                                </button>
                            </div>
            </div>
        );

        const mobileView = (
            <div className={`Selectmain d-md-none d-block `}>
            <p className={`color-blue mt-4 mainheadselect ${lang === "ar" ? "pr-3" : "pl-3"}`}>{t("Select Occassion")}</p>

            <Swiper effect={'coverflow'} grabCursor={true} centeredSlides={true} slidesPerView={'2'} coverflowEffect={{
                    "rotate": 50,
                    "stretch": 0,
                    "depth": 100,
                    "modifier": 1,
                    "slideShadows": true
                    }}
                    onSwiper={(swiper) => {
                        if (this.state.selected.id!== undefined) {
                            swiper.activeIndex = this.state.sliderIndex - 2;
                            swiper.slideNext();
                        }
                    }}
                    pagination={true} className="mySwiper mt-4">
                    <div>
                    {images.map((image, index)=>{
                                return <SwiperSlide onClick={()=>{
                                    this.disableHandler(image.id, index);
                                    }}>
                                    <img className={(this.state.selected.id === image.id)?"selectedborder hoverpointer":"hoverpointer"} src={image?.images?.image.medium_rectangle} alt="occassion images"/>
                                    </SwiperSlide>
                            })}
                           
                    </div>
            </Swiper>


            <div className="w-100 text-center pro">
                <button 
                    className="btn btn-primary"
                    disabled={this.state.disable}
                    onClick={()=>{
                        cardSelectHandler(this.state.selected.id, this.state.sliderIndex);
                        nextStep();
                    }}>
                    {t("Proceed")} 
                </button>
            </div>
        </div>
        );


        return (
            <>
            {loading ? <section>
                    <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
            </section>:<>
                {desktopView}
                {mobileView}
            </>
            }
            </>
        )
    }
}






export default (withTranslation()(SelectOccassion))