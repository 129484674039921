import axios from 'axios'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { REACT_APP_baseUrl } from 'utils/app-constants'

const Loader = () => {
  return (
    <div className="d-flex justify-content-center pt-2 pb-2">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

const PointsTransaction = ({successfulRedeem}) => {
  const [data, setdata] = useState([]);
  const [loader, setloader] = useState(false);
  const [totalrecord, settotalrecord] = useState("");
  const [currpage, setcurrpage] = useState(1);

  useEffect(() => {
    if(typeof successfulRedeem === 'undefined' || successfulRedeem){
      getTransactions(1)
    }
  }, [successfulRedeem])

  const getTransactions = (page) => {
    let token = localStorage.getItem('userToken');
    setloader(true)
    axios.get(`${REACT_APP_baseUrl}/mylist-credits?page=${page}`, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
      .then((res) => {
        setdata(res.data.data.credits)
        settotalrecord(res.data.data.total_record)
        setloader(false)
      })
      .catch((err) => {
        setloader(false)
      })
  }

  const numberWithCommas = (x) => {
    let y = Number(x).toFixed(3)
    return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }


  const stepHandler = (val, page) => {
    if (val === "next") {
      setcurrpage(
        page + 1
      )
      getTransactions(page + 1)
    }
    else {
      setcurrpage(
        page - 1
      )
      getTransactions(page - 1)
    }
  }

  const desktopView = (
    <div className="px-5 mt-5 d-none d-md-block">
      <h4 className="primary-color fw-bold">Transaction Details</h4>
      {
        (!loader && data.length>0) ? <>
          <table className="w-100 border br-2 overflow-auto">
            <tr style={{ backgroundColor: '#EFEFF3' }} className="px-3 py-3">
              <td className="pl-4 py-2">Date</td>
              <td>Type</td>
              <td>Details</td>
              <td>Amount</td>
              <td>Balance</td>
            </tr>
            {
              data?.map((data) => {
                return <tr className="border-bottom">
                  <td style={{maxWidth:"90px"}} className="pl-4 py-2">{moment(data?.created_at).format('MMMM Do YYYY')} <br></br>{moment(data?.created_at).format('h:mm a')}</td>
                  <td className="primary-color">{data.type}</td>
                  <td style={{maxWidth:"150px"}}>{data.type != "PURCHASE" ? `Redemption: ${data.card_number}` : `Order Number ${data.order_id}`}</td>
                  <td className={data.type != "PURCHASE" ? "primary-color" : "text-danger"}>{data.type != "PURCHASE" ? "+ " : "- "}{numberWithCommas(data.amount)}</td>
                  <td>{numberWithCommas(data.current_balance)}</td>
                </tr>
              })
            }
          </table>
          <div className="d-flex w-100 justify-content-end mt-3 mb-3">
            {
              currpage > 1 ? <button onClick={() => stepHandler("previous", currpage)} className="border-none py-2 px-3 mx-3 border-radius-1"> Previous </button> : null
            }
            <span className="btn-sky  p-3 px-4 border-radius-1">{currpage}</span>
            {
              <button onClick={() => stepHandler("next", currpage)} className={parseInt((totalrecord / 10)) + 1 > currpage ? "border-none py-2 px-3 ml-3 border-radius-1 visible" : "border-none py-2 px-3 ml-3 border-radius-1 invisible"}> Next </button>

            }
          </div>
        </> : (!loader && data.length===0) ? <h1 className='mt-3'>No points history available</h1> : <Loader />
      }
    </div>
  );

  const mobileView = (
    <div className="px-2 mt-5 d-md-none">
      <h4 className="primary-color fw-bold">Transaction Details</h4>
      {
        (!loader && data.length>0) ? <>
          <div className="w-100 overflow-auto">
            <table className="w-100 border br-2 overflow-auto pointstable">
              <tr style={{ backgroundColor: '#EFEFF3' }} className="px-3 py-3">
                <td className="pl-4 py-2">Date</td>
                <td>Type</td>
                <td>Details</td>
                <td>Amount</td>
                <td>Balance</td>
              </tr>
              {
                data?.map((data) => {
                  return <tr className="border-bottom">
                    <td className="pl-4 py-2">{moment(data?.created_at).format('MMMM Do YYYY')} <br></br>{moment(data?.created_at).format('h:mm a')}</td>
                    <td className="primary-color">{data.type}</td>
                    <td>{data.type != "PURCHASE" ? `Redemption: ${data.card_number}` : `Order Number ${data.order_id}`}</td>
                    <td className={data.type != "PURCHASE" ? "primary-color" : "text-danger"}>{data.type != "PURCHASE" ? "+ " : "- "}{numberWithCommas(data.amount)}</td>
                    <td>{numberWithCommas(data.current_balance)}</td>
                  </tr>
                })
              }
            </table>
          </div>
          <div className="d-flex w-100 justify-content-end mt-3 mb-5 pb-5">
            {
              currpage > 1 ? <button onClick={() => stepHandler("previous", currpage)} className="border-none py-2 px-3 mx-3 border-radius-1"> Previous </button> : null
            }
            <span className="btn-sky  p-3 px-4 border-radius-1">{currpage}</span>
            {
              <button onClick={() => stepHandler("next", currpage)} className={parseInt((totalrecord / 10)) + 1 > currpage ? "border-none py-2 px-3 ml-3 border-radius-1 visible" : "border-none py-2 px-3 ml-3 border-radius-1 invisible"}> Next </button>

            }
          </div>
        </> : (!loader && data.length===0) ? <h2 className='mt-3'>No points history available</h2> : <Loader />
      }
    </div>
  )

  return <>
    {desktopView}
    {mobileView}
  </>
}

export default PointsTransaction