import { FETCH_ORDER_LIST_FAILURE, FETCH_ORDER_LIST_REQUEST, FETCH_ORDER_LIST_SUCCESS, FETCH_RECEIVEDGIFTS_SUCCESS} from "./MyWalletTypes"

const initialState = {
    eCardEMagOrders:[],
    physicalGiftcardsOrders:[],
    merchantOrders:[],
    mobileTopUpOrders:[],
    receivedGifts:[],
    offersOrders:[],
    experienceOrders:[],
    softwareOrders:[],
    softwareGamesOrder:[],
    gamesOrders:[],
    comingFlights:[],
    pastFlights:[],
    loading: true,
    error:'',
}

const reducer = (state = initialState,action) => {
    switch(action.type){
        case FETCH_ORDER_LIST_REQUEST:
            return {
                loading:true,
                error:'',
                eCardEMagOrders:[],
                merchantOrders:[],
                mobileTopUpOrders:[],
                offersOrders:[],
                experienceOrders:[],
                softwareGamesOrder:[],
                receivedGifts:[],
                receivedOffer:[],   
                softwareOrders:[],
                gamesOrders:[],
                comingFlights:[],
                pastFlights:[]
            }
        case FETCH_ORDER_LIST_SUCCESS:
            return {
                ...state,
                eCardEMagOrders:action.payload.eCardEMagOrders,
                physicalGiftcardsOrders:action.payload.physicalGiftcardOrders,
                merchantOrders:action.payload.merchantOrders,
                offersOrders:action.payload.offersOrders,
                experienceOrders:action.payload.experienceOrders,
                softwareGamesOrder:action.payload.softwareGamesOrder,
                mobileTopUpOrders:action.payload.mobileTopUpOrders,
                softwareOrders:action.payload.softwareOrders,
                gamesOrders:action.payload.gameOrders,
                comingFlights:action.payload.comingFlights,
                pastFlights:action.payload.pastFlights,
                error:'',
                loading:false
            }
        case FETCH_RECEIVEDGIFTS_SUCCESS:
            return{
                ...state,
                receivedGifts:action.payload.giftReceived,
                receivedOffer:action.payload.offerReceived,
            } 
        case FETCH_ORDER_LIST_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.payload
            }
        default: return state
    }
}

export default reducer;
