export const Theme = [
    {
        domain:"https://merit.staging-rewardsby-marketplace.meritincentives.com",
        colorPalette: {
            primaryColor: "#042D49",
            primaryTextColor:"#032338",
            secondaryTextColor:"#032338",
            ternaryTextColor:"#272629",
            primaryBtnBgColor:"#01B6AD",
            secondaryBtnBgColor:"#042D49",
            primaryLinkColor:"#01B6AD",
            primaryBroderColor:"#042D49",
            linkActive:"#01B6AD",
            linkHover:"#01B6AD"
        }
    },
    {
        domain:"https://demo-marketplace.meritincentives.com",
        colorPalette: {
            primaryColor: "#042D49",
            primaryTextColor:"#032338",
            secondaryTextColor:"#032338",
            ternaryTextColor:"#272629",
            primaryBtnBgColor:"#042D49",
            secondaryBtnBgColor:"#042D49",
            primaryLinkColor:"#01B6AD",
            primaryBroderColor:"#042D49",
            linkActive:"#032338",
            linkHover:"#032338"
        }
    }
]

export const setThemeColor = (color) => {
    document.documentElement.style.setProperty('--primaryColor', color?.[0]?.colorPalette?.primaryColor);
    document.documentElement.style.setProperty('--primaryTextColor', color?.[0]?.colorPalette?.primaryTextColor);
    document.documentElement.style.setProperty('--secondaryTextColor', color?.[0]?.colorPalette?.secondaryTextColor);
    document.documentElement.style.setProperty('--ternaryTextColor', color?.[0]?.colorPalette?.ternaryTextColor);
    document.documentElement.style.setProperty('--primaryBtnBgColor', color?.[0]?.colorPalette?.primaryBtnBgColor);
    document.documentElement.style.setProperty('--secondaryBtnBgColor', color?.[0]?.colorPalette?.secondaryBtnBgColor);
    document.documentElement.style.setProperty('--primaryLinkColor', color?.[0]?.colorPalette?.primaryLinkColor);
    document.documentElement.style.setProperty('--primaryBroderColor', color?.[0]?.colorPalette?.primaryBroderColor);
    document.documentElement.style.setProperty('--linkActive', color?.[0]?.colorPalette?.linkActive);
    document.documentElement.style.setProperty('--linkHover', color?.[0]?.colorPalette?.linkHover);
}

export function tradeMarkHandler() {
    let domain = window.location?.origin;
    if (domain === "https://demo-marketplace.meritincentives.com") return "Merit Incentives™"
    else return "RewardsBy ™"
}
