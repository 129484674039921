import React, { Component } from 'react'
import SelectOccassion from './SelectOccassion'
import Breadcumb from './Breadcumb'
import './Buygift.css'
import Yourmessage from './Yourmessage';
import Giftform from './Giftform';
import Customdesign from './Customdesign';
import Birthdaycard from './Birthdaycard';
import {connect} from 'react-redux'

import {fetchocassion} from '../../../Redux/GiftFlowOcassion/giftOcassionActions'

class Giftflow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            form:{
                message: (this.props?.location?.state?.GiftMessage!==undefined)?this.props.location.state.GiftMessage : '',
                to: (this.props?.location?.state?.GiftTo!==undefined)?this.props.location.state.GiftTo : '',
                from: (this.props?.location?.state?.GiftFrom!==undefined)?this.props.location.state.GiftFrom : '',
                email: (this.props?.location?.state?.GiftEmail!==undefined)?this.props.location.state.GiftEmail : '',
                mobile: (this.props?.location?.state?.GiftMobile!==undefined)?this.props.location.state.GiftMobile : '',
                url: (this.props?.location?.state?.GiftUrl!==undefined)?this.props.location.state.GiftUrl : '',
                countryCode: (this.props?.location?.state?.GiftCountryCode!==undefined)?this.props.location.state.GiftCountryCode : '+966',
                format: (this.props?.location?.state?.GiftFormat!==undefined)?this.props.location.state.GiftFormat : 'email'
            },
            cardSelect:(this.props?.location?.state?.GiftCardSelect!==undefined)?this.props.GiftCardSelect:'',
            sliderIndex: (this.props?.location?.state?.GiftSliderIndex!==undefined)?this.props.GiftSliderIndex:'',
            particularCardSelect:(this.props?.location?.state?.GiftCardSelect!==undefined)?this.props.GiftParticularCardSelect:'',
            particularSliderIndex: (this.props?.location?.state?.GiftSliderIndex!==undefined)?this.props.GiftParticularSliderIndex:'',
            second:"maininput",
            third:"maininput"
        }
    }
    componentDidMount() {
        if(this.props.location.state === undefined){
            this.props.history.push('/404');
        }
        if (this.props.images.length===0){
            this.props.fetchocassion();
        }
    }

    nextStep = async ()=>{
        await this.setState({
            step : this.state.step + 1
        });
        this.breadUpdate();
        console.log(this.state);
    }
    previousStep = (val)=>{
        if (val<= this.state.step){ 
            this.setState({
                step: val
            })
        }
    }
    breadUpdate= ()=>{
        if (this.state.step<=2){
            this.setState({
                second: "maininput",
                third: "maininput"
            })
        }
        else if(this.state.step===3){
            this.setState({
                second: "bigcheck",
                third: "maininput"
            })
        }
        else{
            this.setState({
                second: "maininput normalcheck",
                third: "bigcheck"
            })
        }
    }

    changeFormState = (b, e)=>{
        const form = { ...this.state.form, [b]: e };
        this.setState({form});
    };

    cardSelectHandler = (id, index)=>{
        this.setState({
            cardSelect: id,
            sliderIndex: index
        })
    }
    particularCardSelectHandler = (id, index)=>{
        this.setState({
            particularCardSelect: id,
            particularSliderIndex: index
        })
    }


    render() {
        console.log(typeof this.props?.location?.state?.GiftFormat);
        const {step} = this.state;
        const {lang} = this.props;
        const {message, to, from, email, mobile, url, format, countryCode} = this.state.form;
        const {id,GiftMessage,GiftFrom, GiftTo, GiftEmail, GiftMobile, GiftCountryCode} = this.props.location.state || '';
        console.log(this.state);
        switch(step) {
            case 1: {
                return (
                    <>
                        <Breadcumb className
                            lang={lang}
                          step={step}
                          second={this.state.second}
                          third={this.state.third}
                          previousStep={this.previousStep}
                        />
                        <SelectOccassion
                        lang={lang}
                        cardSelectHandler={this.cardSelectHandler}
                        nextStep={this.nextStep}
                        images={this.props.images}
                        loading = {this.props.loading}
                        GiftCardSelect = {this.props.GiftCardSelect}
                        GiftSliderIndex= {this.props.GiftSliderIndex}
                         />
                        
                    </>
                )
            }
            case 2: {
                return (
                    <>
                        <Breadcumb className
                            lang={lang}
                          step={step}
                          second={this.state.second}
                          third={this.state.third}
                          previousStep={this.previousStep}
                        />
                        <Birthdaycard
                        cardSelected={this.state.cardSelect}
                        particularCardSelectHandler={this.particularCardSelectHandler}
                        particularCardSelect={this.state.particularCardSelect}
                        GiftParticularCardSelect={this.props.GiftParticularCardSelect}
                        GiftParticularSliderIndex={this.props.GiftParticularSliderIndex}
                        changeFormState={this.changeFormState}
                        nextStep={this.nextStep}
                        lang={this.props.lang}
                         />
                        
                    </>
                )
            }
            case 3:{
                return (
                    <>
                        <Breadcumb className
                            lang={lang}
                          step={step}
                          second={this.state.second}
                          third={this.state.third}
                          previousStep={this.previousStep}
                        />
                        <Yourmessage
                        message={message}
                        GiftMessage={GiftMessage}
                        changeFormState={this.changeFormState}
                        nextStep={this.nextStep}
                        lang={lang}
                         />
                    </>
                )
            }
            case 4:{
                return (
                    <>
                        <Breadcumb className
                            lang={lang}
                          step={step}
                          second={this.state.second}
                          third={this.state.third}
                          previousStep={this.previousStep}
                        />
                        <Giftform
                        lang={lang}
                        to={to}
                        from={from}
                        email={email}
                        mobile={mobile}
                        countryCode={countryCode}
                        GiftMessage={GiftMessage}
                        GiftFrom={GiftFrom}
                        GiftEmail={GiftEmail}
                        GiftTo={GiftTo}
                        GiftMobile={GiftMobile}
                        GiftCountryCode={GiftCountryCode}
                        GiftFormat={this.props.GiftFormat}
                        changeFormState={this.changeFormState}
                        nextStep={this.nextStep} 
                        />
                    </>
                )
            }
            case 5:{
                return (
                    <>
                        <Breadcumb className
                            lang={lang}
                          step={step}
                          second={this.state.second}
                          third={this.state.third}
                          previousStep={this.previousStep}
                        />
                        <Customdesign
                        lang={lang}
                        id = {id}
                        url={url}
                        message={message}
                        to={to}
                        from={from}
                        email={email}
                        mobile={mobile}
                        countryCode={countryCode}
                        format={format}
                        cardSelect={this.state.cardSelect}
                        sliderIndex={this.state.sliderIndex}
                        particularCardSelect={this.state.particularCardSelect}
                        particularSliderIndex={this.state.particularSliderIndex}
                        GiftParticularSliderIndex={this.props.GiftParticularSliderIndex}
                        GiftSliderIndex={this.props.GiftSliderIndex}
                        GiftMessage={GiftMessage}
                        GiftFrom={GiftFrom}
                        GiftEmail={GiftEmail}
                        GiftTo={GiftTo}
                        GiftMobile={GiftMobile}
                        GiftCountryCode={GiftCountryCode}
                        nextStep={this.nextStep} 
                        />
                    </>
                )
            }
            default:{
                return (
                    <>

                    </>
                )
            }
        }

        
    }
}

const mapStateToProps = (state) => {
    return {
      images: state.giftOcassion.images,
      loading: state.giftOcassion.loading,
      GiftCardSelect: state.giftFormDetails.GiftCardSelect,
      GiftSliderIndex: state.giftFormDetails.GiftSliderIndex,
      GiftParticularCardSelect: state.giftFormDetails.GiftParticularCardSelect,
      GiftParticularSliderIndex: state.giftFormDetails.GiftParticularSliderIndex,
      GiftFormat: state.giftFormDetails.GiftFormat,
    };
  };
  

const mapDispatchToProps = dispatch => {
    return {
        fetchocassion : () => dispatch(fetchocassion()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)((Giftflow));