// import { fetchPointBalance } from "../Redux/point/pointActions";
// import store from '../Redux/store'

export default function  init (){
    window.onload=async()=>{
        // Disabling Points Balance API
        // if(!window.location.pathname.includes('orders-summary')){
        //     // setTimeout(()=>{
        //         store.dispatch(fetchPointBalance())
        //     // },3000)
        // }
    }
    //set Language
}