import React, { Component } from 'react';
import Breadcrumbs from '../shared/Breadcrumbs';
import img1 from '../../assests/img/experiences/img (1).jpg'
import img2 from '../../assests/img/experiences/img (2).jpg'
import img3 from '../../assests/img/experiences/img (3).jpg'
import img4 from '../../assests/img/experiences/img (4).jpg'
import img5 from '../../assests/img/experiences/img (5).jpg'
import adultIcon from '../../assests/img/experiences/icon-user-head-empty.png'
import AboutAndTC from '../shared/expAboutAndT&C';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { fetchExperienceDetails, fetchExperienceImages, fetchExperienceTerms } from '../../Redux/experience/experienceAction';
import { currancyToPoints } from '../../utils/pointsConversion';
import BuyCardforExperience from '../shared/BuyCardforExperience';
import ExperienceConfirmationModal from '../shared/Modals/ExperienceConfirmationModal';
import { checkTokenValidity } from '../../Redux/auth/authActions';
import calendarIcon from '../../assests/img/experiences/icon-calendar.svg'
import DatePicker, { registerLocale }  from "react-datepicker";
import ar from 'date-fns/locale/ar-SA'
import "react-datepicker/dist/react-datepicker.css";
import './Experience.css'
import GoalItemStatusModal from '../GoalItems/GoalItemStatusModal';
import  GoalItemConfirmModal  from '../GoalItems/GoalItemConfirmModal';
import { setGoalItem } from '../../Redux/goalItems/goalItemsActions';
import goalItemImage from '../../assests/img/goalitems/icon-change-goal-item.svg'
registerLocale("ar", ar);

class ExperienceBookingPage extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            experience:{name:"Mada'In Saleh",points:'220',id:21,images:[img1,img2,img3,img4,img5]},
            isLoaded:false,
            selectedmgInd:0,
            selectedtime:null,
            users:1,
            recipient_name:'',
            recipient_email:'',
            recipient_phone:0,
            confirmOrder:false,
            orders:{},
            pyload:{},
            selDate:null,
            slotAvailabe:true,
            includeDates:[],
            cardPayment:false,
            paymentType:'points',
            goalModalShow:false,
            goalStatusModalShow:false,
            goalModalDetail:''

         }
    }
    setSelectedmgInd=(ind)=>{
        this.setState({selectedmgInd:ind})
    }
    setSelectedTime=(time)=>{
        this.setState({selectedtime:time})
    }

    setSelectedDate = (date) => {
        let dateFormat = date.toISOString()?.split("T")[0]
        
        if(this.props.experienceDetails.slots[dateFormat]?.length>=0){
            let timeSlot = this.props.experienceDetails.slots[dateFormat][0]
            this.setState({selectedtime:timeSlot}) 
        }
        
        this.setState({selDate:dateFormat})
    }
    componentDidMount(){
        localStorage.removeItem("type");
        localStorage.setItem("type", "experience");
        window.scrollTo(0, 0)
        const payload = {
            "experiences": {
                "currency": this.props.countrySelected,
                "id":  this.props.match.params.id
            }
        }
        this.props.checkTokenValidity() 
        this.props.fetchExperienceDetails(payload)
        this.props.fetchExperienceImages(payload)
        this.props.fetchExperienceTerms(payload)
        if( !this.props.loading &&  this.props.experienceDetails?.length === 0){
            this.props.history.push({
                pathname:'/404',
                redirectionURL:'/experiences'
            })
        }
        if( !this.props.loading &&  this.props.experienceDetails?.length > 0){
            this.setState({users: this.props.experienceDetails?.price_per_users[0]})
        }
       
    }
    orderSummary = (val) => {
        const experience = this.props.experienceDetails
        let amount = experience.price_per_users[this.state.users]
        let amount_points = currancyToPoints(amount)
        let {paymentType} = this.state
        paymentType === 'creditcard' ? this.setState({cardPayment:true}) : this.setState({cardPayment:false})
        this.setState(prevState=>({
            confirmOrder:!this.state.confirmOrder,
            orders: {
                "brand_id":experience.id,
                "quantity":1,
                "amount":amount,
                "program_id":1,
                "isforself":true,
                "use_hassad_points":paymentType === 'creditcard' ? false : this.props.pointBalance>0,
                "language_code":this.props.lang,
                "currency":this.props.countrySelected,
                "hassad_points": paymentType === 'creditcard' ? 0 : amount_points <= this.props.pointBalance ? amount_points: this.props.pointBalance
            },
            pyload:{
                "experience_orders": {
                    "brand_id":experience.id,
                    "currency": this.props.countrySelected,
                    "experience_value": amount,
                    "number_of_users": this.state.users,
                    "experience_slot_date": this.state.selDate,
		            "experience_slot_time": this.state.selectedtime,
                    "use_hassad_points":paymentType === 'creditcard' ? false : this.props.pointBalance>0,
                    "hassad_points": paymentType === 'creditcard' ? 0 : Number(amount_points) <= Number(this.props.pointBalance) ? Number(amount_points): Number(this.props.pointBalance),
                    "language_code": this.props.lang,
                    "recipient_name": this.state.recipient_name,
                    "recipient_email": this.state.recipient_email,
                    "recipient_phone": this.state.recipient_phone,  
                    "amount": Number(amount)
                }
            }
        }))
}
handleCallback = (childData) =>{

    this.setState({recipient_name: childData.name, recipient_email:childData.email,recipient_phone:childData.number,paymentType:childData.paymentType},()=>this.orderSummary())
}
componentDidUpdate(prevProps,prevState){
    if( typeof this.state.selDate !== 'string' && !this.props.loading && this.props.experienceDetails.slots){
        this.includeDates()
        // Setting Dates
        this.setState({selDate: Object.keys(this.props.experienceDetails.slots)[0]}, 
        ()=>{
            if(!!this.props.experienceDetails?.time_slot_required && this.props.experienceDetails?.slots && Object.values(this.props.experienceDetails?.slots)){
                let time = Object.values(this.props.experienceDetails.slots)
                // Setting Time Slots
               time[0][0] && this.setState({selectedtime:time[0][0]})
            }
        }
        )    
    }
    if((prevProps.currancyCode !== this.props.currancyCode) || (this.props.match.params.id !== prevProps.match.params.id) ){
        this.setState({isLoaded:false})
        const payload = {
            "experiences": {
                "currency": this.props.countrySelected,
                "id":  this.props.match.params.id
            }
        }
        this.props.fetchExperienceDetails(payload)
        this.props.fetchExperienceImages(payload)
        this.props.fetchExperienceTerms(payload)
    }
    if(!this.props.loading && this.props.experienceDetails?.length === 0){
        this.props.history.push({
            pathname:'/404',
            redirectionURL:'/experiences'
        })
    }

    if(!this.props.loading && !this.props.imgLoading){
        // Disabling keyboard to pick dates
        const datePicker=document.getElementsByClassName("react-datepicker__input-container")[0];
        datePicker?.childNodes[0]?.setAttribute("readOnly",true);
    }
    
    if(!this.props.loading && !this.props.imgLoading && !this.state.isLoaded){
       setTimeout(this.setState({isLoaded: true}))
    }
    if( !this.props.loading &&  this.props.experienceDetails?.price_per_users && prevProps.loading !== this.props.loading
        ){
            if(Object.keys(this.props.experienceDetails?.price_per_users)[0] !== this.state.users){
                this.setState({users: Object.keys(this.props.experienceDetails?.price_per_users)[0]})
            }
        }
}
componentWillUnmount(){
    this.setState({isLoaded: false})
}
numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


includeDates = () => {
    // Adding dates which are available
    let includeDates = [];
    let dates = this.props.experienceDetails?.slots && Object.keys(this.props.experienceDetails.slots)
    dates && dates?.forEach(dates => includeDates.push(new Date(dates)))
    this.setState({includeDates:includeDates})
}
handleDateChangeRaw = (e) => {
    // Disabling keyboard input
    e.preventDefault();
  }
//   Using Icon to open calendar
  openDatepicker = () => this._calendar?.setOpen(true);

  setGoalModalView = (value)=>{
    this.setState({goalModalShow: !this.state.goalModalShow,goalModalDetail:value || this.state.goalModalDetail})
}
setGoalConfirmModalView = ()=>{
    this.setState({goalConfirmModalShow: !this.state.goalConfirmModalShow})
}

setGoalStatusModalView = () => {
    this.setState({goalStatusModalShow:false})
}

setGoalItem = (date) => {
    let payload = {
        "customer_goal_item": [{
            "product_id": this.props.match?.params?.id,
            "product_type": "EXPERIENCES",
            "product_price": this.props.experienceDetails?.price_per_users[this.state.users],
            "goal_item": this.state.goalModalDetail === 'Remove' ? false : true,
            "no_of_attendees":this.state.users,
            "remind_me_later_date": date ? new Date(date)?.toISOString()?.slice(0,10) : ''
        }]
    }

    let updatePayload = {
        "customer_goal_items": {
            "product_id": this.props.match?.params?.id,
            "product_type": "EXPERIENCES",
            "product_price": this.props.experienceDetails?.price_per_users[this.state.users],
            "no_of_attendees":this.state.users,
            "remind_me_later_date": date ? new Date(date)?.toISOString()?.slice(0,10) : ''
        }
    }
    
    this.props.setGoalItem(
        this.state.goalModalDetail === 'Update' ? updatePayload : payload,
        this.fetchExperience,
        this.state.goalModalDetail);
    this.setState({goalStatusModalShow:true})
}

    render() { 
        const goalItemStyle = {
            backgroundImage: `url(${ goalItemImage })` ,
             backgroundRepeat : 'no-repeat',
                 backgroundPosition: '10%' , 
          };

        const experience = this.props.experienceDetails
        const experienceImages = this.props.experienceImages
        const {t} = this.props
        const {goalModalShow,goalStatusModalShow,goalModalDetail} = this.state
        const breadcrumbs =[
            {name:'Home',url:'/',active:true},
            {name:'Experiences',url:`/experiences`,active:true},
            {name:this.props.lang !== 'ar' ? experience.name : experience.name_arabic,url:'',active:false}
        ] 
        let showCard = true
       
       if(experience.time_slot_required === 0 && experience.slots && !this.props.loading){
        //    Picking dates for which time slot isn't available
           showCard = true
       }
       else if(experience.time_slot_required === 1 && !experience.slots && !this.props.loading){
        //    Page Break Fix
           showCard = false
       }
       let dates;
       if(experience.slots &&  Object.keys(experience.slots)?.length>=0){
           dates = Object.keys(experience.slots);
        }
        let touchstartX = 0;
        let touchendX = 0;
        function handleGesture() {
            if (touchendX < touchstartX) {document.getElementsByName('expCardNext')[0].click()} ;
            if (touchendX > touchstartX) {document.getElementsByName('expCardPrev')[0].click()} ;
            } 
            
        return ( 
            <>
            <GoalItemStatusModal goalModalDetail={goalModalDetail} show={goalStatusModalShow} setGoalModalView={this.setGoalStatusModalView}/>
                    {
                        !this.state.isLoaded ? <section>
                        <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4 ">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                </section> :
                <section>
                <Breadcrumbs crumbs={breadcrumbs}/>
                <GoalItemConfirmModal goalModalDetail={goalModalDetail} setGoalModalView={this.setGoalModalView} show={goalModalShow} lang={this.props.lang} productValue={experience?.price_per_users?.[this.state.users]} currancyCode={this.props.currancyCode} setGoalItem={this.setGoalItem} image={experience?.image?.medium_rectangle} 
                title={experience.name} title_arabic={experience.name_arabic}
                />
                         {
                                this.state.confirmOrder &&  <ExperienceConfirmationModal lang={this.props.lang} orders={this.state.orders} pyload={this.state.pyload} card={experience} show={this.state.confirmOrder} remove={this.orderSummary} cardPayment={this.state.cardPayment}/>
                            }
                        <div className="p-md-5 p-3 d-flex flex-md-row flex-column">
                        
                            <div className="flex-shrink-0 d-md-flex d-none gap-4 flex-column">
                                {
                                  experienceImages &&  experienceImages
                                    .map((exp,ind)=>(
                        
                                        
                                        <img src={exp.image?.large_rectangle} key={ind} alt="" 
                                            onClick={()=>{this.setSelectedmgInd(ind)}}
                                            className={`cursor-pointer ${this.state.selectedmgInd===ind ?'border-color-skyblue border-2 bg-primary':''}`}
                                            style={{objectFit:'cover',width:'5rem',height:'5rem'}}/>
                                    ))
                                }
                            </div>
                            <div className="flex-grow-1 d-grid grid-cols-md-2 grid-col-1 gap-5 px-md-5 px-0">
                                <div className='d-md-block d-none'>
                                   {experienceImages &&  <img 
                                        className="w-100"
                                        src={experienceImages[this.state.selectedmgInd]?.image?.large_rectangle} 
                                        alt={experience.name} srcSet=""
                                        style={{objectFit:'fill',maxHeight:'30vw'}}/>}
                                </div>
                                <div className="p-0 text-black-50">
                                    <div className="pb-3">
                                        <div className="fs-4 fw-bolder product_title">
                                            {this.props.lang !== 'ar' ? experience.name : experience.name_arabic}
                                        </div>
                                        <div className="fw-bold text-black-50">
                                            {t(`${experience.type?.slice(0,1)}${experience.type?.toLowerCase().slice(1) } Experience`)} 
                                        </div>
                                        {experience.goal_item_flag && <div style={goalItemStyle} className='goalItemSVG text-light text-center p-1'><p className='ml-3'>{t("Goal Item")}</p></div>}
                                        <div className="fs-5 fw-bold product_value" style={{fontSize:"20px"}}>
                                            {experience.price_per_users && this.numberWithCommas(experience.price_per_users[this.state.users])}<small> &nbsp; {t(`${this.props.currancyCode}`)}</small>
                                        </div>
                                    </div>
                                    
                                        <div onTouchStart={(e)=>{touchstartX = e.changedTouches[0].screenX;}} onTouchEnd={(e)=>{touchendX = e.changedTouches[0].screenX;
  handleGesture();}}  id="carouselMobExpIndicators" data-interval='3000' className="carousel slide text-center d-md-block d-lg-none" data-ride="carousel">
                        <div className='d-sm-inline-block'>
                            <ol className="indicator-size carousel-indicators mb-4">
                               
                                {
                                    experienceImages && experienceImages.map((e,index)=>
                                        <li key={index} data-target='#carouselMobExpIndicators' className={`${index===0 && `active`}`} data-slide-to={index}></li>
                                    )
                                }
                                
                            </ol>
                        </div>
                        
                        <div  className="carousel-inner">
                        {experienceImages && experienceImages.map((banner,index) =>
                                <div key={index} className={`${index===0 && `active`} carousel-item`}>
                                <img src={experienceImages[index]?.image?.medium_square} alt='' />
                            </div>) }
                             
                        </div>
                        <div className="d-md-block d-none">
                            <a className="carousel-control-prev" name='expCardPrev' href="#carouselMobExpIndicators" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" name='expCardNext' href="#carouselMobExpIndicators" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a>
                        </div>
                        
                    
                                    </div>
                                    {
                                        experience.slots &&
                                        
                                        <>
                                            <div className="py-3 mobile-margin-top mt-lg-0">
                                        <label 
                                            className="d-block text-muted"
                                            htmlFor="date">{t("Date")}</label>
                                        <div className='d-flex date-border w-50 px-2 py-1'>
                                        <img src={calendarIcon} className='cursor-pointer' alt="" srcSet="" onClick={this.openDatepicker} />
                                        {
                                            dates && <DatePicker className="form-control rounded-0 border-0 no-outline cursor-pointer" 
                                            dateFormat="MMMM d, yyyy"
                                            selected={new Date(this.state.selDate)} 
                                            minDate={new Date(dates[0])}
                                            maxDate={new Date(dates[dates.length - 1])}
                                            onChange={(date) => this.setSelectedDate(date)}
                                            includeDates={this.state.includeDates}
                                            onChangeRaw={this.handleDateChangeRaw}
                                            ref={(c) => this._calendar = c} 
                                            withPortal
                                            calendarClassName="rasta-stripes"
                                            locale={this.props.lang ==='ar' && ar}
                                            />
                                        }
                                        </div>

                                    </div>
                                    {
                                        experience.time_slot_required === 1 && <div className="pb-3">
                                            <div className='pb-2'>{t("Time")}: <span className="text-dark fw-bold">{this.state.selectedtime}</span></div>
                                        <div className="d-flex flex-wrap gap-3">
                                            {
                                              experience.slots && experience.slots[this.state.selDate]?.map(time=>(
                                                    <button
                                                        className={`btn rounded-0 text-muted no-outline ${this.state.selectedtime===time?'selected-value':' border'}`}
                                                        key={time}
                                                        onClick={()=>{this.setSelectedTime(time)}}>
                                                        {time}
                                                    </button>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    }
                                        </>  }
                                        {
                                            !showCard && <div className="text-center mt-5"> 
                                            <p className='text-danger fw-bold'>{t("Sold Out")}...... {t("Coming Soon")}</p>
                                        </div>
                                        }
                                    {
                                        showCard && <div className={`pb-3 d-md-none d-block ${experience.time_slot_required === 0 ? 'mt-5': 'mt-1'}`}>
                                        <label className="d-block">{t("Number of Attendees")}</label>
                                        <div className="dropdown">
                                        <button className="btn  border rounded-0 text-muted   p-2 px-2 " type="button" data-flip='false' data-toggle="dropdown">
                                                <img src={adultIcon} className="px-2 py-1" alt=''/>
                                                {`${this.state.users}`}<span className='dropdown-toggle justify-content-end px-2'></span>
                                            </button>
                                                <ul className="dropdown-menu  list">
                                                {experience.price_per_users && Object.keys(experience.price_per_users)?.map((key) =><li key={key}
                                                onClick={()=> this.setState({users:key})}
                                                style={{cursor:'pointer'}} className="p-2 list-item">{`${key}`}</li> )}
                                            </ul>
                                        </div>
                                    </div>
                                    }
                                    {
                                        showCard && <div className="pb-3 d-md-block d-none mt-1">
                                        <label className="d-block">{t("Number of Attendees")}</label>
                                        <div className="dropdown">
                                        <button className="btn  border rounded-0 text-muted  px-2 p-2 " type="button" data-flip='false' data-toggle="dropdown">
                                                <img src={adultIcon} className="px-2 py-1" alt=''/>
                                                {`${this.state.users} `}<span className='dropdown-toggle justify-content-end px-2'></span>
                                            </button>
                                                <ul className="dropdown-menu list">
                                                {experience.price_per_users && Object.keys(experience.price_per_users)?.map((key) =><li key={key}
                                                onClick={()=> this.setState({users:key})}
                                                style={{cursor:'pointer'}} className="p-2 list-item">{`${key} `}</li> )}
                                            </ul>
                                        </div>
                                    </div>
                                    }
                                    {
                                        showCard && <AboutAndTC lang={this.props.lang} />
                                    }
                                </div>
                            </div>
                        
                            {
                                showCard &&
                                <BuyCardforExperience parentCallback = {this.handleCallback} onSubmit={this.orderSummary} lang={this.props.lang}
                                price={experience?.price_per_users
                                    
                                    && (experience?.price_per_users[this.state.users])>1 ? true : false}
                                    setGoalModalView={this.setGoalModalView}
                                    productID = {this.props.match?.params?.id}
                                    selectedRate = {experience?.price_per_users?.[this.state.users]}
                                    toogleLang={this.props.toogleLang}
                                />
                            }
                        </div>
                    </section>
                    }
            </>
         );
    }
}

const mapStateToProps = state => {
    return {
        experienceDetails:state.experiences.experienceDetails,
        experienceImages:state.experiences.experienceImages,
        loading:state.experiences.detailsLoading,
        countrySelected:state.countries.countryId,
        currancyCode:state.countries.convertionRate.currency_code,
        fetchError:state.experiences.error,
        pointBalance:state.point.pointBalance,
        imgLoading:state.experiences.imgLoading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchExperienceDetails: (payload) => dispatch(fetchExperienceDetails(payload)),
        fetchExperienceTerms: (payload) => dispatch(fetchExperienceTerms(payload)),
        fetchExperienceImages:(payload) => dispatch(fetchExperienceImages(payload)),
        checkTokenValidity : () => dispatch(checkTokenValidity()),
        setGoalItem: (payload,productAPI,status) => dispatch(setGoalItem(payload,productAPI,status))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ExperienceBookingPage));