import { DefalutCountry } from "utils/DefaultCountry";
import { FETCH_COUNTRIES_FAILURE, FETCH_COUNTRIES_REQUEST, FETCH_COUNTRIES_SUCCESS, SET_CONVERSION_RATE, SET_COUNTRY, SET_LANGUAGES } from "./countryTypes"

const userCountry = localStorage.getItem("countryID");
let url = window.location.origin
const initialState = {
    countries:[],
    languages: [],
    loading: true,
    selectedCountry: {},
    error:'',
    countryId:DefalutCountry(url) ? DefalutCountry(url) :(userCountry ? Number(userCountry) : 1),
    convertionRate:{}
}

const reducer = (state = initialState,action) => {
    switch(action.type){
        case FETCH_COUNTRIES_REQUEST:
            return {
                ...state,
                loading:true
            }
        case FETCH_COUNTRIES_SUCCESS:
            return {
                ...state,
                loading:false,
                countries:action.payload.countries,
                languages: action.payload.languages,
                selectedCountry: action.payload.selectedCountry
            }
        case SET_LANGUAGES:
            return {
                ...state,
                languages: action?.payload?.languages,
                selectedCountry: action?.payload?.selectedCountry
            }
        case FETCH_COUNTRIES_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.payload
            }
        case SET_COUNTRY:
            return {
                ...state,
                countryId:action.payload
            }
        case SET_CONVERSION_RATE:
            return {
                ...state,
                convertionRate:action.payload
            }
        default: return state
    }
}

export default reducer;