import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import '../../assests/css/App.css';
import '../../assests/css/Giftsection.css';
import Breadcrumbs from '../shared/Breadcrumbs';
import { Dropdown } from 'react-bootstrap';
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import filterIcon from '../../assests/img/header/icon-filter-categories.png'

class GiftCardsPage extends Component {

    constructor(props) {
        super(props);
        if (props.location.state !== null && props.location.state !== undefined) {
            this.referrer = props.location?.state?.referrer
        }

        this.state = {
            information: this.referrer,
            clicked: false,
            giftcards: [],
            cardsDetails:[],
            letter:'Default',
            sortedGiftCards:undefined,
            catName:'All'
        }
    }
    componentDidMount() {
        this.setState({
            cardsDetails:this.props.giftCards})
            if(!this.props.loading && this.props.giftCards === undefined){
                this.props.history.push({
                    pathname:'/404',
                    redirectionURL:'/giftcards'
                })
            }
    }

    componentDidUpdate=(prevProps,prevState)=>{
 
        if((prevProps.currancyCode !== this.props.currancyCode) ){
            this.setState({sortedGiftCards:undefined,letter:'Default'})
        }
        if(this.props.giftCards?.length===0 ){
            this.props.history.push({
                pathname:'/404',
                redirectionURL:'/giftcards'
            })
        }
        if(prevProps.giftCards !== this.props.giftCards){
            this.setState({
                cardsDetails:this.props.giftCards})
        }
        if(!this.props.loading && this.props.giftCards === undefined){
            this.props.history.push({
                pathname:'/404',
                redirectionURL:'/giftcards'
            })
        }
    }

    sortGiftCards(data,letter){
        let sortedCards;
        if(letter === 'A-Z'){
            
            sortedCards = data.sort(function(a, b){
                return a.name.toLowerCase() === b.name.toLowerCase() ? 0 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
            })
            this.setState({sortedGiftCards:sortedCards,letter:letter})
            
        }
        else if(letter === 'Z-A'){
            
            sortedCards = data.sort(function(b, a){
                return a.name.toLowerCase() === b.name.toLowerCase() ? 0 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
            })
            this.setState({sortedGiftCards:sortedCards,letter:letter})
    
        }
        else{
            this.setState({sortedGiftCards:data,letter:'Default'})
        }

    }

    categorizeGiftCards(catName){
        if(catName === 'All'){
            this.setState({cardsDetails:this.props.giftCards,sortedGiftCards:undefined,letter:'Default',catName:catName})
        }
        else{
            let catGiftCards = this.props.giftCards.filter(e => e.name === catName)
            this.setState({cardsDetails:catGiftCards,sortedGiftCards:undefined,letter:'Default',catName:catName})
        }

    }
    render() {
        const { t } = this.props;
        const breadcrumbs=[
            {name:t("Home"),url:"/",active:true},
            {name:t("Giftcards"),url:"",active:false}
        ]
        let totalGiftCards = [];
        const giftcards = this.state.cardsDetails?.map(cards =>(
            // eslint-disable-next-line array-callback-return
            cards?.brands &&   cards?.brands?.map((card,index) => { 
                    if(!card?.emagazine_flag && !card?.is_topup && !card?.point_exchange_flag) {
                        totalGiftCards.push(card)
                        return (
                            <div className="text-center border-0" key={card?.id}>
                                <Link className="active p-0 "
                                data-testid={`giftCard-${index}`}
                                    to={{
                                        pathname: `/selectedgiftcard/${card?.id}`,
                                        state: {...card }
                                    }}>
                                    <img className="img-fluid card-hover" src={card?.images?.color?.medium_rectangle} alt={card?.name} />
                                </Link>
                                <div className='text-center py-3 product_title fw-bold' >
                                    { this.props.lang !== 'ar' ? card?.name:card?.name_arabic }
                                </div>
                                {card?.rates=== null ?
                         <div className='text-danger fw-bold ' style={{fontSize:"12px"}}>{t("Sold Out")}...... {t("Coming Soon")}</div>
                                  : false    
                                }
                                
                            </div>
                    )
                    }
                }
                )
        ))

        const displaySortCards = this.state.sortedGiftCards?.map(
            card => (
                <div className="text-center border-0" key={card?.id}>
                    <Link className="active p-0 "
                        to={{
                            pathname: `/selectedgiftcard/${card?.id}`,
                            state: {...card }
                        }}>
                        <img className="img-fluid card-hover" src={card?.images?.color?.medium_rectangle} alt={card?.name} />
                    </Link>
                    <div className='text-center py-3 product_title fw-bold' >
                        { this.props.lang !== 'ar' ? card?.name:card?.name_arabic }
                    </div>
                </div>
        )
        )
        const letters = ["Default","A-Z","Z-A"]
        
        const desktopView = (
           <div className='d-md-block d-none'>
               <div className='d-flex justify-content-between'>
            <div>
            <div className="headings fw-md-bold fw-bolder fs-md-3 fs-5">{t("Gift Cards")}</div>
            </div>
            <div className={`${this.props.lang === 'ar'?"":"ml-5"} `} >
            <Dropdown className='d-inline '>
                <DropdownToggle style={{width:'8rem'}} className={`${this.props.lang === 'ar'?"float-left":""} text-light rounded-0 mt-1 px-0 bg-white no-outline`}>
                                <span className='second-color fw-bold float-left px-3'>{`${t(this.state.letter)}`}</span>
                                <span className='arrow-down color-skyblue float-right'></span>
                            </DropdownToggle>
                           <DropdownMenu>
                            {
                                    letters.map((e)=>(
                                        <DropdownItem key={e}
                                        onClick={()=>  
                                            this.sortGiftCards(totalGiftCards,e)}
                                            >
                                        {t(e)}</DropdownItem>
                                    ))
                                }
                            </DropdownMenu>
            </Dropdown>
            </div>
            </div>
           </div>
                    )
        const mobileView =(
            <>
                <div className='d-block d-md-none'>
                            <div>
                             <span className='fs-3 headings fw-bold'>{t("Gift Cards")}</span>
                             <span className='fs-5 text-muted'> - {t(this.state.catName)}</span>
                          </div>
                            <div className='d-flex flex-row justify-content-end py-2'>
                
                            <div>
                             <div style={{maxWidth:"50px", marginRight:"16px", maxHeight:"40px"} }  id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-flip="false">
                                 <img style={{minHeight:"31px",minWidth:"31px"}} src={filterIcon} alt="categorie" className="img-fluid"/>
                            </div>
                
                   <div  className="dropdown-menu" aria-labelledby="dropdownMenuButton">   
                       <DropdownItem  onClick={()=> this.categorizeGiftCards("All")}>{t("All")}</DropdownItem>
                         {
                           this.props.giftCards &&  this.props.giftCards.filter(category =>          
                           category?.name !== "Mobile Recharge" && category?.name !== "E-Magazine" ).map(cate => (
                            <Link
                            to={`/category/${cate.name}`}
                            key={cate?.name}
                            onClick={()=> this.categorizeGiftCards(cate?.name)}
                            className="dropdown-item">{t(cate?.name)}</Link>
                           )) 
                        }          
                    </div>
                      </div>
                    
                    <Dropdown style={{maxWidth:'fit-content'}}  className='d-inline-block'>
                     <DropdownToggle  className={`${this.props.lang === 'ar'?"mr-2":""} text-light rounded-0 px-0 pb-1 bg-white no-outline`}>
                                <span className='second-color fw-bold float-left px-3'>{`${t(this.state.letter)}`}</span>
                                <span className='arrow-down color-skyblue float-right px-2'></span>
                            </DropdownToggle>
                           <DropdownMenu>
                            {
                                    letters.map((e)=>(
                                        <DropdownItem key={e}
                                        onClick={()=>  
                                            this.sortGiftCards(totalGiftCards,e)}
                                            >
                                        {t(e)}</DropdownItem>
                                    ))
                                }
                            </DropdownMenu>
                         </Dropdown>
                </div>
                </div>
             </>                                  
        )



        return (
            <>
           {this.props.loading ? <section>
                <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4 ">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
        </section> :
            <div>
                <Breadcrumbs crumbs={breadcrumbs}/>
                <section className="px-md-5 px-3 py-md-4 py-1">
                    {desktopView}
                    {mobileView}
                    <div className="py-md-4 pt-3 d-grid grid-cols-md-4 grid-cols-2 gap-md-5 gap-2 content-box">
                        {!!this.state.sortedGiftCards ? displaySortCards : giftcards }
                    </div>
                </section>
            </div>
             } 
            </>
        )
    }

}

const mapStateToProps = state => {
    return {
        giftCards:state.brand.brandData,
        loading:state.brand.loading,
        letter:state.giftCards.letter,
        countrySelected:state.countries.countryId,
        currancyCode:state.countries.convertionRate.currency_code,
        countriesloading: state.countries.loading,
        fetchError:state.brand.error
    }
}


export default connect(mapStateToProps)(withTranslation()(GiftCardsPage));
