import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { currancyToPoints } from '../../utils/pointsConversion'
import calendarIcon from '../../assests/img/experiences/icon-calendar.svg'
import DatePicker, { registerLocale }  from "react-datepicker";
import ar from 'date-fns/locale/ar-SA'
registerLocale("ar", ar);



 class GoalItemConfirmModal extends Component {
     constructor(props){
         super(props);
         this.state={
             dateModal:false,
             selDate:new Date()
         }
     }
    numberWithCommas = (x) => {
        let y = Number(x)?.toFixed(2)
        return y?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    handleDateChangeRaw = (e) => {
        // Disabling keyboard input
        e.preventDefault();
      }
    //   Using Icon to open calendar
      openDatepicker = () => this._GoalItemCalendar?.setOpen(true);

      removeGoalItem = () => {this.props.setGoalModalView();this.props.setGoalItem()}
    render() {
        const {productValue,currancyCode,t,setGoalModalView,show,setGoalItem,goalModalDetail,image,title,title_arabic,isPhysicalCard,giftcard_points} = this.props
        return (
            <Modal 
            backdrop="static"
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            >
                <div 
                    className="p-4 bg-light mx-auto text-center py-md-5 py-4 rounded">
                    {
                        this.state.dateModal ?  
                        <>
                           <h3 className='text-muted fw-bold m-0 py-0'>
                            {t("Select your target date")}
                           </h3>
                           <p className='text-muted my-2'>{t("A date, you need to achieve points to your goal item")}</p>
                           <div className='d-flex border-date justify-content-center mx-auto px-5 py-2 my-3'>
                                        <img src={calendarIcon} className='cursor-pointer' alt="" srcset="" onClick={this.openDatepicker} />
                                        <DatePicker className="form-control rounded-0 border-0 no-outline cursor-pointer mx-auto" 
                                            dateFormat="MMMM d, yyyy"
                                            selected={this.state.selDate} 
                                            minDate={new Date()}
                                            onChange={(date) => this.setState({selDate:date})}
                                            onChangeRaw={this.handleDateChangeRaw}
                                            ref={(c) => this._GoalItemCalendar = c} 
                                            withPortal
                                            locale={this.props.lang ==='ar' && ar}
                                            />
                            </div>
                            <button 
                                className="btn primary-btn text-light border-color-green rounded mx-2 mt-3"
                                onClick={()=>{setGoalModalView();setGoalItem(this.state.selDate)}}>
                                {t("Continue")}
                            </button>
                        </>
                        : 
                        <>
                            <h3 className='text-muted m-0 py-0'>
                    {(`${t(goalModalDetail) || ''} ${t("as goal Item")}`)}
                    </h3> 
                    <div className="d-flex flex-row border p-2 m-2 mt-3">
                    <div className="col-4 p-0">
                            <img  
                                className="img-fluid p-0 mx-auto d-block"   
                                src={image}
                                alt={title}
                            />
                        </div>
                    <div className="col-8 d-flex flex-column text-left justify-content-center">
                        <div className="fw-md-bold fw-bolder color-blue">
                        <h4>{ this.props.lang !== 'ar' ? title : title_arabic }</h4>
                        
                        </div>
                        <div className='pb-2'>
                            <span className='text-muted'>{t("Card Value")}</span>: <span className="text-dark fw-bold">{t(currancyCode)} {productValue} ({this.numberWithCommas(isPhysicalCard ? giftcard_points : currancyToPoints(productValue))} {t("Points")})</span></div>
                    </div>
                    </div>
                    <button 
                        className="btn  border-color-blue mt-3 mx-2"
                        onClick={()=>{setGoalModalView()}}>
                        <span className="fw-bold color-blue">{t("Cancel")}</span>
                    </button>
                    <button 
                        className="btn primary-btn text-light border-color-green rounded mx-2 mt-3"
                        onClick={()=>{goalModalDetail === 'Remove' ? this.removeGoalItem() : this.setState({dateModal:true})}}>
                        {t("Confirm")}
                    </button>
                        </>
                    }
                </div>
            </Modal>
        )
    }
}

export default withTranslation()(GoalItemConfirmModal)
