import React, { Component } from 'react';
import calendarIcon from '../../assests/img/experiences/icon-calendar.svg'
import DatePicker, { registerLocale }  from "react-datepicker";
import ar from 'date-fns/locale/ar-SA'
import { Modal } from 'react-bootstrap';
registerLocale("ar", ar);

class UpdateGoalItemDateModal extends Component {

  constructor(props){
    super(props);
    this.state = {
      apiLoading : false,
      showDateModal:false
    }
  }
  handleDateChangeRaw = (e) => {
    // Disabling keyboard input
    e.preventDefault();
  }
//   Using Icon to open calendar
  openDatepicker = () => this._GoalItemCalendar?.setOpen(true);
  render() {
    const {show,t,changeDate,currentDate,onSubmit} = this.props;

    return (
      <>
      <Modal 
            backdrop="static"
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            >
              <div 
                    className="p-4 bg-light mx-auto text-center py-md-5 py-4 rounded">
        <h3 className='text-muted fw-bold m-0 py-0'>
          {t('Select your target date')}
        </h3>
        <p className='text-muted my-2'>
          {t('A date, you need to achieve points to your goal item')}
        </p>
        <div className='d-flex border-date justify-content-center mx-auto px-5 py-2 my-3'>
          <img
            src={calendarIcon}
            className='cursor-pointer'
            alt=''
            srcset=''
            onClick={this.openDatepicker}
          />
          <DatePicker
            className='form-control rounded-0 border-0 no-outline cursor-pointer mx-auto'
            dateFormat='MMMM d, yyyy'
            selected={currentDate}
            minDate={new Date()}
            onChange={(date) => changeDate(date)}
            onChangeRaw={this.handleDateChangeRaw}
            ref={(c) => (this._GoalItemCalendar = c)}
            withPortal
            locale={this.props.lang === 'ar' && ar}
          />
        </div>
        <button
          className='btn primary-btn text-light border-color-green rounded mx-2 mt-3'
          onClick={onSubmit}>
          {t('Update')}
        </button>
        </div>
        </Modal>
      </>
    );
  }
}

export default UpdateGoalItemDateModal;
