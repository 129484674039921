import React, { Component } from "react";
import Breadcrumbs from "../shared/Breadcrumbs";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dropdown } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import filterIcon from "../../assests/img/header/icon-filter-categories.png";

class GamesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortByLetter: "All",
      sortByLetterMob: "All",
      data: undefined,
      title: "Games",
      subTitle:
        "Level up with the best game There are so many genres to choose from",
      cat: "",
      breadcumbActive: false,
      typeData: undefined,
      checked: false,
      checkedcategory: false,
      categoriedata: undefined,
      refProducts: undefined,
      products: undefined,
      categoryFilter: false,
      showNav: false,
      ageFilter: undefined,
      catMobile: undefined,
      AgeFilter: ["All", "0 - 3", "4 - 10", "11 - 15", "16 - 17", "18 +"],
    };
  }
  toggleNav = (val) => {
    this.setState((old) => ({ ...old, showNav: val }));
  };
  componentDidMount() {
    if (this.props.countrySelected !== 2) {
      this.props.history.replace("/404");
    }
    if (
      !this.props.loading &&
      this.props.gamesCards &&
      this.props.gamesCards.length === 0
    ) {
      this.props.history.replace("/404");
    }
    if (
      !this.props.loading &&
      !this.state.products &&
      this.props.gamesCards?.length > 0
    ) {
      let cardState = [];
      let categories = this.props.gamesCards;
      categories.forEach((item) => {
        item.software_and_games?.forEach((SGoffer) => {
          cardState.push(SGoffer);
        });
      });
      this.setState({ products: cardState, refProducts: cardState });
    }
  }
  componentDidUpdate(cat) {
    if (
      !this.props.loading &&
      !this.state.products &&
      this.props.gamesCards?.length > 0
    ) {
      let cardState = [];
      let categories = this.props.gamesCards;
      categories.forEach((item) => {
        item.software_and_games?.forEach((SGoffer) => {
          cardState.push(SGoffer);
        });
      });
      this.setState({ products: cardState, refProducts: cardState });
    }
    if (this.props.countrySelected !== 2) {
      this.props.history.replace("/404");
    }
    if (
      !this.props.loading &&
      this.props.gamesCards &&
      this.props.gamesCards?.length === 0
    ) {
      this.props.history.replace("/404");
    }
  }
  sortFunction(condition, catFilter) {
    let OS = ["Windows", "Mac"];
    if (OS.includes(condition)) {
      let filteredData = this.state.refProducts?.filter((e) =>
        e.operating_system?.includes(condition.toLowerCase())
      );
      this.setState({ products: filteredData, sortByLetter: condition });
    } else if (catFilter) {
      let filteredData = this.props.gamesCards?.filter(
        (e) => e.name === condition
      )[0]?.software_and_games;
      this.setState({
        products: filteredData,
        refProducts: filteredData,
        sortByLetter: "All",
      });
    }
  }

  sortFunctionMob(condition, catFilter) {
    let OS = ["Windows", "Mac"];
    if (OS.includes(condition)) {
      let filteredData = this.state.refProducts?.filter((e) =>
        e.operating_system?.includes(condition.toLowerCase())
      );
      this.setState({ products: filteredData, sortByLetter: condition });
    } else if (condition === "reset") {
      let cardState = [];
      let categories = this.props.gamesCards;
      categories.forEach((item) => {
        item.software_and_games?.forEach((SGoffer) => {
          cardState.push(SGoffer);
        });
      });
      this.setState({
        products: cardState,
        refProducts: cardState,
        sortByLetter: "All",
      });
    } else if (catFilter && this.state.catMobile) {
      let filteredData = this.props.gamesCards?.filter(
        (e) => e.name === this.state.catMobile
      )[0]?.software_and_games;
      if (this.state.ageFilter !== "All" && this.state.ageFilter) {
        let filterValue = this.state.ageFilter.split(" ");
        let fisrtValue = filterValue[0];
        let secondValue = filterValue[2] || 18;
        filteredData = filteredData.filter(
          (e) => +e.rating <= secondValue && +e.rating >= fisrtValue
        );
      }
      this.setState({
        products: filteredData,
        refProducts: filteredData,
        sortByLetter: "All",
      });
    } else if (this.state.ageFilter) {
      if (this.state.ageFilter === "All") {
        this.setState({ products: this.state.refProducts });
      } else {
        let filterValue = this.state.ageFilter.split(" ");
        let firstValue = filterValue[0];
        let secondValue = filterValue[2] || 18;
        let filteredData = this.state.refProducts?.filter(
          (e) => +e.rating <= secondValue && +e.rating >= firstValue
        );
        this.setState({ products: filteredData });
      }
    }

    this.toggleNav(false);
  }
  addActiveClass(elem) {
    let div = document.getElementsByClassName("active-link")[0];
    div?.classList?.remove("active-link");
    elem?.classList?.add("active-link");
  }
  numberWithCommas = (x) => {
    let y = x.toFixed(0);
    return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  render() {
    const breadcrumbs = [
      { name: "Home", url: "/", active: true },
      {
        name: "Games",
        url: "/games",
        active: this.state.breadcumbActive,
      },
      { name: this.state.cat, url: ``, active: false },
    ];
    const { t } = this.props;
    const letters = ["Windows", "Mac"];
    // const AgeFilter= [ 3, 6, 18]
    // const mobletters = [
    //   "Softwares",
    //   "Games",
    //   "Windows",
    //   "Mac",
    // ];
    // let gamesCards = this.state.cards;
    let { products, AgeFilter } = this.state;
    const desktopView = (
      <div className="d-none d-lg-flex row gx-5 p-5 w-100 m-0">
        <div className="col-2 category-nav p-0">
          <div className="sub-Categories-nav">
            <h5 className=" btnTxt ml-0 pb-1 fw-bold">{t("Category")}</h5>
            {this.props.gamesCards &&
              this.props.gamesCards.map((cat, index) => (
                <div className="d-flex mb-3">
                  <Link
                    onClick={(e) => {
                      this.sortFunction(cat.name, true);
                      this.addActiveClass(e.target);
                    }}
                    className="fw-bold"
                    value={index}
                  >
                    {t(cat.name)}
                  </Link>
                </div>
              ))}
            <h5 className=" btnTxt ml-0 pb-1 fw-bold">{t("Age")}</h5>
            {AgeFilter.map((age) => (
              <div className="d-flex align-items-center gap-2 ">
                {/* <input onChange={(e)=>this.setState({ageFilter:e.target.value});this.sortFunctionMob()} className='d-inline' type="radio" name='age' value={age}
                                    /> */}
                <input
                  onChange={(e) => {
                    this.setState({ ageFilter: e.target.value });
                    // this.sortFunctionMob('catFilter',true);
                  }}
                  className="d-inline"
                  type="radio"
                  name="age"
                  value={age}
                />
                <span className="">{t(age)}</span>
              </div>
            ))}
            <div className="py-3">
              <button
                type="button"
                className="btn btn-block btn-primary rounded-0 text-center no-outline"
                onClick={() => {
                  this.sortFunctionMob("catFilter", true);
                }}
              >
                {t("Apply Filters")}
              </button>
            </div>
          </div>
        </div>
        <section className="col-10 px-md-5 px-3 py-md-4 py-1">
          <div className="d-flex justify-content-between">
            <div>
              <div className="color-blue fw-md-bold fw-bolder fs-md-3 fs-5">
                {t(this.state.title)}
              </div>
              <div>{t(this.state.subTitle)}</div>
            </div>
            <div
              className={`d-none d-lg-block ${
                this.props.lang === "ar" ? "" : "ml-5"
              } `}
            >
              <Dropdown className="d-inline ">
                <DropdownToggle
                  style={{ width: "8rem" }}
                  className={`${
                    this.props.lang === "ar" ? "float-left" : ""
                  } text-light rounded-0 mt-1 px-0 bg-white no-outline`}
                >
                  <span className="color-blue fw-bold float-left px-3">{`${t(
                    this.state.sortByLetter
                  )}`}</span>
                  <span className="arrow-down color-skyblue float-right"></span>
                </DropdownToggle>
                <DropdownMenu>
                  {letters?.map((e) => (
                    <DropdownItem onClick={() => this.sortFunction(e)}>
                      {t(e)}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          {products?.length > 0 ? (
            <div className="py-md-4 py-2 d-grid grid-cols-md-4 grid-cols-2 gap-md-2 gap-2">
              {products.map((gamesCards) => (
                <div className="text-center border-0 d-grid">
                  <Link
                    className="active p-0 "
                    to={{
                      pathname: `/games/${gamesCards.id}`,
                      state: { ...gamesCards },
                    }}
                  >
                    <img
                      className="card-hover experiences-img"
                      src={gamesCards.images?.color?.large_rectangle}
                      alt={gamesCards.name}
                    />
                  </Link>
                  <div className="text-center py-2 color-blue fw-md-bold fw-bolder">
                    {this.props.lang !== "ar"
                      ? gamesCards.name
                      : gamesCards.name_arabic}
                  </div>
                  <div
                    style={{ alignItems: "flex-end", justifySelf: "center" }}
                    className="d-flex flex-row text-black-50"
                  >
                    <span>
                      <b>
                        {this.numberWithCommas(gamesCards.points_in_sar)} {t("Points")}
                      </b>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <>
              <div className="container">
                <div
                  style={{ height: "calc(100vh - 540px)" }}
                  className="row d-lg-none d-xl-block d-md-none my-5 d-flex justify-content-center text-center p-5"
                >
                  <h4 className="text-dark pb-3">{`${t("There are no items currently available")}. ${t("Please check again later")}`}</h4>
                </div>
              </div>
            </>
          )}
        </section>
      </div>
    );
    const mobileView = (
      <section className=" d-md-block d-lg-none px-md-5 px-3 py-md-4 py-1">
        <div className="d-flex justify-content-between">
          <div>
            <div className="color-blue fw-md-bold fw-bolder fs-md-3 fs-5">
              {t(this.state.title)}
            </div>
            <div className="text-black-50 fw-bold ">
              {t(this.state.subTitle)}
            </div>
          </div>
        </div>
        <div className=" d-flex justify-content-end text-right py-2">
          <Dropdown className="d-inline ">
            <DropdownToggle
              style={{ width: "8rem" }}
              className={`${
                this.props.lang === "ar" ? "float-left" : ""
              } text-light rounded-0 mt-1 px-0 bg-white no-outline`}
            >
              <span className="color-blue fw-bold float-left px-3">{`${t(
                this.state.sortByLetter
              )}`}</span>
              <span className="arrow-down color-skyblue float-right"></span>
            </DropdownToggle>
            <DropdownMenu>
              {letters?.map((e) => (
                <DropdownItem onClick={() => this.sortFunction(e)}>
                  {t(e)}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          <div
            className="mt-1"
            style={{
              maxWidth: "50px",
              marginRight: "16px",
              marginLeft: "16px",
              minHeight: "40px",
              minWidth: "40px",
            }}
          >
            <img
              src={filterIcon}
              alt="categorie"
              className="img-fluid"
              style={{ minHeight: "33px" }}
              onClick={() => {
                this.toggleNav(true);
              }}
            />
          </div>
        </div>
        {products?.length > 0 ? (
          <div className="py-md-4 pt-3 d-grid grid-cols-md-4 grid-cols-2 gap-md-3 gap-3 content-box">
            {products?.map((gamesCards) => (
              <div className="text-center p-0 d-grid">
                <Link
                  className="active p-0 "
                  to={{
                    pathname: `/games/${gamesCards.id}`,
                    state: { ...gamesCards },
                  }}
                >
                  <img
                    className="card-hover experiences-img"
                    src={gamesCards?.images?.color?.large_rectangle}
                    alt={gamesCards.name}
                  />
                </Link>
                <div className="text-center color-blue fw-md-bold fw-bolder">
                  {this.props.lang !== "ar"
                    ? gamesCards.name
                    : gamesCards.name_arabic}
                </div>
                <div
                  style={{ alignItems: "flex-end", justifySelf: "center" }}
                  className="d-flex flex-row text-black-50"
                >
                  <span>
                    <b>
                      {this.numberWithCommas(gamesCards.points_in_sar)} {t("Points")}
                    </b>
                  </span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="container">
            <div
              style={{ height: "calc(100vh - 540px)" }}
              className="row d-lg-none d-xl-block d-md-none my-5 d-flex justify-content-center text-center p-5"
            >
              <h4 className="text-dark pb-3">{`${t(
                "There are no items currently available. Please check again later"
              )}`}</h4>
            </div>
          </div>
        )}
        <div className="mt-3"></div>
      </section>
    );
    const filterModal = (
      <div
        className="d-md-none d-block position-fixed bg-light w-100 "
        style={{ zIndex: "1500", bottom: "0" }}
      >
        {this.state.showNav && (
          <div
            className="bg-light overflow-auto py-2"
            style={{ zIndex: "1500", minHeight: "100vh" }}
          >
            <div className="py-5  d-flex justify-content-between mt-5">
              <div
                className="col-2 fs-5 cursor-pointer color-skyblue text-right"
                onClick={() => {
                  this.toggleNav(false);
                }}
              >
               <section
                style={{ transform: `${this.props.lang !== 'ar' ? "rotate(180deg)" : ""}` }}
                >➜</section>
              </div>
              <div
                className=" fs-6 cursor-pointer color-skyblue text-right justify-content-end px-4"
                onClick={() => {
                  this.sortFunctionMob("reset");
                }}
              >
                <section>{t("Reset")}</section>
              </div>
            </div>
            <div className="text-muted d-flex flex-column gap-3 border-bottom   px-3 mt-0 pt-0">
              <span className="fs-5 color-blue fw-bold px-3">
                {t("Categories")}
              </span>
              <div className="text-muted d-flex flex-column gap-2 py-3 position-relative px-4">
                <span
                  className="fs-2 fw-bold align-top position-absolute color-skyblue"
                  style={{ top: "0", left: "0" }}
                ></span>
                {this.props.gamesCards &&
                  this.props.gamesCards.map((cat, index) => (
                    <Link
                      onClick={(e) => {
                        this.addActiveClass(e.target);
                        this.setState({ catMobile: cat.name });
                      }}
                      className="fw-bold"
                      value={cat.name}
                    >
                      {t(cat.name)} <br />
                    </Link>
                  ))}
              </div>
            </div>
            <div className="border-bottom fw-bold  px-3 py-4 ">
              <span className="fs-5 color-blue fw-bold px-3">{t("Age")}</span>
              <div className="text-muted d-flex flex-column gap-3 py-3 position-relative">
                {AgeFilter.map((age) => (
                  <div className="d-flex align-items-center gap-2 pl-4">
                    <input
                      onChange={(e) =>
                        this.setState({ ageFilter: e.target.value })
                      }
                      className="d-inline"
                      type="radio"
                      name="age"
                      value={age}
                    />
                    <span className="">{t(age)}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="px-5 py-3">
              <button
                type="button"
                className="btn btn-block btn-primary rounded-0 text-center"
                onClick={() => {
                  this.sortFunctionMob("catFilter", true);
                }}
              >
                {t("Apply Filters")}
              </button>
            </div>
          </div>
        )}
      </div>
    );
    return (
      <>
        {this.props.loading ? (
          <section>
            <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4 ">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </section>
        ) : (
          <>
            <Breadcrumbs crumbs={breadcrumbs} />

            {desktopView}
            {this.state.showNav ? filterModal: mobileView}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    gamesCards: state.games.gamesCards,
    loading: state.games.loading,
    countrySelected: state.countries.countryId,
    currancyCode: state.countries.convertionRate.currency_code,
    fetchError: state.games.error,
  };
};

export default connect(mapStateToProps)(withTranslation()(GamesPage));
