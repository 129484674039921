import {React,Component} from 'react';
import Breadcrumbs from '../shared/Breadcrumbs'
import  CategorySection from './CategorySection';
// import ethnic from  '../../assests/img/categories/section/ethnic.png';
// import giftcard from  '../../assests/img/categories/section/giftcard.png';
import filterIcon from '../../assests/img/header/icon-filter-categories.png'
import { connect } from 'react-redux';
// import { giftCardCategory,fetchOffersCategory, fetchExperiencesCategory, fetchMerchandiseCategory, fetchRecordByCategories, fetchCategoriesList } from '../../Redux/categories/categoriesAction';
import {fetchRecordByCategories, fetchCategoriesList } from '../../Redux/categories/categoriesAction';
import { withTranslation } from 'react-i18next/';
import { Link } from 'react-router-dom';
// import axios from 'axios';
// import secretKeyGenerator from '../../utils/serectKeyGenerator';
import NotFoundCard from '../shared/NotFoundCard';
import Marchendize from "./Marchendize"
import ShopBrand from './ShopBrand';
import category1 from "./../../assests/img/categories/category1.png"
import category2 from "./../../assests/img/categories/category2.png"
import category3 from "./../../assests/img/categories/category3.png"
import category4 from "./../../assests/img/categories/category4.png"
import category5 from "./../../assests/img/categories/category5.png"
import category6 from "./../../assests/img/categories/category6.png"

import brand1 from "./../../assests/img/categories/brand1.png"
import brand2 from "./../../assests/img/categories/brand2.png"
import brand3 from "./../../assests/img/categories/brand3.png"
import brand4 from "./../../assests/img/categories/brand4.png"
import brand5 from "./../../assests/img/categories/brand5.png"
import brand6 from "./../../assests/img/categories/brand6.png"
import brand7 from "./../../assests/img/categories/brand7.png"
import brand8 from "./../../assests/img/categories/brand8.png"
import brand9 from "./../../assests/img/categories/brand9.png"
import brand10 from "./../../assests/img/categories/brand10.png"
import brand11 from "./../../assests/img/categories/brand11.png"
import brand12 from "./../../assests/img/categories/brand12.png"
import { REACT_APP_baseUrl } from 'utils/app-constants';


class CategoryPage extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            categoryName:props.match.params.categoryName,
            cateogryData:{},
            isLoaded:false,
            featuredCategories:['Brasseire and Bistro','Casual Dining','Ethnic','Family Style','Fast Food','Fine Dining'],
            popularBrands:['Al Baik','Al Khayam','Al Tazaj','Breathe Coffee','Chamonix Cafe','Hangout pizzeria','Hardees','Herfy','Kudu','Oriya',`Parker's`,'Petit Parry','The Bellevue'],
            listOfCategories:undefined,
            bannerDetails:{},
            brands:['Apple','Beats','Bose','Canon','Dell',"HP","JBL","Lenovo"],
            sidebar:[{
                name:"Mobile Phones",
                img:category1
            },{
                name:"Laptops & IT Accessories",
                img:category2
            },
            {
                name:"Headphones & Earphones",
                img:category3
            },
            {
                name:"Video Games",
                img:category4
            },
            {
                name:"Television",
                img:category5
            },{
                name:"Home Applications",
                img:category6
            }],
            Brands:[brand1,brand2,brand3,brand4,brand5,brand6,brand7,brand8,brand9,brand10,brand11,brand12]

         }
    }
  
    fetchData(categoryName){
        const url = `${REACT_APP_baseUrl}/categories/get_categories`
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            response.json().then((resp) => {
                this.setState({
                    categoryName:categoryName,
                    cateogryData:{...resp.data.categories.find((cat)=>(cat.name===categoryName))},
                    isLoaded:true
                })
            })
            
        }).catch(err=>{
        })
    }
    fetchBanner(){
        
        let CatName =  this.props.location.pathname?.split('/')[2]
        let  id = this.props.category?.filter((e) => e.name === CatName)[0]?.id 
        const payload = {
            "categories": {
                "category_id": id,
                "currency": this.props.countrySelected
            }
        }
        this.props.fetchRecordByCategories(payload)
         
    }

    fetchDetails(categoryID,currency){
        
        const payload = {
            "categories": {
                "category_id": categoryID,
                "currency": currency
            }
        }
        this.props.fetchRecordByCategories(payload)
         
    }

    componentDidMount(){
        // this.props.fetchCategoriesList()
        if(this.props.category?.length>0){
            this.fetchBanner()
        }

    }
    componentWillUpdate(nextProps,nextState){
        
        if(nextState.categoryName!==nextProps.match.params.categoryName){
            this.fetchDetails(nextProps.location.state,this.props.countrySelected)
            this.setState({categoryName:nextProps.match.params.categoryName})
            
            // this.fetchData(nextProps.match.params.categoryName)
        }
        else if(this.props.countrySelected !== nextProps.countrySelected){
            this.fetchDetails(nextProps.location.state,nextProps.countrySelected)
        }
       
        
    }
    componentDidUpdate(prevProps){
        if(prevProps.category?.length !== this.props.category?.length){
            this.fetchBanner()
        }
    }
    urlRedirect(){
        this.props.history.replace("/404")
    }
    render() { 
        const {t} = this.props
       
        const BreadcrumbLinks = [
            {
                name:"Home",
                url:"/",
                active:true
    
            },
            {
                name:this.state.categoryName,
                url:"",
                active:false
            }
        ]
    
        const {categoriesData,loading} = this.props;
        const {categoryName} = this.state;
        console.log(categoryName);
       const desktopView = (
            
            <div className="d-md-block d-none p-0">
                
                
                <Breadcrumbs
                    crumbs={BreadcrumbLinks}
                />
            {loading?
            <div  className="row gx-5 pl-5 w-100 m-0">
                {/* <div  className="col-2 category-nav p-0"> */}
                {/* </div> */}
                {/* <div className="d-flex gap-5">
                    <div className='mt-4 col-2 p-0'>
                        <div className='border-bottom fw-bold '>
                            <span className='fs-5 btnTxt fw-bold'>Featured Categories</span>
                            <div className='text-muted d-flex flex-column gap-2 py-3'>
                                    {
                                        this.state.sidebar.map((val,ind)=>{
                                            return(
                                                <Link className='category text-muted' style={{fontSize:"14px"}} to={{pathname:`./${categoryName}/${val.name}`}}>
                                                    {val.name}</Link>
                                            )
                                        })
                                    }
                            </div>
                        </div>
                        <div className='border-bottom fw-bold py-4'>
                            <span className='fs-5 btnTxt fw-bold'>Popular Brands</span>
                            <div className='text-muted d-flex flex-column gap-2 py-3'>
                                {
                                    this.state.brands.map((val)=>{
                                        return(
                                            <Link className='category text-muted' style={{fontSize:"14px"}}>{val}</Link>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-9 mt-2 mx-3">
                        <div className='d-flex justify-content-between'>
                            <div className=''>
                                <img 
                                    className="w-100 mb-4"
                                    src={`${this.props.lang === 'en' ? categoriesData?.category.banner?.large_rectangle :categoriesData?.category?.banner_arabic.large_rectangle}`}
                                    alt="banner"
                                />
                                <span className='fs-3 btnTxt fw-bold'>Choose from our categories</span>
                                <p className='text-muted small'>You can send gift cards to anyone anywhere in the world</p>
                            </div>
                        </div>
                        <div className="d-grid grid-cols-md-3 grid-cols-2 gap-md-3 gap-2 py-3">
                            {
                              this.state.sidebar  &&  this.state.sidebar.map( (card,id)=>(
                                    <Marchendize categoryName={categoryName}  card={card} key={id} />
                                ))
                            }
                       </div>
                       <div className=''>
                            <span className='fs-3 btnTxt fw-bold'>Shop by brand</span>
                        </div>
                        <div className="d-grid grid-cols-md-6 grid-cols-2 gap-md-3 gap-2 py-3 mx-2">
                            {
                              this.state.Brands  &&  this.state.Brands.map( (card,id)=>(
                                    <ShopBrand  card={card} key={id} device="desktop"/>
                                ))
                            }
                       </div>
                    </div>
                </div> */}

                {/* //new category section code */}



                <div className="col-12 category-cards px-3">
                    {(categoriesData?.giftcards?.length > 0 
                        // Commenting Offers for next release
                        // || categoriesData?.offers?.length > 0 
                        // || categoriesData?.products?.length > 0
                        // || categoriesData?.experiences?.length > 0
                        // || categoriesData?.software?.length > 0
                        // || categoriesData?.games?.length > 0 
                        
                        ) ? <div className="banner-img">
                        <img 
                            className="w-100"
                            src={`${this.props.lang !== 'ar' ? categoriesData?.category.banner?.large_rectangle :categoriesData?.category?.banner_arabic.large_rectangle}`}
                            alt="banner"
                            />
                          
                            {/* <div className="title-section">
                                <h5
                                className="btnTxt ml-0 pt-3 pb-0 mb-0 fw-bold"
                                >{t('Choose from our categories')}</h5>
                                
                            </div> */}
                              
                            {
                                categoriesData?.giftcards && <CategorySection
                               
                                 lang = {this.props.lang}
                                catItems={categoriesData?.giftcards} catType='featured'
                                catTitle={`${t('Our Favourite Brands')} ${t('in')} ${t(this.props.match.params.categoryName)}`}
                                catSubTitle={t("Send gift cards to anyone anywhere in the world")}
                                showAllLink={true} 
                                goToPage='/giftCards'
                                showAllLinkText= {t("Show All")}
                               ></CategorySection>
                            }
                            {/* {
                                (categoriesData.offers && categoriesData.offers.length>0) && <CategorySection
                                 lang = {this.props.lang}
                                catItems={categoriesData?.offers} catType='offers'
                                catTitle={`${t('CHSC Offers & Discounts')} ${t('in')} ${t(this.props.match.params.categoryName)}`}
                                catSubTitle={t("Send gift cards to anyone anywhere in the world")}
                                showAllLink={true} 
                                goToPage='/offers'
                                showAllLinkText= {t("Show All")}
                               ></CategorySection>
                            } */}
                           
                            {/* {
                                 categoriesData.products && <CategorySection
                                 lang = {this.props.lang}
                                catItems={categoriesData?.products} catType='merchandise'
                                
                                catTitle={`${t('CHSC Gifts')} ${t('in')} ${t(this.props.match.params.categoryName)}`}
                                catSubTitle={t("Send gift cards to anyone anywhere in the world")}
                                showAllLink={true} 
                                goToPage='/gifts'
                                showAllLinkText= {t("Show All")}
                               ></CategorySection>
                            } */}
{/* 
                            {
                                 categoriesData.experiences && <CategorySection
                                 lang = {this.props.lang}
                                catItems={categoriesData?.experiences} catType='experience'
                                
                                catTitle={`${t('CHSC Experience')} ${t('in')} ${t(this.props.match.params.categoryName)}`}
                                catSubTitle={t("Send gift cards to anyone anywhere in the world")}
                                showAllLink={true} 
                                goToPage='/experiences'
                                showAllLinkText= {t("Show All")}
                               ></CategorySection>
                            }

                            {
                                 categoriesData.software && <CategorySection
                                 lang = {this.props.lang}
                                catItems={categoriesData?.software} catType='softwares'
                                catTitle={`${t('Softwares')} ${t('in')} ${t(this.props.match.params.categoryName)}`}
                                catSubTitle={t("Send gift cards to anyone anywhere in the world")}
                                showAllLink={true} 
                                goToPage='/softwares'
                                showAllLinkText= {t("Show All")}
                               ></CategorySection>
                            }

                            {
                                 categoriesData.games && <CategorySection
                                 lang = {this.props.lang}
                                catItems={categoriesData?.games} catType='games'
                                catTitle={`${t('Games')} ${t('in')} ${t(this.props.match.params.categoryName)}`}
                                catSubTitle={t("Send gift cards to anyone anywhere in the world")}
                                showAllLink={true} 
                                goToPage='/games'
                                showAllLinkText= {t("Show All")}
                               ></CategorySection>
                            } */}
                               
                    </div>: <div>
                        <NotFoundCard/>
                       </div> 
                        }
                </div>
            </div>:<section>
                    <div className="d-flex justify-content-center pt-2 pb-2">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
            </section>}
        </div>
        )
        const mobileView = (
            <div className="d-block d-md-none p-0">
                
        
          <Breadcrumbs
                        crumbs={BreadcrumbLinks}
                    />
                {loading?
                <div style={{marginBottom:'7rem'}} className="row gx-5 w-100 mx-0">
                    <div className="col-12 category-cards p-1">
                    {(categoriesData?.giftcards?.length > 0 
                        // Commenting Offers For Next Release
                        // || categoriesData?.offers?.length > 0 
                        // || categoriesData?.products?.length > 0
                        // || categoriesData?.experiences?.length > 0
                        // || categoriesData?.software?.length > 0
                        // || categoriesData?.games?.length > 0
                        ) ? <div className="banner-img">
                        <img 
                            className="img-fluid"
                            src={`${this.props.lang !== 'ar' ? categoriesData?.category.banner.large_rectangle :categoriesData?.category.banner_arabic.large_rectangle}`}
                            alt="banner"
                            />
                                
                               <div  className={`row  pt-2 w-100 ${this.props.lang !== 'ar' ? 'pl-2' : 'pr-2'}`}>
                                <div  className="col-10 title-section ">
                                    <h5
                                    className="btnTxt pb-0 mb-0 fw-bold"
                                    >{t("Choose from our categories")}</h5>
                                     <p className='text-muted small'>You can send gift cards to anyone anywhere in the world</p>
                                </div>
                                <div style={{alignItems:'center',justifyContent:'flex-end'}} className="col-2 d-flex dropleft dropdown m-0 p-0 ">
                                {/* <div style={{maxWidth:"50px", maxHeight:"40px"} }className="border filter-green  text-uppercase float-right" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> */}
                                <div style={{maxWidth:"50px", maxHeight:"50px"}} className={this.props.lang !== 'ar' ? 'mr-3' : 'ml-3'}  id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                   <img src={filterIcon} alt="categorie" className="img-fluid"/>
                   </div>
                               <div className="dropdown-menu pre-scrollable custom-scrollbar" aria-labelledby="dropdownMenuButton">   
                                 {
                                   this.props.category &&  this.props.category.map(category => <Link
                                    to={{pathname:`/category/${category.name}`,state:category.id}}
                                        className=" dropdown-item">{t(category.name)}</Link>) 
                                }          
                            </div>
                                </div>    
                               </div>
                               {/* <div className="d-grid grid-cols-md-3 grid-cols-2 gap-md-3 gap-2 py-3">
                            {
                              this.state.sidebar  &&  this.state.sidebar.map( (card,id)=>(
                                    <Marchendize categoryName={categoryName}  card={card} key={id} />
                                ))
                            }
                       </div>
                       <div className=''>
                            <h5 className='fs-3 btnTxt fw-bold'>Shop by brand</h5>
                        </div>
                        <div className="d-grid grid-cols-md-6 grid-cols-2 gap-md-3 gap-2 py-3">
                            {
                              this.state.Brands  &&  this.state.Brands.map( (card,id)=>(
                                    <ShopBrand  card={card} key={id} device="mobile"/>
                                ))
                            }
                       </div> */}

                               
                               
                                {
                                categoriesData.giftcards && 
                                <div className="p-2 mb-2">
                                    <CategorySection
                                 lang = {this.props.lang}
                                 catItems={categoriesData?.giftcards}catType='featured'
                                catTitle={`${t('Our Favourite Brands')} ${t('in')} ${t(this.props.match.params.categoryName)}`}
                                catSubTitle={t("Send gift cards to anyone anywhere in the world")}
                                showAllLink={true} 
                                showAllLinkText= {t("Show All")}
                                goToPage='/giftcards'
                               ></CategorySection>
                                </div>
                            }
                       
                                {/* {
                                    (categoriesData?.offers && 
                                    
                                categoriesData?.offers.length>0) && 
                                <div className=''>
                                
                                <CategorySection
                                 lang = {this.props.lang}
                                 catItems={categoriesData.offers} catType='offers'
                                catTitle={`${t('CHSC Offers & Discounts')} ${t('in')} ${t(this.props.match.params.categoryName)}`}
                                catSubTitle={t("Send gift cards to anyone anywhere in the world")}
                                showAllLink={true} 
                                showAllLinkText= {t("Show All")}
                                goToPage='/offers'
                               ></CategorySection>
                                </div>
                            }
                                { categoriesData.products &&
                                <div className='p-2 mb-5'>
                              
                                <CategorySection
                                 lang = {this.props.lang}
                                catItems={categoriesData?.products} catType='merchandise'
                                catTitle={`${t('CHSC Gifts')} ${t('in')} ${t(this.props.match.params.categoryName)}`}
                                catSubTitle={t("Send gift cards to anyone anywhere in the world")}
                                showAllLink={true} 
                                showAllLinkText= {t("Show All")}
                                goToPage='/gifts'
                               ></CategorySection>
                            
                            </div>
                            }
                                {
                                    categoriesData.experiences && 
                            <div className='p-2 mb-5'>
                                
                                <CategorySection
                                 lang = {this.props.lang}
                                catItems={categoriesData?.experiences} catType='experience'
                                catTitle={`${t('CHSC Experience')} ${t('in')} ${t(this.props.match.params.categoryName)}`}
                                catSubTitle={t("Send gift cards to anyone anywhere in the world")}
                                showAllLink={true} 
                                showAllLinkText= {t("Show All")}
                                goToPage='/experiences'
                               ></CategorySection>
                            </div>
                            }

                                {
                                    categoriesData.software &&
                                
                            <div className='p-2 mb-5'>
                                <CategorySection
                                 lang = {this.props.lang}
                                catItems={categoriesData?.software} catType='softwares'
                                catTitle={`${t('Softwares')} ${t('in')} ${t(this.props.match.params.categoryName)}`}
                                catSubTitle={t("Send gift cards to anyone anywhere in the world")}
                                showAllLink={true} 
                                showAllLinkText= {t("Show All")}
                                goToPage='/softwares'
                               ></CategorySection>
                            </div>
                            }

                                {
                                    categoriesData.games && 
                            <div className='p-2 mb-5'>
                                
                                <CategorySection
                                 lang = {this.props.lang}
                                catItems={categoriesData?.games} catType='games'
                                catTitle={`${t('Games')} ${t('in')} ${t(this.props.match.params.categoryName)}`}
                                catSubTitle={t("Send gift cards to anyone anywhere in the world")}
                                showAllLink={true} 
                                showAllLinkText= {t("Show All")}
                                goToPage='/games'
                               ></CategorySection>
                            </div>
                            } */}
                        </div>: <div>
                        <NotFoundCard/>
                       </div> 
                       
                        }
                    </div>
                </div>: <section>
                    <div className="d-flex justify-content-center pt-2 pb-2">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
            </section>}
            </div>
        )
        return ( 
            <>
            {desktopView}
            {mobileView}
            </>
        );
    }
}
 

const mapStateToProps = state => {
    return {
        categoriesData:state.categories.recordByCategories,
        loading :state.categories.recordLoading,
        countrySelected:state.countries.countryId,
        category:state.categories.allCategories
    }
}


const mapDispatchToProps = dispatch => {
    return {
        fetchRecordByCategories: (payload) => dispatch(fetchRecordByCategories(payload)),
        fetchCategoriesList: () => dispatch(fetchCategoriesList())
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)((withTranslation()(CategoryPage)));




