import {CardCvcElement, CardExpiryElement, CardNumberElement, Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import React, { Component} from 'react';
import secretKeyGenerator from '../../utils/serectKeyGenerator';
import CardProcessingModal from '../shared/Modals/CardProcessingModal';
import FailMessageModal from '../shared/Modals/FailMessageModal';
import StripeFailureModal from '../shared/Modals/StripeFailureModal';
import OrderSummaryCard from './OrderSummaryCard';
import { useTranslation } from 'react-i18next';
import { REACT_APP_stripePublishableKey,REACT_APP_baseUrl } from 'utils/app-constants';
class CheckoutForm extends Component {
    constructor(props){
        super(props);
        this.myRef = React.createRef();
        this.state ={
            paymentFail:false,
            stripeFail:false,
            errorMsg:" ",
            show:false,
            orderDetails:null,
            nameOnCard:''
        }
    }

// Create Order (Backend API) - Create Stripe Payment (Stripe library) - Create Stripe(Backend API) - Confirm (Stripe library) - Redirect to Order Summary Page - Get Order - Process

    orderCreationRequest=()=>{
        let payloadParam = this.props.location.state?.pyload ? this.props.location.state?.pyload : this.props.location.state?.order
        const payload =payloadParam;
        let token = localStorage.getItem("userToken");
        if(this.props.location.state?.splitPayment){
          if(this.props.location.state?.module === 'experience'){
            payload['experience_orders']['amount'] = this.props.location.state?.splitPayment?.value
          }
          else{
            payload['orders']['amount'] = Number(this.props.location.state?.splitPayment?.value)?.toFixed(2)
          }
        }
        axios.post(`${REACT_APP_baseUrl}/${this.props.location.state?.orderCreationURL}`,
        payload,{
            headers:{
                "Authorization":`Bearer ${token}`
            },
            params:{
                "secret_key":secretKeyGenerator(payload)
            }
        })
            .then(response  => {
                if(response.data.code === 200){
                    const orderDetails = response.data.data?.order;
                    this.setState({orderDetails:orderDetails},()=>{
                      this.createStripePayment()
                    })
                }
                else{
                this.setState({
                    show:false,
                    paymentFail:true,
                    errorMsg:response.data?.message
                })
                }
            })
            .catch(error => {
                this.setState({
                show:false,
                paymentFail:true,
                errorMsg:"Something went wrong!! Please try again!!"
                })
            })
    }

    createStripePayment= async()=>{
        const {stripe, elements} = this.props;
        const cardElement = elements.getElement(CardNumberElement);
        const {error, paymentMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        });

        if (error) {
            cardElement.focus()
            this.setState({errorMsg:error.message,show:false,stripeFail:true})
        } else {
            this.stripeCreationAPI(paymentMethod.id)
        }
    }

    stripeCreationAPI = (stripeToken) => {
        let amount 
        // for Stripe Currency Conversion
        if(this.props.location.state.currancyCode === "KWD"){
          amount = (this.state.orderDetails?.payable_amount * 1000).toFixed(0)
        }else{
          amount = (this.state.orderDetails?.payable_amount * 100).toFixed(0)
        }
        const payload={
          "payment": {
              "payment_currency": this.props.location.state.currancyCode,
              "amount": Number(amount), 
              "orderid": this.state.orderDetails?.orderid,
              "token": stripeToken,
              "name_on_card":this.state.nameOnCard
          }
      }
      let token = localStorage.getItem("userToken")
        axios.post(`${REACT_APP_baseUrl}/payments/create_stripe_payment/`,payload,{
          headers:{
              "Authorization":`Bearer ${token}`
          },
          params:{
              "secret_key":secretKeyGenerator(payload)
          }
      })
        .then(response =>{
            if(response.data?.code === 200){
              let secretKey = response.data.client_secret;
              this.stripePaymentConfirmation(secretKey)
            }
            else{
                this.setState({
                    show:false,
                    paymentFail:true,
                    errorMsg:response.data?.message
                  })  
            }
        })
        .catch(err => {
            this.setState({
                show:false,
                paymentFail:true,
                errorMsg:"Something went wrong!! Please try again!!"
              })
        })
    }

    stripePaymentConfirmation = async(secretKey) =>{
        const {stripe} = this.props;
       await stripe.confirmCardPayment(secretKey)
        .then(response => {
            if(response.error){
              if(response.error.code === 'payment_intent_authentication_failure'){
                this.setState({
                  show:false,
                  paymentFail:true,
                  errorMsg:"We are unable to authenticate your payment method. Please try again later."
                })
              }
              else{
                this.setState({
                  show:false,
                  paymentFail:true,
                  errorMsg:response.error.message
                })
              }
                
                let token = localStorage.getItem("userToken")
                let payload = { "orderid": this.state.orderDetails?.orderid } 
                   axios.post(`${REACT_APP_baseUrl}/payments/failed_order_after_redirect`,payload,{
                    headers:{
                        "Authorization":`Bearer ${token}`
                    },
                    params:{
                        "secret_key":secretKeyGenerator(payload)
                    }
                })
            }
            else{
                let param = this.props.location.state?.passOrderID ? this.state.orderDetails.orderid : this.state.orderDetails.id
                this.props.history.push({pathname:`${this.props.location.state?.summaryPageURL}/${param}`})
            }
        })
        .catch(()=>{
          this.setState({
            show:false,
            paymentFail:true,
            errorMsg:"Something went wrong!"
          })
        })
    }

    handleSubmit = async (event) => {
        const {stripe, elements} = this.props;

        if (!stripe || !elements) {
        // Stripe.js has not loaded yet.
        return;
        }
        this.setState({show:true})
        this.orderCreationRequest()
    };

    componentDidMount(){
      this.myRef.current?.scrollIntoView({behavior: "smooth"})
    }
  
  render() {
    const {paymentFail, errorMsg,stripeFail} = this.state;
    const {t} = this.props
    return (
        <div ref={this.myRef}>
        <header className="border px-md-5 px-2 py-2 border-bottom text-muted d-flex bg-light w-100 position-fixed-mobile">
            <div className="d-flex align-items-center flex-shrink-1 " >
                <span className="btn bg-color-skyblue rounded-0 mx-2  p-md-2 p-1"></span>
                <div className="m-0 d-inline px-md-1 px-0 pb-1 fs-md-6 fs-7 fw-bold">{t("Order Confirmation")}</div>
            </div>
            <hr className="hr-line flex-grow-1 mx-1"/>
            <div className="d-flex align-items-center flex-shrink-1 " >
                <span className="btn bg-color-skyblue rounded-0 mx-2 p-md-3 p-2" ></span>
                <div className="m-0 d-inline px-md-1 px-0 pb-1 fs-md-6 fs-7 fw-bold">{t("Payment")}</div>
            </div>
            <hr className="hr-line flex-grow-1 mx-1"/>
            <div className="d-flex align-items-center flex-shrink-1 " >
                <span className="btn bg-color-skyblue rounded-0 mx-2  p-md-2 p-1"></span>
                <div className="m-0 d-inline px-md-1 px-0 pb-1 fs-md-6 fs-7 fw-bold">{t("Order Placed")}</div>
            </div>
        </header>
        <FailMessageModal show={paymentFail} Message={errorMsg}/>
        <StripeFailureModal show={stripeFail} changeStripeStatus={()=>this.setState({stripeFail:false})} Message={errorMsg} {...this.props}/>
        <div className="d-flex p-md-5 p-3 flex-md-row flex-column" >
        <CardProcessingModal show={this.state.show}/>
          <div className="flex-grow-1 text-black-50 p-0 mt-5">
            <div className="py-md-0 py-2 m-0 mt-md-0 mt-5 fw-bold fs-md-2 fs-4 headings">{t("Card Details")}:</div>
                  <div className="col-md-6 col-12 p-0">
                      <label htmlFor="">{t("Card Number")}</label>
                        <CardNumberElement onReady={(e)=> e.focus()} id='cardElement' className='py-3 px-1 mb-3 input-field rounded-0 border outline-color-skyblue' options={{placeholder:`${t('Enter your card number')}`}}/>
                  </div>
                  <div className="col-md-6 col-12 p-0">
                      <label htmlFor="">{t("Name on Card")}</label>
                        <input style={{fontFamily: 'sans-serif', fontSize:'0.88em'}} value={this.state.nameOnCard} onChange={(e)=> this.setState({nameOnCard:e.target.value})} id='cardElement' className='d-flex col-12 border py-3 px-1 mb-3 input-field rounded-0 border outline-color-skyblue' placeholder={`${t('Enter your name')}`}/>
                  </div>
                    
                  <div className="d-flex col-12 col-lg-10 p-0 flex-row no-gutters">
                    <div className="col-md-6 col-12 p-0">
                      <label htmlFor="">{t("Card Expiry Date")}</label>
                      <CardExpiryElement className='py-3 px-1 my-3 input-field rounded-0 border outline-color-skyblue'/>
                    </div>
                  </div>
                    {/* <div className="col-md-6 col-12 p-0">
                        <InputFeild data={formFeilds['nameOnCard']} lang={this.props.lang}/>
                    </div> */}
                  <div className="col-md-6 col-12 p-0 d-flex flex-md-row flex-column ">
                      <div className="col-3 p-0">
                        <label htmlFor="">{t("CVV/CVC")}</label>
                      <CardCvcElement className='py-3 px-1 input-field rounded-0 border outline-color-skyblue'/>
                      </div>
                  </div>
                  <div className="w-100 flex-shrink-0 pt-md-5 pt-0 mt-md-2 mt-1 px-md-3 px-0 d-flex fw-bold pb-5">
                        <svg className="flex-shrink-0" width="40" height="30">
                            <rect x="2.5" y="4" rx="2" ry="2" width="30" height="19"
                            style={{fill:'white',stroke:'#888',strokeWidth:'2'}} />
                            <line x1="2.5" y1="11"  x2="32" y2="11"
                            style={{fill:'white',stroke:'#888',strokeWidth:'5'}} />
                        </svg>
                        {t("3 digits on the back of your card")}
                  </div>
          </div>
          <OrderSummaryCard onSubmit={this.handleSubmit} convertedCurrency={this.props.location.state?.convertedCurrency} selectedCurrency={this.props.location.state.currancyCode} order={this.props.location?.state?.order} lang={this.props.lang} cardPayment={this.props.location?.state?.cardPayment}/>
        </div>
      </div>
    );
  }
}

export const StripePaymentPag = (props) => {
  const {t} = useTranslation()
  return (
    <ElementsConsumer>
      {({elements, stripe}) => (
        <CheckoutForm elements={elements} stripe={stripe} {...props} t={t} />
      )}
    </ElementsConsumer>
  );
};

const stripePromise = loadStripe(REACT_APP_stripePublishableKey);

export const StripePaymentPage = (props) => {
  return (
    <div className="AppWrapper">
      <Elements stripe={stripePromise} >
        <StripePaymentPag {...props} />
      </Elements>
    </div>
  );
};
