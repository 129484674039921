import axios from "axios";
import { FETCH_FEATUREDCARDS_FAILURE, FETCH_FEATUREDCARDS_REQUEST, FETCH_FEATUREDCARDS_SUCCESS } from "./featureCardsTypes";
import secretKeyGenerator from "../../utils/serectKeyGenerator";
import { REACT_APP_baseUrl } from 'utils/app-constants';

export const fetchFeatureCardsRequest = () => {
    return {
        type:FETCH_FEATUREDCARDS_REQUEST,
    }
}

export const fetchFeatureCardsSuccess = data => {
    return {
        type:FETCH_FEATUREDCARDS_SUCCESS,
        payload:data
    }
}

export const fetchFeatureCardsFailure = error =>{
    return {
        type:FETCH_FEATUREDCARDS_FAILURE,
        payload:error
    }
}

export const fetchFeatureCards = (payload) => {
    return (dispatch)=>{
        dispatch({type:FETCH_FEATUREDCARDS_REQUEST});
        axios.post(`${REACT_APP_baseUrl}/brands/featured_brands`,
            payload,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache, no-store, must-revalidate',
                    'Expires': '0'
                },
                params:{
                    "secret_key":secretKeyGenerator(payload)
                }
            })
            .then(response  => {

                if(response.data?.code === 200){
                    const featureCards = response.data?.data?.brands;
                dispatch(fetchFeatureCardsSuccess(featureCards))
                }
                else{
                    dispatch(fetchFeatureCardsFailure(response.data?.message))
                }
              
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
                dispatch(fetchFeatureCardsFailure("Something went wrong!! Please try again!!"))
            })
    }
}