import axios from 'axios'
import { REACT_APP_baseUrl } from 'utils/app-constants';
import { Gift_OCCASION_IMAGES_FETCH, Gift_OCCASION_REQUEST, Gift_OCCASION_SUCCESS, Gift_OCCASION_IMAGES_REQUEST, Gift_OCCASION_IMAGES_SUCCESS } from './giftOcassionTypes'

export const fetchGiftOcassionImages = data => {
    return {
        type: Gift_OCCASION_IMAGES_FETCH,
        payload: data
    }
}
export const fetchOcassionRequest = () => {
    return {
        type: Gift_OCCASION_REQUEST
    }
}
export const fetchOcassionImagesRequest = () => {
    return {
        type: Gift_OCCASION_IMAGES_REQUEST
    }
}
export const fetchOcassionSucess = (giftoccasions) => {
    return {
        type: Gift_OCCASION_SUCCESS,
        payload: giftoccasions
    }
}
export const fetchOcassionImagesSucess = (giftoccasionsimages) => {
    return {
        type: Gift_OCCASION_IMAGES_SUCCESS,
        payload: giftoccasionsimages
    }
}

// fetching images

let token = localStorage.getItem('userToken');

export const fetchocassion = () => {
    return (dispatch) => {
        dispatch(fetchOcassionRequest())
        axios.get(`${REACT_APP_baseUrl}/gift_occasions`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
        )
            .then(response => {
                let giftoccasions = response.data.data.gift_occasions

                dispatch(fetchOcassionSucess(giftoccasions))
            })
            .catch(error => {
                console.log(error);
            })
    }
}

// fetching particular images

export const fetchocassionimage = (id) => {
    return (dispatch) => {
        dispatch(fetchOcassionImagesRequest())
        // axios.get(`https://run.mocky.io/v3/5a8414c0-8d51-4adf-a74f-2828d2ccb7df`
        axios.get(`${REACT_APP_baseUrl}/gift_occasions/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
        )
            .then(response => {
                let giftoccasionsimages = response.data.data.gift_occasion;
                dispatch(fetchOcassionImagesSucess(giftoccasionsimages));
            })
            .catch(error => {
                console.log(error);
            })
    }
}