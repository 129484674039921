import axios from "axios"
import secretKeyGenerator from "../../utils/serectKeyGenerator"
import store from "../store"
import { SET_GOAL_FAILURE, SET_GOAL_REQUEST, SET_GOAL_SUCCESS, FETCH_GOAL_FAILURE, FETCH_GOAL_REQUEST, FETCH_GOAL_SUCCESS } from "./goalItemsTypes"
import { REACT_APP_baseUrl } from "utils/app-constants"


export const fetchGoalItems = (productAPI) => {
    return async(dispatch) => {
        dispatch({type:FETCH_GOAL_REQUEST});

       await axios.get(`${REACT_APP_baseUrl}/customer_goal_items`,{
            headers:{
                "Authorization":`Bearer ${store.getState().auth.authToken}`
            }
        })
        .then(response => {
            typeof productAPI === 'function' && productAPI();
            if(response.data.code === 200){
                dispatch({
                    type: FETCH_GOAL_SUCCESS,
                    payload:response.data.data
                })
            }
            else{
                dispatch({
                    type: FETCH_GOAL_FAILURE,
                    payload: response.data.data?.message
                })
            }
        })
        .catch(error => {
            dispatch({
                type: FETCH_GOAL_FAILURE,
                payload:'Something went wrong!'
            })
        })
    }
}

export const setGoalItem = (payload,productAPI,status) =>{
    let url;
    status === 'Update' ? url = `${REACT_APP_baseUrl}/customer_goal_items/update_customer_goal` : url = `${REACT_APP_baseUrl}/customer_goal_items`
    return (dispatch) =>{
        dispatch({type:SET_GOAL_REQUEST})
        axios({
            method: 'POST',
            url:url,
            data:payload,
            headers:{"Authorization":`Bearer ${store.getState().auth.authToken}`},
            params:{"secret_key":secretKeyGenerator(payload)}
        })
        .then(response => {
            if(response.data.code === 200){
                dispatch({
                    type:SET_GOAL_SUCCESS,
                    payload:response.data?.message
                })
                dispatch(fetchGoalItems(productAPI))
                
            }
            else{
                dispatch({
                    type:SET_GOAL_FAILURE,
                    payload:response.data?.message
                })
            }
        })
        .catch(error => {
            dispatch({
                type:SET_GOAL_FAILURE,
                payload:"Something went wrong!"
            })
        })
    }
}
