/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import Breadcrumbs from '../shared/Breadcrumbs';
import BuyNowCard from '../shared/BuyNowCard';
import { connect } from 'react-redux';
import { currancyToPoints, } from '../../utils/pointsConversion';
import { removeOrder } from '../../Redux/order/orderActions'
import { fetchOfferDetails, fetchTermsAndConditions } from '../../Redux/offers/offerActions';
import OfferConfirmationModal from '../shared/Modals/OfferConfirmationModal';
import { withTranslation } from 'react-i18next';
import { checkTokenValidity } from '../../Redux/auth/authActions';
import { Link } from 'react-router-dom';
import EmailPreview from 'components/shared/giftflow/EmailPreview';
import { setGoalItem } from 'Redux/goalItems/goalItemsActions';
import goalItemImage from '../../assests/img/goalitems/icon-change-goal-item.svg'
import GoalItemStatusModal from 'components/GoalItems/GoalItemStatusModal';
import GoalItemConfirmModal from 'components/GoalItems/GoalItemConfirmModal';


class OfferPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmOrder: false,
            orders: {},
            quantity: 1,
            newOrders: {},
            cardPayment: false,
            showNewGiftFlow: true,
            goalModalShow:false,
            goalStatusModalShow:false,
            goalModalDetail:''
        }
    }
    componentDidMount = () => {
        localStorage.removeItem("type");
        localStorage.setItem("type", "offers");
        window.scrollTo(0, 0);
        const payload = {
            "offer_campaign": {
                "offer_id": this.props.match.params.id,
                "currency": this.props.countrySelected
            }
        }
        this.props.checkTokenValidity()
        this.props.fetchOfferDetails(payload)
        this.props.fetchTermsAndConditions(payload)
        this.props.removeOrder()
    }
    componentDidUpdate = (prevProps, prevState) => {

        if ((prevProps.currancyCode !== this.props.currancyCode) || (this.props.match.params.id !== prevProps.match.params.id)) {
            const payload = {
                "offer_campaign": {
                    "offer_id": this.props.match.params.id,
                    "currency": this.props.countrySelected
                }
            }
            this.props.fetchOfferDetails(payload)
            this.props.fetchTermsAndConditions(payload)

        }
        if (!this.props.loading && typeof this.props.offerDetails === 'undefined') {
            this.props.history.push({
                pathname:'/404',
                redirectionURL:'/offers'
            })
        }

    }

    setQuantity = (val) => {
        this.setState({ quantity: val })
    }

    orderSummary = (val, ...props) => {

        let amount = this.state.quantity * this.props.offerDetails.hassad_offer_value
        let amount_points = currancyToPoints(amount)
        let data = props[0]?.data || null
        let type = props[0]?.type || null
        let paymentType = props[0]?.paymentType

        let orders;
        let newOrders;

        if (this.state.showNewGiftFlow && this.props.message != "") {
            if (paymentType === 'creditcard') {
                orders = {
                    "brand_id": this.props.offerDetails.id,
                    "quantity": this.state.quantity,
                    "giftcard_value": this.props.offerDetails.hassad_offer_value,
                    "amount": amount,
                    "program_id": 1,
                    "isforself": false,
                    "use_hassad_points": false,
                    "language_code": this.props.lang,
                    "currency": 2,
                    "price_in_points": amount_points,
                    "hassad_points": 0
                };
                newOrders = {
                    "quantity": this.state.quantity,
                    "offer_campaign_id": this.props.offerDetails.id,
                    "is_gift": (this.props.message == "" ? true : false),
                    "use_hassad_points": false,
                    "hassad_points": 0,
                    "language_code": this.props.lang,
                    "price_in_points": amount_points,
                    "giftcard_value": this.props.offerDetails.hassad_offer_value,
                    "gift_message": this.props.message,
                    "sender_name": this.props.from,
                    "gift_occasion_image_id": this.props.GiftParticularCardSelect,
                    "recipient_name": this.props.to,
                }
                this.setState({ cardPayment: true })
            }
            else {
                orders = {
                    "brand_id": this.props.offerDetails.id,
                    "quantity": this.state.quantity,
                    "giftcard_value": this.props.offerDetails.hassad_offer_value,
                    "amount": amount,
                    "program_id": 1,
                    "isforself": true,
                    "use_hassad_points": this.props.pointBalance > 0,
                    "language_code": this.props.lang,
                    "currency": 2,
                    "price_in_points": amount_points,
                    "hassad_points": amount_points <= Number(this.props.pointBalance) ? amount_points : Number(this.props.pointBalance)
                }

                newOrders = {
                    "quantity": this.state.quantity,
                    "offer_campaign_id": this.props.offerDetails.id,
                    "is_gift": (this.props.message == "" ? true : false),
                    "use_hassad_points": this.props.pointBalance > 0,
                    "hassad_points": amount_points <= Number(this.props.pointBalance) ? amount_points : Number(this.props.pointBalance),
                    "language_code": this.props.lang,
                    "price_in_points": amount_points,
                    "giftcard_value": this.props.offerDetails.hassad_offer_value,
                    "amount": amount,
                    "program_id": 1,
                    "gift_message": this.props.message,
                    "sender_name": this.props.from,
                    "gift_occasion_image_id": this.props.GiftParticularCardSelect,
                    "recipient_name": this.props.to,
                }
                this.setState({ cardPayment: false })
            }
            this.setState({
                confirmOrder: !this.state.confirmOrder,
                orders: orders,
                newOrders: newOrders
            }, () => {
                if (this.state.orders.isforself == false) {
                    if (this.props.format == "both") {
                        this.setState(prevState => ({
                            newOrders: {
                                ...prevState.newOrders,
                                "recipient_email": this.props.email,
                                "recipient_phone": `${this.props.giftCountryCode}${this.props.mobile}`,
                            }
                        }))
                    }
                    else if (this.props.format == "email") {
                        this.setState(prevState => ({
                            newOrders: {
                                ...prevState.newOrders,
                                "recipient_email": this.props.email,
                            }
                        }))
                    }
                    else {
                        this.setState(prevState => ({
                            newOrders: {
                                ...prevState.newOrders,
                                "recipient_phone": `${this.props.giftCountryCode}${this.props.mobile}`,
                            }
                        }))
                    }
                }
            })
        }

        else{
            paymentType === 'creditcard' ? this.setState({ cardPayment: true }) : this.setState({ cardPayment: false })
        this.setState(prevState => ({
            confirmOrder: val,
            // just to map on the UI
            orders: {
                "offer_campaign_id": this.props.offerDetails.id,
                "quantity": prevState.quantity,
                "giftcard_value": this.props.offerDetails.hassad_offer_value,
                "amount": amount,
                // "program_id": 1,
                // "isforself": true,
                "use_hassad_points": paymentType === 'creditcard' ? false : this.props.pointBalance > 0,
                "language_code": this.props.lang,
                "currency": this.props.countrySelected,
                // "price_in_points": amount_points,
                "hassad_points": paymentType === 'creditcard' ? 0 : amount_points <= this.props.pointBalance ? amount_points : this.props.pointBalance
            },
            // Payload for offers
            newOrders: {
                "quantity": prevState.quantity,
                "offer_campaign_id": this.props.offerDetails.id,
                "currency": this.props.countrySelected,
                // "is_gift":props[0]?.isGift,
                "gift_flag": props[0]?.isGift,
                "use_hassad_points": paymentType === 'creditcard' ? false : this.props.pointBalance > 0,
                "hassad_points": paymentType === 'creditcard' ? 0 : Number(amount_points) <= Number(this.props.pointBalance) ? Number(amount_points) : Number(this.props.pointBalance),
                "language_code": this.props.lang,
                // "price_in_points":amount_points,
                "giftcard_value": this.props.offerDetails.hassad_offer_value,
                "gift_message": props[0]?.message,
                "amount":this.props.offerDetails.hassad_offer_value
            }
        }), () => type === 'email' ?
            this.setState(prevState => ({
                newOrders: {
                    ...prevState.newOrders,
                    'contact_email': data
                }
            })) :
            this.setState(prevState => ({
                newOrders: {
                    ...prevState.newOrders,
                    'contact_phone': data
                }
            }))
        )
        }
    }
    detectURLs(text) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.split(urlRegex)
            .map(part => {
                if (part?.match(urlRegex)) {
                    return <a href={part} key={part} target="_blank" rel="noreferrer" > {part} </a>;
                }
                return part;
            });
    }
    emailPreviewshowHandler = () => {
        this.setState({ emailPreviewshow: !this.state.emailPreviewshow });
    }
    setGoalModalView = (value)=>{
        this.setState({goalModalShow: !this.state.goalModalShow,goalModalDetail:value || this.state.goalModalDetail})
    }
    setGoalConfirmModalView = ()=>{
        this.setState({goalConfirmModalShow: !this.state.goalConfirmModalShow})
    }

    setGoalStatusModalView = () => {
        this.setState({goalStatusModalShow:false})
    }

    setGoalItem = (date) => {
        let payload = {
            "customer_goal_item": [{
                "product_id": this.props.match?.params?.id,
                "product_type": "OFFERS",
                "product_price": this.props.offerDetails?.hassad_offer_value,
                "goal_item": this.state.goalModalDetail === 'Remove' ? false : true,
                "remind_me_later_date": date ? new Date(date)?.toISOString()?.slice(0,10) : ''
            }]
        }
        this.props.setGoalItem(
            payload,
            this.fetchOffer,
            this.state.goalModalDetail);
        this.setState({goalStatusModalShow:true})
    }
    render() {
        const goalItemStyle = {
            backgroundImage: `url(${ goalItemImage })` ,
             backgroundRepeat : 'no-repeat',
                 backgroundPosition: '10%' , 
          }; 
        const { t, email, message, url, from, to, mobile, giftCountryCode } = this.props
        const breadcrumbs = [
            { name: t("Home"), url: '/', active: true },
            { name: t("Offers"), url: `/offers`, active: true },
            {
                name: this.props.lang !== 'ar' ? this.props.offerDetails && this.props.offerDetails.title
                    : this.props.offerDetails && this.props.offerDetails.title_arabic
                , url: '', active: false
            }

        ]
        const { confirmOrder, orders, quantity, goalModalDetail, goalStatusModalShow, goalModalShow } = this.state;
        const offerDetails = this.props.offerDetails;
        const { state } = this.props.location;

        const numberWithCommas = (x) => {
            // let y = +x.toFixed(0)
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        const checkExpired = () => {
            if (this.props.offerDetails?.effective_to_date && new Date() > new Date(this.props.offerDetails?.effective_to_date)) return false
            else return true
        }
        return (
            <>
            <GoalItemStatusModal goalModalDetail={goalModalDetail} show={goalStatusModalShow} setGoalModalView={this.setGoalStatusModalView}/>
            {this.props.loading ? 
                <section>
                    <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    </section> :
                    this.props.offerDetails && <section>
                        <Breadcrumbs crumbs={breadcrumbs} />
                        <GoalItemConfirmModal goalModalDetail={goalModalDetail} setGoalModalView={this.setGoalModalView} show={goalModalShow} lang={this.props.lang} productValue={offerDetails?.hassad_offer_value} currancyCode={this.props.currancyCode} setGoalItem={this.setGoalItem}
                image={offerDetails?.image?.medium_rectangle} 
                title={offerDetails?.title} title_arabic={offerDetails?.title_arabic}
                />
                        <div className="px-md-5 pt-md-5 pt-3 px-3  d-flex flex-md-row flex-column">
                            {
                                confirmOrder && <OfferConfirmationModal
                                    pyload={this.state.newOrders}
                                    orders={orders}
                                    card={this.props.offerDetails}
                                    show={confirmOrder}
                                    remove={this.orderSummary}
                                    lang={this.props.lang}
                                    cardPayment={this.state.cardPayment}
                                    GiftCardValue={numberWithCommas(currancyToPoints(offerDetails?.hassad_offer_value * quantity))}
                                    GiftCardEmail={email} GiftCardMobile={mobile}
                                    GiftCardMobileCode={giftCountryCode}
                                    GiftCardQuantity={quantity}
                                    GiftCardMessage={message} url={url}
                                />
                            }
                            <div className="col-3 p-0 d-md-block d-none mb-2">
                                <img
                                    className="img-fluid mx-auto d-block w-100"
                                    src={offerDetails?.image?.medium_rectangle}
                                    alt={offerDetails?.brand_name}
                                />
                            </div>
                   

                            <div className="flex-grow-1 px-md-5 px-0">
                                <div className="pb-md-3 pb-2">
                                    <div className="fs-md-3 fs-5 fw-md-bold fw-bolder product_title">
                                        {this.props.lang !== 'ar' ? offerDetails.title : offerDetails.title_arabic}
                                    </div>
                                </div>
                                { offerDetails?.goal_item_flag && <div style={goalItemStyle} className='goalItemSVG text-light text-center p-1 mb-2'><p className='ml-3'>{t("Goal Item")}</p></div>}
                                {
                            checkExpired() && <div className="fs-5 fw-bold text-black-50">
                            {this.props.lang !== 'ar' ? offerDetails?.detail : offerDetails?.detail_arabic}
                        </div>
                        }
                                {
                            checkExpired() ? <div className="fs-5 fw-bold text-dark" style={{fontSize:"20px"}}>
                            {numberWithCommas(currancyToPoints(offerDetails?.hassad_offer_value * quantity))} <small> {t("Points")}</small>
                        </div> : <div className='d-none d-md-block'>
                        <hr className="hr-line my-2"/>
                        <div className="text-center"> 
                                    <p className='text-danger fw-bold'>{t("Sold Out")}...... {t("Coming Soon")}</p>
                                </div>
                        </div>
                        }
                        </div>
                        <div className='d-md-none d-block p-3'>
                            <img
                                className="mx-auto d-block"
                                src={offerDetails?.image?.medium_rectangle} 
                                alt={offerDetails?.brand_name} srcSet=""
                                style={{objectFit:'cover',height:'60vw',maxWidth:'100%'}}/>
                        </div>
                        {
                                checkExpired() && <BuyNowCard
                                    quantityDisable={true}
                                    onSubmit={this.orderSummary}
                                    setQuantity={this.setQuantity}
                                    lang={this.props.lang}
                                    price={(offerDetails?.hassad_offer_value * quantity) > 1 ? true : false}
                                    id={this.props.match.params.id}
                                    termsConds={this.state.termsConds}
                                    emailPreviewshowHandler={this.emailPreviewshowHandler}
                                    emailPreviewshow={this.state.emailPreviewshow}
                                    state={state}
                                    giftCardValue={numberWithCommas(currancyToPoints(offerDetails?.hassad_offer_value * quantity))}
                                    card={offerDetails}
                                    currancyCode={this.props.currancyCode}
                                    productID = {this.props.match?.params?.id}
                        selectedRate = {offerDetails?.hassad_offer_value}
                        setGoalModalView={this.setGoalModalView}
                        GoalItem={true}
                        toogleLang={this.props.toogleLang}
                                />
                            }
                        </div>
                        
                        
                        
                        <div className=' my-4'>
                        {/* <div className="d-grid pb-2 text-center">
                </div> */}
                {
                        checkExpired() ? false : <div className='d-block d-md-none'>
                        <hr className="hr-line my-2"/>
                        <div className="text-center"> 
                                    <p className='text-danger fw-bold'>{t("Sold Out")}...... {t("Coming Soon")}</p>
                                </div>

                            </div>
    }
                           

                        </div>

                        {
                            checkExpired() && <div className='d-md-block d-none pb-4 px-5 col-9'>
                                <h5 className=" headings fw-bold fs-5 mb-2"> <p className=''>{t("Terms")}</p></h5>
                                {this.props.termsAndConditions &&
                                    this.props.termsAndConditions.terms[(this.props.lang === "ar" && this.props.termsAndConditions.terms?.find(val => val.language?.includes("ARABIC"))) ? 1 : 0]
                                        .terms_text?.split("\n")
                                        ?.map(terms =>
                                            <>
                                                <div className="py-1">
                                                    {this.detectURLs(terms)}
                                                </div>

                                            </>
                                        )}
                            </div>
                        }
                        <EmailPreview
                            show={this.state.emailPreviewshow}
                            showMethod={this.emailPreviewshowHandler}
                            card={offerDetails}
                            termsConds={this.props.termsAndConditions.terms}
                            from={from}
                            url={url}
                            GiftMessage={message}
                            type="offer"
                            currancyCode={this.props.currancyCode}
                            giftCardValue={numberWithCommas(currancyToPoints(offerDetails?.hassad_offer_value * quantity))}
                        />
                        {
                            (message) ? <div className='d-md-block d-none px-5 position-relative'>
                                <Link
                                    to={{
                                        pathname: '/giftflow',
                                        state: {
                                            id: this.props.match.params.id,
                                            GiftMessage: message,
                                            GiftFrom: from,
                                            GiftTo: to,
                                            GiftEmail: email,
                                            GiftMobile: mobile,
                                            GiftCountryCode: giftCountryCode
                                        }
                                    }}
                                >
                                    <button className="color-blue giftcardeditbuttondesk">Edit</button>
                                </Link>
                                <div className="giftcardeditdesk px-5 pt-5">
                                    <div className="row pb-5 border-bottom">
                                        <div className="col-3">
                                            <img width="100%" height="auto" src={url} alt="" />
                                        </div>
                                        <div className="col-4 d-flex align-items-center pl-4">
                                            <p className="giftcardeditmssg">{message}</p>
                                        </div>
                                        <div className="col-2">
                                        </div>
                                        <div className="col-3 text-center">
                                            <img width="80%" height="auto"
                                                src={offerDetails?.image?.medium_rectangle}
                                                alt="" />
                                            <p className="fw-bold fs-4 ">{t(this.props.currancyCode)} {numberWithCommas(currancyToPoints(offerDetails?.hassad_offer_value * quantity))}</p>
                                        </div>
                                    </div>
                                </div>
                                <div>

                                    <div className="pt-2 hoverpointer">
                                        <p onClick={this.emailPreviewshowHandler} className="w-100 text-right fw-bold"> <ins> Click for the email preview </ins></p>
                                    </div>
                                </div>
                            </div> : null
                        }

                    </section>
                }
            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        offerDetails: state.offers.offerDetails.offer,
        termsAndConditions: state.offers.termsAndConditions,
        loading: state.offers.offerDetailsLoading,
        pointBalance: state.point.pointBalance,
        fetchError: state.offers.error,
        countrySelected: state.countries.countryId,
        currancyCode: state.countries.convertionRate.currency_code,
        email: state.giftFormDetails.email,
        message: state.giftFormDetails.message,
        url: state.giftFormDetails.url,
        from: state.giftFormDetails.from,
        to: state.giftFormDetails.to,
        mobile: state.giftFormDetails.mobile,
        giftCountryCode: state.giftFormDetails.giftCountryCode,
        convertionRate: state.countries.convertionRate,
        // giftCountryCode: state.giftFormDetails.giftCountryCode,
        format: state.giftFormDetails.GiftFormat,
        GiftParticularCardSelect: state.giftFormDetails.GiftParticularCardSelect,

    }
}


const mapDispatchToProps = dispatch => {
    return {
        fetchOfferDetails: (payload) => dispatch(fetchOfferDetails(payload)),
        removeOrder: () => dispatch(removeOrder()),
        fetchTermsAndConditions: (payload) => dispatch(fetchTermsAndConditions(payload)),
        checkTokenValidity: () => dispatch(checkTokenValidity()),
        setGoalItem: (payload,productAPI,status) => dispatch(setGoalItem(payload,productAPI,status))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OfferPage));