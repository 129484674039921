import { SET_GOAL_FAILURE, SET_GOAL_REQUEST, SET_GOAL_SUCCESS, FETCH_GOAL_FAILURE, FETCH_GOAL_REQUEST, FETCH_GOAL_SUCCESS } from "./goalItemsTypes"

const initialState = {
    fetchLoading:true,
    setLoading:false,
    goalItemData:undefined,
    fetchError:'',
    msg:'',
    goalStatus:'Processing'
}

const reducer = (state= initialState,action) => {

    switch (action.type){
        case FETCH_GOAL_REQUEST:
            return {
                ...state,
                fetchLoading:true,
                goalItemData:undefined,
                fetchError:''
            }
        case FETCH_GOAL_SUCCESS:
            return{
                ...state,
                goalItemData:action.payload,
                fetchLoading:false
            }
        case FETCH_GOAL_FAILURE:
            return{
                ...state,
                fetchError: action.payload,
                fetchLoading:false
            }
        case SET_GOAL_REQUEST:
            return{
                ...state,
                setLoading:true,
                msg:'',
                goalStatus:'Processing'
            }
        case SET_GOAL_SUCCESS:
            return{
                ...state,
                msg:action.payload,
                setLoading:false,
                goalStatus:'Success'
            }
        case SET_GOAL_FAILURE:
            return{
                ...state,
                msg:action.payload,
                setLoading:false,
                goalStatus:'Failure'
            }
        default:
            return state
    }
}

export default reducer