import React, { Component } from "react";
import smilySvg from "../../../assests/img/smilyy.svg";
import { Link } from "react-router-dom";
import ModalWrapper from "./ModalWrapper";
import { withTranslation } from "react-i18next";

class UserPreferenceSuccessModel extends Component {
  render() {
    const { t, userProfile } = this.props;
    return (
      <ModalWrapper show={this.props.show}>
        <div
          className="p-4 bg-light mx-auto text-center py-md-5 py-4"
          style={{ width: "35rem" }}
        >
          <img className="p-md-0 p-3" height="100" src={smilySvg} alt="" />
          <h3 className="text-muted m-0 py-md-3 py-0">
            {t(`You have successfully updated your country to`)}
            <span className="font-weight-bold"> {userProfile?.country_name} </span>
            {t(`and language`)}
            <span className="font-weight-bold"> {userProfile?.language_name} </span>
          </h3>
          <Link
            to={{pathname:`/`}}
          >
            <button className="btn btn-secondary mt-3 mx-2">
              <span className="fw-bold primary-color">{t("OK")}</span>
            </button>
          </Link>
        </div>
      </ModalWrapper>
    );
  }
}

export default withTranslation()(UserPreferenceSuccessModel);
