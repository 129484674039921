import React, { Component } from "react";
import Breadcrumbs from "../shared/Breadcrumbs";
// import img1 from "../../assests/img/experiences/img (1).jpg";
// import img2 from "../../assests/img/experiences/img (2).jpg";
// import img3 from "../../assests/img/experiences/img (3).jpg";
// import img4 from "../../assests/img/experiences/img (4).jpg";
// import img5 from "../../assests/img/experiences/img (5).jpg";
// import adultIcon from "../../assests/img/experiences/icon-user-head-empty.svg";
import AboutAndTC from "../shared/SoftwareGamesT&C";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  fetchSoftwareGamesDetails,
  fetchSoftwareGamesTerms,
} from "../../Redux/Software&Games/SoftwaresAction";
import { currancyToPoints } from "../../utils/pointsConversion";
import BuyCardforExperience from "../shared/BuyCardforExperience";
import ExperienceConfirmationModal from "../shared/Modals/SoftwareGamesConfirmationModal";
import { checkTokenValidity } from "../../Redux/auth/authActions";
// import calendarIcon from "../../assests/img/experiences/icon-calendar.svg";
// import DatePicker, { registerLocale } from "react-datepicker";
// import ar from "date-fns/locale/ar-SA";
import "react-datepicker/dist/react-datepicker.css";
import "../Softwares&Games/Softwares&Games.css";
import windows from "../../assests/img/header/icon-windows.png";
import mac from "../../assests/img/header/icon-apple.png";
import linux from "../../assests/img/header/icon-linux.png";
import android from "../../assests/img/header/icon-android.png"
import GoalItemStatusModal from '../GoalItems/GoalItemStatusModal';
import GoalItemConfirmModal  from '../GoalItems/GoalItemConfirmModal';
import { setGoalItem } from '../../Redux/goalItems/goalItemsActions';
import goalItemImage from '../../assests/img/goalitems/icon-change-goal-item.svg'
// registerLocale("ar", ar);

class SoftwareGamesBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      recipient_name: "",
      recipient_email: "",
      recipient_phone: 0,
      confirmOrder: false,
      orders: {},
      pyload: {},
      categorie: "",
      types:"",
      cardPayment:false,
      paymentType:'points',
      goalModalShow:false,
      goalStatusModalShow:false,
      goalModalDetail:''
    };
  }

  componentDidMount() {
    localStorage.removeItem("type");
    localStorage.setItem("type", "softwareGames");
    window.scrollTo(0, 0);
    const payload = {
      software_and_game: {
        currency: this.props.countrySelected,
        id: this.props.match.params.id,
      },
    };
    this.props.checkTokenValidity();
    this.props.fetchSoftwareGamesDetails(payload);
    this.props.fetchSoftwareGamesTerms(payload);
    if (!this.props.loading && this.props.softwareGamesDetails?.length === 0) {
      this.props.history.push({
        pathname:'/404',
        redirectionURL:'/softwares&games'
      })
    }
    this.setState({
      types:this.props.softwareGamesDetails.type
    })
  }
  orderSummary = (val) => {
    const details = this.props.softwareGamesDetails;
    let amount = details.price
    let amount_points = currancyToPoints(amount)
    let {paymentType} = this.state
    paymentType === 'creditcard' ? this.setState({cardPayment:true}) : this.setState({cardPayment:false})
    this.setState((prevState) => ({
      confirmOrder: !this.state.confirmOrder,
      orders: {
        "brand_id":details.id,
        "quantity":1,
        "amount":amount,
        "program_id":1,
        "isforself":true,
        "use_hassad_points":paymentType === 'creditcard' ? false : this.props.pointBalance>0,
        "language_code":this.props.lang,
        "currency":this.props.countrySelected,
        "hassad_points": paymentType === 'creditcard' ? 0 : amount_points <= this.props.pointBalance ? amount_points: this.props.pointBalance
      },
      pyload: {
        orders: {
          brand_id: details.id,
          quantity: 1,
          isforself: true,
          // giftcard_value: amount,
          product_value:amount,
          amount: amount,
          // price_in_points: details.price,
          use_hassad_points:
            paymentType === "creditcard" ? false : this.props.pointBalance > 0,
          hassad_points:
            paymentType === "creditcard"
              ? 0
              : Number(amount_points) <= Number(this.props.pointBalance)
              ? amount_points
              : this.props.pointBalance,
          language_code: this.props.lang,
          reward_type: details.type,
          currency: this.props.countrySelected,
          recipient_name: this.state.recipient_name,
          recipient_email: this.state.recipient_email,
          recipient_phone: this.state.recipient_phone,
        },
      },
    }));
  };
  handleCallback = (childData) =>{

    this.setState({recipient_name: childData.name, recipient_email:childData.email,recipient_phone:childData.number,paymentType:childData.paymentType},()=>this.orderSummary())
}
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.currancyCode !== this.props.currancyCode ||
      this.props.match.params.id !== prevProps.match.params.id
    ) {
      const payload = {
        software_and_game: {
          currency: this.props.countrySelected,
          id: this.props.match.params.id,
        },
      };
      this.props.fetchSoftwareGamesDetails(payload);
      this.props.fetchSoftwareGamesTerms(payload);
    }
    if (!this.props.loading && this.props.softwareGamesDetails?.length === 0) {
      this.props.history.push({
        pathname:'/404',
        redirectionURL:'/softwares&games'
      })
    }
    if(!this.state.types && this.props.softwareGamesDetails.length !== 0 ){
      this.setState({
        types:this.props.softwareGamesDetails.type
      })
    }
  }
  componentWillUnmount() {
    this.setState({ isLoaded: false });
  }
  numberWithCommas = (x) => {
    // let y = x.toFixed(2);
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  imageFinder(value){
    if(value === 'windows') return windows
    else if(value === 'mac') return mac
    else if( value === "linux") return linux
    else if( value === "android") return android
    else if (value === 'ios') return mac
    else return null
  }

  setGoalModalView = (value)=>{
    this.setState({goalModalShow: !this.state.goalModalShow,goalModalDetail:value || this.state.goalModalDetail})
}
setGoalConfirmModalView = ()=>{
    this.setState({goalConfirmModalShow: !this.state.goalConfirmModalShow})
}

setGoalStatusModalView = () => {
    this.setState({goalStatusModalShow:false})
}

setGoalItem = (date) => {
  let payload = {
      "customer_goal_item": [{
          "product_id": this.props.softwareGamesDetails?.id,
          "product_type": "SOFTWARE_AND_GAME",
          "product_price": this.props.softwareGamesDetails?.price,
          "goal_item": this.state.goalModalDetail === 'Remove' ? false : true,
          "remind_me_later_date": date ? new Date(date)?.toISOString()?.slice(0,10) : ''

      }]
  }

  
  this.props.setGoalItem(
       payload,
      this.fetchSoftwareGames,
      this.state.goalModalDetail);
  this.setState({goalStatusModalShow:true})
}

  render() {
    const goalItemStyle = {
      backgroundImage: `url(${ goalItemImage })` ,
       backgroundRepeat : 'no-repeat',
           backgroundPosition: '10%' , 
    };
    const details = this.props.softwareGamesDetails;
    let date = new Date(details?.pub_date)?.toDateString();
    const { t } = this.props;
    const breadcrumbs = [
      { name: "Home", url: "/", active: true },
      { name: "Softwares & Games", url: `/softwares&games`, active: true },
    //   {
    //     name:this.state.types , active:true
    //   },
      {
        name: this.props.lang !== "ar" ? details?.name : details?.name_arabic,
        url: "",
        active: false,
      },
    ];
    let showCard = true;
    return (
      <>
      <GoalItemStatusModal goalModalDetail={this.state.goalModalDetail} show={this.state.goalStatusModalShow} setGoalModalView={this.setGoalStatusModalView}/>
        {this.props.loading ? (
          <section>
            <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4 ">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </section>
        ) : (
          <section>
            <Breadcrumbs crumbs={breadcrumbs} />
            <GoalItemConfirmModal goalModalDetail={this.state.goalModalDetail} setGoalModalView={this.setGoalModalView} show={this.state.goalModalShow} lang={this.props.lang} productValue={ this.props.softwareGamesDetails?.price} currancyCode={this.props.currancyCode} setGoalItem={this.setGoalItem} image={ this.props.softwareGamesDetails?.images?.color?.medium_rectangle} 
                title={ this.props.softwareGamesDetails?.name} title_arabic={ this.props.softwareGamesDetails?.name_arabic}
                />
            {this.state.confirmOrder && (
              <ExperienceConfirmationModal
                lang={this.props.lang}
                orders={this.state.orders}
                pyload={this.state.pyload}
                card={details}
                show={this.state.confirmOrder}
                remove={this.orderSummary}
                cardPayment={this.state.cardPayment}
              />
            )}
            <div className="p-md-5 p-3 d-flex flex-md-row flex-column">
              <div className="d-md-block d-none col-3">
                {details && (
                  <img
                    className=" img-fluid"
                    src={details?.images?.color?.large_rectangle}
                    alt={details?.name}
                    srcSet=""
                  />
                )}
              </div>
              <div className="flex-grow-1 d-grid grid-cols-md-1 grid-col-1 gap-5 px-0     px-md-5">
                <div className="p-0 text-black-50">
                  <div className="pb-3">
                    <div className="fs-4 fw-bolder headings">
                      {this.props.lang !== "ar"
                        ? details?.name
                        : details?.name_arabic}
                    </div>
                    <div
                      className="fs-5 fw-bold text-dark"
                      style={{ fontSize: "20px" }}
                    >
                      {this.numberWithCommas(currancyToPoints((details?.price))
                      )}
                      <small> {t("Points")}</small>
                    </div>
                  </div>
                  { details.goal_item_flag && <div style={goalItemStyle} className='goalItemSVG text-light text-center p-1 mb-2'><p className='ml-3'>{t("Goal Item")}</p></div>}
                  <div>
                    <div>
                      <div className="d-md-none d-block p-3">
                        <img
                          className="mx-auto d-block"
                          src={details?.images?.color?.medium_rectangle}
                          alt={details?.name}
                          srcSet=""
                          style={{
                            objectFit: "cover",
                            height: "60vw",
                            maxWidth: "100%",
                          }}
                        />
                      </div>
                      <table
                        class="borderless fw-bold my-3 "
                        style={{ color: "black" }}
                      >
                        <tbody style={{ lineHeight: "1.8rem" }}>
                          <tr>
                            <td class="d-flex justify-content-start flip">
                              {t("Publisher")}:
                            </td>
                            <td class="no-padding">
                              {this.props.lang === "en"
                                ? details?.publisher
                                : details?.publisher}
                            </td>
                          </tr>
                          <tr>
                            <td class="d-flex justify-content-start flip">
                              {t("Operating System")}:
                            </td>
                            <td class="no-padding ">
                        
                              {   details?.operating_system?.map(operating_system => 
                              <>
                                <span> {operating_system}</span>
                                <img src={this.imageFinder(operating_system)} alt='' srcSet="" />
                              </>
                              
                              )}
                              
                              {/* <img src={windowsIcon} alt="windows" /> */}
                            </td>{" "}
                          </tr>
                          <tr>
                            <td class="d-flex justify-content-start flip">
                              {t("Release Date")}:
                            </td>
                            <td class="no-padding">                            
                               {date?.slice(3)}
                            </td>
                          </tr>
                          {/* <tr>
                            <td class="d-flex justify-content-start flip">
                              {t("Category")}:
                            </td>
                            <td class="no-padding">
                              {this.props.lang === "en" ? null : null}
                            </td>
                          </tr> */}
                          <tr>
                           {
                           details &&  details?.file_size !== null &&
                            <>
                             <td class="d-flex justify-content-start flip">
                             {t("File size")}:
                           </td>
                           <td class="no-padding">
                           
                            { details?.file_size}
                              
                           </td>
                            </>
                           }
                          </tr>
                          <tr>
                            <td class="d-flex justify-content-start flip">
                              {t("Age Rating")}:
                            </td>
                            <td class="no-padding">
                              {Math.round(details?.rating)}{"+"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {showCard && <AboutAndTC lang={this.props.lang} />}
                </div>
              </div>

              {showCard && (
                <BuyCardforExperience
                  parentCallback={this.handleCallback}
                  onSubmit={this.orderSummary}
                  lang={this.props.lang}
                  price = {details?.price_in_sar > 1}
                  catType = 'software'
                  setGoalModalView={this.setGoalModalView}
                  productID = {this.props.match?.params?.id}
                  selectedRate = {this.props.softwareGamesDetails?.price}
                  toogleLang={this.props.toogleLang}
                />
              )}
            </div>
          </section>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    softwareGamesDetails: state.softwareGames.softwareGameseDetails,
    // details:state.softwareGames.experienceImages,
    loading: state.softwareGames.detailsLoading,
    countrySelected: state.countries.countryId,
    currancyCode: state.countries.convertionRate.currency_code,
    fetchError: state.experiences.error,
    pointBalance: state.point.pointBalance,
    imgLoading: state.experiences.imgLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSoftwareGamesDetails: (payload) =>
      dispatch(fetchSoftwareGamesDetails(payload)),
    fetchSoftwareGamesTerms: (payload) =>
      dispatch(fetchSoftwareGamesTerms(payload)),
    checkTokenValidity: () => dispatch(checkTokenValidity()),
    setGoalItem: (payload,productAPI,status) => dispatch(setGoalItem(payload,productAPI,status))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SoftwareGamesBooking));
