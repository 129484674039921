import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { currancyToPoints } from 'utils/pointsConversion';

class ProductOffersCard extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    numberWithCommas = (x) => {
        let y = x.toFixed(0)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    
    render() { 
        const card= this.props.card
        const{t}=this.props;
        let date = new Date(card.effective_to_date).toDateString();
        
        return ( 
            <div className='' style={{overflow:'hidden'}}>
                 <Link className='offerCardHover' to={`/offer/${card.id}`}>
                    <div style={{cursor:'pointer'}} className="border p-0">
                <img
                    className="img-fluid w-100" 
                    src={card.image && card.image.small_square } alt={card.title} />
                <div className="p-md-3 p-2">
                    <h5 className="product_title fw-bold m-0 d-inline-block"
                        style={{minHeight:"75px",textOverflow:'ellipsis',fontSize:'1.1rem'}}>{this.props.lang!=='ar'?card.title:card.title_arabic}</h5>
                        {/* <div style={{minHeight:"70px"}}className="text-black-50">by {card.brand_name}</div> */}
                    <div className='pt-1 pb-3 product_value'>
                        <h4 className="d-inline">{currancyToPoints(this.numberWithCommas(card.hassad_offer_value))}</h4> <small>Points</small>
                       
                    </div>
                    {
                        new Date() < new Date(date) ? <div style={{minHeight:"45px"}} className="text-danger"> {t("Valid Until")}{`: ${date.slice(3)}`}</div> : <div style={{minHeight:"45px"}} className="text-danger"> {t("Sold Out")}...... {t("Coming Soon")}</div>
                    }
                </div>
            </div>
                 </Link>
            </div>
         );
    }
}
 
export default (withTranslation() (ProductOffersCard));