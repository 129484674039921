import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// import Card from '../shared/Card';
import SearchBar from './SearchBar';


class DisplayCards extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            searchFilter:"",
            dropdownFilter:"ALL",
            filteredlist:[],
            displayFiltered:false
         }
    }
    setDropdownFilter = (val) =>{this.setState({dropdownFilter:val})}
    setFilter=(val)=>{this.setState({searchFilter:val})}
    searchFilter=(list,type)=>{

       return this.props.cards && this.props.cards
                .filter(em=>
                    
                    (((this.props.type === 'merchandise' || this.props.type === "physicalGiftcards")? em.product_name : em.brand_name).match(new RegExp(`${this.state.searchFilter.replace(/[^\w\s]/gi, "")}`,"ig"))
                    &&
                    (this.state.dropdownFilter==="ALL"?
                        true:
                        this.state.dropdownFilter===em.order_status || this.state.dropdownFilter===em.status)
                    ))
              
        
    }

    componentDidMount(){
        window.scrollTo(0, 0)
    }
    render() { 
        // Mobile view mywallet inner module
        const {cards,name } = this.props
        const {dropdownFilter} = this.state;
        const{t} = this.props
        const linkCards = (type,row) =>{
            if(row.giftcard_token){
                type = 'receivedGiftcards'
            }
            else if(row.voucher_url){
                type = 'receivedOffers'
            } 

            if(type === 'giftCards' || type === 'eMagazines'){
                return (
                    <>
                    {
                       row.isgift === false &&      row?.giftcard_tokens?.map((token,index)=>{
                            return(
                              <>       
                              <Link
                                target='_blank'
                              to={{
                              pathname:`/giftcard/${token}/details?lang=${this.props.lang}`,
                               state: {...row }
                             }}
                 
                            >
                              <td className="px-1 py-2 align-bottom  "><button className='bg-light-gray border-0 px-3  fw-bold view-button' ><p className='m-0'  style={{fontSize:"15px"}}>0{index+1}</p>
                                        <h6 className='m-0 fw-bold '  style={{fontSize:"11px", color:"#00008b"}}>{t("View")}</h6>
                                </button> </td>
                                 </Link>
                            
                              </>
                               )
                            })
                      } 
                   </>       
                )
            }
            else if(type === 'offers'){
                if(row.vouchers === null){
                    return 
                }
                return (
                    <>
                    {
                        row.is_gift === false &&    row.vouchers.map((element,index)=>{
                        return(
                         <a style={{textDecoration:"none"}}
                         href= {element.offer_url}
                         target="_blank"
                         rel="noreferrer"
                         >
                                     <td className="px-1 py-4 align-bottom  "><button className='bg-light-gray border-0 px-3  fw-bold view-button' ><p className='m-0'  style={{fontSize:"15px"}}>0{index+1}</p>
                                     <h6 className='m-0 fw-bold mb-1 '  style={{fontSize:"11px", color:"#00008b"}}>{t("View")}</h6>
                             </button> </td>
                         </a>
                        )
                          })
                    }     
                     </>
                )
            }
            else if(type === 'merchandise' || type === "physicalGiftcards"){
                return(
                    <Link
                    to={{pathname:row.shipment_tracking_url}}
                    target="_blank" rel="noopener noreferrer"
                    >
                    <td className="px-1 py-2 align-bottom  "><button className='bg-light-gray border-0 px-3  fw-bold view-button' ><p className='m-0'  style={{fontSize:"15px"}}>01</p>
                                     <h6 className='m-0 fw-bold '  style={{fontSize:"11px", color:"#00008b"}}>{t("View")}</h6>
                             </button> </td>
                    </Link>
                )
            }
            else if(type === 'experience'){
                
                return (
                    row.experience_url && 
                    <Link
                    to={{pathname:row.experience_url}}
                    target="_blank" rel="noopener noreferrer"
                    >
                    <td className="px-1 py-4 align-bottom  "><button className='bg-light-gray border-0 px-3  fw-bold view-button' >
                    <p className='m-0'  style={{fontSize:"15px"}}>01</p>
                    <h6 className='m-0 fw-bold '  style={{fontSize:"12px", color:"#00008b"}}>{t("View")}</h6>
                             </button> </td>
                    </Link>
                )
            }

            else if(type === 'software' || type === 'games'){
                
                return (
                    <Link
                    to={{pathname:`/softwareGames/${row.id}/details?lang=${this.props.lang}`,
                    state: {data:row}
                }}
                    target="_blank" rel="noopener noreferrer"
                    >
                    <td className="px-1 py-4 align-bottom  "><button className='bg-light-gray border-0 px-3  fw-bold view-button' >
                    <p className='m-0'  style={{fontSize:"15px"}}>01</p>
                    <h6 className='m-0 fw-bold '  style={{fontSize:"12px", color:"#00008b"}}>{t("View")}</h6>
                             </button> </td>
                    </Link>
                )
            }
            else if(type === 'receivedgifts'){
                
                return (
                  <>
                   {
                                  row.gift_message &&     row.gift_message !== null?
                                  
                                      <button className="bg-light-gray border-0 px-3 py-2 fw-bold text-muted text-capitalize"style={{fontSize:"12px"}}>
                                      {row.gift_message}
                                  </button>:
                                  false
                                  }
                    <Link
                    to={{
                        pathname:`/giftcard/${row?.giftcard_token}/details?lang=${this.props.lang}`,
                         state: {...row }
                       }}
                    >
                    <td className="px-1 py-2 align-bottom  "><button className='bg-light-gray border-0 px-3 fs-regular  fw-bold view-button' >
                    <p className='m-0'  style={{fontSize:"15px"}}>01</p>
                    <h6 className='m-0 fw-bold my-1'  style={{fontSize:"12px", color:"#00008b"}}>{t("View")}</h6>
                             </button> </td>
                    </Link>
                  </>
                )
            }
            else if(type === 'receivedGiftcards'){
                
                return (
                    <>
                              
                              <div className="py-2 fs-7">
                         <span>{t("Gift Card Number")}: </span><span className="color-skyblue fw-bold">{row.giftcard_number}</span>
                         </div>  
                                 {
                                  row.gift_message &&     row.gift_message !== null?
                                  
                                      <button className="bg-light-gray border-0 px-3 py-2 fw-bold text-muted text-capitalize" style={{fontSize:"12px"}}>
                                      {row.gift_message}
                                  </button>:
                                  false
                                  }
                    <Link
                    target='_blank'
                    to={{
                        pathname:`/giftcard/${row.giftcard_token}/details?lang=${this.props.lang}`,
                         state: {...row }
                       }}
                    >
                    <td className="px-1 py-2 align-bottom  "><button className='bg-light-gray border-0 px-3  fw-bold view-button' >
                    <p className='m-0'  style={{fontSize:"15px"}}>01</p>
                    <h6 className='m-0 fw-bold my-1'  style={{fontSize:"12px", color:"#00008b"}}>{t("View")}</h6>
                             </button> </td>
                    </Link>
                    </>
                )
            }
            else if(type === 'receivedOffers'){
                
                return (
                    <>
                         <div className="py-2 fs-7">
                         <span>{t("Voucher Number")}: </span><span className="color-skyblue fw-bold">{row.voucher_number}</span>
                         </div>   
                      {
                                  row.gift_message &&     row.gift_message !== null?
                                  
                                      <button className="bg-light-gray border-0 px-3 py-2 fw-bold text-muted text-capitalize" style={{fontSize:"12px"}}>
                                      {row.gift_message}
                                  </button>:
                                  false
                                  }
                    <a style={{textDecoration:"none"}}
                             href= { row.voucher_url}
                             target="_blank"
                             rel="noreferrer"
                             >     
                                     <td className="px-1 py-4 align-bottom  "><button className='bg-light-gray border-0 px-3  fw-bold view-button' ><p className='m-0'  style={{fontSize:"15px"}}>01</p>
                                     <h6 className='m-0 fw-bold mb-1 '  style={{fontSize:"11px", color:"#00008b"}}>{t("View")}</h6>
                             </button> </td>
                                
                             </a>
                    </>
                )
            }
        }
        return ( 
            <section>
                <SearchBar 
                    sectionName={name} 
                    onChange={this.setFilter} 
                    dropdownFilter={dropdownFilter} 
                    setDropdownFilter={this.setDropdownFilter}
                    // dropdownItems = {
                    //     cards[0].experience_value ? ["ALL","CONFIRMED","FULFILLED","REFUNDED","CANCELED","PLACED"] :
                    //     ["ALL","CONFIRMED","FULFILLED","REFUNDED","CANCELED"]
                    // }
                    dropdownItems  = {
                       (this.props?.type === 'merchandise' || this.props?.type ===  "physicalGiftcards")? 
                        ["ALL","CONFIRMED","FULFILLED","REFUNDED","CANCELED","SHIPPED"]
                     :    
                        ["ALL","CONFIRMED","FULFILLED","REFUNDED","CANCELED"]
                    }
                    
                   
                />
                <div className="d-flex flex-wrap gap-2 py-4">
                    {this.searchFilter(cards,this.props.type)?.map(card=>(
                        <>
                            <div key={card.id} className="border flex-grow-0" style={{width:"calc(50% - 0.3rem)"}}>
                        <div className=" bg-color-gray d-flex align-items-center justify-content-center">
                            <img 
                                className="d-block img-fluid p-3 w-100"
                                style={{}}
                                src={card.images?.small_rectangle || card.image?.small_rectangle || card.card_images?.small_rectangle } alt="" />
                        </div>
                        <div className="p-3 overflow-hidden">
                            <div className="product_title m-0 pb-1 fw-bold fs-5 overflow-hidden" style={{maxHeight:'6rem'}}>{this.props.lang !== 'ar'? card.product_name || card.brand_name : card.product_name_arabic || card.brand_name_arabic}</div>
                            <div className="text-muted fw-bold">{t("by")} {this.props.lang !== 'ar'? card.brand_name : card.brand_name_arabic}</div>
                           {
                               this.props.type === "receivedgifts" ?
                               <div className="bg-light-gray rounded-0 fw-bold text-muted text-capitalize my-2 fs-6 d-inline-block px-2 py-2">
                               {t("RECEIVED")}
                           </div>
                               :
                               <div className="bg-light-gray rounded-0 fw-bold text-muted text-capitalize my-2 fs-6 d-inline-block px-2 py-2">
                               {t(card.order_status || card.status)}
                           </div>
                           }
                            
                            <div className="py-2 fs-7">                                     {
                       this.props.type === "receivedgifts" ?
                                       
                                         <span className='my-3'><span>{t("Expiry Date")}: </span><span className="text-dark"><b>{card?.expiration_date ||card.effective_to_date}</b></span></span>
                                       
                                         :
                                         <>
                                          <div className='my-3'><span>{t("Date")}: </span><span className="text-dark"><b>{new Date(card.date).toDateString() || new Date(card.expiration_date).toDateString() }</b></span></div>
                                          </>

                                    } 
                        {  this.props.type !== "receivedgifts" && 
                      <>
                         <span>{t("Order ID")}: </span><span className="color-skyblue fw-bold">{card.orderid}</span>
                         {
                            this.props.type !== "pointsExchange" &&
                         <div><span>{t("Product Value")}: </span><span className="color-skyblue fw-bold">{card.order_total}{" "}{card.unit_name || card.currency_name}</span></div>
                         }
                         </>                          
                       }
                                    {
                                        this.props.type === 'shop' && card.order_status ==="SHIPPED" && <div><span>{`${t("Tracking ID")}:`} </span><span className="text-dark">{card.shipment_tracking_id}</span></div>
                                    }
                                    {
                           ( card?.isgift || card.is_gift) && (card.order_status=== 'FULFILLED' || card.order_status === 'CONFIRMED')  ?
                           <>
                           {card.recipient_email && <div><span>{`${t("Recipient Email")}:`} </span><span className="color-skyblue text-break">{card.recipient_email}</span></div>}
                                       {card.recipient_phone && <div><span>{`${t("Recipient Mobile")}:`} </span><span className="color-skyblue">{card.recipient_phone}</span></div>}
                                        <div className='justify-content-start d-flex'>
                                        <button className="bg-light-gray border-0 px-3 py-2 mt-2  fw-bold text-muted text-capitalize"  style={{color:"#00008b"}}>
                                         {t("Gifted")}
                                        </button>
                                         </div>
                                         </>
                                         :
                                          false
                                    }
                                    {
                                        this.props.type === 'experience' && card.status ==="CONFIRMED" && <div>
                                            
                                            {
                                                card.slot_date && 
                                                <div>
                                                    <span>{`${t("Slot Date")}:`} </span><span className="text-dark">{card.slot_date}</span>
                                                </div>
                                            }

                                            {
                                                card.slot_time && 
                                                <div>
                                                    <span>{`${t("Slot Time")}:`} </span><span className="text-dark">{card.slot_time}</span>
                                                </div>
                                            }

                                            {
                                                card.experience_code_id && 
                                                <div>
                                                    <span>{`${t("Experience Code")}:`} </span><span className="text-dark">{card.experience_code_id}</span>
                                                </div>
                                            }
                                            
                                            {
                                                card.confirmation_message && 
                                                <div>
                                                    <span>{`${t("Confirmation Message")}:`} </span><span className="text-dark">{card.confirmation_message}</span>
                                                </div>
                                            }
                                            </div>
                                    }

                                </div>
                                
                                {
                                   card.order_status ==="FULFILLED" || card.order_status ==="SHIPPED" || card.status === "CONFIRMED" ? 
                                   linkCards(this.props.type,card)             : false
                               }
                                 {/* {   this.props.type === "receivedgifts" ? 
                                    linkCards(name,card)
                                 :
                                false
                            } */}
                            </div>
                        
                    </div>
                        </>
                    ))}
                </div>
            </section>
            
         );
    }
}
 
export default withTranslation()(DisplayCards);


