import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

class ProductTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
          }
        setTimeout(() =>
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            }), 800);
    }
    render() { 
        let lang = this.props.i18n.language
        const linkCards = (type,row) =>{ 
            if(type === 'GiftCards'){
                
                return (

                    <>

                        {
                             row?.giftcard_tokens?.map((token,index)=>{
                            return(
                           <>
                            {
                                row.isgift === false ?
                                <Link style={{textDecoration:"none"}}
                                to={{
                                   pathname:`/giftcard/${token}/details?lang=${lang}`,
                                   state: {...row }
                               }}
                                target="_blank"
                                rel="noreferrer"
                                >
                                      <td className="px-1 py-4 align-bottom  "><button className='bg-light-gray border-0 px-3  fw-bold view-button' ><p className='m-0'  style={{fontSize:"15px"}}>0{index+1}</p>
                                        <h6 className='m-0 fw-bold primary-color'  style={{fontSize:"11px"}}>{t("View")}</h6>
                                </button> </td> 
                                </Link>
                                : false
                            }
                           </>
                            )
                              })
                        } 
                           {/* {
                               row?.isgift === false?
                               <Link
                               to={{
                                  pathname:`/giftcard/${row.giftcard_token}/details?lang=${lang}`,
                                  state: {...row }
                              }}
                            
                              ><td className="px-1 py-4 align-bottom  "><button className='bg-light-gray border-0 px-3  fw-bold view-button' >
                              <p className='m-0'  style={{fontSize:"15px"}}>0{index+1}</p>
                          <h6 className='m-0 fw-bold primary-color'  style={{fontSize:"12px"}}>{t("View")}</h6>
                  </button> </td></Link>
                              :
                              false
                           } */}

                    </>                 
                )
            }
            else if(type === 'Offers'){

                if(row.vouchers === null){
                    return 
                }

                else
                return ( 
                         <>
                        {
                        row.is_gift=== false &&     row.vouchers.map((element,index)=>{
                            return(
                             <a style={{textDecoration:"none"}}
                             href= {element.offer_url}
                             target="_blank"
                             rel="noreferrer"
                             >     
                                     <td className="px-1 py-4 align-bottom  "><button className='bg-light-gray border-0 px-3  fw-bold view-button' ><p className='m-0'  style={{fontSize:"15px"}}>0{index+1}</p>
                                     <h6 className='m-0 fw-bold primary-color'  style={{fontSize:"11px"}}>{t("View")}</h6>
                             </button> </td>
                                
                             </a>
                            )
                              })
                        }     
                         </>
            //             // <>
            //             //   {
            //             //      row.vouchers.map((element)=>{
            //             //     return(
            //             //      <a style={{textDecoration:"none"}}
            //             //      href= {element.offer_url}
            //             //      target="_blank"
            //             //      rel="noreferrer"
            //             //      >
            //             //         <td className="px-0 py-4 align-bottom"><u className="color-skyblue text-decoration-underline">{t("view details")}</u></td>
            //             //      </a>
            //             //     )
            //             //       })
            //             // }     
            //             // </>
 
            //          //   <Link
            //          //   to={{pathname:row.offer_url}}
            //          //   target="_blank" rel="noopener noreferrer"
            //          //   >
            //          //   <td className="px-0 py-4 align-bottom"><u className="color-skyblue text-decoration-underline">{t("view details")}</u></td>
            //          //   </Link>
                 
                )
            }
            else if(type === 'merchandise' || type === 'physicalGiftcards'){
                
                return(
                    <Link
                    to={{pathname:row.shipment_tracking_url}}
                    target="_blank" rel="noopener noreferrer"
                    >
                   <td className="px-1 py-4 align-bottom  "><button className='bg-light-gray border-0 px-3  fw-bold view-button' >
                                         <p className='m-0'  style={{fontSize:"15px"}}>01</p>
                                     <h6 className='m-0 fw-bold mb-1 primary-color'  style={{fontSize:"12px"}}>{t("View")}</h6>
                             </button> </td>
                    </Link>
                )
            }

            else if(type === 'Experience'){
                
                return (
                    row.experience_url && 
                    <Link
                    to={{pathname:row.experience_url}}
                    target="_blank" rel="noopener noreferrer"
                    >
                   <td className="px-1 py-4 align-bottom  "><button className='bg-light-gray border-0 px-3  fw-bold view-button' >
                   <p className='m-0'  style={{fontSize:"15px"}}>01</p>
                                     <h6 className='m-0 fw-bold mb-1 primary-color'  style={{fontSize:"12px"}}>{t("View")}</h6>
                             </button> </td>
                    </Link>
                )
            }

            else if(type === 'Software&Games'){
                return (
                    <Link style={{textDecoration:"none"}}
                    to={{
                        pathname:`/softwareGames/${row.id}/details?lang=${lang}`,
                       state: {data:row}
                   }}
                    target="_blank"
                    rel="noreferrer"
                    >
                          <td className="px-1 py-4 align-bottom  "><button className='bg-light-gray border-0 px-3  fw-bold view-button' ><p className='m-0'  style={{fontSize:"15px"}}>01</p>
                            <h6 className='m-0 fw-bold primary-color'  style={{fontSize:"11px"}}>{t("View")}</h6>
                    </button> </td> 
                    </Link>
                )
            }
        }
        const {t} = this.props
        return ( 
          <>
          {/* {
              this.state.tableLoading ?
              <section>
              <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4">
                  <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                  </div>
              </div>
          </section>
              : */}
              <div className="p-4 d-md-block d-none">
              <table className="table table-borderless">
                  <thead className="border-bottom">
                      <tr>
                          {this.props.headers.map(header=>(
                              <th 
                                  className="text-muted fw-bold px-0"
                                  scope="col"
                                  key={header}>
                                  {t(header)}
                              </th>
                          ))}
                      </tr>
                  </thead>
                  <tbody>
                      {this.props.rows?.map(row=>(
                          <tr className="border-bottom fw-bold text-black-50" key={row.id}>
                              <td className="px-0 py-4" width="200">
                                  <div className=" bg-color-gray d-flex align-items-center justify-content-center" style={{width:'9rem',height:'9rem'}}>
                                      <img 
                                          className="d-block img-fluid"
                                          style={{maxWidth:'9rem',maxHeight:'9rem'}}
                                      src={row.images?.small_rectangle || row.image?.small_rectangle  } alt="" />
                                  </div>
                                  
                              </td>
                              <td className="px-0 py-4 w-25">
                                  <h5 className="m-0 product_title">{lang=== 'en'? row.product_name || row.brand_name :row.product_name_arabic || row.brand_name_arabic}</h5>
                                  <div className="pt-1 pb-3"> {t("by")} { lang === 'en'? row.brand_name : row.brand_name_arabic }</div>
                                  <div><span>{`${t("Order ID")}:`} </span><span className="color-skyblue">{row.orderid}</span></div>
                                  <div><span>{`${t("Order Date")}:`} </span><span className="text-dark">{new Date(row.date).toDateString()}</span></div>
                                  {
                                    this.props.type !== "pointsExchange" &&
                                  <div><span>{t("Product Value")}: </span><span className="color-skyblue fw-bold">{row.order_total}{" "}{row.unit_name || row.currency_name}</span></div>
                                  }
                                  {
                                     (row.isgift || row.is_gift)  && (row.order_status=== 'FULFILLED' || row.order_status === 'CONFIRMED')?
                                     <>
                                       {row.recipient_email && <div><span>{`${t("Recipient Email")}:`} </span><span className="color-skyblue">{row.recipient_email}</span></div>}
                                       {row.recipient_phone && <div><span>{`${t("Recipient Mobile")}:`} </span><span className="color-skyblue">{row.recipient_phone}</span></div>}
                                        <button className="bg-light-gray border-0 px-3 mt-2  fw-bold text-muted text-capitalize"  style={{color:"#00008b"}}>
                                            {t("Gifted")}
                                        </button>
                                     </>
                                 : false

                                  }
                                  {
                                      (this.props.type === 'merchandise' || this.props.type === 'physicalGiftcards') && row.order_status ==="SHIPPED" && <div><span>{`${t("Shipment Tracking ID")}:`} </span><span className="text-dark">{row.shipment_tracking_id}</span></div>
                                  }
                                  {
                                      this.props.type === 'Experience' && row.status ==="CONFIRMED" && <div>
                                          
                                          {
                                              row.slot_date && 
                                              <div>
                                                  <span>{`${t("Slot Date")}:`} </span><span className="text-dark">{row.slot_date}</span>
                                              </div>
                                          }

                                          {
                                              row.slot_time && 
                                              <div>
                                                  <span>{`${t("Slot Time")}:`} </span><span className="text-dark">{row.slot_time}</span>
                                              </div>
                                          }

                                          {
                                              row.experience_code_id && 
                                              <div>
                                                  <span>{`${t("Experience Code")}:`} </span><span className="text-dark">{row.experience_code_id}</span>
                                              </div>
                                          }
                                          
                                          {
                                              row.confirmation_message && 
                                              <div>
                                                  <span>{`${t("Confirmation Message")}:`} </span><span className="text-dark">{row.confirmation_message}</span>
                                              </div>
                                          }
                                          </div>
                                  }

                              </td>
                              <td className="pl-3 py-4">
                                  <button className="bg-light-gray border-0 px-3 py-2 fw-bold text-muted text-capitalize">
                                      {t(row.order_status || row.status)}
                                  </button>
                              </td>
                              {/* <td className="px-0 py-4" width="200"></td> */}
                              <td className="px-0 py-4 text-center" width="200"></td>
                              {
                                 row.order_status ==="FULFILLED" || row.order_status ==="SHIPPED" || row.status === 'CONFIRMED' ? 
                                linkCards(this.props.type,row)
                                : false
                             }
                          </tr>
                      ))}
                      
                  </tbody>
              </table>
          </div>
          {/* } */}
          </>
            );
            
            
    }
}
 
export default withTranslation()(ProductTable);