import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import facbookSvg from '../../assests/img/Footer/facebook.svg'
import twitterSvg from '../../assests/img/Footer/twitter.svg'
import instagramSvg from '../../assests/img/Footer/instagram.svg'
import globeSvg from '../../assests/img/social-media/globe.svg'
import offersImg from '../../assests/img/main-navigation-header/icon-nav-offers.png'
import giftcardImg from '../../assests/img/main-navigation-header/icon-nav-gift-cards.png'
import { withTranslation } from 'react-i18next';
import eMagazinesIcon from '../../assests/img/main-navigation-header/icon-nav-e-magazines.png'
// import hassadPartnersIcon from '../../assests/img/main-navigation-header/icon-nav-hassad-partners.png'
import mobileTopupIcon from '../../assests/img/main-navigation-header/icon-nav-mobile-topup.png'
import shopsIcon from '../../assests/img/main-navigation-header/icon-nav-shop.png'
import travelIcon from '../../assests/img/main-navigation-header/icon-nav-travel.png'
// import hassadIcon from '../../assests/img/header/hassad-logo.png'
import pointExchangeIcon from '../../assests/img/main-navigation-header/icon-more-points-exchange.png'
import experiencesIcon from '../../assests/img/main-navigation-header/icon-more-experiences.png'
import iconHome from '../../assests/img/Footer/home-grey.svg'
import iconHomeSelected from '../../assests/img/Footer/home.svg'
import iconCategorie from '../../assests/img/Footer/mall-hall-grey.svg'
import iconCategaroieSelected from '../../assests/img/Footer/mall-hall.svg'
import iconWallet from '../../assests/img/Footer/wallet-grey.svg'
import iconWalletSelected from '../../assests/img/Footer/wallet.svg'
import iconMore from '../../assests/img/Footer/more-grey.svg'
import iconMoreSelected from '../../assests/img/Footer/more.svg'
// import phoneIcon from '../../assests/img/Footer/whatsapp.svg'
import softwareIcon from '../../assests/img/header/icon-more-softwares.png'
// import GamesIcon from '../../assests/img/header/icon-more-games.png'
import whatsappKSA from '../../assests/img/social-media/icon-whatsapp-ksa.svg'
import whatsappUAE from '../../assests/img/social-media/icon-whatsapp-uae.svg'


// import iconMoreSelected from '../../assests/img/Footer/icon-more.png'
import  backButton from  '../../assests/img/arrow-back.svg'
import { connect } from 'react-redux';
import { tradeMarkHandler } from 'utils/Theme/theme';



class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            showNav:false,
            isSelected:"one",
            forgetPass:false
         }

    }
    
    toggleNav=(val,selectedValue)=>{
        this.setState({showNav:val,isSelected:selectedValue})
    }
    componentDidMount(){
        if(this.props.history?.location?.pathname === '/more'){
            this.setState({showNav:true,isSelected:'four'})
        }
        if(this.props.location.pathname.includes("forgetPassword")){
            this.setState({forgetPass:true})
        }
    }
    socialMediaLinks = [
        
        // {id:'phone',url:'http://wa.me/966590197340',imgSrc:whatsappKSA},
        // {id:'phone1',url:'http://wa.me/971527682403',imgSrc:whatsappUAE},
        // {id:'globe',url:"https://www.flexxpay.com",imgSrc:globeSvg},
        {id:'instagram',url:"https://www.instagram.com/capitalhealthae/",imgSrc:instagramSvg},
        {id:'twitter',url:"https://twitter.com/CapitalHealthAE",imgSrc:twitterSvg},
        {id:'facebook',url:"https://www.facebook.com/CapitalHealthAE/",imgSrc:facbookSvg},
    ]
    othersLinks =[
        // {id:'backToApp',url:'closehassadmall.rmob',arabic_url:'closehassadmall.rmob',name:'Back to Flexx Pay Mobile app'},
        // {id:'contact',url:'https://www.capitalhealth.ae/contact-us/',arabic_url:"https://www.capitalhealth.ae/contact-us/",name:"Contact Us"},
        // {id:'faq',url:'https://www.capitalhealth.ae/faqs/',
        // arabic_url:'https://www.capitalhealth.ae/faqs/',name:"FAQ's"},
        // {id:'policy',url:'/privacy-policy',arabic_url:'/privacy-policy',name:'Privacy Policy'},
        // {id:'terms',url:'/terms-and-conditions',arabic_url:'/terms-and-conditions',name:'Terms & Conditions'},
        // {id:'redeemPoints',url:'https://www.riyadbank.com/en/personal-banking/hassad-rewards-program/hassad-points-redemption',arabic_url:'https://www.riyadbank.com/ar/personal-banking/hassad-rewards-program/hassad-points-redemption',name:'How To Redeem My Points?'},
    ]
    navLinks=[
        // {name:'Travels',id:'travels',link:'',imgSrc:travelIcon},
        // {name:'Hassad Partners',id:'hassadPartners',link:'/hassadpartners',imgSrc:hassadPartnersIcon},
        {name:'DIGITAL GIFT CARDS',id:'giftCard',link:'/giftcards',key:'Gift Cards',imgSrc:giftcardImg},
        {name:'PHYSICAL GIFT CARDS',id:'giftCard',link:'/physicalgiftcards',key:'Physical Giftcards',imgSrc:giftcardImg},
        {name:'Offers',id:'offers',key:'RewardsBy Offers',link:'/offers',imgSrc:offersImg},
        {name:'E-Magazines',id:'eMagazines',key:'E-Magazines',link:'/e-magazines',imgSrc:eMagazinesIcon},
        {name:'Merchandise',id:'HassadGifts',key:'RewardsBy Gifts',link:'/gifts',imgSrc:shopsIcon},
        {name:'Points Exchange',id:'pointsExchange',key:'Points Exchange',link:'/points-exchange',imgSrc:pointExchangeIcon},
        {name:'Experiences',id:'experiences',key:'Experiences',link:'/experiences',imgSrc:experiencesIcon},
        {name:'Mobile Topup',id:'mobileTopup',key:'Mobile TopUp',link:'/mobileTopUp',imgSrc:mobileTopupIcon},
        // {name:'Games',id:'HassadGifts',link:'/games',key:'Games',imgSrc:GamesIcon},
        {name:'Softwares & Games',id:'softwares&games',key:'Software&Games',link:'/softwares&games',imgSrc:softwareIcon},
        {name:'TRAVEL SHOP',id:'TRAVELSHOP',key:"Travel shop",link:'/flights',imgSrc:travelIcon},
    ]

    
    getFullYear=()=>{
        const year=new Date().getFullYear();
        return year;
    }

    render() { 
        const {t,modulesData} = this.props
        const desktopFooter =
         <div className="footer px-5 py-4 w-100 d-none d-md-block">    
                {/* <ul className=" list-inline text-center pt-2">
                    {this.socialMediaLinks.map(link =>(
                            <li className="px-2" key={link.id}>
                                <a href={link.url} target="_blank" rel="noreferrer" >
                                    <img 
                                        className=' img-fluid'
                                        style={{height:'2.0rem'}}
                                        src={link.imgSrc} alt={link.id}/>
                                </a>
                            </li>
                    ))}
                    </ul> */}
                    {/* <div className='pt-5 pb-3 d-flex justify-content-around'>
                        {this.othersLinks.map(link=>(
                        
                            link.id !== 'backToApp' && link.id === "hassedPartners"? 
                            <Link 
                                key={link.id}
                                to={{pathname:link.url}}
                                className="text-decoration-none px-2 text-light ">
                                {`${t(link.name)}`}
                            </Link> :
                            <a key={link.id} target={(link.name === "Terms & Conditions" || link.name === "Privacy Policy")?"":"_blank"} rel="noreferrer"
                            href={`${this.props.lang === 'en' ? link.url : link.arabic_url}`}
                            className="text-decoration-none px-2 text-light ">
                                {`${t(link.name)}`}
                            </a>
                        ))}
                    </div> */}
                    {/* <hr/> */}
                    <div className='w-100 text-center text-white'>
                    {`${t(`© ${this.getFullYear()} ${tradeMarkHandler()}`)}`} {`${t(`All Rights Reserved.`)}`}
                    </div>
            </div>

    const mobileNav=(
    
      this.state.showNav &&
        <div className="d-md-none d-flex flex-column position-fixed bg-light-gray w-100 " style={{zIndex:'1500',top:'0',minHeight:'calc(100vh - 7rem)',position:'relative'}}>
        <div className="bg-color-gray overflow-auto d-flex flex-column" style={{zIndex:'1500',maxHeight:'calc(100vh - 7rem)',position:'absolute',top:'0',bottom:'0',minWidth:'100vw'}}>
            <div className=" pb-2 bg-light d-flex justify-content-start px-4" style={{paddingTop:"2rem"}}>
                <button className="btn" onClick={()=>{this.toggleNav(false)}}
                >
                     <div className=" fs-4 cursor-pointer color-skyblue " onClick={()=>{this.props.history.goBack()}} style={{transform:'rotate(180deg)'}}>
                        {/* <section style={{transform: `${this.props.lang==='ar'?"rotate(180deg)":""}`}}>➜</section> */}
                        <section  style={{transform: `${this.props.lang!=='ar'?"rotate(180deg)":""}`}}          
                        >
                            <section style={{marginTop: `${this.props.lang==='ar'?"8px":""}`}}>
                            <img src={backButton} alt="" />

                            </section>
                        </section>
                        </div>
                </button>
            </div>
            <div className="mb-2 pt-2">
                 {this.navLinks.map(link=>(
                    modulesData[link.key] && <Link 
                    key={link.id}
                    to={{pathname:link.link}}
                    onClick={()=>{this.toggleNav(false)}}
                    className="text-decoration-none px-4 fw-bold border bg-light d-block text-capitalize d-flex justify-content-between align-items-center">
                    <div>
                        <img  className = "px-1" src={link.imgSrc} alt=""/>
                        {`${t(link.name)}`}
                    </div>
                    <span className="text-muted pb-2" style={{fontSize:'1.8rem',opacity:'0.4'}}>&#x203A;</span>
                </Link>
                ))}
            </div>
           
            <div className="">
                {this.othersLinks.map(link=>(
                    link.id === "hassedPartners" || link.id === 'backToApp' ? 
                     <Link 
                     key={link.id}
                     to={{pathname:link.url}}
                     onClick={()=>{this.toggleNav(false)}}
                     className="text-decoration-none px-4 fw-bold border bg-light d-block text-capitalize d-flex justify-content-between align-items-center">
                     <div>
                     {`${t(link.name)}`}
                     </div>
                     <span className="text-muted pb-2" style={{fontSize:'1.8rem',opacity:'0.4'}}>&#x203A;</span>
                 </Link> : <a 
                     key={link.id}
                    rel="noreferrer"
                            href={`${this.props.lang !== 'ar' ? link.url : link.arabic_url}`}
                            onClick={()=>{this.toggleNav(true)}}
                     className="text-decoration-none px-4 fw-bold border bg-light d-block text-capitalize d-flex justify-content-between align-items-center">
                     <div>
                     {`${t(link.name)}`}
                     </div>
                     <span className="text-muted pb-2" style={{fontSize:'1.8rem',opacity:'0.4'}}>&#x203A;</span>
                 </a>
                ))}
            </div>
            <footer className="text-center header_footer py-4">
                {/* <div className="d-flex justify-content-center text-center p-4">
                    {this.socialMediaLinks.map(link =>(
                            <div className="px-2" key={link.id}>
                                <a href={link.url} target="_blank" rel="noreferrer" >
                                    <img 
                                        className='img-fluid'
                                        style={{height:'2.0rem'}}
                                        src={link.imgSrc} alt=""/>      
                                </a>
                            </div>
                    ))}
                </div> */}
                <div className=" text-white">
                {`${t(`© ${this.getFullYear()} ${tradeMarkHandler()}`)}`} {`${t(`All Rights Reserved.`)}`}
                
                </div>
            </footer>
        </div>
    </div>
    )

    const mobileFooter = <>
    <div className="d-md-none d-block position-fixed bg-white w-100" style={{bottom:'0',zIndex:'1000'}}>
        <div className="d-flex w-100 text-center z-index-top" style={{height:"5rem"}}>
            <Link to={{pathname:"/"}} className="w-25 "
            onClick={()=>{this.toggleNav(false,'one')}}>
            { this.state.isSelected === "one"
          ? <img className="mx-auto d-block pt-1 " alt="" width="30" src={iconHomeSelected} />
          :<img className="mx-auto d-block pt-1 " alt="" width="30" src={iconHome} />
                // <img className="mx-auto d-block pt-1 " alt="" width="30" src={hassadIcon}/>
            }
                <small className="text-dark fw-bold">{t("Home")}</small>
            </Link>
            <Link to={{pathname:'/categories'}}className="w-25"
             onClick={()=>{this.toggleNav(false,'two')}}>
                   { this.state.isSelected ==="two"
          ? <img className="mx-auto d-block pt-1 " alt="" width="30" src={iconCategaroieSelected} />
          :<img className="mx-auto d-block pt-1 " alt="" width="30" src={iconCategorie} />
                // <img className="mx-auto d-block pt-1 " alt="" width="30" src={hassadIcon}/>
            }
                <small className="text-dark fw-bold">{t("Categories")}</small>
            </Link>
            <Link to={{pathname:'/mywallet/'}} className="w-25"
             onClick={()=>{this.toggleNav(false,'three')}}>
                  { this.state.isSelected ==="three"
          ? <img className="mx-auto d-block pt-1 " alt="" width="30" src={iconWalletSelected} />
          :<img className="mx-auto d-block pt-1 " alt="" width="30" src={iconWallet} />
                // <img className="mx-auto d-block pt-1 " alt="" width="30" src={hassadIcon}/>
            }
                {/* <img className="mx-auto d-block pt-1 filter-green" alt="" width="30" src={offersImg}/> */}
                <small className="text-dark fw-bold">{t("My Wallet")}</small>
            </Link>
            <Link to='/more'  className="w-25" onClick={()=>{this.toggleNav(true,'four')}}>
            
                { this.state.isSelected ==="four" 
          ?
              <img className="mx-auto d-block pt-1  " alt="more" width="30" src={iconMoreSelected} />
               /* <svg height='30' width='25'>
          <line x1='0' y1='7' x2='25' y2='7' style={{stroke:'#000',strokeWidth:'1.5'}}/>
          <line x1='0' y1='15' x2='25' y2='15' style={{stroke:'#000',strokeWidth:'1.5'}}/>
          <line x1='0' y1='23' x2='25' y2='23' style={{stroke:'#000',strokeWidth:'1.5'}}/>
      </svg> */
        //   <img className="mx-auto d-block pt-1 " alt="" width="30" src={iconWalletSelected} />
          :
          <img className="mx-auto d-block pt-1 " alt="more" width="30" src={iconMore} />
                // <img className="mx-auto d-block pt-1 " alt="" width="30" src={hassadIcon}/>
            }
                   
                <small className="text-dark fw-bold">{t("More")}</small>
            </Link>
        </div>
        
    </div>
    </>
        return ( 
            <>
                {desktopFooter}
                {
                    !this.state.forgetPass && <>
                    
                    {mobileNav}
                    {mobileFooter}
                    </>
                }
               
            </>
         );
    }
}

const mapStateToProps = state => {
    return {
       modulesData:state.categories.moduleInfo
    }
}
export default connect(mapStateToProps)((withTranslation()(Footer)));
 
