import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { fetchPhoneFormat } from 'Redux/CountryCode/CountryCodeActions';
import MerchandiseInputField from './MerchandiseInputField'
// import OrderSummaryCard from './OrderSummaryCard';
import ShippingSummaryCard from './ShippingSummaryCard';
import parsePhoneNumber from 'libphonenumber-js'
import phoneNumberCode from "../../utils/countrycodes/phoneNoCode.json"

class ShippingAddressPage extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            phoneCode:"",
            countryList:[],
            formFeilds:{
                firstName:{id:"firstName",title:"First Name",type:"text",value:"",required:true,tabIndex:"1",readOnly:false,ariaLabel:"enter your first name",errorMessage:""},
                lastName:{id:"lastName",title:"Last Name",type:"text",value:"",required:true,tabIndex:"2",readOnly:false,ariaLabel:"enter your last name",errorMessage:""},
                companyName:{id:"companyName",title:"Company Name (Optional)",type:"text",value:"",required:false,tabIndex:"3",readOnly:false,ariaLabel:"enter your company name",errorMessage:""},
                email:{id:"email",title:"Email",type:"email",value:"",required:true,tabIndex:"5",readOnly:false,ariaLabel:"enter your email id",errorMessage:""},
                country:{id:"country",title:"Country",type:"dropdown",value:this.props.selectedCountry,required:true,tabIndex:"3",readOnly:false,ariaLabel:"select your country",errorMessage:"",options:[this.props.selectedCountry]},
                phNumber:{id:"phNumber",title:"Mobile Number",type:"tel",value:"",required:true,tabIndex:"4",readOnly:false,ariaLabel:"enter your mobile number",errorMessage:""},
                address:{id:"address",title:"Address",type:"textarea",value:"",required:true,tabIndex:"6",readOnly:false,ariaLabel:"enter the delivery address",errorMessage:""},
                city:{id:"city",title:"Town/City",type:"text",value:"",required:true,tabIndex:"7",readOnly:false,ariaLabel:"enter your city/town name",errorMessage:""},
                state:{id:"state",title:"State/Province",type:"text",value:"",required:true,tabIndex:"8",readOnly:false,ariaLabel:"enter your state name",errorMessage:""},
                zip:{id:"zip",title:"Zip",type:"number",value:"",required:true,tabIndex:"9",readOnly:false,ariaLabel:"enter your zip code",errorMessage:""},    
            },
            GiftCardformFeilds:{
                firstName:{id:"firstName",title:"First Name",type:"text",value:"",required:true,tabIndex:"1",readOnly:false,ariaLabel:"enter your first name",errorMessage:""},
                lastName:{id:"lastName",title:"Last Name",type:"text",value:"",required:true,tabIndex:"2",readOnly:false,ariaLabel:"enter your last name",errorMessage:""},
                email:{id:"email",title:"Email",type:"email",value:"",required:true,tabIndex:"3",readOnly:false,ariaLabel:"enter your email id",errorMessage:""},
                country:{id:"country",title:"Country",type:"dropdown",value:this.props.selectedCountry,required:true,tabIndex:"4",readOnly:false,ariaLabel:"select your country",errorMessage:"",options:this.props.countries},
                phNumber:{id:"phNumber",title:"Mobile Number",type:"tel",value:"",required:true,tabIndex:"5",readOnly:false,ariaLabel:"enter your mobile number",errorMessage:""},
                address:{id:"address",title:"Address",type:"textarea",value:"",required:true,tabIndex:"6",readOnly:false,ariaLabel:"enter the delivery address",errorMessage:""},
                city:{id:"city",title:"Town/City",type:"text",value:"",required:true,tabIndex:"7",readOnly:false,ariaLabel:"enter your city/town name",errorMessage:""},
                state:{id:"state",title:"State/Province",type:"text",value:"",required:true,tabIndex:"8",readOnly:false,ariaLabel:"enter your state name",errorMessage:""},
                zip:{id:"zip",title:"Zip",type:"number",value:"",required:true,tabIndex:"9",readOnly:false,ariaLabel:"enter your zip code",errorMessage:""},    
            }
         }
    }
    componentDidMount() {
        // window.scrollTo(0, 0)
        // const url = "https://run.mocky.io/v3/078957a9-ae15-430a-895b-ad70693baba8"
        // fetch(url, {
        //     method: 'GET',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json',
        //     }
        // }).then((response) => {
        //     response.json().then((resp) => {
        //         const countryList = resp.data.giftcard_units.map(d=>(d.country_name)) 
        //         this.setState(old=>(
        //                             { 
        //                                 countryList,
        //                                 formFeilds:{...old.formFeilds,country:{...old.formFeilds.country,options:[...countryList]}}
        //                             }))})})
        const countryload = {
            "countries": {
                "country_name": this.props.selectedCountry
            }
        }

        this.props.fetchPhoneFormat(countryload,this.setPhoneCode)

    }

    setPhoneCode = (val) =>{
        this.setState({phoneCode:val})
    }
    
    setInputValue=(val,feild)=>{
        if(feild === "phNumber" && val?.startsWith(0)){
            val = val?.slice(1)
        }
        let updatedFormFeilds = this.props.physicalCard ? {...this.state.GiftCardformFeilds} : {...this.state.formFeilds}
        updatedFormFeilds[feild].value = val
        updatedFormFeilds[feild].errorMessage = ""
        this.setState({
            formFeilds:updatedFormFeilds
        })
    }

    validateForm=()=>{
     const {t}= this.props
    const phoneNoCode = phoneNumberCode?.filter(val => val?.dial_code === this.state.phoneCode)
    let valid = true;
    let updatedFormFeilds = this.props.physicalCard ? {...this.state.GiftCardformFeilds} : {...this.state.formFeilds}
        Object.values(this.state.formFeilds).forEach(feild=>{
            if(feild.value==="" && feild.id!=='companyName'){
                updatedFormFeilds[feild.id].errorMessage=`${t(feild.title)}${t(" is Missing")}`
                valid=false
            }
        });
        var re = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
        if (!re.test(updatedFormFeilds['email'].value)){
            updatedFormFeilds['email'].errorMessage = "Invalid Email"
            valid=false
        }else if(updatedFormFeilds['phNumber'].value !=="" && parsePhoneNumber(`${updatedFormFeilds['phNumber'].value}`, phoneNoCode?.[0]?.code)?.isValid() === false){
            updatedFormFeilds['phNumber'].errorMessage = "Please enter valid number"
            valid = false
        }
        this.setState({
            formFeilds:updatedFormFeilds
        })
        return valid
    }
    // submitForm=()=>{
        
    //     if(this.validateForm()){
    //         this.props.nextStage(1)
    //     }
    // }
    
    render() { 
        const {t} = this.props
        return ( 
            <main>
                <header className="border px-md-5 px-2 py-2 border-bottom text-muted d-flex bg-light w-100">
                    <div className="d-flex align-items-center flex-shrink-1" >
                        <span className="btn bg-color-skyblue rounded-0 mx-2 p-md-3 p-2" ></span>
                        <div className="m-0 d-inline px-md-1 px-0 pb-1 fs-md-6 fs-7 fw-bold">{t("Shipping Address")}</div>
                    </div>
                    <hr className="hr-line flex-grow-1 mx-1"/>
                    <div className="d-flex align-items-center flex-shrink-1 " >
                        <span className="btn border-color-skyblue rounded-0 mx-2  p-md-2 p-1"></span>
                        <div className="m-0 d-inline px-md-1 px-0 pb-1 fs-md-6 fs-7 fw-bold">{t("Payment")}</div>
                    </div>
                    <hr className="hr-line flex-grow-1 mx-1"/>
                    <div className="d-flex align-items-center flex-shrink-1 " >
                        <span className="btn border-color-skyblue rounded-0 mx-2  p-md-2 p-1"></span>
                        <div className="m-0 d-inline px-md-1 px-0 pb-1 fs-md-6 fs-7 fw-bold">{t("Order Placed")}</div>
                    </div>
                </header>
                <div className="d-flex p-md-5 p-3 flex-md-row flex-column">
                    <div className="flex-grow-1 text-black-50 p-0 mb-4">
                        <div className="py-md-0 py-2 m-0 mt-md-0 mt-5 fw-bold fs-md-2 fs-4 product_title">
                            {t("Delivery Details")}:
                            <p style={{fontSize:'1.2rem'}} className='text-black-50'>{this.props.physicalCard ?  t("Physical Giftcard will be delivered to the address below") : t("Product will be delivered to the address below")}</p>    
                        </div>
                            
                        <div className={`d-grid grid-cols-1 grid-cols-md-2 ${this.props.physicalCard && "mb-2"}`}>
                                {
                                    Object.values(this.props?.physicalCard ? this.state.GiftCardformFeilds :this.state.formFeilds).map(f=>(
                                        <MerchandiseInputField data={f} key={f.id} onChange={this.setInputValue} lang={this.props.lang} selectedCountry={this.props.selectedCountry} PhoneFormat={this.props.PhoneFormat} physicalCard={this.props.physicalCard} setPhoneCode={this.setPhoneCode} phoneCode={this.state.phoneCode}/>
                                    ))
                                }
                      </div>
                    </div>
                   
                    <ShippingSummaryCard cardPayment={this.props.cardPayment} lang={this.props.lang} validForm={this.validateForm}  formFeilds={this.state.formFeilds}  setShippingPage={this.props} order={this.props.order} physicalCard={this.props.physicalCard} giftcardPoints={this.props.giftcardPoints} giftcardCurrency={this.props.giftcardCurrency} phoneCode={this.state.phoneCode}/>

                </div>
        </main>
         );
    }
}

const mapStateToProps = state => {
    return {
        selectedCountry:state?.countries?.selectedCountry?.countryName,
        PhoneFormat:state.PhoneFormat.PhoneFormat,
        countries:state.countryCode.countryCode,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchPhoneFormat : (payload,setPhoneCode) => dispatch(fetchPhoneFormat(payload,setPhoneCode)),
    }
}
 
export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(ShippingAddressPage));