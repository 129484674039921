export function DefalutCountry(url) {
    return url ===
      "https://enftest.develop-rewardsby-marketplace.meritincentives.com"
      ? 36
      : url ===
        "https://enftest.staging-rewardsby-marketplace.meritincentives.com"
      ? 36
      : url === "https://enfactum.marketplace.rewardsby.com"
      ? 36
      : url === "https://cib.marketplace.rewardsby.com"
      ? 4
      : false;
  }
  