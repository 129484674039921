import React, { Component } from 'react';
import ModalWrapper from './ModalWrapper';
import smilySvg from '../../../assests/img/smilyy.svg'
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { fetchPointBalance } from '../../../Redux/point/pointActions';

class SuccessfullMessageModal extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            show:true,
            type:'all'
         }
    }
    setModalView=()=>{
        this.setState({
            show:false
        })
    }
    componentDidMount(){
        if(localStorage.getItem('type')){
            this.setState({type:localStorage.getItem('type')})
        }
    }
    // Disabling Points Balance API
    componentDidUpdate(){
        if(this.props.show){
            this.props.fetchPointBalance()
        }
    }
    render() { 
        const {t}= this.props
        
        return ( 
            <ModalWrapper show={this.state.show && this.props.show}>
                <div 
                    className="p-4 bg-light mx-auto text-center py-md-5 py-4" 
                    style={{width:'35rem'}}>
                    <img 
                        className="p-md-0 p-3"
                        height="100"
                        src={smilySvg} alt="" /> 
                    <h3 className='text-muted m-0 py-md-3 py-0'>
                        {t("Payment Successful")}
                    </h3> 
                    <div className='text-muted m-0'>
                       {t("You can find the voucher in my wallet section soon after the order is fullfilled")}
                    </div>
                    <Link 
                        to={{pathname:`/mywallet/${this.state.type}`}}
                        className="btn primary-btn rounded-0 mx-2 mt-3 text-light border-color-skyblue"
                        onClick={()=>{this.setModalView()}}>
                        {t("My Wallet")}
                    </Link>
                    <button 
                        className="btn  btn-secondary mt-3 mx-2"
                        onClick={()=>{this.setModalView()}}>
                        <span className="fw-bold primary-color">{t("Close")}</span>
                    </button>
                </div>
            </ModalWrapper>
         );
    }
}
const mapDispatchToProps = dispatch => {
    return {    
        fetchPointBalance : () => dispatch(fetchPointBalance())
    }
}


export default connect(null,mapDispatchToProps)(withTranslation()(SuccessfullMessageModal));