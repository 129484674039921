import React, { Component } from 'react'

export default class PrivacyPolicy extends Component {
    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }
    render() {
        const arabicPrivacyPolicy = (
            <div className="container-fluid p-3">
                <div className="text-black-50 fw-bold mt-3 px-3 ">
                    <b className='color-skyblue' >سياسة الخصوصية</b>
                    <p >
                        أهلاً بكم في         سوق مركز العاصمة الصحي"(    مركز العاصمة للفحص الطبي"  "،نحن:  "،لنا"  "، الخاص بنا )"مسجلين
                        في السجل التجاري1192526"(              ، ونقوم- بتشغيل هذه المنصة لتزود مستخدميناالمستخدم"   "،المستخدمون"،
                        "أنت"  "،الخاص بك )"ب سوق الكترونية.
                    </p>
                    <p >
                        نحن نحترم خصوصيتك فيما يتعلق بأية معلومات قد نجمعها أثناء إدارة موقعنا[Insert]"(الموقع"  "،موقعنا )"أو
                        "             تطبيق هاتف محمول أو بالبرامج ذات صلةالتطبيق"
                    </p>
                    <p >
                        يرجى قراءة سياسة الخصوصية هذه سياسة الخصوصية .              )"بعناية- لفهم كيف نقوم باستخدام المعلومات الشخصية في
                        حال عدم موافقتك على سياسة الخصوصية هذه، يجب عليك عدم استخدام الموقع، أو المواقع، أو الخدمات ذات
                        .                    الصلة، أو أي موقع آخر نقوم بتشغيله مرتبط بسياسة الخصوصية هذه وبناءً على ذلك فمن خلال الدخول أو استخدام
                        .              هذا الموقع فإنك توافق على سياسة الخصوصية هذه
                    </p>
                    <p >
                        يمكن أي يتم تغيير سياسة الخصوصية هذه من حين لآخر، وسيتم نشر هذه التعديلات على الموقع وسنتخذ أيضاً أي
                        .                                  خطوات أخرى قد يتطلبها القانون- المعمول به، بما في ذلك إخطارك أو طلب موافقتك الصريحة لأي تغييرات جوهرية
                        .  "   "               تسري التغييرات على سياسة الخصوصية هذه من تاريخ آخر تحديث مذكور
                        وبغير الحالات التي نسعى فيها إلى الحصول على موافقتك الصريحة، فإن استمرارك باستخدام الموقع بعد إجرائنا
                        التغيرات يجب أن يعتبر قبولاًوموافقة على الالتزام بهذه التغييرات، وبناءً- على ذلك يرجى مراجعة سياسة الخصوصية
                        .                    بشكل دوري لتبقى على اطلاع بأي تغيير أو تحديث يطرأ عليه
                    </p>
                    <p>
                        أي وجميع المصطلحات المعرّفة في سياسة الخصوصية هذه هي ذاتها المشار إليها في الشروط والأحكام المتاحة- على
                        .موقعنا
                        في حال تعريف أي من المصلحات المستخدمة في سياسة الخصوصية هذه ضمن هذه السياسة، وكان قد تم تعريفه- أيضاً
                        .                  في الشروط والأحكام، سيكون لها التعريف الوارد في هذه السياسة
                        .                            تحكم سياسة الخصوصية هذه جمع البيانات، صيانتها، الإفصاح عنها، واستخدامها في الموقع والمحتوى المرتبط به
                        يوافق المستخدمون بموجب استخدامهم للموقع على جمع واستخدام معلوماتهم الشخصية وفقاً لسياسة الخصوصية
                        .هذه
                    </p>
                    <ol >
                        <li>
                            <b>جمع المعلومات</b>
                            <ul>

                                <li>
                                    <b>المعلومات التي تقوم بتزويدنا بها</b>
                                    <p>عندما تقوم باستخدام الموقع قد تقوم بتزويدنا- بمعلومات عنك بما في ذلك على سبيل المثال لا الحصر اسمك،
                                        معلومات الاتصال الخاصة بك، عنوانك، ومعلوماتك المالية لإجراء أو لتلقي دفعات مقابل الخدمات التي تم الحصول
                                        .    قد نقوم أيضاً بجمع معلومات متعلقة باستخدامك للموقع وتجميعها مع المعلومات التي تم عليها عبر الموقع
                                        .      جمعها حول مستخدمين آخرين</p>

                                </li>
                                <li>
                                    <b>المعلومات الواردة من قبل طرف ثالث</b>
                                    <p>قد يتم تزويدنا- بمعلومات عنك من قبل أطراف ثالثة في حال قمنا بدمج هذه المعلومات مع المعلومات التي قمنا
                                        بجمعها عنك من خلال موقعنا، فإننا سنقوم بمعاملة هذه المعلومات مجتمعةً على النحو المنصوص عليه في
                                        .    سياسة الخصوصية هذه</p>


                                </li>
                                <li>
                                    <b>المعلومات التي تم جمعها عنكتلقائياً-</b>
                                    <p>

                                        على غرار أي منصة الكترونية- أخرى، فإننا نتلقى بيانات تقنية على سبيل المثال لا الحصر الرقم التعريفي الفريد
                                        .                      لجهازك، بيانات- عن موقعك، سرعة الانترنت الخاص بك، وغيرها من المعلومات المماثلة نحن لا نقوم بتخزين
                                        البيانات التقنية هذه، بل إنها تُستخدم فقط لتحسين تجربتك أثناء تصفح الموقع ويتم حذفها بمجرد خروجك من
                                        .الموقع
                                        المعلومات التقنية التي تم جمعها على خوادمنا، يتم استخدامها فقط لتحليل كيفية استخدام الأشخاص لخدماتنا،
                                        /                          تحسين وظائف موقعنا، حفظ معلومات تسجيل الدخول الخاصة بك للجلسات القادمة، وتزويدك بمعلومات و أو
                                        :                   .      بيانات- أخر قد تهمك قد يتضمن جمع المعلومات التلقائي على سبيل المثال لا الحصر المعلومات المتعلقة
                                        بالمتصفح ونظام التشغيل الذي تقوم باستخدامه، الرابط أو الإعلان الذي أحالك إلى موقعنا، ومصطلحات البحث[ type Annotation]
                                        التي استخدمتها في محرك البحث الذي قادك إلى الموقع، الأقسام التي قمت بزيارتها داخل الموقع، الروابط- التي
                                        قمت بالنقر عليها، الصفحات والمحتوى الذي شاهدته، وأي معلومات أو إحصاءات- أخرى مشابهة حول تفاعلك
                                        (مثل أوقات استجابة المحتوى، أخطاء التحميل، مدة الزيارات- لصفحات محددة وغيرها من المعلومات التي تتم
                                        .              مشاركتها عادةً عندما تتصل المتصفحات مع مواقع الأخرى
                                        قد نقوم بدمج أي أو جميع البيانات التي تم جمعها خلال استخدامك لموقعنا مع أي معلومات أخرى تم الحصول
                                        .                      .  عليها عنك نحن نقوم بذلك من أجل تحسين عملية التسويق، التحليلات، ووظائف- الموقع
                                        “(                   أيضاً قد تتضمن البيانات التي تم جمعها عنوان بروتوكول الانترنتIP           )”أو معرفات الأجهزة الأخرى لأي جهاز
                                        .            )       (حاسوب، هاتف محمول، إلخ تم استخدمها للوصول إلى الخدمات والموق</p>

                                </li>

                            </ul>
                        </li>
                        <li>
                            <b>استخدام المعلومات</b>
                            <ul>
                                <li>
                                    في سياق استخدامك لموقعنا، قد نقوم بجمع بعض المعلومات الشخصية         عنك التي تحدد هويتك كفرد معين
                                    "(                          حسب الضرورة، ويمكن أن يتم استخدام هذه المعلومات للاتصال بك أو التعرّف عليكالمعلومات
                                    الشخصية         :              .)"ومثال عن المعلومات الشخصية التي يتم جمعها اسمك، عنوان بريدك الالكتروني، عنوان
                                    :                  .    التسليم، ورقم الهاتف نحن نقوم باستخدام المعلومات الشخصية والمعلومات الأخرى للأغراض التالية
                                    <ul>
                                        <li>
                                            توفير وتطوير الموقع، إتمام معاملاتك، معالجة استفساراتك، معالجة تسجيلك، التحقق من أن
                                            .                المعلومات التي تقدمها صالحة، بالإضافة لأغراض الامتثال والأعمال الداخلية
                                        </li>
                                        <li>
                                            لتواصل معك بشأن الاتصالات الإدارية و أو العروض الترويجية باسمنا أو بالنيابة- أن أطراف أخرى
                                            .          وغيرها من المعلومات التي قد تهمك
                                        </li>
                                        <li>
                                            تخصيص المحتوى عن طريق إظهار العروض مع الإشارة إلى الموقع
                                        </li>
                                        <li>
                                            إدارة وتطوير علاقتنا التجارية معك، أو مع الشركة أو أي كيان قانوني تمثله، إن أمكن

                                        </li>
                                        <li>
                                            تنفيذ القوانين المعمول بها والامتثال لها، وقد يتضمن ذلك إجراء التحقيق لحماية الملكية والحقوق
                                            المتعلقة بالمنصة، أو أي من مستخدميها، لأي نشاط قد نعتبره خطراُ، غير قانوني، احتيالي، أو غير
                                            أخلاقي
                                        </li>
                                        <li>
                                            الامتثال للالتزامات- القانونية، تحليل وتطوير أعمالنا، اتصالاتنا، الإجابة على استفساراتك حول
                                            .موقعنا
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    لأي غرض آخر يتم الإشارة إليه في سياسة الخصوصية هذه
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>الاحتفاظ بالبيانات</b>
                            <ul>
                                <li>
                                    في حال لم تقم بطلب حذف بيانات- و أو معلومات معينة، فإننا نحتفظ بالحق في الاحتفاظ بهذه البيانات
                                    /            .          طالما حسابك في الموقع لايزال نشط لن يتم تخزين هذه البيانات و أو المعلومات عند اختيارك- إنهاء حسابك
                                    "               .    أو إلغاء تفعيله والذي يمكن طلبه عبر الضغط على قسم اتصل بنا             ."في حال عدم حذف حسابك أو طلب
                                    /    حذف بيانات- و أو معلومات معينة، فقد يتم الاحتفاظ بمعلوماتك لأغراض النسخ الاحتياطي، التحليل
                                    .  واستمرارية- الأعمال إذا قمت بتزويدنا بمعلوماتك الشخصية أو أي بيانات أخرى، فإننا نشجعك على تحديث
                                    .                البيانات بانتظام- لضمان حصولنا على معلومات دقيقة ومحدثة عنك
                                </li>
                                <li>
                                    في حال حدوث تغيير في سيطرة الشركة ناتج ، على سبيل المثال عن بيعها أو اندماجها مع كيان آخر، أو
                                    في حالة بيع الأصول أو الإفلاس، فإننا نحتفظ بالحق في نقل معلوماتك الشخصية إلى الطرف الجديد المسيطر
                                    .        أو الطرف الذي يكتسب الأصول في حالة حدوث مثل هذا التغيير، سيستمر التعامل مع معلوماتك الشخصية
                                    .      وفق سياسة الخصوصية هذه
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>مشاركة البيانات والافصاح عنها</b>
                            <p>يجوز لنا مشاركة بياناتك لضرورة تقديم خدماتنا، و أو لأغراض قانونية- وتحقيقية، فيما يتعلق بالموقع أو العروض
                                .                  الترويجية أو إذا كنا جزءاً من عملية دمج أو استحواذ كما يجوز لنا مشاركة معلومات غير محددة للهوية مع أطراف
                                ثالثة</p>
                            <ul>
                                <li>يحق لنا في أي وقت مشاركة معلوماتك الشخصية و أو- بياناتك في الحالات التالية</li>
                                <ul>
                                    <li>
                                        عندما يطلب مننا ذلك امتثالاً للقوانين المعمول بها، الطلبات الحكومية، الإجراءات القضائية، أو
                                        .        الإجراءات القانونية كالاستجابة- لأمر محك
                                    </li>
                                    <li>
                                        ندما نعتقد أنه- من الضروري التحقيق في الانتهاكات المحتملة لشروطنا و أو- سياساتنا، أو منع هذه
                                        الانتهاكات أو اتخاذ إجراء بشأنها، أو الاحتيال المشتبه به، أو المواقف التي تنطوي على تهديدات
                                        محتملة لسلامة أي شخص أو مستخدم، أو أي أنشطة غير قانونية، أو كدليل في التقاضيوفق
                                        مقتضيات القانون.
                                    </li>
                                    <li>
                                        ع مزودي الخدمات والمقاولين- والوكلاء الذين يؤدون خدمات لنا أو نيابة- عنا ويحتاجون إلى الوصول
                                        .                    إلى هذه المعلومات للقيام بهذا العمل أو الموظفين أو الشركات التابعة ومن الأمثلة على ذلك
                                        معالجة الدفع وتحليل البيانات- وتسليم البريد الإلكتروني وخدمات الاستضافة وخدمة العملاء وأعمال
                                        .التسويق قد نسمح لأطراف خارجية مختارة باستخدام تقنية التتبع على الموقع الإلكتروني، مثل
                                        Google Analytics.                        ، والتي ستمكنهم من جمع بيانات حول كيفية تفاعلك مع الموقع بمرور الوقت
                                        يمكن استخدام هذه المعلومات، على سبيل المثال لتحليل البيانات- وتتبعها، وتحديد انتشار محتوى
                                        .            معين وفهم النشاط عبر الإنترنت بشكل أفضل
                                    </li>
                                    <li>
                                        فيحال                           نقل ملكية الأعمال مثل الاندماج، بيع أصول الشركة، التمويل، الاستحواذ على كل أو جزء
                                        .        من أعمالنا- إلى شركة أخرى
                                    </li>
                                    <li>
                                        لنقدم لك عروضاًترويجية معينة
                                    </li>
                                </ul>
                                <li>
                                    يجوز لنا مشاركة معلوماتك الشخصية والكشف عنها لأي غرض آخر بعد الحصول على موافقتك                                </li>
                                <li>
                                    قد تؤثر المعلومات غير الدقيقة على تجربتك أثناء استخدام الموقع وقدرتنا على الاتصال بك كما هو موضح
                                    .                            في سياسة الخصوصية هذه، لذلك فإنك مسؤول عن ضمان دقة أي معلومات شخصية ترسلها إلينا
                                </li>
                                <li>
                                    يوافق المستخدمون عند تقديم المعلومات الشخصية، التي يتم جمعها من خلال استخدام خدماتنا، على
                                    استخدام ومعالجة وتخزين المعلومات الشخصية في البلدان التي قد يكون لديها قوانين لحماية البيانات
                                    .              تختلف عن القوانين- المعمول بها في بلد المستخدم
                                </li>
                                <li>
                                    نحن نقوم أيضاً بجمع أي جميع المعلومات، التي لا تعتبر معلومات شخصية، والتي يتم توفيرها من خلال
                                    :        متصفحات الويب والخوادم وقد تشمل نوع المتصفح، وتفضيل اللغة، وموقع الإحالة، ووقت وتاريخ طلب كل
                                    .زائر
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>ملفات تعريف الارتباط والتقنيات المماثلة</b>
                            <ul>
                                <li>
                                    ملفات تعريف الارتباط هي ملفات تحتوي على كميات صغيرة من البيانات، والتي قد تتضمن معرفاً فريداً
                                    .مجهولاً نحن نستخدم ملفات تعريف الارتباط مثل ملفات تعريف ارتباط الجلسة الواحدة وملفات تعريف
                                    .              الارتباط المستمرة، لجمع المعلومات وإعلامنا بأنك مستخدم سابق قد تتضمن هذه المعلومات عناوينIP ،
                                    (                         ونوع المتصفح، ومزود خدمة الإنترنت، وصفحات الإحالة، الخروج ، والملفات المعروضة على موقعنا على
                                    .    سبيل المثالHTML /          )              الوقت، الصفحات ، والرسومات ، وما إلى ذلك ونظام التشغيل، وختم التاريخ-
                                    /            ) (             وعمليات تسجيل الدخول إلى موقع الويب، والعنصر العناصر في عربة التسوق الخاصة بك، و أو- بيانات
                                    "(   تدفق النقر  ملفات تعريف الارتباط              .)"لا تستخدم ملفات تعريف الارتباط لتخزين المعلومات الشخصية
                                    .الأخرى يمكن للمستخدمين توجيه متصفح الويب الخاص بهم لرفض جميع ملفات تعريف الارتباط أو للإشارة
                                    .          إلى وقت إرسال ملف تعريف الارتباط ومع ذلك، إذا لم يقبل المستخدمون ملفات تعريف الارتباط، فقد لا
                                    .              يتمكنوا من استخدام ميزات معينة من موقع الويب
                                </li>
                                <li>
                                    يجوز للأطراف الخارجية المتعاقدة معنا استخدام ملفات تعريف الارتباط لجمع أي كل شيء عن استخدامك للموقع
                                    قد يستخدم مقدمو الخدمات هذه المعلومات- لمساعدتنا في تقديم الخدمات على الموقع- بناء على نشاط التصفح الخاص
                                    .بك
                                </li>
                                <li>
                                    نحن نستخدم ملفات تعريف الارتباط أيضاً من أجل تمكيننا من إجراء استطلاعات لاستخدامنا الخاص ونيابة
                                    .  عن معلنينا تستخدم ملفات تعريف الارتباط هذه لتحديد ما إذا كنت قد شاهدت محتوى أو إعلانات معينة
                                    .        حتى نتمكن- من طلب آرائك قد نستخدم أيضاً ملفات تعريف الارتباط تسجيل الذين شملهم الاستطلاع أو
                                    .              لمساعدتك في المتابعة- من حيث توقفت في الاستطلاع إذا تم تعطيل ملفات تعريف الارتباط لديك، فقد لا
                                    .          تتمكن من المشاركة في بعض الاستطلاعات
                                </li>
                                <li>
                                    وفقاً للفقرة4.1.3                       أعلاه، يجوز لنا استخدام تقنية التتبع على الموقع،- بما في ذلكGoogle Analytics   ، والتي
                                    ستمكن المواقع التابعة لجهات خارجية، بما في ذلك، على سبيل المثال لا الحصرGoogle         ، من جمع معلومات حول
                                    .                  .          الطريقة التي تتفاعل بها مع موقعنا ومن هذه المعلومات، ما إذا كنت قد أكملت معاملة
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>مواقع أخرى</b>
                            <ul>
                                <li>
                                    قد يحتوي الموقع، من وقت لآخر، على روابط- من وإلى مواقع خارجية إذا اخترت الدخول إلى هذه الروابط، سوف
                                    .              يتم توجيهك إلى موقع ويب خاص بطرف خارجي في الواقع إن وجود مثل هذه الروابط ليس تأييداً منا أو تفويضاً أو
                                    تمثيلاً أو انتساباً- إلى هذا الطرف الخارجي، كما أنه ليس تأييداً لسياسات أو ممارسات الخصوصية أو أمن-
                                    .    المعلومات الخاصة بهم قد تضع هذه المواقع الأخرى ملفات تعريف الارتباط الخاصة بها أو ملفات أخرى على
                                    .                    الجهاز الخاص بك أو تجمع بيانات- أو تطلب معلومات شخصية منك يخضع استخدامك لمواقع الويب الخاصة
                                    .                بالجهات الخارجية- للامتثال للشروط والأحكام وسياسة الخصوصية الخاصة بها لن نكون مسؤولين- عن أي مشكلات
                                    .                    تنشأ عن استخدامك لمواقع أي طرف ثالث بمجرد توجيهك إلى صفحتهم إذا كان لديك أي أسئلة حول استخدامهم
                                    .              للمعلومات التي يجمعونها، فيجب عليك الاتصال بهم مباشرة
                                </li>
                                <li>
                                    نحن نبذل قصارى جهدنا للحفاظ على أمان- معلوماتك ومع ذلك لا يمكننا ضمان سلامة معلوماتك الشخصية،
                                    .                              وبالتالي لن نكون مسؤولين، بأي حال من الأحوال، عند حدوث خرق للأمن خارج عن سيطرتنا المعقولة
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>مواقع أخرى</b>
                            <ul>
                                <li>
                                    قد يحتوي الموقع، من وقت لآخر، على روابط- من وإلى مواقع خارجية إذا اخترت الدخول إلى هذه الروابط، سوف
                                    .              يتم توجيهك إلى موقع ويب خاص بطرف خارجي في الواقع إن وجود مثل هذه الروابط ليس تأييداً منا أو تفويضاً أو
                                    تمثيلاً أو انتساباً- إلى هذا الطرف الخارجي، كما أنه ليس تأييداً لسياسات أو ممارسات الخصوصية أو أمن-
                                    .    المعلومات الخاصة بهم قد تضع هذه المواقع الأخرى ملفات تعريف الارتباط الخاصة بها أو ملفات أخرى على
                                    .                    الجهاز الخاص بك أو تجمع بيانات- أو تطلب معلومات شخصية منك يخضع استخدامك لمواقع الويب الخاصة
                                    .                بالجهات الخارجية- للامتثال للشروط والأحكام وسياسة الخصوصية الخاصة بها لن نكون مسؤولين- عن أي مشكلات
                                    .                    تنشأ عن استخدامك لمواقع أي طرف ثالث بمجرد توجيهك إلى صفحتهم إذا كان لديك أي أسئلة حول استخدامهم
                                    .              للمعلومات التي يجمعونها، فيجب عليك الاتصال بهم مباشرة
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>التغيرات التي تطرأ على سياسة الخصوصية الخاصة بنا   </b>
                            <ul>
                                <li>
                                    تبقى سياسة الخصوصية هذه سارية المفعول ما لم يطرأ أي تغيير على أحكامها في المستقبل، وتكون هذه
                                    .              التغييرات سارية المفعول فور نشرها على هذه الصفحة
                                </li>
                                <li>
                                    قد يتم تتغير الموقع وأعمالنا- من وقت لآخر ونتيجة لذلك، قد يكون من الضروري بالنسبة لنا في بعض الأحيان
                                    .                              إجراء تغييرات على سياسة الخصوصية هذه من أجل أن تعكس بدقة ممارسات- جمع البيانات- والإفصاح عنها
                                    نحن نحتفظ بالحق في تحديث أو تعديل سياسة الخصوصية هذه في أي وقت ومن وقت لآخر دون إشعار مسبق، ما
                                    .                  لم تنص أي من القوانين- المعمول بها على خلاف ذلك وبناء على ذلك، يرجى مراجعة سياسة الخصوصية هذه
                                    .              بشكل دوري وخاصة قبل تقديم أي معلومات شخصية إن استمرارك- في استخدام الموقع بعد أي تغييرات أو
                                    مراجعات على سياسة الخصوصية هذه يشير إلى موافقتك على شروط سياسة الخصوصية المعدلة، ذلك دون
                                    .            المساس بحقوقك بموجب أي قوانين معمول بها
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>الموافقة على هذه الشروط</b>
                            <ul>
                                <li>
                                    إن استخدام الموقع،- يشير إلى قبول المستخدمون بسياسة الخصوصية هذه نحن نحتفظ بالحق في تغيير و أو تعديل أي من
                                    /                .              الأحكام المذكورة هنا دون إشعار مسبق لأي مستخدم يعتبر استمرار استخدام الموقع بعد أي تغيير و أو تعديل بمثابة قبول
                                    .    المستخدمين لهذه التغييرات
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>معلومات الاتصال</b>
                            <ul>
                                <li>
                                    لمزيد من الاستفسارات- أو لإبداء الملاحظات حول الموقع أو سياسة الخصوصية- هذه، يرجى الاتصال بنا عبر البريد الإلكتروني
                                    :على]   [يرجى الإدراج
                                </li>
                            </ul>
                        </li>
                    </ol>


                </div>
            </div>
        )

        const englishPrivacyPolicy = (
            <div className="container-fluid p-3">
                <div className="text-black-50 fw-bold mt-3 px-3 ">
                    <b className='color-skyblue' >Privacy Policy</b>
                    <p >
                        Welcome to CAPITAL HEALTH REWARDS MARKETPLACE, (“  Capital Medical Centre For Health
                        Screening”, “We”, “Us”, or “Our”) holding business license number 1192526, who operates this platform to
                        provide Our users (“User(s)”,  “You”  or  “Your”) with a marketplace. CAPITAL HEALTH REWARDS
                        MARKETPLACE respects Your privacy regarding any information We may collect while operating Our
                        website [Insert] (“Website”) and any related mobile or software application (“App”).
                    </p>
                    <p >
                        Please read this Privacy Policy (“Privacy Policy”) carefully to understand how We use Personal Information.
                        If You do not agree with this Privacy Policy, You must not use the Website, its related websites, services, or
                        any other website operated by Us that links to this Privacy Policy. By accessing or using this Website, You
                        agree to this Privacy Policy. This Privacy Policy may change from time to time. Any changes We make to this
                        Privacy Policy shall be posted on the Website. We shall also take any other steps, to the extent required by
                        any Applicable Laws, including notifying You and/or seeking Your explicit consent to material changes.
                        Changes to this Privacy Policy are effective as of the stated ‘Last Updated’ date. Other than where We have
                        sought such explicit consent from You, Your continued use of the Website after We make changes shall
                        constitute acceptance of, and agreement to be bound by, those changes. Accordingly, please review the
                        Privacy Policy periodically for any updates or changes.
                    </p>
                    <p >
                        Any/all defined terms in this Privacy Policy are the ones referred to in the Terms and Conditions available on
                        Our Website. In the event where a defined term in this Privacy Policy is also a defined term in the Terms and
                        Conditions, the defined terms stated herein shall take precedence over the same defined term in the Terms and
                        Conditions.
                    </p>
                    <p >
                        This  Privacy  Policy  governs  data  collection,  maintenance,  disclosure,  and  use  of  the  Website  and  its
                        associated   Content.   By   using   the   Website,  Users   consent   to   the   collection   and   use   of   their   Personal
                        Information in accordance with this Privacy Policy.
                    </p>
                    <ol >
                        <li>
                            <b>INFORMATION COLLECTION</b>
                            <ul>

                                <li>
                                    <b>Information You Provide to Us</b>
                                    <p>When You use the Website,  You may provide Us with information about You, including, but not
                                        limited to, Your name and contact information, Your address, Your financial information to make or
                                        receive payment for services obtained through the Website. When You use the Website, We may also
                                        collect information related to Your use of the Website and aggregate this with information about other
                                        users.</p>

                                </li>
                                <li>
                                    <b>Information Received from Third Parties</b>
                                    <p>Third   parties   may   also   give   Us   information   about   You.   If   We   combine   that   information   with
                                        information about You, collected through Our Website, We will still treat that combined information as
                                        set forth in this Privacy Policy.</p>


                                </li>
                                <li>
                                    <b>Information Collected Automatically</b>
                                    <p>

                                        Similar to any other online platform, We receive technical data including, but not limited to, Your
                                        unique   device   identification   number,   Your   location   data,   Your   internet   speed,   and   other   such
                                        information. We do not store this technical data; it is only used to improve Your experience while
                                        browsing the Website and shall be deleted from our servers once you exit the Website. The technical
                                        information collected on Our servers are used to analyse how people use Our services, improve Our
                                        Website functions, save Your log-in information for future sessions, and to serve You with other
                                        information and/or data that may be of interest to You. Automatic data collection may include, but is not
                                        limited to, information pertaining to the browser and operating system You are using, the URL or
                                        advertisement that referred You to Our Website , the search terms you entered into a search engine that
                                        led You to the Website , areas within the websites that You visited, links You clicked on, pages or
                                        Content You viewed, the duration of Content viewed by You and other similar information and statistics
                                        about Your interactions (such as, Content response times, download errors, length of visits to certain
                                        pages, and other information commonly shared when browsers communicate with other websites). We
                                        may combine any/all data collected during Your usage of Our Website along with any other information
                                        retrieved about You. We do this to improve marketing, analytics, and Website functionality. The data[ type  Annotation]
                                        collected may also include the Internet Protocol (“IP”) address or other device identifiers for any device
                                        (computer, mobile phones etc.) used to access the services and Website.</p>

                                </li>

                            </ul>
                        </li>
                        <li>
                            <b>USE OF INFORMATION</b>
                            <ul>
                                <li>
                                    In the course of using Our Website, We may collect Personal Information about You as necessary that
                                    identifies   You   as   a   specific   individual   and   can   be   used   to   contact   or   identify   You   (“Personal
                                    Information”). Examples of Personal Information include Your name, electronic mail address, delivery
                                    address, and phone number. We use Personal Information and other information to:
                                    <ul>
                                        <li>
                                            Provide and improve the Website, complete Your transactions, address Your inquiries, process
                                            Your registration, verify the information You provide is valid, and for compliance and internal
                                            business purposes.
                                        </li>
                                        <li>
                                            Contact You with administrative communications and or promotional offers (on behalf of Us or
                                            third parties) and other information that may be of interest to You.
                                        </li>
                                        <li>
                                            Tailor the Content by displaying offers in reference to the Website.
                                        </li>
                                        <li>
                                            Administer and develop Our business relationship with You and, if applicable, the corporation
                                            or any other legal entity You represent.
                                        </li>
                                        <li>
                                            Enforce and comply with the relevant Applicable Laws, including to conduct an investigation,
                                            to protect the property and respective rights of the platform or any User on the platform, for any
                                            activity We may consider posing a risk of being, illegal, fraudulent, unethical activity.
                                        </li>
                                        <li>
                                            Comply with legal obligations, analysing and improving Our business, communications, and
                                            responding to Your inquiries about Our Website.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    For any other purposes specified elsewhere in this Privacy Policy.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>DATA RETENTION</b>
                            <ul>
                                <li>
                                    If You do not request Us to delete certain data and/or information, We reserve the right to retain the
                                    information so long as Your Account is activated. Such data and/or information shall no longer be stored
                                    once you choose to terminate or deactivate your Account, which You can request by clicking on our
                                    ‘Contact Us’ section. If You do not delete Your User Account or request to delete certain data and/or
                                    information Your information may be retained for backup, analysis, and business continuity purposes. If
                                    You provide Us with Your Personal Information or any other data, We encourage You to regularly
                                    update the data to ensure that We have accurate and up-to-date information about You.
                                </li>
                                <li>
                                    In the event of a corporate change in control resulting from, for example, a sale to, or merger with,
                                    another entity, or in the event of a sale of assets or a bankruptcy, We reserve the right to transfer Your
                                    Personal Information to the new party in control or the party acquiring assets. In the event of such a
                                    change, Your Personal Information shall continue to be treated in accordance with this Privacy Policy.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>DATA SHARING AND DISCLOSURE</b>
                            <p>We may share Your data to provide Our services, for legal and investigative purposes, in connection with
                                the Website, promotions, or if We are part of a merger or acquisition. We may also share non-identifying
                                information with third parties.</p>
                            <ul>
                                <li>We may share Your Personal Information and/or data at any time, under the following circumstances:</li>
                                <ul>
                                    <li>
                                        Where We are legally required to do so in order to comply with Applicable Laws, governmental
                                        requests, judicial proceedings, or legal processes, such as in response to a court order;
                                    </li>
                                    <li>
                                        Where We believe it is necessary to investigate, prevent, or take action regarding potential
                                        violations of Our Terms and/or policies, suspected fraud, situations involving potential threats
                                        to the safety of any person or User, any illegal activities, or as evidence in litigation as required
                                        by law;
                                    </li>
                                    <li>
                                        With third-party service providers, contractors, agents who perform services for Us or on Our
                                        behalf   and   require   access   to   such   information   to   do   that   work,   employees   or   affiliates.
                                        Examples include, payment processing, data analysis, electronic mail delivery, hosting services,
                                        customer service and marketing efforts. We may allow selected third parties to use tracking
                                        technology on the Website, such as Google Analytics, which shall enable them to collect data
                                        about how You interact with the Website  over time. This information may be used to, among
                                        other things, analyse and track data, determine the popularity of certain Content and better
                                        understand online activity;
                                    </li>
                                    <li>
                                        Where there is a transfer of business ownership such as, a merger, sale of company assets,
                                        financing, or acquisition of all or a portion of Our business to another company;
                                    </li>
                                    <li>
                                        To offer You certain promotions.
                                    </li>
                                </ul>
                                <li>
                                    We may share and disclose Your Personal Information for any other purpose with Your consent.
                                </li>
                                <li>
                                    You are responsible for ensuring the accuracy of any Personal Information that You submit to Us.
                                    Inaccurate information may affect Your experience whilst using the Website and Our ability to contact
                                    You as described in this Privacy Policy.
                                </li>
                                <li>
                                    When providing Personal Information, collected through use of Our services, Users agree to the use,
                                    processing, and storage of Personal Information in countries which may have data protection laws that
                                    differ from the laws in the User’s country.
                                </li>
                                <li>
                                    We also collect any/all information, that is not considered Personal Information, made available through
                                    web browsers and servers and may include, browser type, language preference, referring site, and date
                                    and time of each visitor’s request.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>COOKIES AND SIMILAR TECHNOLOGIES</b>
                            <ul>
                                <li>
                                    Cookies are files with small amounts of data, which may include an anonymous unique identifier. We use
                                    cookies, such as, but not limited to, session cookies and persistent cookies, to collect information to let
                                    Us know that You are a prior User. This information may include IP addresses, browser type, internet
                                    service provider, referring/exit pages, the files viewed on Our Website (e.g. HTML pages, graphics, etc.),
                                    operating system, date/time stamp, Website logins, Item(s) in Your shopping cart, and/or clickstream data
                                    (“Cookies”). Cookies are not used to store other Personal Information. Users can instruct their web
                                    browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if Users do not accept
                                    Cookies, they may not be able to use certain features of the Website.
                                </li>
                                <li>
                                    Third parties under contract with Us may use Cookies to collect any/all about Your usage of the Website.
                                    These third-party service providers may use this information to help Us deliver services on the Website
                                    based on Your browsing activity.
                                </li>
                                <li>
                                    We also use Cookies in order to enable Us to conduct surveys for Our own use and on behalf of Our
                                    advertisers. These Cookies are used to determine whether You have seen certain Content or advertising
                                    so that We may request Your opinions. We may also use Cookies to authenticate respondents or to help
                                    You pick up where You left off in a survey. If You have Cookies disabled, You may not be able to
                                    participate in some surveys.
                                </li>
                                <li>
                                    Pursuant to Clause 4.1.3 above, We may use tracking technology on the Website, including Google
                                    Analytics,   which will   enable   third-party   websites, including,   but   not   limited   to Google,   to   collect
                                    information about the way in which You interact with Our Website . Such information includes, but is
                                    not limited to, whether You have completed a transaction.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>SECURITY</b>
                            <ul>
                                <li>
                                    We do not sell, trade, or rent Users’ Personal Information to others. We may, however, share generic
                                    aggregated demographic information, not linked to any Personal Information with Our business partners
                                    and trusted affiliates.
                                </li>
                                <li>
                                    We endeavour to exercise Our best efforts to keep Your information secure. However, We cannot
                                    guarantee the safety of Your Personal Information and therefore shall not be held liable, in any manner
                                    and capacity whatsoever, in the event of a breach of security beyond Our reasonable control.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>OTHER WEBSITES</b>
                            <ul>
                                <li>
                                    Our Website may, from time to time, contain links to and from third party websites. If You choose to
                                    click on third party links, You will be directed to that third party’s website. The fact that We link to a
                                    website is not an endorsement, authorisation, or representation or Our affiliation with that third party, nor
                                    is it an endorsement of their privacy or information security policies or practices. These other websites
                                    may place their own Cookies or other files on Your computer, collect data or solicit Personal Information
                                    from You. Your use of Our respective third-party websites is subject to the compliance of their terms and
                                    conditions and privacy policy. We shall not be held liable for any issues arising out of Your use of the
                                    third-party websites once you have been redirected to their page. Should you have any questions about
                                    their use of the information they collect, you should contact them directly.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>CHANGES TO THE PRIVACY POLICY   </b>
                            <ul>
                                <li>
                                    This Privacy Policy shall remain in effect except with respect to any changes in its provisions in the
                                    future, which shall be in effect immediate after being posted on this page.
                                </li>
                                <li>
                                    The Website and Our business may change from time to time. As a result, at times it may be necessary
                                    for Us to make changes to this Privacy Policy in order to accurately reflect Our data collection and
                                    disclosure practices. We reserve the right to update or modify this Privacy Policy at any time and from
                                    time to time without prior notice, unless otherwise required by any Applicable Laws. Accordingly, please
                                    review this Privacy Policy periodically and especially before You provide any Personal Information.
                                    Your continued use of the Website after any changes or revisions to this Privacy Policy shall indicate
                                    Your agreement with the terms of such revised Privacy Policy, without prejudice to Your rights under
                                    any Applicable Laws.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>ACCEPTANCE OF THESE TERMS</b>
                            <ul>
                                <li>
                                    By using the Website, Users signify their acceptance of this Privacy Policy. We reserve the right to alter,
                                    modify, and/or amend any one of the provisions stated herein without prior notice to any User. Continued
                                    use of the Website following any alterations, modifications, and/or amendments shall be deemed as the
                                    Users’ acceptance of those changes.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>CONTACT INFORMATION</b>
                            <ul>
                                <li>
                                    For further inquiries or comments about the Website or this Privacy Policy, please contact Us via email
                                    at: [Insert]
                                </li>
                            </ul>
                        </li>
                    </ol>


                </div>
            </div>

        )
        return (
            this.props.lang !== 'ar' ? englishPrivacyPolicy : arabicPrivacyPolicy
        )
    }
}
