import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link,withRouter ,Prompt} from 'react-router-dom'
import editButton from '../../assests/img/TravelShop/editButton.svg'
import userIcon from '../../assests/img/TravelShop/userIcon.svg'
import TravelShopHOC from './TravelShopHOC'
import TravelConfirmationModal from '../shared/Modals/TravelConfirmationModal.jsx'
import Policy from './Policy.jsx'
import { currancyToPoints, numberWithCommas } from '../../utils/pointsConversion'
import { TermsAndConditionEnglish,TermsAndConditionArabic } from './termsDetails'
import TravelConfirmationPopup from './Modal/TravelConfirmationPopup'


class DetailsDisplay extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = { 
            confirmOrder: false,
            blockNavigation:false,
            TermsModal:false,
            checkoutBtn:true,
            orders:{},
            quantity:1,
            cardPayment:false,
            paymentType:'points',
            paymentMethod:'points',
            showConfirmationPopup:false
         }
    }
    orderConfirmation = ()=> {
        this.setState({
            confirmOrder:true
        })
    }

    setConfirmationPopup = (val)=>{
        if(val === 'proceed'){
            this.orderSummary(this.state.paymentMethod);
        }
        this.setState({showConfirmationPopup:!this.state.showConfirmationPopup})
    }
    componentDidMount(){
        this.myRef.current?.scrollIntoView({behavior: "smooth"})
    }
    closeModal=()=> {
        this.setState ({
           TermsModal:false
        })
    }
    setCheckoutBtn(val){
        this.setState(old=>({
            checkoutBtn:!old.checkoutBtn
        }))
    }
    passengerType = (index) =>{
        const {adults,child} =  this.props.passengerCountDetails;
        if (index <= adults){
            return '1'
        }
        else if(index > adults && index <= child + adults){
            return '2'
        }
        else{
            return '3'
        }
    }
    orderSummary=(paymentType,props)=>{
        const {flightDetails,searchQueryData,passengerCountDetails} = this.props;
        let fare = flightDetails?.data?.fare_local
        let amount_points = fare?.price_in_points;
        let orders;
        let passengerArr = [];
        let passengerDetailsOrg = this.props.passengerDetails;
        let passengerDetails = JSON.parse(JSON.stringify(passengerDetailsOrg))
        Object.keys(passengerDetails).forEach((e,index) => {
        passengerDetails[e]['address_line2'] = '';
        passengerDetails[e]['expiry_date'] = passengerDetails[e]['expiry_date']+'T00:00:00';
        passengerDetails[e]['date_of_birth'] = passengerDetails[e]['date_of_birth']+'T00:00:00';
        passengerDetails[e]['mobile_country_code'] = '966';
        passengerDetails[e]['country'] = 'Saudi Arabia';
        passengerDetails[e]['city'] = 'Riyadh';
        passengerDetails[e]['address_line1'] = '#2704 , Api tower Novotel hotel';
        passengerDetails[e]['mobile_number'] = `966-${passengerDetails[e]['mobile_number']}`;
        passengerDetails[e]['title'] === 'Mr' ? passengerDetails[e]['gender'] = '1' : passengerDetails[e]['gender'] = '2';
        passengerDetails[e]['passenger_type'] = this.passengerType(index+1);
        if(passengerDetails[e]['issued_date']){
            passengerDetails[e]['issued_date'] =  passengerDetails[e]['issued_date']+'T00:00:00'
        }});



        Object.keys(passengerDetails).forEach(e => passengerArr.push(passengerDetails[e]))
        orders = {

            "travel_origin_code": searchQueryData?.travel_origin_code,
            "travel_destination_code": searchQueryData?.travel_destination_code,
            "hassad_points":paymentType === 'creditcard' ? 0 : amount_points <= this.props.pointBalance ? amount_points: this.props.pointBalance,
            "language_code": this.props.lang, 
            "use_hassad_points":paymentType === 'creditcard' ? false : this.props.pointBalance>0,
            "currency": this.props.countrySelected,
            "search_id": localStorage.getItem('search_id'),
            "result_id": localStorage.getItem('result_id'),
            "departure_date": flightDetails?.data?.segments?.[0]?.departure_time,
            "adult_count": passengerCountDetails?.adults,
            "child_count": passengerCountDetails?.child,
            "infant_count": passengerCountDetails?.infant,
            "departure_country": searchQueryData?.departure_country,
            "total_fare_in_sar": fare?.total_fare_sar,
            "price_in_points": fare?.price_in_points,
            "round_trip_flag": searchQueryData?.round_trip_flag,
            "passengers":passengerArr,

            "amount":fare?.total_fare_sar
        }
        this.setState(prevState=>({
            confirmOrder:true,
            orders:orders,
            cardPayment : paymentType === 'creditcard'
        }))
    }
    render() {
        const customizeNodal  = {    
            opacity: ".5 !important"
      };
        const {contactDetails,passengerDetails,t,flightDetails,pointBalance} = this.props;
        const {confirmOrder,orders,showConfirmationPopup} = this.state;
        const missingPoints = flightDetails?.data?.fare_local?.price_in_points - pointBalance
        return (
            <div ref={this.myRef}>
            <Policy
                style={customizeNodal}
                showModal = {this.closeModal}
                show = {this.state.TermsModal}
                title="Terms & Conditions"
                list={true}
                contentEnglish={TermsAndConditionEnglish}
                contentArabic={TermsAndConditionArabic}
                lang={this.props.lang}
                />
            <Prompt
            when={this.state.blockNavigation}
            message="All the entered information will have to be reentered"
            />
            {
                showConfirmationPopup && <TravelConfirmationPopup termsModal={()=> this.setState({TermsModal: true})} show={showConfirmationPopup} setModalView={this.setConfirmationPopup}/>
            }
                            {
                                confirmOrder ? this.props.history.push({ 
                                    pathname: "/travel-confirmation-order",
                                    state:{
                                        orders: orders ,
                                        card:this.props.offerDetails ,
                                        show:confirmOrder ,
                                        cardPayment:this.state.cardPayment,
                                    }
                                })
                            :
                <TravelShopHOC
                childComponent ={
                    <div className='d-flex flex-column h-100 justify-content-between pb-5'>
                        <div className="d-flex flex-column overflow-auto custom-scrollbar">
                <div className='w-100 bg-white p-4 border mb-2'>
                    <div className='d-flex justify-content-between align-items-center'>
                    <h4 className="fw-bold color-blue">{t("Contact Details")}</h4>
                    <Link to={{pathname:'/contacts',state:{editParam:true}}}><img loading='lazy' decoding='async' src={editButton} alt="Edit Button" className='cursor-pointer' /></Link>
                    </div>
                    <div className="d-flex p-2 mx-2">
                        <div><img loading='lazy' decoding='async' src={userIcon} alt="" /></div>
                        <div className='px-2'>
                            <h5>{contactDetails['first_name']}{' '}{contactDetails['last_name']}</h5>
                            <h6 className='text-muted'>{contactDetails['email']}</h6>
                            <h6 className='text-muted'>966 {contactDetails['phone']}</h6>
                        </div>
                    </div>
                </div>
                {
                    Object.keys(passengerDetails).map((e,index) => 
                        <div className='w-100 bg-white p-4 border my-2'>
                    <div className='d-flex justify-content-between align-items-center'>
                    <h4 className="fw-bold color-blue">{t("Passenger")} {index + 1} {t("Details")}</h4>
                    <Link to={{pathname:`/passenger-details/${index+1}`,state:{editParam:true}}}><img loading='lazy' decoding='async' src={editButton} alt="Edit Button" className='cursor-pointer' /></Link>
                    </div>
                    <div className="d-flex p-2 mx-2">
                        <div><img loading='lazy' decoding='async' src={userIcon} alt="" /></div>
                        <div className='px-2 w-100'>
                            <div className="row">
                            <div className="col-md-6">
                            <h5>{passengerDetails[e]['first_name']}{' '}{passengerDetails[e]['last_name']}</h5>
                            {passengerDetails[e]['number'] && <h6 className='mx-2 text-muted'>{t("Passport")} : {' '} {passengerDetails[e]['number']}</h6>}
                            <h6 className='mx-2 text-muted'>{t("Mobile")} : {' '} {'+966'} {passengerDetails[e]['mobile_number']}</h6>
                            </div>
                            <div className="col-md-6">
                            <h6 className='mx-2 text-muted'>{`${t(passengerDetails[e]['nationality'])}`}</h6>
                            {passengerDetails[e]['expiry_date'] && <h6 className='mx-2 text-muted'>{t("Passport Expiry")} : {' '} {passengerDetails[e]['expiry_date']}</h6>}
                            
                            </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
                
                        )
                }
                
                
            </div>
            <div className="bg-flightGray d-flex mb-4 ">
                        <div className="w-100">
                            <div className="d-flex flex-column bg-white travelBoxShadow p-4 footerMargin">
                                <div>
                                    <h5>{numberWithCommas(flightDetails?.data?.fare_local?.price_in_points)}{" "}{t("Points")}</h5>
                                </div>
                                {
                                    missingPoints > 0 &&
                                    <div className='d-flex'>
                                        <div>
                                        <span className='text-muted'>{t("Remaining points")}:</span>
                                        <span className='color-red'>{numberWithCommas(missingPoints)}</span>
                                        </div>
                                        <div className='mx-3'>
                                            <span className='fw-bold'>{numberWithCommas(missingPoints)} {t("Points")} </span>
                                            <span className='text-muted'>{t("To be paid by Card")}</span>
                                        </div>
                                    </div>
                                }
                                <hr className='hrLine' />
                                <div className='d-flex gap-2'>
                                <input onClick={()=>{this.setCheckoutBtn()}}  type="checkbox" className='flex-shrink-0' />
                                    <label className='text-muted'>{t("I have read and accepted")}<Link to="/travel-details" onClick={()=> this.setState({TermsModal: true})} className='color-green'>{" "}{t("Terms & Conditions")}</Link></label>
                                </div>
                                <div className="mt-4 d-flex flex-column flex-md-row justify-content-md-end">
                                    <button disabled={this.state.checkoutBtn} onClick={()=>{this.setState({paymentMethod:'points'});this.setConfirmationPopup()}}  className="btn primary-btn rounded mx-3 px-lg-4 my-2 my-md-0">
                                       {t("Pay with Points")}
                                    </button>
                                    
                                    <button disabled={this.state.checkoutBtn} onClick={()=>{this.setState({paymentMethod:'creditcard'});this.setConfirmationPopup()}} 
                                    className="btn primary-btn rounded mx-3 px-lg-4 my-2 my-md-0">
                                    {t("Pay with Card")}
                                    </button>
                                    </div>
                            </div>
                        </div>
                </div>
                    </div>
                }
                />
            }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        pointBalance:state.point.pointBalance,
        contactDetails:state.travel.contactDetails,
        passengerDetails:state.travel.passengerDetails,
        flightDetails:state.travel.selectedFlightDetail?.search,
        searchQueryData:state.travel.searchQueryData,
        passengerCountDetails:state.travel.passengerCountDetails,
        countrySelected:state.countries.countryId
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(DetailsDisplay)));