import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import TravelShopHOC from "./TravelShopHOC";
import PlaneTicket from "../../assests/img/TravelShop/plane-ticket-large.svg";
import grayTicket from "../../assests/img/TravelShop/grey-plane.svg";
import PlaneLight from "../../assests/img/TravelShop/plane-up.svg";
import { Card } from "react-bootstrap";
import axios from "axios";
import FailMessageModal from "../shared/Modals/FailMessageModal";
import ProcessingModal from "../shared/Modals/ProcessingModal";
import SuccessfullMessageModal from '../shared/Modals/SuccessfulMessageModal';
import secretKeyGenerator from "../../utils/serectKeyGenerator";
import {fetchOrderDetailsForTravel} from '../../Redux/order/orderActions'
import moment from "moment";
import PlaneDown from "../../assests/img/TravelShop/plane-down-right.svg";
import { remove,load } from "react-cookies"
import { REACT_APP_baseUrl } from "utils/app-constants";
class BookingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetails:{},
      pending:true,
      paymentCaptured:false,
      orderProcessed:false,
      orderStatus:'PENDING',
      paymentFail:false,
      order_total:"",
      hassad_points:null,
      amount_paid_in_sar:null,
      bookingResponse:undefined,
      orderID:undefined
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const payload = {
      "orders": {
        "id": this.props.match.params.id
      }
    }
  
  this.props.fetchOrderDetailsForTravel(payload);
  }
  componentDidUpdate(prevProps,prevState){      
    if(!this.props.orderDetailsLoading && !this.state.paymentCaptured && !this.props.errorFlag && this.state.pending){
        
      if(Number(this.props.orderDetails?.payable_amount)===0){
        this.setState({
            paymentCaptured:true,
            orderProcessed:true
        })
        this.processOrder()
      }
      else if(this.props.orderDetails?.order_status === "PENDING"
      && this.props.orderDetails?.payment_method === "Credit/Debit Cards"
      ){
          this.setState({
              paymentCaptured:true,
              orderProcessed:true
          })
          this.processOrder(true)
      }
         else if(this.props.orderDetails?.order_status === "PENDING" 
         && this.props.orderDetails?.payable_amount
         && this.props.orderDetails?.payment_method === "applepay"
         && !!this.props.orderDetails?.payment_refno
         // && !!this.props.orderDetails?.payment_session_id
         ){
             this.setState({
                 paymentCaptured:true,
                 orderProcessed:true
             })
             this.processOrder()   
         }
         else if(this.props.orderDetails?.order_status === "IN_PROGRESS" || this.props.orderDetails?.order_status === "CONFIRMED" || this.props.orderDetails?.order_status === "IN_HOLD"){
             this.setState({pending:false,orderStatus:this.props.orderDetails?.order_status})
         }
         
         else if(this.props.orderDetails?.order_status === "CANCELED"){
             this.setState({pending:false,
                 paymentFail:true,
                 errorMsg:"Sorry, your payment was unsuccessful. Please try again later."})
         }
         else{
             this.setState({pending:false,
                 paymentFail:true
             })
         }
     }
     else if(this.props.errorFlag && this.state.pending){
         this.setState({pending:false,
             paymentFail:true,
             errorMsg:this.props.getErrorMsg
         })
     }

     if(typeof this.props.orderDetails !== prevProps.orderDetails && this.props.orderDetails?.search_detail?.segments && !this.state.bookingResponse){
       this.setState({bookingResponse:this.props.orderDetails?.search_detail})
        this.flightDetails(this.props.orderDetails?.search_detail.segments)
     }
 }

 //  Payment Functions
 capturePayment(){
  const payload =  
  {
      "payment": {
          "orderid": this.props.orderDetails?.orderid,
          "type": "TRAVEL"
      }
  }
  let token = localStorage.getItem('userToken')
  axios.post(`${REACT_APP_baseUrl}/payments/capture_payment`,
  payload,
  {
      headers:{
          "Authorization":`Bearer ${token}`
      },
      params:{
          "secret_key":secretKeyGenerator(payload)
      }
  }).then(response=>{
      
      if(response.data?.code === 200 && response.data?.data?.order?.order_status === "PENDING"){
              this.processOrder()
          }
       else if(response.data?.data?.order?.order_status === "CANCELED"){
          this.setState({
              pending:false,
              paymentFail:true,
              errorMsg:response.data?.message
          })
      }
          else{
          this.setState({
              pending:false,
              paymentFail:true,
              errorMsg:response.data?.message
          })
      }
  })
  .catch(error => {
      this.setState({pending:false,
          paymentFail:true,
          errorMsg:"Something went wrong!! Please try again!!"
      })
  })
}

 processOrder=(creditCardPayment)=>{
  let payload =  {}

  if(creditCardPayment){
            payload =  {
                "orderid": this.props.orderDetails?.orderid
            }
            
  }else {
            payload = {
                "orders": {
                    "orderid": this.props.orderDetails?.orderid
                }
            }
  }
  let processOrderURL = creditCardPayment ? "payments/process_order_after_redirect" : "orders/process_travel_order"
  let token = localStorage.getItem('userToken')
  axios.post(`${REACT_APP_baseUrl}/${processOrderURL}`,
  payload,
  {
      headers:{
          "Authorization":`Bearer ${token}`
      },
      params:{
          "secret_key":secretKeyGenerator(payload)
      }
  }).then(response=>{
      if(response.data.code === 200){
          const orderStatus = response.data?.data?.order?.order_status
          if(orderStatus === "CANCELED"){
              this.setState({
                  pending:false,
                  paymentFail:true,
                  errorMsg:response.data?.message
              })
          }
          else if(orderStatus === "FULFILLED" || orderStatus === "IN_PROGRESS" || orderStatus === "CONFIRMED" || orderStatus === "IN_HOLD"){
              this.setState({
                  pending:false,
                  errorMsg:response.data?.message,
                  order_total:response.data?.data?.order?.order_total,
                  hassad_points:response.data?.data?.order?.hassad_points,
                  amount_paid_in_sar:response.data?.data?.order?.amount_paid_in_sar,
                  orderID:response.data?.data?.order?.orderid
              })
          }
      }
      else{
          this.setState({
              pending:false,
              paymentFail:true,
              errorMsg:response.data?.message
          })
      }
  })
  .catch(error => {
      this.setState({
      pending:false,
        paymentFail:true,
        errorMsg:"Something went wrong!! Please try again!!"
      })
  })
}

  passengerType = (index) =>{
    const {passengerCount} = this.props
    const {adults,child} =  this.props.passengerCountDetails;
    if (index <= adults){
        return '1'
    }
    else if(index > adults && index <= child + adults){
        return '2'
    }
    else if(index > child && index <= passengerCount){
        return '3'
    }
}

  flightDetails = (segments) => {
    const flightSegments = segments?.reduce((result, segment) => {
      if (segment.segment_indicator === 1) {
        result["outBoundFlight"].push(segment)
      } else {
        result["inBoundFlight"].push(segment)
      }
      return result
    }, { outBoundFlight: [], inBoundFlight: [] })
    this.setState({flightSegments:flightSegments})
  }

  addTimes(segments) {
    let duration = 0;
    segments?.forEach(segment => {
      duration = duration + moment.duration(segment.duration).as('milliseconds')
    });
    let time = moment.utc(duration).format("HH:mm")?.split(":");
    return `${time[0]} h ${time[1]} m`
   }

   airlinesDetails = (data) => {
    let airlineName = [];
    let duplicateArr = [];
    data?.forEach(e=> {
      if(!duplicateArr.includes(e.airline_name)){
        duplicateArr.push(e.airline_name);
        airlineName.push([e.airline_name,e.airline_image])
      }
    })
    return airlineName
  }
  render() {
    const {pending,paymentFail, errorMsg,bookingResponse,orderID} = this.state;
    const {t,orderDetails} = this.props
    const {outBoundFlight,inBoundFlight} = this.state.flightSegments || [];
    return (
      <>
      <SuccessfullMessageModal show={!pending && !paymentFail} lang={this.props.lang}/>
      <FailMessageModal show = {!pending && paymentFail} lang={this.props.lang} Message={errorMsg}/>
      <ProcessingModal show={pending} lang={this.props.lang}/>
        <TravelShopHOC
          childComponent={
            <>
              <div className="bg-white p-4 d-md-block d-none mb-3">
                <div className="">
                  <h4 className="fw-bold color-green">{t("Booking Confirmed")}!</h4>
                  <p className="text-muted ">
                    <span className="text-black">{t("Order ID")}:{orderID || ''} |</span>{" "}
                    <span className="text-muted">
                      {t("Booked on")} {new Date().toLocaleString("en-US", {day: "numeric",month: "short",year: "numeric"})}
                    </span>
                  </p>
                </div>
                <hr />
                <div>
                  <h5>{t("Departing Flight")}</h5>
                </div>

                <div className="d-flex my-3">
                  <div
                    className="text-center p-3"
                    style={{ filter: "grayscale(1)" }}
                  >
                    <img loading='lazy' decoding='async'
                      alt="logo"
                      variant="top"
                      className=""
                      width="110%"
                      src={PlaneTicket}
                    />
                  </div>
                  <div claassName="d-block mt-3 mb-5">
                    <div className="d-flex mt-2">
                      {/* Flight Details start */}
                      <div className="px-3">
                        <span className="px-2">{orderDetails?.travel_origin_code}</span>
                        <img loading='lazy' decoding='async' src={grayTicket} alt="" />
                        <span className="px-2">{orderDetails?.travel_destination_code}</span>
                      </div>
                      <div>
                        <p>{orderDetails?.airline_name}</p>
                      </div>
                      {
                        bookingResponse && outBoundFlight && <div className="px-3 fw-bold d-flex">
                        <div className="mx-2">
                                  <span>{moment(outBoundFlight?.[0]?.departure_time).format('HH:mm')}</span>-<span>{moment(outBoundFlight?.[outBoundFlight.length - 1]?.arrival_time).format('HH:mm')}</span> {outBoundFlight?.length - 1 > 0 && <sup>+{outBoundFlight?.length - 1}</sup>}
                                </div>
                                <div className="">|</div>
                                <div className="d-flex flex-column mx-2">
                                  <span>{moment(outBoundFlight?.[0]?.departure_time).format('MMM Do YYYY')}</span>
                                  <small className='text-muted'>{t("Duration")} {this.addTimes(outBoundFlight)}</small>
                                </div>
                        
                      </div>
                      }
                      {/* flight Details End  */}
                    </div>
                    {
                      orderDetails?.round_trip && <div className="d-flex">
                      {/* flight Details start */}
                      <div className="px-3">
                        <span className="px-2">{orderDetails?.travel_destination_code}</span>
                        <img loading='lazy' decoding='async' src={grayTicket} alt="" />
                        <span className="px-2">{orderDetails?.travel_origin_code}</span>
                      </div>
                      <div>
                        <p>{orderDetails?.airline_name}</p>
                      </div>
                      {
                        bookingResponse && inBoundFlight && <div className="px-3 fw-bold d-flex">
                        <div className="mx-2">
                                  <span>{moment(inBoundFlight?.[0]?.departure_time).format('HH:mm')}</span>-<span>{moment(inBoundFlight?.[inBoundFlight.length - 1]?.arrival_time).format('HH:mm')}</span> {inBoundFlight?.length - 1 > 0 && <sup>+{inBoundFlight?.length - 1}</sup>}
                                </div>
                                <div className="">|</div>
                                <div className="d-flex flex-column mx-2">
                                  <span>{moment(inBoundFlight?.[0]?.departure_time).format('MMM Do YYYY')}</span>
                                  <small className='text-muted'>{t("Duration")} {this.addTimes(inBoundFlight)}</small>
                                </div>
                        
                      </div>
                      }
                      {/* flight Details End  */}
                    </div>
                    }
                  </div>
                </div>
                <hr />
                <div className="row mt-4 justify-content-end">
                  <button onClick={()=>{this.props.history.push('/mywallet/travel')}} className="btn primary-btn rounded-0 mx-3 d-md-block d-none">
                    {t("Continue to My Wallet")}
                  </button>
                </div>
                {/* <div className="row mt-4">
                <button
                  onClick={this.validateForm}
                  className="btn btn-primary rounded-0 mx-3 d-md-none d-block w-100"
                >
                  Save and Continue
                </button>
              </div> */}
              </div>
              {/* Mobile View */}
              <div className="bg-white  d-block d-md-none my-3">
               <div className='d-flex justify-content-center mx-auto'>
               <Card style={{ width: "100%", backgroundColor:"#e9e6f1" }} className='text-center py-3'>
                  <div className="text-center p-3">
                    <img loading='lazy' decoding='async'
                      alt="logo"
                      variant="top"
                      className="img-fluid color-blue"
                      width="30%"
                      src={PlaneTicket}
                    />
                  </div>
                  <Card.Title className='color-blue'><h4>{t("Booking Confirmed")}!</h4></Card.Title>
                  <h5>{t("Order ID")}:{orderID || ''}</h5>
                </Card>
               </div>
               {
                 outBoundFlight && bookingResponse && <div className='d-block mt-3 px-auto px-5'>
                 <div className='d-flex'>
                                    {
                                      this.airlinesDetails(outBoundFlight)?.map((e,index) => (
                                        <div className="d-flex align-items-center" key={index}>
                                            <span><img loading='lazy' decoding='async' height={'25px'} width={'25px'} src={e[1]} alt="flight" /></span>
                                            <h4 className='px-2 color-blue'>{e[0]}</h4>
                                        </div>
                                      ))
                                    }
                              </div>
                      <div className='fs-medium my-2'>
                      <span className="fw-bold">{moment(outBoundFlight?.[0]?.departure_time)?.format('MMMM Do YYYY')}</span> <br /><span className="fw-bold">{`${outBoundFlight?.[0]?.origin?.city_code},${outBoundFlight?.[0]?.origin?.city}`}</span>
                      </div>
                      <div className='d-flex mt-3'>
                          <div className="d-flex align-items-center">
                              <img loading='lazy' decoding='async' src={PlaneLight} alt="" />
                          </div>
                          <div className='px-3'>
                          <div className="fw-bold fs-large">
                          <span>{moment(outBoundFlight?.[0]?.departure_time).format('HH:mm')}</span>-<span>{moment(outBoundFlight?.[outBoundFlight.length - 1]?.arrival_time).format('HH:mm')}</span> {outBoundFlight?.length - 1 > 0 && <sup>+{outBoundFlight?.length - 1}</sup>}
                          </div>
                          <span className='text-muted'>Duration {this.addTimes(outBoundFlight)}</span>
                          </div>
                      </div>
                 </div>
               }
               {
                 bookingResponse && inBoundFlight?.length>0 && <div className='d-block mt-3 px-auto px-5 mt-4'>
                 <div className='d-flex'>
                                  {
                                    this.airlinesDetails(inBoundFlight)?.map((e,index) => (
                                      <div className="d-flex align-items-center" key={index}>
                                          <span><img loading='lazy' decoding='async' height={'25px'} width={'25px'} src={e[1]} alt="flight" /></span>
                                          <h4 className='px-2 color-blue'>{e[0]}</h4>
                                      </div>
                                    ))
                                  }
                            </div>
                    <div className='fs-medium my-2'>
                    <span className="fw-bold">{moment(inBoundFlight?.[0]?.departure_time).format('MMMM Do YYYY')}</span> <br /><span className="fw-bold">{`${inBoundFlight?.[0]?.origin?.city_code},${inBoundFlight?.[0]?.origin?.city}`}</span>
                    </div>
                 <div className='d-flex mt-3'>
                        <div className="d-flex align-items-center">
                            <img loading='lazy' decoding='async' src={PlaneDown} alt="" />
                        </div>
                        <div className='px-3'>
                        <div className="fw-bold fs-large">
                        <span>{moment(inBoundFlight?.[0]?.departure_time).format('HH:mm')}</span>-<span>{moment(inBoundFlight?.[inBoundFlight.length - 1]?.arrival_time).format('HH:mm')}</span> {inBoundFlight?.length - 1 > 0 && <sup>+{inBoundFlight?.length - 1}</sup>}
                        </div>
                        <span className='text-muted'>Duration {this.addTimes(inBoundFlight)}</span>
                        </div>
                    </div>
            </div>
               }
               <div className="row mt-4 px-3">
                <button
                 onClick={()=>{this.props.history.push('/mywallet/travel')}}
                  className="btn btn-primary rounded-0 mx-3 d-md-none d-block w-100"
                >
                 {t("My Wallet")}
                </button>
              </div>
              </div>
            </>
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    contactDetails: state.travel.contactDetails,
    passengerCount:state.travel.passengerCount,
    passengerDetails:state.travel.passengerDetails,
    passengerCountDetails:state.travel.passengerCountDetails,
    authToken:state.auth.authToken,
    authLoading:state.auth.loading,
    orderDetails:state.order.orderDetails,
    orderDetailsLoading:state.order.loading,
    currancyCode:state.countries.convertionRate.currency_code,
    errorFlag:state.order.errorFlag,
    getErrorMsg:state.order.err
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrderDetailsForTravel:(payload) =>dispatch(fetchOrderDetailsForTravel(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(BookingDetails)));
