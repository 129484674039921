import axios from "axios"
import secretKeyGenerator from "../../utils/serectKeyGenerator"
import { FETCH_BRAND_FAILURE, FETCH_BRAND_REQUEST, FETCH_BRAND_SUCCESS, 
    GET_BRAND_FAILURE, GET_BRAND_REQUEST, GET_BRAND_SUCCESS } from "./brandTypes"
import {cacheHeaders} from "../../utils/pointsConversion"
import { REACT_APP_baseUrl } from "utils/app-constants"


const API_URL = `${REACT_APP_baseUrl}`
export const fetchBrandRequest = () => {
    return {
        type:FETCH_BRAND_REQUEST,
    }
}

export const fetchBrandSuccess = data => {
    return {
        type:FETCH_BRAND_SUCCESS,
        payload:data
    }
}

export const fetchBrandFailure = error =>{
    return {
        type:FETCH_BRAND_FAILURE,
        payload:error
    }
}

export const fetchBrand = (payload) => {
    return (dispatch)=>{
        dispatch({type: FETCH_BRAND_REQUEST})
        dispatch(fetchBrandRequest);
        axios.post(`${API_URL}/brands/get_brand_details`,
            payload,
            {
                cacheHeaders,
                params:{
                    "secret_key":secretKeyGenerator(payload)
                }
            })
            .then(response  => {
                if(response.data.code === 200){

                    const brand = response.data?.data?.brand
                    dispatch(fetchBrandSuccess(brand))
                }
                else{

                    const errorMsg = response.data?.message
                    dispatch(fetchBrandFailure(errorMsg))
                }
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
                dispatch(fetchBrandFailure("Something went wrong!! Please try again!!"))
            })
    }
}

export const getBrandRequest = () => {
    return {
        type:GET_BRAND_REQUEST,
    }
}

export const getBrandSuccess = data => {
    return {
        type:GET_BRAND_SUCCESS,
        payload:data
    }
}

export const getBrandFailure = error =>{
    return {
        type:GET_BRAND_FAILURE,
        payload:error
    }
}

export const getBrandData = (payload) => {
    return (dispatch)=>{
        dispatch({type: GET_BRAND_REQUEST})
        dispatch(getBrandRequest);
        //https://run.mocky.io/v3/72b05a66-0e1d-496a-bf64-2d77054ff305
        axios.post(`${API_URL}/brands/get_brands`,
            payload,
            {
                cacheHeaders,
                params:{
                    "secret_key":secretKeyGenerator(payload)
                }
            })
            .then(response  => {

                if(response.data.code === 200){
                const brandData = response.data.data.categories

                dispatch(getBrandSuccess(brandData))
                            }
                else {
                    const errorMsg = response.data?.message
                    dispatch(getBrandFailure(errorMsg))
                    }
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
                dispatch(getBrandFailure("Something went wrong!! Please try again!!"))
            })
    }
}