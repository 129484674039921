import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { currancyToPoints } from "../../utils/pointsConversion";
import { ReactComponent as AlertSvg } from "../../assests/img/alert.svg";
import { ReactComponent as SmilySVG } from "../../assests/img/smily.svg";
import { Link } from "react-router-dom";

export class RemoveGoalItemModal extends Component {
  numberWithCommas = (x) => {
    let y = Number(x)?.toFixed(2);
    return y?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  render() {
    const {
      t,
      card,
      show,
      setModalView,
      removeGoalItem,
      processing,
      deletStatus,
      msg,
      confirmModal,
      showModal,
      failedModal,currancyCode
    } = this.props;
    return (
      <Modal
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
      >
        {!processing && confirmModal && (
          <div className="p-4 bg-light mx-auto text-center py-md-5 py-4 rounded">
            <h3 className="text-muted m-0 py-0">{t("Remove the Goal Item")}</h3>
            <div className="d-flex flex-row border p-2 m-2 mt-3">
              <div className="col-4 p-0">
                <img
                  className="img-fluid p-0 mx-auto d-block"
                  src={card?.images?.medium_rectangle}
                  alt={card?.product_name}
                />
              </div>
              <div className="col-8 d-flex flex-column text-left">
                <div className="fw-md-bold fw-bolder color-blue">
                  <h4>{ this.props.lang !== 'ar' ? card?.product_name : card?.product_name_arabic}</h4>
                </div>
                <div className="pb-2 mt-auto">
                  <span className="text-muted">{t("Card Value")}</span>:{" "}
                  <span className="text-dark fw-bold">
                    {t(card?.child_product_type === "PHYSICAL_GIFT_CARD" ? card?.currency :currancyCode)} {card?.product_price} (
                    {this.numberWithCommas(
                      card?.child_product_type === "PHYSICAL_GIFT_CARD" ? card?.product_point_price :currancyToPoints(card?.product_price)
                    )}
                    {t("Points")})
                  </span>
                </div>
              </div>
            </div>
            <button
              onClick={() => {
                setModalView();
              }}
              className="btn  border-color-blue mt-3 mx-2"
            >
              <span className="fw-bold color-blue">{t("Cancel")}</span>
            </button>
            <button
              className="btn primary-btn text-light border-color-green rounded mx-2 mt-3"
              onClick={() => {
                removeGoalItem();
              }}
            >
              {t("Confirm")}
            </button>
          </div>
        )}
        {processing && (
          <div className="p-4 bg-light mx-auto text-center py-md-5 py-4">
            <AlertSvg className="p-md-0 p-3" height="100" />
            <h3 className="text-muted m-0 py-md-3 py-0">
            {t("Processing Request")}
            </h3>
            <h5 className='text-muted'>{t("Please Wait")}</h5>
          </div>
        )}
        {deletStatus && (
          <>
            <div className="p-4 bg-light mx-auto text-center py-md-5 py-4">
              <SmilySVG className="p-md-0 p-3" height="100" />
              <h4 className="text-muted m-0 py-md-3 py-0">{t(msg)}</h4>
              <div className='d-flex justify-content-center'>
              <button
                className="btn primary-btn text-light border-color-green rounded mx-2 mt-3 d-none d-md-block"
                onClick={() => {
                  showModal();
                }}
              >
                {t("Continue")}
              </button>
              </div>
             <div className='d-md-none d-block'>
             <Link to='/goal-item' className='btn primary-btn text-light border-color-green rounded mx-2 mt-3 '>
               {t("Continue")}
              </Link>
             </div>
            </div>
          </>
        )}

        {
          failedModal &&
          <>
          <div className="p-4 bg-light mx-auto text-center py-md-5 py-4">
            <AlertSvg className="p-md-0 p-3" height="100" />
            <h3 className="text-muted m-0 py-md-3 py-0">
             {t(msg)}
            </h3>
            <h5 className='text-muted'>{t("Please try later")}</h5>
            <button
              onClick={() => {
                setModalView();
              }}
              className="btn  border-color-blue mt-3 mx-2"
            >
              <span className="fw-bold color-blue">{t("Close")}</span>
            </button>
          </div>
          </>
        }
      </Modal>
    );
  }
}
export default connect()(withTranslation()(RemoveGoalItemModal));
