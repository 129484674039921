import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter,Prompt } from "react-router";
import { addContactDetails } from "../../Redux/travelShop/TravelAction";
import RouteLeavingGuard from "../../utils/RouteLeavingGuard";
import TravelShopHOC from "./TravelShopHOC";
import TravelInputField from './TravelInputField';
import { fetchPhoneFormat } from "Redux/CountryCode/CountryCodeActions";

class ContactDetail extends Component {
    constructor(props){
        super(props);
        this.myRef = React.createRef();
        this.state={
            formFields:{
              first_name:{id:"first_name",title:"First Name",type:"text",value:"",required:true,tabIndex:"1",readOnly:false,ariaLabel:"Enter your first name",errorMessage:""},
              last_name:{id:"last_name",title:"Last Name",type:"text",value:"",required:true,tabIndex:"2",readOnly:false,ariaLabel:"Enter your last name",errorMessage:""},
                // country:{id:"country",title:"Country",type:"dropdown",value:"Saudi Arabia",required:true,disabled:true,tabIndex:"3",readOnly:false,ariaLabel:"select your country",errorMessage:"",options:["Saudi Arabia"],sideAlign:true},
                phone:{id:"phone",title:"Mobile Number",type:"tel",value:"",required:true,tabIndex:"3",readOnly:false,ariaLabel:"Enter Your Mobile Number",errorMessage:"",sideAlign:true,maxLength:9},
                email:{id:"email",title:"Email",type:"email",value:"",required:true,tabIndex:"4",readOnly:false,ariaLabel:"Enter your Email ID",errorMessage:"",sideAlign:true}
            },
            blockNavigation:true,
            phoneCode: ""
        }
    }

    componentDidMount(){
      this.myRef.current?.scrollIntoView({behavior: "smooth"})
      if(this.props.location?.state?.editParam || Object.keys(this.props.contactDetails)?.length !== 0){
        this.setEditValue()
      }

      const countryload = {
        "countries": {
            "country_name": this.props.selectedCountry
        }
    }

    this.props.fetchPhoneFormat(countryload,this.setPhoneCode)
    }

    setPhoneCode = (val) =>{
      this.setState({phoneCode:val})
  }

    componentDidUpdate(prevProps,prevState){
      if(this.props.location.pathname !== window.location.pathname){
        window.history.forward();
        if(this.state.blockNavigation){
          this.setState({blockNavigation:false})
        }
      }
      if(prevState.blockNavigation !== this.state.blockNavigation && !this.state.blockNavigation){
        this.setState({blockNavigation:true})
      }
    }

    setEditValue = () => {
      let updatedformFields = {...this.state.formFields}
      Object.keys(this.props.contactDetails).forEach(e => updatedformFields[e].value = this.props.contactDetails[e]);
      this.setState({formFields:updatedformFields})
    }
    setInputValue=(val,feild)=>{
      let updatedformFields = {...this.state.formFields};
      if(feild === 'phone'){
        updatedformFields[feild].value = val.replace(/\D/g, "");  
      }
      else if((updatedformFields[feild]?.type === 'text' || updatedformFields[feild]?.type === 'email') && val){
        let regforeng = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._,-]+$/g
        if(regforeng.test(val)){
            updatedformFields[feild].value = val;
        }
    }
      else{
        updatedformFields[feild].value = val
      }
      updatedformFields[feild].errorMessage = ""
      this.setState({
          formFields:updatedformFields
      })
  }

  validateForm=()=>{
    const {t}= this.props
       let valid = true;
       let updatedformFields = {...this.state.formFields}
       Object.values(this.state.formFields).forEach(feild=>{
           if(feild.value==="" && feild.id!=='companyName'){
               updatedformFields[feild.id].errorMessage=`${t(feild.title)}${t(" is missing")}`
               valid=false
           }
       });
       var re = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
       if (!re.test(updatedformFields['email']?.value)){
           updatedformFields['email'].errorMessage = "Invalid Email"
           updatedformFields['email'].value="";
           valid=false
       }

       if(updatedformFields['phone'].value?.length < 8){
        updatedformFields['phone'].errorMessage = "Invalid Number"
        updatedformFields['phone'].value="";
        valid=false
       }
       
       this.setState({
           formFields:updatedformFields
       })
       
       if(valid){
         this.setState({blockNavigation:false},()=>{
          let contactDetails = {};
          for ( var key in this.state.formFields ) {
            contactDetails[key] = this.state.formFields[key].value
          }
          this.props.addContactDetails(contactDetails);
          this.props.history.push({
            pathname:'/passenger-details/1',
            state:{editParam:this.props.location?.state?.editParam || false}
          })
         })
        
       }
   }

  render() {
    const {history , t,flightDetails} = this.props;
    return (
      <div ref={this.myRef}>
        <Prompt
          when={this.state.blockNavigation}
          message="Are you sure you want to leave?"
        />
      <TravelShopHOC 
      lang= {this.props.lang}
      childComponent ={
        <div className="bg-white p-4">
        <div className="">
          <h4 className="fw-bold color-blue">{t("Contact Details")}</h4>
          <p className="color-red fw-bold">
            {t("This is where your e-ticket will be sent")}
          </p>
        </div>
        {Object.values(this.state.formFields).map((f) => (
          <TravelInputField phoneCode={this.state.phoneCode} data={f} key={f.id} onChange={this.setInputValue} lang={this.props.lang} />
        ))}

        <div className="row mt-4 justify-content-end">
          <button onClick={this.validateForm} className="btn btn-sky rounded-0 mx-3 d-md-block d-none" tabIndex={'5'}>
            {t("Save and Continue")}
          </button>
        </div>
        <div className="row mt-4">
       {flightDetails?.fare_rules?.[0]?.details && <ul className='list-unstyled p-4 overflow-auto d-block d-md-none custom-scrollbar' style={{height:160}}>

{
   flightDetails?.fare_rules?.map((val,ind)=>{
    return(
      <>
                  <div className='d-flex' key={ind}>
                    <span className='fw-bold'>{t("Flight Number")}</span> 
                    <span className='text-bold ml-2'> - {val.flight_number}</span> 
                  </div>
          {
            
            val.details?.endsWith("\n") && val.details?.substring(0, val.details?.length -2)?.split("\n").map((val2)=>{
              return(
                <>
                  <li className='text-muted small'>● {val2}</li>
                </>
              )
            })  
          }
          {
            !val?.details?.endsWith("\n") && val?.details?.split("\n").map((val3)=>{
              return(
                <>
                  <li className='text-muted small'>● {val3}</li>
                </>
              )
            })
          }
      </>
    )
  })
}
        </ul> }
          <button onClick={this.validateForm} className="btn btn-sky rounded-0 mx-3 d-md-none d-block w-100">
            {t("Save and Continue")}
          </button>
        </div>
      </div>
      }
      
      />
      </div>

    );
  }
}

const mapStateToProps = state => {
  return {
      contactDetails:state.travel.contactDetails,
      flightDetails:state.travel.selectedFlightDetail?.search?.data,
      selectedCountry:state?.countries?.selectedCountry?.countryName,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addContactDetails:(details) => dispatch(addContactDetails(details)),
    fetchPhoneFormat : (payload,setPhoneCode) => dispatch(fetchPhoneFormat(payload,setPhoneCode)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(ContactDetail)));
