import React, { Component } from "react";
import Password from "../../assests/img/Login/password.svg";
import { ReactComponent as ResetPassword } from "../../assests/img/Login/reset-password.svg";
import { ReactComponent as PasswordChanged } from "../../assests/img/Login/password-changed-successfully.svg";
import { ReactComponent as TokenExp } from "../../assests/img/Login/token-expired.svg";
import { ReactComponent as TokenInvalid } from "../../assests/img/Login/token-invalid.svg";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ForgetPassSuccess } from "Redux/ForgetPassword/ForgetPassAction";
import ModalWrapper from "components/shared/Modals/ModalWrapper";
import { ReactComponent as Cancel } from "../../assests/img/Login/cancel-btn.svg";

export class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: "",
      mismatch: false,
      ErrorMsg:""
    };
  }

  handlePassword = () => {
    const validPassword = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$");
    if(this.state.password === "" || this.state.confirmPassword === ""){
      this.setState({ErrorMsg:"Empty"})
    }else if(!validPassword.test(this.state.password)){
      this.setState({ErrorMsg:"Invalid"})
    }else if (this.state.password !== this.state.confirmPassword) {
      this.setState({ mismatch: true, ErrorMsg:"" });
    } else {
      this.setState({ mismatch: false, ErrorMsg:"" });
      const token = this.props.match.params.token;
      this.props.ForgetPassSuccess(this.state.password, this.state.confirmPassword, token);
    }
  };

  render() {
    const { lang, t } = this.props;

    const { password, confirmPassword, mismatch, ErrorMsg } = this.state;

    const Loader = () => {
      return (
        <div className="d-flex justify-content-center pt-2 pb-2">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    };

    return (
      <>
        <div className="forget-password-modal">
          <div className="bg-white mx-auto LoginModel border my-4">
            <div className="text-center pt-3">
              <ResetPassword />
              <h1
                className="btnTxt fw-bolder mb-1 mt-2"
                style={{ fontSize: "20px" }}
              >
                {t("Create new Password")}
              </h1>
              <p className="fs-7" style={{ color: "black" }}>
                {t(
                  "Your new password must be different from the previous used password"
                )}
              </p>
              <div className="d-flex flex-column LoginIcon">
                <div className="my-1 position-relative">
                  <img
                    src={Password}
                    alt="user"
                    className={`img-fluid position-absolute ${
                      lang === "ar" ? "img2" : ""
                    }`}
                  />
                  <input
                    className={`w-100 login-input outline-color-skyblue ${
                      lang === "ar" ? "input2" : ""
                    }`}
                    type="password"
                    name="password"
                    value={password}
                    placeholder={t("Enter password")}
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                  />
                </div>
                <div className="my-2 position-relative">
                  <img
                    src={Password}
                    alt="password"
                    className={`img-fluid position-absolute ${
                      lang === "ar" ? "img2" : ""
                    }`}
                  />
                  <input
                    className={`w-100 login-input outline-color-skyblue ${
                      lang === "ar" ? "input2" : ""
                    }`}
                    type="password"
                    name="Confirmpassword"
                    value={confirmPassword}
                    placeholder={t("Confirm password")}
                    onChange={(e) =>
                      this.setState({ confirmPassword: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="text-center mb-2">
              {
                ErrorMsg === "Empty" ? <p className="text-danger start-text mb-1">{t("Please fill all the fields")}</p> : 
                ErrorMsg === "Invalid" ? <p className="text-danger start-text">{t("Password must contain at least one (upper case and lower case letter, digit, special character) and min 8 characters")}</p> :
                mismatch ? <p className="text-danger start-text mb-0">{t("Password Mismatch")}</p> : null
              }
              <button
                className="btn btnTextDark text-light w-100 "
                disabled={this.props.loading}
                onClick={this.handlePassword}
              >
                {this.props.loading ? <Loader /> : t("Change Password")}
              </button>
              <br />
            </div>
          </div>
        </div>

        <ModalWrapper show={this.props.passwordChanged}>
          <div className="bg-white mx-auto LoginModel">
            <div
              className={`position-relative d-md-block d-none ${
                lang === "ar" ? "cross3" : "cross1"
              }`}
            >
              <a href="/">
                <Cancel/>
              </a>
            </div>
            <div
              className={`position-relative d-md-none d-block ${
                lang === "ar" ? "cross4" : "cross2"
              } `}
            >
              <a href="/">
                <Cancel/>
              </a>
            </div>
            <div className="text-center">
              {this.props.passwordChanged === "Password updated successfully!" ? (
                <PasswordChanged />
              ) : this.props.passwordChanged === "Invalid token. Please resend password reset instructions and try again." ? (
                <TokenInvalid />
                ) : (
                <TokenExp />
              )}
              <h1
                className="btnTxt fw-bolder mb-1 mt-2"
                style={{ fontSize: "18px" }}
              >
                {this.props.passwordChanged === "Password updated successfully!"
                  ? t("Password Changed Successfully")
                  : this.props.passwordChanged === "Invalid token. Please resend password reset instructions and try again."
                  ? t("Invalid token")
                  : t("Token expired")}
              </h1>
            </div>
          </div>
        </ModalWrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state?.ForgetPassReducer?.loading,
    passwordChanged: state?.ForgetPassReducer?.message,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ForgetPassSuccess: (pass, confirmPassword, token) =>dispatch(ForgetPassSuccess(pass, confirmPassword, token)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ForgetPassword));
