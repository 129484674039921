import React, { Component } from 'react';
import ModalWrapper from './ModalWrapper';
import warningIcon from "../../../assests/img/warning-icon.svg";
import { withTranslation } from 'react-i18next';
import Loader from '../Loader';

class RedeemConfirmationModal extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            show:true
         }
    }

 
    render() { 
        const {t,closeModal,show,onProceed,loading}=this.props
        return ( 
            <ModalWrapper show={show}>
                <div 
                    className="p-4 bg-light mx-auto text-center py-md-5 py-4" 
                    style={{width:'35rem',borderRadius:'20px'}}>
                    <img 
                        className="p-md-0 p-3"
                        height="100"
                        src={warningIcon} alt="" /> 
                    <h3 className='fw-bold m-0 py-md-3 py-0'>
                    {t("Are you sure?")}
                    </h3> 
                    <p style={{fontSize:'1em'}} className='fw-bold py-3 m-0 py-0'>
                    {t("By clicking Yes, your reward will be converted to RewardsBy points and the balance will be redeemed. This cannot be undone.")}
                    </p> 
                    {
                        loading ? <Loader/> : <div>
                        <button className="btn mt-3 mx-2 py-2" style={{backgroundColor:'#B2B2C9',color:'white',width:'8em'}}
                            onClick={()=>{closeModal()}}>
                            <span style={{fontSize:'16px',color:'white'}} className="fw-bold">{t("Cancel")}</span></button>
                        <button style={{backgroundColor:'#00B2AE',color:'white',width:'8em'}} className="btn mt-3 mx-2 py-2" onClick={onProceed}><span className="fw-bold">{t("Ok")}</span></button>
                        </div>
                    }
                </div>
            </ModalWrapper>
         );
    }
}

export default  withTranslation()(RedeemConfirmationModal)