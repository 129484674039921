import React, { Component } from 'react';
import { connect } from 'react-redux';
// import ProductCards from '../ProductCards';
import ProductTable from '../ProductTable';
import SearchBar from '../SearchBar';

class AllSection extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            searchFilter:"",
            dropdownFilter:"ALL",
            isLoaded:true
         }
    }
    setDropdownFilter = (val) =>{this.setState({dropdownFilter:val})}
    setFilter=(val)=>{this.setState({searchFilter:val})}
    searchFilter=(list)=>{
        
       return  list && list
                .filter(em=>
                    (this.props.lang !== "ar" ? em.brand_name.match(new RegExp(`${this.state.searchFilter.replace(/[^0-9a-zA-Z\u0600-\u06FF\s]/gi, "")}`,"ig")) :
                    em.brand_name_arabic.match(new RegExp(`${this.state.searchFilter.replace(/[^0-9a-zA-Z\u0600-\u06FF\s]/gi, "")}`,"ig"))

                    &&
                    (this.state.dropdownFilter==="ALL"?
                        true:
                        this.state.dropdownFilter===em.order_status || this.state.dropdownFilter=== em.status)
                    ))
    }
    // componentDidUpdate(){
    //     if(!this.props.loading && this.state.isLoaded){

    //         setTimeout(() => {this.setState({isLoaded: false})}, 100);
    //     }
    // }
    componentDidMount(){
        setTimeout(() => {this.setState({isLoaded: false})}, 100);
        
    }
    render() { 
        const {dropdownFilter} = this.state;
        const {modulesData} = this.props
        
        return ( 

            <>
           { this.state.isLoaded ? <section>
                <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4 ">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
        </section> :
            <section>
                <SearchBar 
                    sectionName="All" 
                    onChange={this.setFilter} 
                    dropdownFilter={dropdownFilter} 
                    setDropdownFilter={this.setDropdownFilter}
                    dropdownItems = {["ALL","CONFIRMED","FULFILLED","REFUNDED","CANCELED"]}
                />
                <div>
                    <>
                        {
                            this.props.giftCardOrders?.length >0 && modulesData["Gift Cards"] &&  <ProductTable type='GiftCards' lang={this.props.lang} headers={['Digital Gift cards']} rows={this.searchFilter(this.props.giftCardOrders)}/>
                        }
                        {
                            this.props.physicalGiftcardsOrders?.length >0 && modulesData["Physical Giftcards"] && <ProductTable type='physicalGiftcards' lang={this.props.lang} headers={['Physical Gift cards']} rows={this.searchFilter(this.props.physicalGiftcardsOrders)}/>
                        }
                       {
                           this.props.offersOrders?.length >0 && modulesData["RewardsBy Offers"] &&  <ProductTable type='Offers' lang={this.props.lang} headers={['Offers']} rows={this.searchFilter(this.props.offersOrders)}/>
                       }
                       {
                           this.props.merchantOrders?.length >0 && modulesData["RewardsBy Gifts"] && <ProductTable type='merchandise' lang={this.props.lang} headers={['RewardsBy Gifts']} rows={this.searchFilter(this.props.merchantOrders)}/>
                       }
                        {
                            this.props.eMagazineOrders?.length >0 && modulesData["E-Magazines"] &&
                            <ProductTable type='GiftCards' lang={this.props.lang} headers={['E-magazines']} rows={this.searchFilter(this.props.eMagazineOrders)}/>
                        }
                          {
                            this.props.pointsExchnageOrders?.length >0 && modulesData["Points Exchange"] &&
                            <ProductTable lang={this.props.lang} headers={['Point Exchange']} rows={this.searchFilter(this.props.pointsExchnageOrders)}/>
                        }    
                          {
                            this.props.experienceOrders?.length >0 && modulesData["Experiences"] &&
                            <ProductTable type='Experience' lang={this.props.lang} headers={['Experiences']} rows={this.searchFilter(this.props.experienceOrders)}/>
                        }    
                       {
                            this.props.mobileTopUpOrders?.length >0 && modulesData["Mobile TopUp"] && 
                            <ProductTable lang={this.props.lang} headers={['Mobile Topup']} rows={this.searchFilter(this.props.mobileTopUpOrders)}/>
                        }    
                        {
                            this.props.softwareGamesOrders?.length >0 && modulesData["Software&Games"] && 
                            <ProductTable lang={this.props.lang} headers={['Software & Games']} rows={this.searchFilter(this.props.softwareGamesOrders)}/>
                        }  
                         
                        {/* <ProductTable lang={this.props.lang} headers={['Received Gifts']} />    */}
                               
                    </>
                </div>
            </section>
              }  
            </>
         );
    }
}
const mapStateToProps = state => {
    return {
        eMagazineOrders:state.myWallet.eCardEMagOrders.filter(order=>(order.emagazine_flag===1)),
        giftCardOrders:state.myWallet.eCardEMagOrders.filter(order=>(order.order_type==='USER_ORDER' && order.emagazine_flag===0)),
        offersOrders:state.myWallet.offersOrders,
        experienceOrders:state.myWallet.experienceOrders,
        pointsExchnageOrders:state.myWallet.eCardEMagOrders.filter(order=>(order.order_type==="POINTS_EXCHANGE")),
        merchantOrders:state.myWallet.merchantOrders,
        mobileTopUpOrders:state.myWallet.mobileTopUpOrders,
        loading:state.myWallet.loading,
        softwareGamesOrders:state.myWallet.softwareGamesOrder,
        modulesData:state.categories.moduleInfo,
        physicalGiftcardsOrders: state.myWallet.physicalGiftcardsOrders,
    }
}

export default connect(mapStateToProps)(AllSection);