/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import Breadcrumbs from '../shared/Breadcrumbs';
import BuyNowCardGiftSection from '../shared/BuyNowCardGiftSection';
import { connect } from 'react-redux';
import { currancyToPoints, pointsToCurrancy } from '../../utils/pointsConversion';
import { removeOrder } from '../../Redux/order/orderActions'
import { fetchTermsAndConditions } from '../../Redux/offers/offerActions';
import {fetchProductsDetails} from '../../Redux/Merchandise/merchandiseActions';
import { withTranslation } from 'react-i18next';
import ProducDetailsImages from './ProductDetailsImages';
import ProducDetailsImageSelected from './ProductDetailsImageSelected';
import ProducDetailsContent from './ProductDetailsContent';
import Line from './Line';
import Variants from './Variants';
import './HassadProductDetailsPage.css';
import MerchandiseConfirmationModal from '../shared/Modals/MerchandiseConfirmationModal';
import { fetchMerchandiseTerms } from '../../Redux/Merchandise/merchandiseActions';
import TermsCon from '../shared/MerchandiseT&C'
import { checkTokenValidity } from '../../Redux/auth/authActions';
import  GoalItemConfirmModal  from '../GoalItems/GoalItemConfirmModal';
import GoalItemStatusModal from '../GoalItems/GoalItemStatusModal';
import { setGoalItem } from '../../Redux/goalItems/goalItemsActions';
import goalItemImage from '../../assests/img/goalitems/icon-change-goal-item.svg'
class GiftPage extends Component {
    constructor(props) {
        super(props);

        this.total_cost = '';
        this.parentKey = '';
        this.childKey = '';
        this.selectedParentTitle = '';
        this.sku = '';
        this.state = {
            confirmOrder: false,
            orders: {},
            newOrders: {},
            images: [],
            imageSelected: null,
            variantsData: {},
           total_cost : this.total_cost,
            selectedParentTitle : this.selectedParentTitle,
            setDefault : false,
            color:" ",
            Memory:" ",
            quantity:1,
            cardPayment:false,
            goalModalShow:false,
            goalStatusModalShow:false,
            goalModalDetail:''
        }
    }

    numberWithCommas = (x) => {
        // let y = x.toFixed(0)
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    numberWithCom = (val) =>{
        let x = val.toString()
        return x
    }
    componentDidMount = () => {
        localStorage.removeItem("type");
       localStorage.setItem("type","merchandise");
     window.scrollTo(0, 0);
        const payload =
        {
            "merchant_product": {
                "product_id": this.props.match.params.id,
                "currency": this.props.countrySelected
            }
        }
        this.props.checkTokenValidity() 
        this.props.fetchProductsDetails(payload)
        this.props.removeOrder();
        this.props.fetchMerchandiseTerms(payload)
        // if( !this.props.loading && this.props.prodDetails?.length === 0){
        //     this.props.history.replace("/404")         
        // }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if ((this.props.match.params.id !== prevProps.match.params.id) || (prevProps.currancyCode !== this.props.currancyCode)) {
            const payload =
        {
            "merchant_product": {
                "product_id": this.props.match.params.id,
                "currency": this.props.countrySelected
            }
        }
        this.props.fetchProductsDetails(payload)
        }
        if (this.state.imageSelected === null && this.props.prodImages && this.props.prodImages.length > 0) {
            this.setState({
                images: this.props.prodImages && this.props.prodImages?.map(img => img.image?.small_rectangle),
                imageSelected: this.props.prodImages && this.props.prodImages?.filter(val => val.default_image_flag === true)[0].image?.small_rectangle
            })
        }
        
        if(!this.props.loading && !this.state.setDefault && this.props.prodVariants){
            this.initVariant();
            this.updateVariant(this.selectedParentTitle );
            this.setState({
            setDefault : true
        })
    }

        if(prevProps.prodImages != this.props.prodImages){
            this.setState({
                images: this.props.prodImages && this.props.prodImages?.map(img => img.image?.small_rectangle),
                imageSelected: this.props.prodImages && this.props.prodImages?.filter(val => val.default_image_flag === true)[0]?.image?.small_rectangle
            })
        }

        if(prevProps.prodDetails != this.props.prodDetails){
            this.initVariant();
            this.updateVariant(this.selectedParentTitle );
        this.setState({
            setDefault : true
        })
        // if(this.props.countrySelected!= 2){
        //     this.props.history.replace("/404")
        // }
    }
    if( !this.props.loading && this.props.prodDetails?.length === 0){
        this.props.history.push({
            pathname:'/404',
            redirectionURL:'/gifts'
        })        
    }
}

initVariant() {
    this.variantsData = {};
    this.props.prodDetails && this.props.prodDetails.prices && this.props.prodDetails["prices"].forEach((item, i) => {
         this.total_cost = this.props.prodDetails["prices"][0].total_cost
         this.sku = this.props.prodDetails["prices"][0].sku
        if(item["variants"] != null)
        for (const [key, value] of Object.entries(item["variants"])) {
            if (key !== 'color_code') {
                if (this.parentKey === '') {
                    this.parentKey = key;
                    this.selectedParentTitle = value;
                } else if (this.childKey === '') {
                    this.childKey = key;
                } 
                if (!this.variantsData[key]) {
                    this.variantsData[key] = {};
                    this.variantsData[key]["items"] = [];
                    this.variantsData[key]["selectedTitle"] = "";

                }
                if (this.variantsData[key]["items"].length == 0) {
                    this.variantsData[key]["selectedTitle"] = value;
                }
                if (!(this.variantsData[key]["items"].findIndex(x => x.title === value) > -1)) {
                    this.variantsData[key]["items"].push({
                        title: value,
                        selected: (this.variantsData[key]["items"].length == 0 && this.parentKey === key ? true : false),
                        disableOrHide: this.parentKey !== key,
                        color: (key === "Color") ? item["variants"].color_code : null,
                    });
                }
            }
        }
    });
    this.setState({
        variantsData : this.variantsData
    })
}

updateVariant(selectedParentTitle) {
    this.props.prodVariants && this.variantsData && Object.keys(this.variantsData).length >0 && this.variantsData[this.childKey]["items"].forEach((item, i) => {
    

        item.selected =false;
        item.disableOrHide  = true;


       });
       let  makeFirstValueSlected = true;
       this.props.prodDetails && this.props.prodDetails.prices &&  this.props.prodDetails["prices"].forEach((item, i) => {
            if(item["variants"] != null)
       if( item["variants"][this.parentKey] === selectedParentTitle) {
              let  val =    item["variants"][this.childKey] ;
             let  variant =this.variantsData[this.childKey]["items"].find(x=>x.title === val) ;
             if(variant) {
                variant.disableOrHide  = false;
                if(makeFirstValueSlected) {
                    // update price here  
                    this.total_cost = item.total_cost 
                    this.sku = item.sku
                    variant.selected =true;
                    this.variantsData[this.childKey].selectedTitle = val;
                    makeFirstValueSlected = false;
                }
                this.setState({
                    color : this.variantsData[this.parentKey].selectedTitle,
                    Memory : this.variantsData[this.childKey].selectedTitle
                })
             }

       }
       
    });
    
}

    orderSummary = (val,...props) => {

        const points = this.props.prodDetails?.prices[0].total_cost //Currancy
        const sku = this.props.prodDetails?.prices[0].sku
        let amount_points = currancyToPoints(this.total_cost * this.state.quantity) //Points
        let paymentType = props[0]?.paymentType
        paymentType === 'creditcard' ? this.setState({cardPayment:true}) : this.setState({cardPayment:false})

        this.setState({
            confirmOrder:val,
            
                orders: {
                  "sku": sku,
                  "quantity": this.state.quantity,
                //   "is_gift": props[0]?.isGift,
                  "isforself": !props[0]?.isGift,
                  "use_hassad_points":paymentType === 'creditcard' ? false : this.props.pointBalance>0,
                  "hassad_points": paymentType === 'creditcard' ? 0 : Number(amount_points) <= Number(this.props.pointBalance) ? amount_points: Number(this.props.pointBalance),
                  "language_code": this.props.lang,
                  "amount":this.total_cost * this.state.quantity,
                  "currency":this.props.countrySelected
                }
              
        
        })
    }

    onSelectedMemory = (v) => {
        let variant = this.variantsData[v.key];
        variant.selectedTitle = v.title;
        variant.items.forEach((item) => {
            item.selected = false;
            if (item.title === v.title) {
                item.selected = true;
            }
        });
        if(this.parentKey === v.key) {
         
           this.selectedParentTitle = v.title;
       
           this.updateVariant(v.title );
        } else {
           
              this.props.prodDetails && this.props.prodDetails["prices"].forEach((item, i) => {
            
    
               if( item["variants"][this.parentKey] === this.state.selectedParentTitle &&
               item["variants"][this.childKey] === v.title
               ) {
                                            
                            // update price here  
                            this.total_cost = item.total_cost
                            this.sku = item.sku 
                       
                     }
    
               
               
            });
        }

        this.setState({
            variantsData: this.variantsData,
            total_cost:this.total_cost,
            selectedParentTitle: this.selectedParentTitle,
            color : this.variantsData[this.parentKey].selectedTitle,
            Memory : this.variantsData[this.childKey].selectedTitle
        });
    }


    onSelectedImage = (img) => {
        
        this.setState({
            imageSelected: img && img.image
        });
    }

    setQuantity=(val)=>{
        this.setState({quantity:val})
    }

        // Goal Item
        setGoalModalView = (value)=>{
            this.setState({goalModalShow: !this.state.goalModalShow,goalModalDetail:value || this.state.goalModalDetail})
        }
        setGoalConfirmModalView = ()=>{
            this.setState({goalConfirmModalShow: !this.state.goalConfirmModalShow})
        }
    
        setGoalStatusModalView = () => {
            this.setState({goalStatusModalShow:false})
        }
    
        setGoalItem = (date) => {
            let payload = {
                "customer_goal_item": [{
                    "product_id": this.props.match?.params?.id,
                    "product_type": "MERCHANT_PRODUCT",
                    "product_price": this.total_cost,
                    "goal_item": this.state.goalModalDetail === 'Remove' ? false : true,
                    "product_storage": this.state.Memory,
                    "product_color": this.state.color,
                    "remind_me_later_date": date ? new Date(date)?.toISOString()?.slice(0,10) : ''
                }]
            }
    
            let updatePayload = {
                "customer_goal_items": {
                    "product_id": this.props.match?.params?.id,
                    "product_type": "MERCHANT_PRODUCT",
                    "product_price": this.total_cost,
                    "product_storage": this.state.Memory,
                    "product_color": this.state.color,
                    "remind_me_later_date": date ? new Date(date)?.toISOString()?.slice(0,10) : ''
                }
            }
    
            const fetchExp = () => {
                const payload =
            {
                "merchant_product": {
                    "product_id": this.props.match.params.id,
                    "currency": this.props.countrySelected
                }
            }
            this.props.fetchProductsDetails(payload)
            }
    
            this.props.setGoalItem(
                this.state.goalModalDetail === 'Update' ? updatePayload : payload,
                fetchExp,
                this.state.goalModalDetail);
            this.setState({goalStatusModalShow:true})
        }

    render() {
        const goalItemStyle = {
            backgroundImage: `url(${ goalItemImage })` ,
             backgroundRepeat : 'no-repeat',
                 backgroundPosition: '10%' , 
          };
        let touchstartX = 0;
        let touchendX = 0;
        let availabePoints = this.props.pointBalance || 0
        let missingPoints = currancyToPoints(this.total_cost * this.state.quantity) - availabePoints
        function handleGesture() {
            if (touchendX < touchstartX) {document.getElementsByName('HassadCardNext')[0].click()} ;
            if (touchendX > touchstartX) {document.getElementsByName('HassadCardPrev')[0].click()} ;
            }
        const { t } = this.props
        const breadcrumbs = [
            { name: t("Home"), url: '/', active: true },
            { name: t("Gifts"), url: `/gifts`, active: true },
            // { name: this.props.prodDetails && this.props.prodDetails.product_name, url: '', active: false }
            {name:this.props.lang!=='ar'?this.props.prodDetails && this.props.prodDetails.product_name: this.props.prodDetails && this.props.prodDetails.product_name_arabic,url:'',active:false}
        ]
        const { confirmOrder, orders, selectedMemory, selectedColor, imageSelected, variantsData } = this.state;
        const variantsDataDetails = (Object.keys(variantsData).map(key =>
            <Variants items={variantsData[key]} title={key} onSelected={this.onSelectedMemory} ></Variants>
        ));

        const desktopView = (
            <div>
          
            <div className="d-md-block d-none container-fluid p-0 pb-5">
                <div className="d-flex">
                    <div className="w-100">
                        {typeof this.props.prodDetails === 'undefined' ?
                            <section>
                                <div class="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4">
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </section> :
                            <section>
                                <Breadcrumbs crumbs={breadcrumbs} />
                                    {
                                        confirmOrder ? <MerchandiseConfirmationModal orders={this.state.orders} card={this.props.prodDetails} show={confirmOrder} remove={this.orderSummary} lang={this.props.lang} cardPayment={this.state.cardPayment}/> :
                                    
                                <div className="hassad-Product-details-page row">
            
                                    <div className="hassad-Product-details-images col-1 d-flex justify-content-end">
                                       {this.state.images && <ProducDetailsImages images={this.state.images} selected={imageSelected} onSelected={this.onSelectedImage} lang={this.props.lang} ></ProducDetailsImages>}
                                    </div>

                                    <div className="hassad-Product-details-image-selected col-3">
                                       {this.state.imageSelected &&  <ProducDetailsImageSelected image={imageSelected} lang={this.props.lang}></ProducDetailsImageSelected> }
                                    </div>

                                    <div className="hassad-Product-details-content col-4">
                                        <ProducDetailsContent {...this.props.prodDetails} variantsData={this.state.variantsData} total_cost={this.total_cost * this.state.quantity} lang={this.props.lang} pointBalance={this.props.pointBalance} value={this.state}></ProducDetailsContent>    
                                        <Line></Line>
                                                     {
                    variantsDataDetails
                }
                <br/>

                                          <TermsCon lang={this.props.lang} />
                                    </div>

                                    <div className="hassad-Product-details-shipping col-3 d-flex justify-content-center">
                                        <BuyNowCardGiftSection setQuantity={this.setQuantity} onSubmit={this.orderSummary} price={(this.total_cost * this.state.quantity)>1 ? true : false} lang={this.props.lang} setGoalModalView={this.setGoalModalView}
                                        productID = {this.props.match?.params?.id}
                                        selectedRate = {this.total_cost}
                                        toogleLang={this.props.toogleLang}
                                        />
                                    </div>

                                </div>
    }
                            </section>
                        }
                    </div>
                </div>
            </div>
            
          
            </div>
            );

        const mobileView = (
            <>
          
            <div className="d-block d-md-none container-fluid px-0 py-3 mb-2" style={{ zIndex: '1500', bottom: '4rem' }}>
              {this.props.prodDetails &&
              confirmOrder ? <MerchandiseConfirmationModal orders={this.state.orders} card={this.props.prodDetails} show={confirmOrder} remove={this.orderSummary} lang={this.props.lang} cardPayment={this.state.cardPayment}/> :
              
              <div className="d-flex">
                    <div className="w-100 mobile-view-gifts">
                        <div class="pb-3">
                        <div class="fs-medium fw-bold color-blue">
                            { this.props.lang !== 'ar' ?this.props.prodDetails.product_name:this.props.prodDetails.product_name_arabic } 
                            <span className="pl-3">{this.state.Memory && this.state.Memory} </span>
                            <span className="pl-2">{this.state.color && this.state.color}</span>
                            {this.props.prodDetails?.goal_item_flag && <div style={goalItemStyle} className='goalItemSVG text-light text-center p-1 mt-1'><p className='ml-3'>{t("Goal Item")}</p></div>}
                                </div>
                                {/* <div class="fs-4  color-blue">
                                (16GB, Red) [Locked] + Carrier Subscription
                                </div> */}
                                {/* <div class="fs-5 fw-bold text-black-50 pb-2">
                                {t("by")}:  { this.props.lang === 'en' ?this.props.prodDetails.brand_name:this.props.prodDetails.brand_name_arabic }
                            </div> */}
                            <div  class="fs-medium fw-bold text-dark justify-content-start">
                            {this.numberWithCommas(currancyToPoints(this.total_cost * this.state.quantity))} <small> {t("Points")}</small>
                            </div>
                        </div>
                        <div onTouchStart={(e)=>{touchstartX = e.changedTouches[0].screenX;}} onTouchEnd={(e)=>{touchendX = e.changedTouches[0].screenX;
  handleGesture();}} id="carouselMobHassadIndicators" data-interval='3000' className="carousel slide text-center d-md-block d-lg-none mb-2 pb-2" data-ride="carousel">
                            <div className='d-sm-inline-block pt-4 mt-4'>
                                <ol className="indicator-size carousel-indicators mb-2">

                                    {
                                        this.state.images && this.state.images.map((e, index) =>
                                            <li  data-target='#carouselMobHassadIndicators' className={`${index === 0 && `active`}`} data-slide-to={index}></li>
                                        )
                                    }

                                </ol>
                            </div>

                            <div className="carousel-inner">
                                {this.state.images && this.state.images.map((banner, index) =>
                                    <div key={index} className={`${index === 0 && `active`} carousel-item`}>
                                        <img src={banner} alt='' />
                                    </div>)}

                            </div>
                            <div className="d-md-block d-none">
                                <a className="carousel-control-prev" name='HassadCardPrev' href="#carouselMobHassadIndicators" role="button" data-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a className="carousel-control-next" name='HassadCardNext' href="#carouselMobHassadIndicators" role="button" data-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </div>


                        </div>
                      
                           
                            <div className=" mobile-view-gift-details fs-medium ml-0">
                               { missingPoints>0 && 
                        <>
                            <span class="pt-title">{this.numberWithCom(missingPoints)} {t("Points")} ({this.numberWithCom(pointsToCurrancy(missingPoints))} SAR) </span>
                       <span class="text-muted ">{t("to be paid by Credit Card")}
                       </span>
                        </>
                       }
                       </div>  
                       
                        {
                            this.props.prodDetails.expiration_date && <div className="mobile-view-gift-valid-date fs-5">
                            <span class="text-muted">Valid Until: </span>
                            <span class="text-muted">{this.props.prodDetails.expiration_date}
                            </span>
                            </div>
                        }
                        <Line></Line>
                        {
                    variantsDataDetails
                }
               
               <div className='py-3 mt-3'>
                <TermsCon lang={this.props.lang} />
                </div>
                    <div className="hassad-Product-details-shipping">
                        <BuyNowCardGiftSection onSubmit={this.orderSummary} setQuantity={this.setQuantity} price={(this.total_cost * this.state.quantity)>1 ? true : false} lang={this.props.lang} setGoalModalView={this.setGoalModalView}
                                        productID = {this.props.match?.params?.id}
                                        selectedRate = {this.total_cost}
                                        />
                    </div>
               
                    </div>
                </div>
            }  
            </div>
    
    </>
            );

        return (
         <>
         <GoalItemStatusModal goalModalDetail={this.state.goalModalDetail} show={this.state.goalStatusModalShow} setGoalModalView={this.setGoalStatusModalView} />
           { this.props.loading ? 
                <section>
                    <div class="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </section> 
                :
            <div>
                <GoalItemConfirmModal goalModalDetail={this.state.goalModalDetail} setGoalModalView={this.setGoalModalView} show={this.state.goalModalShow} card={this.props.prodDetails} lang={this.props.lang} productValue={this.total_cost} currancyCode={this.props.currancyCode} setGoalItem={this.setGoalItem} image={this.props.prodDetails?.image?.medium_rectangle}
                title={this.props.prodDetails?.product_name}
                title_arabic={this.props.prodDetails?.product_name_arabic}
                        />
                {desktopView}
                {mobileView}
            </div>
    }
         </>
        )
    }
}
const mapStateToProps = state => {
    return {
        prodDetails: state.merchandise.prodDetails,
        // loading : state.merchandise.loading,
        prodImages: state.merchandise.prodImages,
        prodVariants: state.merchandise.prodVariants,
        termsAndConditions: state.offers.termsAndConditions,
        loading: state.merchandise.detailsLoading,
        pointBalance: state.point.pointBalance,
        countrySelected:state.countries.countryId,
        currancyCode:state.countries.convertionRate.currency_code,
        merchandiseTerms:state.merchandise.merchandiseTerms,
        fetchError:state.merchandise.error
    }
}


const mapDispatchToProps = dispatch => {
    return {
        fetchProductsDetails: (payload) => dispatch(fetchProductsDetails(payload)),
        removeOrder: () => dispatch(removeOrder()),
        fetchTermsAndConditions: (payload) => dispatch(fetchTermsAndConditions(payload)),
        fetchMerchandiseTerms: (payload) => dispatch(fetchMerchandiseTerms(payload)),
        checkTokenValidity : () => dispatch(checkTokenValidity()),
        setGoalItem: (payload,productAPI,status) => dispatch(setGoalItem(payload,productAPI,status))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GiftPage));
