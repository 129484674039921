import React, { Component } from 'react';
import ModalWrapper from './ModalWrapper';
import alertSvg from '../../../assests/img/alertt.svg';
import { withTranslation } from 'react-i18next';

class ProcessingModal extends Component {

    render() { 
        const {t}= this.props
        return ( 
            <ModalWrapper show={this.props.show}>
                <div 
                    className="px-4 bg-light mx-auto text-center py-md-5 py-4" 
                    style={{width:'35rem'}}>
                    <img 
                        className="p-md-0 p-3"
                        height="100"
                        src={alertSvg} alt="alert" /> 
                    <div className='text-muted m-0 py-md-3 py-0 fs-md-3 fs-4 fw-bold'>
                    {t("Payment Processing")}
                    </div> 
                    <div className='text-muted m-0'>
                        {t("Please do not refresh the page!")}
                    </div>      
                </div>
            </ModalWrapper>
         );
    }
}
 
export default (withTranslation()  (ProcessingModal));