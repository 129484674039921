import React, { Component } from 'react';
import shoesImg from '../../assests/img/Shoes.png'
import iphoneImg from '../../assests/img/Iphone.png'
import dollsImg from '../../assests/img/Dolls.png'
import bagImg from '../../assests/img/Bag.png'
import ProductGiftsCard from '../shared/ProductGiftsCard';
import { Dropdown } from 'react-bootstrap';
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import Breadcrumbs from '../shared/Breadcrumbs';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import { sortOfferCards } from '../../Redux/offers/offerActions';
// import { fetchOffers } from '../../Redux/offers/offerActions';
// import { fetchBrand } from '../../Redux/brand/brandActions';
import { fetchProductsList, sortMerchandiseCards } from '../../Redux/Merchandise/merchandiseActions';
import { pointsToCurrancy } from '../../utils/pointsConversion';
import { withTranslation } from 'react-i18next/';

class GiftsCategory extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            categoryName:false,
            filteredCards:false,
            fromPoint:null,
            toPoint:null,
            categories:[],
            data:this.props.offers,
            currentCategory:window.location.pathname.split('/')[2].replace(/%20/ig,' '),
            Bcategories:['Brasino and Bisro','Casual Dinning','Ethinic','Family Style','Fast Food','Fine Dining'],
            brands:['Al Baik','Al Tazaj','Hardes','Harfy','Kudu'],
            establisdhments:['Casual Dining','Food Court'],
            discountProductCards:[
                {product_name:'Pink nubuck sneakers, seasonal shoes for walking and sports',img:shoesImg,brand_name:'Ellese',original_rate:'245',discounted_rate:'213',offer:'15% off',points_rate:'426',expiry_date:'Expires 25 Oct,2020'},
                {product_name:'Apple iPhone XR (64GB,Blue)',img:iphoneImg,brand_name:'Apple',original_rate:'908',discounted_rate:'825',offer:'10% off',points_rate:'1650',expiry_date:'Expires 27 Oct,2020'},
                {product_name:'Kids toy-rattle, blue monkey Rattle for Baby infant toddler',img:dollsImg,brand_name:'Toy Land',original_rate:'22',discounted_rate:'50',offer:'Buy 1 Get 1 Free',points_rate:'660',expiry_date:'Expires 08 Nov,2020'},
                {product_name:'Ben Sherman Nottingham Lightweight Hardside 4-Wheeles',img:bagImg,brand_name:'Mosafer',original_rate:'908',discounted_rate:'825',offer:'With Orders above SAR 100',points_rate:'660',expiry_date:'Expires 10 Nov,2020'},
            ],
            showNav:false,
         }
    }
    componentDidMount(){
        // this.fetchOrdersList()   
    }
    componentDidUpdate(prevState){
        console.log("Props: ",this.props);
        if(this.props.categoryName !== this.props.match.params.category && this.props.products?.length >0){
            this.props.sortMerchandiseCards(this.props.categories,"Default",this.props.match.params.category,true);this.setState({filteredCards:true,categoryName:this.props.match.params.category});
        }
    }
    fetchOrdersList(){
        const payload = {
            "offer_campaign": {
                "currency": 2
            }
        } 
        this.props.fetchProductsList(payload)
    }

    toggleNav=(val)=>{
        this.setState(old=>(
            {...old,showNav:val}
        ))
    }
    setFromPoint=(val)=>{
        this.setState({
            fromPoint:val
        })
    }

    setToPoint=(val)=>{
        this.setState({
            toPoint:val
        })
    }
    render() { 
        const {Bcategories,currentCategory} = this.state
        const breadcrumbs =[
            {name:'Home',url:'/',active:true},
            {name:'Gifts',url:'/gifts',active:true},
            {name:currentCategory,url:'',acitve:false}
        ] 
        let data = this.props.filteredCards;
       
        if(this.state.filteredCards){
            data = this.props.filteredCards
            
        }
        const {t} = this.props
       const desktopview=(
        <div className="d-md-block d-none">
                
                
        <Breadcrumbs crumbs={breadcrumbs}/>
        <div className="d-flex p-5 gap-5">
            <div className='col-2 p-0'>
                <div className='border-bottom fw-bold '>
                    <span className='fs-5 headings fw-bold'>{t("Categories")}</span>
                    <div className='text-muted d-flex flex-column gap-2 py-3 position-relative px-4'>
                        <span className='fs-2 fw-bold align-top position-absolute color-skyblue' style={{top:'0',left:'0'}}>
                            <Link
                             onClick={()=>{
                                this.props.sortMerchandiseCards(this.props.categories,"Default","All",true);this.setState({filteredCards:true,categoryName:false});
                            }}
                            to={{pathname:'/gifts'}}>‹</Link>
                        </span>
                        {
                          
                        <Link  className='color-skyblue'
                                             
                        > {t(this.props.categoryName)}</Link>
                      
                    }
                        {/* {
                            Bcategories.map(category => <div>{category}</div>) 
                        } */}
                    </div>
                </div>
                {/* <div className='border-bottom fw-bold '>
                    <span className='fs-5 color-blue fw-bold'>Brands</span>
                    <div className='text-muted d-flex flex-column gap-3 py-3 position-relative'>
                        {
                            brands.map(brand => (
                                <div className='d-flex align-items-center gap-2'>
                                    <input className='d-inline' type="checkbox" />
                                    <span className=''>{brand}</span>
                                </div>
                            )) 
                        }
                    </div>
                </div> */}
                <div className='border-bottom fw-bold py-4'>
                            <span className='fs-5 headings fw-bold'>{`${t("Points")}`}</span>
                            <div className='text-muted d-flex gap-2 py-3 align-items-center justify-content-between'>
                                <input 
                                onChange={(e)=>{this.setFromPoint(e.target.value)}}
                                value={this.state.fromPoint}
                                onWheelCapture={e => {e.target.blur()}}
                                type="number" min='0' className='p-2 border' style={{width:'5rem'}}/>
                                    {t("to")}
                                <input
                                onChange={(e)=>{this.setToPoint(e.target.value)}}
                                value={this.state.toPoint}
                                min="1"
                                onWheelCapture={e => {e.target.blur()}}
                                type="number" className='p-2 border' style={{width:'5rem'}}/>
                                <button  onClick={()=>{
                                        this.props.sortMerchandiseCards(this.state.filteredCards? this.props.filteredCards : this.props.products,"Default",this.props.categoryName,false,{from:(this.state.fromPoint),to:(this.state.toPoint)});this.setState({filteredCards:true});
                                    
                                    }} type="button" class="btn primary-btn fw-bold">{t("Go")}</button>
                            </div>
                        </div>
                    
                {/* <div className='border-bottom fw-bold '>
                    <span className='fs-5 color-blue fw-bold'>Establishment Type</span>
                    <div className='text-muted d-flex flex-column gap-3 py-3 position-relative'>
                        {
                            establisdhments.map(establisdhment => (
                                <div className='d-flex align-items-center gap-2'>
                                    <input className='d-inline' type="checkbox" />
                                    <span className=''>{establisdhment}</span>
                                </div>
                            )) 
                        }
                    </div>
                </div> */}
            </div>
            <div>
                <div style={{minWidth:'70vw'}} className='d-flex justify-content-between'>
                    <div className='pb-4'>
                        <span className='fs-3 headings fw-bold'>{t("Merchandise")}</span>
                        <span className='fs-5 text-muted'> - {t(this.props.categoryName)}</span>
                    </div>
                    <div >
                        <Dropdown className='d-inline' >
                                    <DropdownToggle style={{width:'8rem'}} className='text-light rounded-0 mx-3 py-2 px-0 bg-white'>
                                        <span className='color-blue fw-bold float-left px-3'>{t(this.props.sortBy)}</span>
                                        <span className='arrow-down color-skyblue float-right'></span>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={(event)=>{
                                        this.props.sortMerchandiseCards(this.props.categories,"Default",this.props.categoryName,true);this.setState({filteredCards:true});
                                    }    
                                    }>{t("Default")}</DropdownItem>
                                        <DropdownItem onClick={()=>{this.props.sortMerchandiseCards(data,"Points",this.props.categoryName,false);this.setState({filteredCards:true})}}>{t("Points")}</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                    </div>
                </div>
                <div className="d-grid grid-cols-md-4 grid-cols-2 gap-md-3 gap-2 py-3">
                    {
                      data &&  data.map( (card,id)=>(
                        
                            <ProductGiftsCard  card={card} key={id} lang={this.props.lang}/>
                        ))
                    }
               </div>
            </div>
        </div>
    </div>
       )
       const mobileview=(
        <div className="d-block d-md-none container-fluid px-0 py-3 mb-2"  style={{zIndex:'1500',bottom:'4rem'}}>
        <div className="d-flex">
            <div className="w-100">

            <div className='pb-4 px-4'>
                     <span className='fs-3 headings fw-bold'>Merchandise</span>
                     <span className='fs-5 text-muted'> - {this.props.categoryName}</span>
                       <div  className='text-right'>
                      
                       {/* <span>
                       <span className="mr-5">Sort By</span><br/>
                        
                       </span> */}
                     {/* <div className="d-flex text-right justify-content-end"> */}
                     {/* <div className="filter-green mr-5  my-3 mt-4 ml-5">
                     <Link className="w-25 border px-3 py-2 " onClick={()=>{this.toggleNav(true)}}>
                    
                       <img src="https://img.icons8.com/small/16/000000/drag-list-down.png"/>
                     
                     </Link>
                     </div> */}
                    {/* </div> */}
                    </div>          
             </div>
             
             {/* <div className='border-bottom fw-bold '>
                    <span className='fs-5 color-blue fw-bold filter-green'>
                    <img src="https://img.icons8.com/ios/24/000000/drag-list-down.png"/>
                    </span>
                    <div className='text-muted d-flex flex-column gap-2 py-3'>
                       
                    </div>
                </div> */}

            <div className="py-md-4 pt-3 d-grid grid-cols-md-4 grid-cols-2 gap-md-5 gap-2 px-3 content-box">
                
            {
                      data &&  data.map( (card,id)=>(
                        
                            <ProductGiftsCard card={card} key={id} lang={this.props.lang}/>
                        ))
                    }
        </div>
            </div>
        </div>
    </div>

       )
       const filterModal=(
        <div className="d-md-none d-block position-fixed bg-light w-100 " style={{zIndex:'1500',bottom:'4rem'}}>
        { this.state.showNav &&
         <div className="bg-light overflow-auto py-2" style={{zIndex:'1500',maxHeight:'calc(100vh - 6rem)'}}>
             <div className="py-5  d-flex justify-content-between" >
                 <div className="col-2 fs-5 cursor-pointer color-skyblue text-right" onClick={()=>{this.toggleNav(false)}} style={{transform:'rotate(180deg)'}}>      
                    <section >➜</section>              
                </div>   
                <div className=" fs-6 cursor-pointer color-skyblue text-right justify-content-end px-4" onClick={()=>{this.toggleNav(false)}} >      
                    <section >Reset</section>              
                </div>          
             </div>
                      <div className='text-muted d-flex flex-column gap-3 border-bottom   px-3 mt-0 pt-0'>
                      <span className='fs-5 headings fw-bold px-3'>{t("Categories")}</span>
                      <div className='text-muted d-flex flex-column gap-2 py-3 position-relative px-4 pl-5'>
                        <span className='fs-2 fw-bold align-top position-absolute color-skyblue' style={{top:'0',left:'0'}}>
                            <Link className="pl-4 color-skyblue" to={{pathname:'/gifts'}}>‹</Link>
                        </span>
                        {
                          
                        <Link  className='color-skyblue'
                                             
                        > {this.props.categoryName}</Link>
                      
                    }
                        {
                            Bcategories.map(category => <h6>{category}</h6>) 
                        }
                    </div>
                        </div>
                        <div className='border-bottom fw-bold  px-3 py-4 '>
                        <span className='fs-5 color-blue fw-bold px-3'>Brands</span>
                        {/* <div className='text-muted d-flex flex-column gap-3 py-3 position-relative'>
                            {
                                brands.map(brand => (
                                    <div className='d-flex align-items-center gap-2 pl-4'>
                                        <input className='d-inline' type="checkbox" />
                                        <span className=''>{brand}</span>
                                    </div>
                                )) 
                            }
                        </div> */}
                     </div>
                     <div className='border-bottom fw-bold py-4 px-3'>
                        <span className='fs-5 color-blue fw-bold px-3'>Price(SAR)</span>
                        <div className='text-muted d-flex gap-2 py-3 align-items-center px-3 '>
                            <input type="number" className='p-2 border' style={{width:'5rem'}}/>
                                to
                            <input type="number" className='p-2 border' style={{width:'5rem'}}/>
                            <span className="color-skyblue">Go</span>
                        </div>
                    </div>
                    {/* <div className='border-bottom fw-bold px-3 py-4 '>
                        <span className='fs-5 color-blue fw-bold px-3'>Establishment Type</span>
                        <div className='text-muted d-flex flex-column gap-3 py-3 position-relative'>
                            {
                                establisdhments.map(establisdhment => (
                                    <div className='d-flex align-items-center gap-2 px-3'>
                                        <input className='d-inline' type="checkbox" />
                                        <span className=''>{establisdhment}</span>
                                    </div>
                                )) 
                            }
                        </div>
                    </div> */}
                       
                        <div className="px-5 py-3">
                        <button type="button" className="btn btn-block btn-primary rounded-0 text-center"onClick={()=>{this.toggleNav(false)}}  >Apply Filters</button>
                        </div>
            </div>
        }
        
        </div>
    )
        return ( 
            <>
            { this.props.loading ? 
               <section>
                   <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4">
                       <div className="spinner-border" role="status">
                           <span className="sr-only">Loading...</span>
                       </div>
                   </div>
               </section> :
               <>
                {desktopview}  
               {mobileview}
               {filterModal}
               </>
           }
               
           </> 
      
         );
    }
}
const mapStateToProps = state => {
    return {
        loading:state.merchandise.loading,
        sortBy:state.merchandise.sortBy,
        filteredCards: state.merchandise.filteredCards,
        countrySelected:state.countries.countryId,
        categoryName:state.merchandise.categoryName,
        products:state.merchandise.products,
        categories:state.merchandise.categories,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchProductsList : (payload) => dispatch(fetchProductsList(payload)),
        sortMerchandiseCards:(data,sortBy,categoryName,onlyCategory,onlyPointsFilter) => dispatch(sortMerchandiseCards(data,sortBy,categoryName,onlyCategory,onlyPointsFilter))
    }
}

export default connect (mapStateToProps, mapDispatchToProps) (withTranslation()(GiftsCategory));