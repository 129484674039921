import React, { Component } from 'react';
// import { Accordion, Card } from 'react-bootstrap';
// import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
// import {fetchTermsConds} from '../../Redux/TermsandCond/TermsActions';
class TAndConly extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    
    // componentDidMount(){
    //     const payload={
    //         "brands": {
    //             "id": this.props.id,
    //             "currency": this.props.countrySelected
    //         }
    //     }
    //     this.props.fetchTermsConds(payload)
    // }
    detectURLs(text) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text?.split(urlRegex)
          .map(part => {
            if (part?.match(urlRegex)) {
              return <a href={part} key={part} target="_blank" rel="noreferrer" > {part} </a>;
            }
          else{
                return part;
          }
          });
      }
    render() { 
        const {t}= this.props
        return ( 
            <div>
                <div className="d-grid pb-2 ">
                    {/* <div className={"py-2 bg-color-skyblue text-light"}>
                    {t("T&Cs")}
                    </div> */}
                </div>
                <h5 className="headings fw-bold mb-2"> {t("Terms")}</h5>
                    {/* this.state.selected==='t&c' && */}
                    {/* <div className='py-4'> */}
                        {/* <div className='fw-bold color-blue fs-5'>Included in the price</div> */}
                        {!this.props.loading && 
                             this.props.termsConds?.length >0  &&    this.props.termsConds[(this.props.lang==="ar" && this.props.termsConds.find(val=>val.language.includes("ARABIC") ) )?1:0]
                            .terms_text?.split("\n")
                            .map(terms=>
                            <div className="py-1" key={terms}>
                                {this.detectURLs(terms)}
                            </div>
                         )}
                        
                        {/* <div className='text-danger fw-bold'>Lorem ipsum dolor sit amet consectetur adipisicing elit.</div> */}
                    </div>
               
         );
    }
}
 

// const mapStateToProps = state => {
//     return {
//         termsConds:state.termsConds.termsConds,
//         loading:state.termsConds.loading,
//         pointBalance:state.point.pointBalance,
//         countrySelected:state.countries.countryId,
//     }
// }

// const mapDispatchToProps = dispatch => {
//     return {
//         fetchTermsConds : (payload) => dispatch(fetchTermsConds(payload))
//     }
// }

export default (withTranslation()(TAndConly));