import React, { Component } from 'react'
import {  Modal } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import {ReactComponent as SmilySVG} from '../../assests/img/smily.svg'
import {ReactComponent as AlertSvg} from '../../assests/img/alert.svg'
import {ReactComponent as SadSVG} from '../../assests/img/sad.svg'
import { connect } from 'react-redux';


class GoalItemStatusModal extends Component {
    successModal =(t) =>  (<>
        <SmilySVG className="p-md-0 p-3" height="100"/>
        <h4 className='text-muted m-0 py-md-3 py-0'>
            {t(this.props.goalMsg)}
        </h4> 
        {
            this.props.goalModalDetail !== 'Remove' && <div className='text-muted m-0'>
            {t("You can always track your progress against it")}
            </div>
        }
        </>)

        processingModal = (t) =>( <>
                    <AlertSvg className="p-md-0 p-3" height="100"/>
                    <h3 className='text-muted m-0 py-md-3 py-0'>
                    {t("Processing Request")}
                    </h3> 
                    <div className='text-muted m-0'>
                        {t("Please do not refresh the page!")}
                    </div>
        </>)

        failureModal = (t) =>(<>
                    <SadSVG className="p-md-0 p-3" height="100" />
                    <h3 className='text-muted m-0 py-md-3 py-0'>
                        {t("Request Failed")}
                    </h3> 
                    <div className='text-muted m-0'>
                       {t(this.props.goalMsg)}
                    </div>
        </>)
        settingModal = (t) => {
            let status = this.props.status;
            if(status === "Processing") {return this.processingModal(t)}
            else if(status === "Success"){return this.successModal(t)}
            else {return this.failureModal(t)}
        }
    render() {
        const {show,setGoalModalView,status,t} = this.props;
        return (
            <Modal 
            backdrop="static"
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            >
                <div 
                    className="p-4 bg-light mx-auto text-center py-md-5 py-4">
                    {this.settingModal(t)}
                    {status !== "Processing" && <button 
                        className="btn primary-btn mx-2 mt-3 text-light border-color-green rounded"
                        onClick={()=>{setGoalModalView()}}>
                        <span className="fw-bold">{t("Close")}</span>
                    </button>}
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        goalMsg:state.goalItem.msg,
        status:state.goalItem.goalStatus
    }
}


export default connect(mapStateToProps)(withTranslation()(GoalItemStatusModal))
