import React from 'react'
import { useState } from 'react';
import { connect } from 'react-redux';

const Pointsredeem = (props) => {
    const [cardNumberError , setCardNumberError] = useState(false);
    const {checkBalanceLoading} = props
    const giftCardNumberValidation = () => {
        const {rewardsToken,checkRedemptionPoints} = props;

        if(rewardsToken.length >20 || rewardsToken.length <6){
            return setCardNumberError(true)
        }
        else{
            if(cardNumberError){
                setCardNumberError(false)
            }
            checkRedemptionPoints()
        }

    }
    
    const desktopView = (
        <div className="w-100 px-5 d-none d-md-block">
            <div className="d-flex flex-column w-100">
                <div className="d-flex justify-content-between w-100 align-items-center">
                    <div className="fd-flex">
                        <h5 className="fw-bold">Redeem your Reward</h5>
                        <input
                            type="text"
                            value={props.rewardsToken}
                            onChange={(e) => {
                                if(cardNumberError){setCardNumberError(false)}
                                props.setRewardsToken(e.target.value)
                            }}
                            placeholder="Enter Gift Card Number"
                            className="px-3 py-2 "
                            maxLength={20}
                            style={{ borderRadius: '10px 0px 0 10px', height: '50px', width: '650px', border: '2px solid lightgray' }}
                        />
                        <button style={{ borderRadius: '0px 10px 10px 0px', height: '50px', }} className="mt-3 px-3 py-2 border-0 btn-sky" onClick={giftCardNumberValidation} disabled={checkBalanceLoading}>{checkBalanceLoading ? 'Loading' : 'Redeem'}</button>
                        {cardNumberError && <p className='text-danger m-0 p-0'>Enter Valid Gift Card Number</p>}
                    </div>
                    <div style={{ height: '147px', width: '327px', backgroundColor: '#EFEFF3'}} className="d-flex flex-column align-items-center justify-content-center border-radius-1">
                        <h2 className="fw-bold">Points</h2>
                        <span className="fs-3 fw-bold primary-color">{props.point} Points</span>
                    </div>
                </div>
            </div>
        </div>
    )

    const mobileView = (
        <div className="w-100 px-2 d-md-none">
            <div className="d-flex flex-column w-100">
                <div className="d-flex flex-column justify-content-between w-100 align-items-center">
                    <div className="fd-flex">
                        <h5 className="fw-bold">Redeem your Reward</h5>
                        <input
                            type="text"
                            value={props.rewardsToken}
                            onChange={(e) => {
                                if(cardNumberError){setCardNumberError(false)}
                                props.setRewardsToken(e.target.value)
                            }}
                            placeholder="Enter Gift Card Number"
                            className="px-3 py-2 "
                            style={{ borderRadius: '10px 0px 0 10px', height: '50px', width: '70%', border: '2px solid lightgray' }}
                        />
                        <button style={{ borderRadius: '0px 10px 10px 0px', height: '50px', }} className="mt-3 px-3 py-2 border-0 btn-sky" onClick={giftCardNumberValidation}>Redeem</button>
                        {cardNumberError && <p className='text-danger m-0 p-0'>Enter Valid Gift Card Number</p>}
                    </div>
                    <div style={{ height: '147px', width: '80%', backgroundColor: '#EFEFF3'}} className="d-flex flex-column align-items-center justify-content-center border-radius-1 mt-4">
                        <h2 className="fw-bold">Points</h2>
                        <span className="fs-3 fw-bold primary-color">{props.point} Points</span>
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        <>
            {desktopView}
            {mobileView}
        </>
    )
}
function mapStateToProps(state) {
    return {
        point:state.point.pointBalance,
    };
    
  }   
export default connect(mapStateToProps)(Pointsredeem)    

