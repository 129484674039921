import React, { Component } from 'react'
import '../../assests/css/App.css';
import '../../assests/css/Giftsection.css';
import { withTranslation } from 'react-i18next';
import Breadcrumbs from '../shared/Breadcrumbs';
import { REACT_APP_baseUrl } from '../../utils/app-constants';
class HassadPartnersSection extends Component {

    constructor(props) {
        super(props);
        if (props.location.state !== null && props.location.state !== undefined) {
            this.referrer = props.location.state.referrer
        }

        this.state = {
            information: this.referrer,
            clicked: false,
            partners: [],
        }
    }

    componentDidMount() {
        let categories = []
        const url = `${REACT_APP_baseUrl}/hassad_redemption_partners`
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            response.json().then((resp) => {
                categories = resp.data.redemption_partners;
                const productList = []
                categories.forEach(list => {
                    
                        productList.push(
                            {
                                name: list.partner_name,
                                images: list.partner_logo,
                                arname:list.partner_name_arabic,
                            }
                        )
                   
                })
                this.setState({ partners: productList })
            })
        })

    }

    render() {
        const { t } = this.props;
        const breadcrumbs=[
            {name:"Home",url:"/",active:true},
            {name:"Hassadpartners",url:"",active:false}
        ]

        const hassadPartners = this.state.partners.map(card => {
            const desktopView = (
                <div className={`d-md-block d-none w-25 py-2 px-3`}>
                    <div className="text-center border-0" >
                        
                            <img className="img-fluid card-hover p-0" src={card.images?.medium_rectangle} alt="Second slide" />
                        

                        <div className="card-body pt-2">
                        { this.props.lang !== 'ar' ?  <p className="card-text" style={{ "color": "gray" }}>{card.name}</p>
                          : <p className="card-text" style={{ "color": "gray" }}>{card.arname}</p> }
                        </div>
                    </div>
                </div>
            )

            const mobileView = (
                <div className={`d-block d-md-none w-100 py-2 px-3`}>
                    <div className="text-center border-0" >
                        
                            <img className="img-fluid card-hover p-0" src={card.images?.medium_rectangle} alt="Second slide" />
                    
                        <div className="card-body pt-2">
                        { this.props.lang !== 'ar' ?  <p className="card-text" style={{ "color": "gray" }}>{card.name}</p>
                          : <p className="card-text" style={{ "color": "gray" }}>{card.arname}</p> }
                        </div>
                    </div>
                </div>
            )
            return (
                <>
                {desktopView}
                {mobileView}
                </>
                
            )
        })
        return (
            <div>
                
                
                <Breadcrumbs crumbs={breadcrumbs}/>
                <section className="px-5 py-4">
                <h3 className="color-blue">{t('Hassad Partners')}</h3>
                    
                    <div className="d-flex row">
                        {hassadPartners}
                    </div>
                </section>
            </div>
        )
    }

}

export default withTranslation()(HassadPartnersSection);