import React, { Component } from "react";
import Breadcrumbs from "../shared/Breadcrumbs";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { currancyToPoints } from "../../utils/pointsConversion";
import { Dropdown } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownItem from "react-bootstrap/esm/DropdownItem";

import filterIcon from "../../assests/img/header/icon-filter-categories.png";

class Softwaregames extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortByLetter: "All",
      sortByLetterMob: "All",
      data: undefined,
      title: "Softwares & Games",
      subTitle:
        "Level up with the best software & games There are so many genres to choose from",
      cat: "",
      breadcumbActive: false,
      typeData: undefined,
      checked: false,
      checkedcategory: false,
      categoriedata: undefined,
      ageFilter: undefined,
      catMobile: undefined,
      catname: undefined,
      AgeFilter: ["All", "0 - 3", "4 - 10", "11 - 15", "16 - 17", "18 +"],
    };
  }

  componentDidMount() {
    // if (this.props.countrySelected != 2) {
    //   this.props.history.replace("/404");
    // }
    if (
      !this.props.loading &&
      this.props.softwareGamesCards &&
      this.props.softwareGamesCards.length === 0
    ) {
      this.props.history.push({
        pathname:'/404',
        redirectionURL:'/softwares&games'
      })
    }
  }
  componentDidUpdate(cat) {
    if (typeof this.state.data === "undefined" && !this.props.loading) {
      // this.setState({ data: this.props.softwareGamesCards });
    }
    // if (this.props.countrySelected != 2) {
    //   this.props.history.replace("/404");
    // }
    if (
      !this.props.loading &&
      this.props.softwareGamesCards &&
      this.props.softwareGamesCards.length === 0
    ) {
      this.props.history.push({
        pathname:'/404',
        redirectionURL:'/softwares&games'
      })
    }
  }
  checkCheckBox(index) {
    let checkbox = document.getElementsByClassName("check");

    if (index === 0) {
      checkbox[0].checked = true;
      checkbox[1].checked = false;
    } else {
      checkbox[0].checked = false;
      checkbox[1].checked = true;
    }
  }
  unCheckBox() {
    let checkbox = document.getElementsByClassName("check");
    checkbox[0].checked = false;
    checkbox[1].checked = false;
  }
  render() {
    let card = [];
    let categories = this.props.softwareGamesCards;
    categories.forEach((item) => {
      item.software_and_games.forEach((SGoffer) => {
        card.push(SGoffer);
      });
    });
    const breadcrumbs = [
      { name: "Home", url: "/", active: true },
      {
        name: "Softwares & Games",
        url: "/softwares&games",
        active: this.state.breadcumbActive,
      },
      { name: this.state.cat, url: ``, active: false },
    ];
    const { t } = this.props;
    const letters = ["Android", "Windows", "Mac", "iOS"];
    const mobletters = [
      "Softwares",
      "Games",
      "Windows",
      "Android",
      "Mac",
      "iOS",
    ];
    const filters = ["Softwares", "Games"];
    let softwareGamesCards = card;
    const sortBy = (letter, index) => {
      if (letter === "Softwares") {
        // this.checkCheckBox(index);
        this.setState({
          catname: "Software",
        })
        let filteredData = this.state?.checkedcategory
          ? this.state?.categoriedata?.filter(
            (item) => item?.type === "software"
          )
          : card?.filter((item) => item?.type === "software");
        this.setState({
          checked: true,
          checkedType: "SOFTWARE",
          sortByLetter: "All",
          data: filteredData,
          title: "Softwares",
          subTitle:
            "Level up with the best softwares There are so many genres to choose from.",
          cat: "Softwares",
          breadcumbActive: true,
          sortByLetterMob: "Softwares",
        });
      } else if (letter === "Games") {
        // this.checkCheckBox(index);
        this.setState({
          catname: "Games",
        })
        let filteredData = this.state?.checkedcategory
          ? this.state?.categoriedata?.filter((item) => item?.type === "game")
          : card?.filter((item) => item?.type === "game");
        this.setState({
          checked: true,
          checkedType: "GAME",
          sortByLetter: "All",
          data: filteredData,
          title: "Games",
          subTitle:
            "Level up with the best games. There are so many genres to choose from.",
          cat: "Games",
          breadcumbActive: true,
          sortByLetterMob: "Games",
        });
      } else if (letter === "Android") {
        let filteredData = [];
        if (
          this.state.checked === true &&
          this.state.checkedType === "SOFTWARE" &&
          letter === "Android"
        ) {
          const initialFilter = card?.filter(
            (item) => item?.type === "software"
          );
          filteredData = initialFilter.filter((type) =>
            type.operating_system?.includes("android")
          );
        } else if (
          this.state.checked === true &&
          this.state.checkedType === "GAME" &&
          // letter !== this.state.sortByLetter &&
          letter === "Android"
        ) {
          const initialFilter = card?.filter((item) => item.type === "game");
          filteredData = initialFilter.filter((type) =>
            type.operating_system?.includes("android")
          );
        } else {
          filteredData = card.filter((type) =>
            type.operating_system?.includes("android")
          );
        }

        this.setState({
          data: filteredData,
          sortByLetterMob: "Android",
          sortByLetter: "Android",
        });
      } else if (letter === "Windows") {
        let filteredData = [];
        // console.log('cat2', this.state.categoriedata)
        // if ( this.state.checkedcategory )    
        // {
        //   filteredData= this.state.categoriedata
        //   this.setState({
        //     data:filteredData

        //   });

        // }
        if (
          this.state.checked === true &&
          this.state.checkedType === "SOFTWARE" &&
          letter === "Windows"
        ) {
          const initialFilter =
            card?.filter(
              (item) => item.type === "software"
            );
          filteredData = initialFilter.filter((type) =>
            type.operating_system?.includes("windows")
          );
        } else if (
          // letter !== this.state.sortByLetter &&
          this.state.checked === true &&
          this.state.checkedType === "GAME" &&
          letter === "Windows"
        ) {
          const initialFilter = card?.filter((item) => item.type === "game");
          filteredData = initialFilter.filter((type) =>
            type.operating_system?.includes("windows")
          );
        } else {
          filteredData = card.filter((type) =>
            type.operating_system?.includes("windows")
          );
        }
        this.setState({
          data: filteredData,
          sortByLetterMob: "Windows",
          sortByLetter: "Windows",
        });
      } else if (letter === "Mac") {
        let filteredData = card.filter((type) =>
          type.operating_system?.includes("mac")
        );
        this.setState({
          data: filteredData,
          sortByLetterMob: "Mac",
          sortByLetter: "Mac",
        });
      } else if (letter === "iOS") {
        let filteredData = card.filter((type) =>
          type.operating_syste?.includes("ios")
        );
        this.setState({
          data: filteredData,
          sortByLetterMob: "iOS",
          sortByLetter: "iOS",
        });
      }
    };

    const sortFunctionMob = (condition, catFilter) => {
    
      if (catFilter) {
        let filteredData
        if (this.state.catname == undefined) {
          let softwares = (this.props.softwareGamesCards[0]?.software_and_games)
          let games = (this.props.softwareGamesCards[1]?.software_and_games)
          games = games ? games : ""
          filteredData = [...softwares, ...games]
          
        }
        else {
          filteredData = this.props.softwareGamesCards?.filter(
            (e) => e.name === this.state.catname
          )[0]?.software_and_games;
        }
        if (this.state.ageFilter !== "All" && this.state.ageFilter) {
          let filterValue = this.state.ageFilter.split(" ");
          let fisrtValue = filterValue[0];
          let secondValue = filterValue[2] || 18;
          filteredData = filteredData.filter(
            (e) => +e.rating <= secondValue && +e.rating >= fisrtValue
          );
        }
        this.setState({
          data: filteredData,
          refProducts: filteredData,
          sortByLetter: "All",
        });
      } else if (this.state.ageFilter) {
        if (this.state.ageFilter === "All") {
          this.setState({ data: this.state.refProducts });
        } else {
          let filterValue = this.state.ageFilter.split(" ");
          let firstValue = filterValue[0];
          let secondValue = filterValue[2] || 18;
          let filteredData = this.state.refProducts?.filter(
            (e) => +e.rating <= secondValue && +e.rating >= firstValue
          );
          this.setState({ data: filteredData });
        }
      }

      toggleNav(false);
    }

    const toggleNav = (val) => {
      this.setState((old) => ({ ...old, showNav: val }));
    };

    if (!!this.state.data) {
      softwareGamesCards = this.state.data;
    }

    const numberWithCommas = (x) => {
      // let y = x.toFixed(2);
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    const desktopView = (
      <div className="d-none d-lg-flex row gx-5 p-5 w-100 m-0">
        <div className="col-2 category-nav p-0">
          <div className="sub-Categories-nav">
            {/* <h5 className=" btnTxt ml-0 pb-1 fw-bold">{t("Filters")}</h5> */}
            {/* {filters &&
              filters.map((cat, index) => (
                <div className="d-flex mb-3">
                  <input
                    onClick={() => sortBy(cat, index)}
                    className={
                      this.props.lang === "en"
                        ? "inline mr-2 check"
                        : "inline ml-2 check"
                    }
                    type="checkbox"
                    value={index}
                  />
                  <span>{t(cat)}</span>
                </div>
              ))} */}

            <h5 className=" btnTxt ml-0 pb-1 fw-bold">{t("Category")}</h5>
            {this.props.softwareGamesCards &&
              this.props.softwareGamesCards.map((cat, index) => (
                <div className="d-flex mb-3">
                  <Link
                  to="/softwares&games"
                    onClick={() => {
                      // this.unCheckBox();
                      this.setState({
                        data: this.props.softwareGamesCards.filter(
                          (e) => e.name === cat.name
                        )[0].software_and_games,
                        checkedcategory: true,
                        catname: cat.name,
                        categoriedata: this.props.softwareGamesCards.filter(
                          (e) => e.name === cat.name
                        )[0].software_and_games,
                      });
                    }}
                    // className={
                    //   this.props.lang === "en"
                    //     ? "inline mr-2 check"
                    //     : "inline ml-2 check"
                    // }
                    className={
                      (this.state.catname == "Software"  && cat.name == "Software" && this.props.lang !== "ar") ? "inline mr-2  active-link"
                      : (this.state.catname == "Software"  && cat.name == "Software" && this.props.lang === "ar") ? "inline ml-2  active-link"
                      : (this.state.catname == "Games"  && cat.name == "Games" && this.props.lang !== "ar") ? "inline mr-2  active-link"
                      : (this.state.catname == "Games"  && cat.name == "Games" && this.props.lang === "ar") ? "inline ml-2  active-link"
                      : (this.state.catname == undefined && this.props.lang === "ar") ? "inline ml-2 "
                      : "inline mr-2 "
                    }
                    value={index}
                  >
                    {t(cat.name) }
                  </Link>
                </div>
              ))}

            <hr className="hr-line" />
            <h5 className=" btnTxt ml-0 pb-1 fw-bold">{t("Age")}</h5>

            {this.state.AgeFilter.map((age) => (
              <div className="d-flex align-items-center gap-2 ">
                <input
                  onChange={(e) => {
                    this.setState({ ageFilter: e.target.value });
                  }}
                  className="d-inline"
                  type="radio"
                  name="age"
                  value={age}
                />
                <span className="">{t(age)}</span>
              </div>
            ))}
            <div className="py-3">
              <button
                type="button"
                className="btn btn-block primary-btn rounded-0 text-center no-outline"
                onClick={() => {
                  sortFunctionMob("catFilter", true);
                }}
              >
                {t("Apply Filters")}
              </button>
            </div>
          </div>
        </div>
        <section className="col-10 px-md-5 px-3 py-md-4 py-1">
            <div className="d-flex justify-content-between">
              <div>
                <div className="headings fw-md-bold fw-bolder fs-md-3 fs-5">
                  {t(this.state.title)}
                </div>
                <div className="text-black-50 fw-bold ">
                  {t("Level up with the best software & games")}.{t("There are so many genres to choose from")}.
                </div>
              </div>
              <div
                className={`d-none d-lg-block ${this.props.lang === "ar" ? "" : "ml-5"
                  } `}
              >
                <Dropdown className="d-inline ">
                  <DropdownToggle
                    style={{ width: "8rem" }}
                    className={`${this.props.lang === "ar" ? "float-left" : ""
                      } text-light rounded-0 mt-1 px-0 bg-white no-outline`}
                  >
                    <span className="product_title fw-bold float-left px-3">{`${t(
                      this.state.sortByLetter
                    )}`}</span>
                    <span className="arrow-down color-green float-right"></span>
                  </DropdownToggle>
                  <DropdownMenu>
                    {letters?.map((e) => (
                      <DropdownItem onClick={() => sortBy(e)}>
                        {t(e)}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div> 
          {
            (softwareGamesCards.length===0 || this.state?.data?.length===0) &&
            <div className='text-center mt-5'>
                      <h4>{t("There are no items currently available. Please check again later")}</h4>
                    </div>
          }
          <div className="py-md-4 py-2 d-grid grid-cols-md-4 grid-cols-2 gap-md-2 gap-2">
            {
              (this.state.data == undefined) ?
                <>
                  {softwareGamesCards.length > 0 &&
                    softwareGamesCards.map((softwareGamesCards) => {
                      let url = ""
                      if (softwareGamesCards.type == "software") url = `/softwares/${softwareGamesCards.id}`
                      else url = `/games/${softwareGamesCards.id}`
                      return <div className="text-center border-0 d-grid">
                        <Link
                          className="active p-0 "
                          to={{
                            pathname: url,
                            state: { ...softwareGamesCards },
                          }}
                        >
                          <img
                            className="card-hover experiences-img"
                            src={softwareGamesCards.images?.color?.large_rectangle}
                            alt={softwareGamesCards.name}
                          />
                        </Link>
                        <div className="text-center py-2 product_title fw-md-bold fw-bolder">
                          {this.props.lang !== "ar"
                            ? softwareGamesCards.name
                            : softwareGamesCards.name_arabic}
                        </div>
                        <div
                          style={{ alignItems: "flex-end", justifySelf: "center" }}
                          className="d-flex flex-row text-black-50"
                        >
                          <span>
                            <b>
                              {numberWithCommas(currancyToPoints(softwareGamesCards.price))}{" "}
                              {t("Points")}
                            </b>
                          </span>
                        </div>
                      </div>
                    })
                  }
                </>
                :
                <>
                  {this.state.data.length > 0 &&
                    this.state.data.map((data) => {
                      let url = ""
                      if (data.type == "software") url = `/softwares/${data.id}`
                      else url = `/games/${data.id}`
                      return <div className="text-center border-0 d-grid">
                        <Link
                          className="active p-0 "
                          to={{
                            pathname: url,
                            state: { ...data },
                          }}
                        >
                          <img
                            className="card-hover experiences-img"
                            src={data.images?.color?.large_rectangle}
                            alt={data.name}
                          />
                        </Link>
                        <div className="text-center py-2 headings fw-md-bold fw-bolder">
                          {this.props.lang !== "ar"
                            ? data.name
                            : data.name_arabic}
                        </div>
                        <div
                          style={{ alignItems: "flex-end", justifySelf: "center" }}
                          className="d-flex flex-row text-black-50"
                        >
                          <span>
                            <b>
                              {data.price}{" "}
                              {t("Points")}
                            </b>
                          </span>
                        </div>
                      </div>
                    })}
                </>
            }
          </div>
        </section>
      </div>
    );
    const mobileView = (
      <section className=" d-md-block d-lg-none px-md-5 px-3 py-md-4 py-1">
        <div className="d-flex justify-content-between">
          <div>
            <div className="headings fw-md-bold fw-bolder fs-md-3 fs-5">
              {t(this.state.title)}
            </div>
            <div className="text-black-50 fw-bold ">
            {t("Level up with the best software & games")}.{t("There are so many genres to choose from")}.
            </div>
          </div>
        </div>
        <div className=" d-flex justify-content-end text-right py-2">
          <Dropdown className="d-inline ">
            <DropdownToggle
              style={{ width: "8rem" }}
              className={`${this.props.lang === "ar" ? "float-left" : ""
                } text-light rounded-0 px-0 bg-white no-outline`}
            >
              <span className="headings fw-bold float-left px-3">{`${t(
                this.state.sortByLetterMob
              )}`}</span>
              <span className="arrow-down color-green float-right"></span>
            </DropdownToggle>
            <DropdownMenu>
              {mobletters?.map((e, index) => (
                <DropdownItem onClick={() => sortBy(e, index)}>
                  {t(e)}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          <div
            style={{
              maxWidth: "50px",
              marginRight: "16px",
              marginLeft: "16px",
              minHeight: "40px",
              minWidth: "40px",
            }}
            id="dropdownMenuButtons"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img loading='lazy' decoding='async'
              src={filterIcon}
              alt="categorie"
              className="img-fluid"
              style={{ minHeight: "33px" }}
              onClick={() => {
                toggleNav(true);
              }}
            />
          </div>

          <div className="dropdown-menu " aria-labelledby="dropdownMenuButtons">
            {this.props.softwareGamesCards &&
              this.props.softwareGamesCards.map((cat, index) => (
                <Link
                  to="/softwares&games"
                  className="px-2 py-2"
                  onClick={() => {
                    this.setState({
                      data: this.props.softwareGamesCards.filter(
                        (e) => e.name === cat.name
                      )[0].software_and_games,
                      checkedcategory: true,
                      categoriedata: this.props.softwareGamesCards.filter(
                        (e) => e.name === cat.name
                      )[0].software_and_games,
                    });
                  }}
                >
                  {t(cat.name)} <br />
                </Link>
              ))}
          </div>
        </div>
        {
            (softwareGamesCards.length===0 || this.state?.data?.length===0) &&
            <div className='text-center mt-5'>
                      <h4>{t("There are no items currently available. Please check again later")}</h4>
                    </div>
          }
        <div className="py-md-4 pt-3 d-grid grid-cols-md-4 grid-cols-2 gap-md-3 gap-3 content-box">
          {softwareGamesCards.length > 0 &&
            softwareGamesCards?.map((softwareGamesCards) => {
              let url = ""
              if (softwareGamesCards.type == "software") url = `/softwares/${softwareGamesCards.id}`
              else url = `/games/${softwareGamesCards.id}`
              return <div className="text-center p-0 d-grid">
                <Link
                  className="active p-0 "
                  to={{
                    pathname: url,
                    state: { ...softwareGamesCards },
                  }}
                >
                  <img loading='lazy' decoding='async'
                    className="card-hover experiences-img"
                    src={softwareGamesCards?.images?.color?.large_rectangle}
                    alt={softwareGamesCards.name}
                  />
                </Link>
                <div className="text-center product_title fw-md-bold fw-bolder">
                  {this.props.lang !== "ar"
                    ? softwareGamesCards.name
                    : softwareGamesCards.name_arabic}
                </div>
                <div
                  style={{ alignItems: "flex-end", justifySelf: "center" }}
                  className="d-flex flex-row text-black-50"
                >
                  <span>
                    <b>
                      {numberWithCommas(currancyToPoints(softwareGamesCards.price))}{" "}
                      Points
                    </b>
                  </span>
                </div>
                {/* <div style={{alignItems:'flex-end',justifySelf:'center'}} className="d-flex flex-row text-black-50">
                                             <span>{t("From")} <b>{currancyToPoints(experience.price_per_users["1"])}</b> {t('Points Per Adult')}</span>
                                            </div>  */}
              </div>
            }
            )
          }
        </div>
        <div className="mt-3"></div>
      </section>
    );

    // const filterModal = (
    //   <div
    //     className="d-md-none d-block position-fixed bg-light w-100 "
    //     style={{ zIndex: "1500", bottom: "0" }}
    //   >
    //     {this.state.showNav && (
    //       <div
    //         className="bg-light overflow-auto py-2"
    //         style={{ zIndex: "1500", minHeight: "100vh" }}
    //       >
    //         <div className="py-5  d-flex justify-content-between mt-5">
    //           <div
    //             className="col-2 fs-5 cursor-pointer color-green text-right"
    //             onClick={() => {
    //               toggleNav(false);
    //             }}
                
    //           >
    //             <section
    //             style={{ transform: `${this.props.lang === 'en' ? "rotate(180deg)" : ""}` }}
    //             >➜</section>
    //           </div>
    //           <div
    //             className=" fs-6 cursor-pointer color-green text-right justify-content-end px-4"
    //             onClick={() => {
    //               sortFunctionMob("catFilter", true);
    //             }}
    //           >
    //             <section>{t("Reset")}</section>
    //           </div>
    //         </div>
    //         <div className="text-muted d-flex flex-column gap-3 border-bottom   px-3 mt-0 pt-0">
    //           <span className="fs-5 color-blue fw-bold px-3">
    //             {t("Categories")}
    //           </span>
    //           <div className="text-muted d-flex flex-column gap-2 py-3 position-relative px-4">
    //             <span
    //               className="fs-2 fw-bold align-top position-absolute color-green"
    //               style={{ top: "0", left: "0" }}
    //             ></span>
    //             {this.props.softwareCards &&
    //               this.props.softwareCards.map((cat, index) => (
    //                 <a href
    //                   onClick={(e) => {
    //                     this.addActiveClass(e.target);
    //                     this.setState({ catMobile: cat.name });
    //                   }}
    //                   className="fw-bold"
    //                   value={cat.name}
    //                 >
    //                   {t(cat.name)} <br />
    //                 </a>
    //               ))}
    //           </div>
    //         </div>
    //         <div className="border-bottom fw-bold  px-3 py-4 ">
    //           <span className="fs-5 color-blue fw-bold px-3">{t("Age")}</span>
    //           <div className="text-muted d-flex flex-column gap-3 py-3 position-relative">
    //             {this.state.AgeFilter.map((age) => (
    //               <div className="d-flex align-items-center gap-2 pl-4">
    //                 <input
    //                   onChange={(e) =>
    //                     this.setState({ ageFilter: e.target.value })
    //                   }
    //                   className="d-inline"
    //                   type="radio"
    //                   name="age"
    //                   value={age}
    //                 />
    //                 <span className="">{t(age)}</span>
    //               </div>
    //             ))}
    //           </div>
    //         </div>
    //         <div className="px-5 py-3">
    //           <button
    //             type="button"
    //             className="btn btn-block primary-btn rounded-0 text-center"
    //             onClick={() => {
    //               sortFunctionMob("catFilter", true);
    //             }}
    //           >
    //             {t("Apply Filters")}
    //           </button>
    //         </div>
    //       </div>
    //     )}
    //   </div>
    // );

    return (
      <>
        {this.props.loading ? (
          <section>
            <div class="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4 ">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </section>
        ) : (
        <>
          <Breadcrumbs crumbs={breadcrumbs} />

          {desktopView}
          {mobileView}
          {/* {this.state.showNav ? filterModal: mobileView} */}
        </>
         )} 
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    softwareGamesCards: state.softwareGames.softwareGamesCards,
    loading: state.softwareGames.loading,
    countrySelected: state.countries.countryId,
    currancyCode: state.countries.convertionRate.currency_code,
    fetchError: state.softwareGames.error,
  };
};

export default connect(mapStateToProps)(withTranslation()(Softwaregames));