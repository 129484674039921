import React, { Component } from 'react';
import thumbLogo from '../../../assests/img/header/thumbLogo.png'
import enfactem from "../../../assests/img/header/enfactemlogo.png"
import Barcode from '../../../assests/img/giftflow/Barcode.png'
import hassadLogo from '../../../assests/img/header/hassad-logo.png'
import './Buygift.css'
import { withTranslation } from 'react-i18next';
import {connect} from 'react-redux'
import { logoHandler } from 'utils/Theme/Logo-handler';


class EmailPreview extends Component {
    constructor(props) {
        super(props);
        this.state={
            url : "",
        }
    }

    detectURLs(text) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text?.split(urlRegex)
          .map(part => {
            if (part?.match(urlRegex)) {
              return <a href={part} key={part} target="_blank" rel="noreferrer" > {part} </a>;
            }
          else{
                return part;
          }
          });
      }

      componentDidMount() {
          const data = this.props.ocassionimage?.occasion_banner_images?.data;
          console.log('data',data);
          for (let i=0; i<data?.length; i++) {
            if (this.props.GiftParticularCardSelect === data[i].id){
                this.setState({
                    url : data[i].occasion_banner_image?.large_rectangle
                })
            }
        }
      }

    render() { 
        
        const {card, show, showMethod,giftCardValue,from, url, GiftMessage, t, currancyCode} = this.props;
        const desktopView = (
            <div className={(show)?"position-fixed modal-backdrop top-0 d-flex justify-content-center overflow-auto d-md-block d-none":"d-none"} style={{zIndex:'1400'}}>
             <section style={{height:'fit-content'}} className="bg-white w-100" >
                    <div className="w-100 emailimgtop row fw-bold mx-0">
                        <div className="col-11 text-center">
                            <p className="pt-3">{t("You have got a e-giftcard from")} {from}</p>
                        </div>
                        <div className="col-1 text-center">
                            <p className="pt-3 hoverpointer" onClick={showMethod}>x</p>
                        </div>
                    </div>
                    <div>
                    <div style={{background: `url(${this.state.url})`, height:'50vh'}} className="w-100 emailImage">
                    </div>
                    </div>
                    <div className="w-100 emailimgtop1 d-flex justify-content-center align-items-center fw-bold">
                        <p className="mb-0">{GiftMessage}</p>
                    </div>
                    <div className="row mt-5">
                        <div className="col-4">
                            <div className="w-100 my-4 d-flex justify-content-center align-items-center fw-bolder">
                                <p className="mb-0">{t(currancyCode)} {giftCardValue}</p>
                            </div>
                            <div className="w-100 text-center my-3">
                                <img width="45%" height="auto" src={(this.props.type==="offer")? card?.image?.medium_rectangle:card?.images?.color?.medium_rectangle} alt="" />
                            </div>
                            <div className="w-100 text-center my-4">
                                <img width="60%" height="auto" src={Barcode} alt="" />
                                <p className="text-black-50 my-2 fw-bolder">5922xxxxxxxxxxx</p>
                                <p className="text-black-50 my-2 fw-bolder">{t("Expiry date")}:</p>
                                <p className="text-black-50 my-2 fw-bolder">30-05-2022</p>
                            </div>
                           
                        </div>
                        <div className="col-8">
                            <div className="w-100 my-4 px-3">
                                    <p className="text-black-50 my-2 fw-bolder">{t("How To Redeem?")}</p>
                                    <p className="text-black-50 my-2 fw-bold">
                                        {t("Please visit any of our partner brand stored and presenr your gift card at the time of checkout If you are transacting online, you need to enter the gift card number on the 'Redeem Gift Card' boc on the checkout page")}
                                    </p>
                                </div>
                            <div className="w-100 my-4 px-3">
                                <p className="text-black-50 my-2 fw-bolder">{t("Terms & Conditions?")}</p>
                                <p className="text-black-50 my-2 fw-bold">
                                {!this.props.loading && 
                                    this.props.termsConds?.length >0  &&    this.props.termsConds[(this.props.lang==="ar" && this.props.termsConds.find(val=>val.language.includes("ARABIC") ) )?1:0]
                                    .terms_text.split("\n")
                                    .map(terms=>
                                    <div className="py-1" key={terms}>
                                        {this.detectURLs(terms)}
                                    </div>
                                )}
                                </p>
                            </div>
                        </div>
                 
                    </div>
                </section>
        </div>
        );

        const mobileView = (
            <div className={(show)?"position-fixed modal-backdrop top-0 d-flex justify-content-center overflow-auto  d-md-none d-block":"d-none"} style={{zIndex:'1400'}}>
             <section style={{height:'fit-content'}} className="bg-white w-100" >
                    <header className="d-flex py-md-4 py-2 px-md-5 px-3 gap-md-4 gap-3 border shadow-sm ">
                        <div className="d-flex w-100">
                            <div style={{width: '90%'}} className=" d-flex justify-content-center">
                                <img className="img-fluid d-md-none d-block" style={{width:'2rem', height:'fit-content'}} src={logoHandler()} alt="logo"/>
                                <p className="text-black-50">|</p>
                                <img className="img-fluid d-md-block" style={{width:'5.5rem', height:'fit-content'}} src={logoHandler()} alt="logo"/>
                            </div>
                            <div className="fw-bold fs-5">
                                <p onClick={showMethod} >X</p>
                            </div>
                        </div>
                        
                    </header>
                    <div className="w-100 emailimgtop d-flex justify-content-center align-items-center fw-bold">
                        <p className="mb-0">{t("You have got a e-giftcard from")} {from}</p>
                    </div>
                    <div>
                    <img width="100%" height="auto" src={url} alt="" />
                    </div>
                    <div className="w-100 emailimgtop1 d-flex justify-content-center align-items-center fw-bold">
                        <p className="mb-0">{GiftMessage}</p>
                    </div>
                    <div className="w-100 my-4 d-flex justify-content-center align-items-center fw-bolder">
                        <p className="mb-0">{t(currancyCode)} {giftCardValue}</p>
                    </div>
                    <div className="w-100 text-center my-3">
                        <img width="50%" height="auto" src={(this.props.type==="offer")? card?.image?.medium_rectangle:card?.images?.color?.medium_rectangle} alt="" />
                    </div>
                    <div className="w-100 text-center my-4">
                        <img width="50%" height="auto" src={Barcode} alt="" />
                        <p className="text-black-50 my-2 fw-bolder">591663362519422</p>
                        <p className="text-black-50 my-2 fw-bolder">{t("Expiry date")}:</p>
                        <p className="text-black-50 my-2 fw-bolder">30-05-2022</p>
                    </div>
                    <div className="w-100 my-4 px-3">
                        <p className="text-black-50 my-2 fw-bolder">{t("How To Redeem?")}</p>
                        <p className="text-black-50 my-2 fw-bold">
                        {t("Please visit any of our partner brand stored and presenr your gift card at the time of checkout If you are transacting online, you need to enter the gift card number on the 'Redeem Gift Card' boc on the checkout page")}

                        </p>
                    </div>
                    <div className="w-100 my-4 px-3">
                        <p className="text-black-50 my-2 fw-bolder">{t("Terms & Conditions?")}</p>
                        <p className="text-black-50 my-2 fw-bold">
                        {!this.props.loading && 
                             this.props.termsConds?.length >0  &&    this.props.termsConds[(this.props.lang==="ar" && this.props.termsConds.find(val=>val.language.includes("ARABIC") ) )?1:0]
                            .terms_text.split("\n")
                            .map(terms=>
                            <div className="py-1" key={terms}>
                                {this.detectURLs(terms)}
                            </div>
                         )}
                        </p>
                    </div>
                 
                </section>
        </div>
        );

        return (
            <>
                {desktopView}
                {mobileView}
            </>
         );
    }
}

const mapStateToProps = (state) => {
    return {
        ocassionimage: state.giftOcassion.ocassionimage,
        GiftParticularCardSelect: state.giftFormDetails.GiftParticularCardSelect,
    };
  };


export default connect(mapStateToProps)(withTranslation()(EmailPreview))
