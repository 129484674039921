import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import { currancyToPoints } from "../../utils/pointsConversion";

class SoftwareSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    // let card = [];
    let softwareCards = this.props.softwareCards;
    // this.props.softwareGamesCards.forEach((item) => {
    //   item.software_and_games.forEach((SGoffer) => {
    //     card.push(SGoffer);
    //   });
    // });
    const desktopView = (
      <div className="d-none d-lg-flex row gx-5 px-5 pt-0 w-100 m-0">
        <section className="px-md-5 px-3 py-md-4 py-1">
          <div>
            {softwareCards && (
              <Link
                className={`text-decoration-none color-skyblue fw-bold  ${
                  this.props.lang !== "ar" ? "float-right" : "float-left"
                } d-inline-block p-0 pb-2`}
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                to="/softwares"
              >{`${this.props.lang !== "ar" ? "Show All" : "ترتيب حسب"}`}</Link>
            )}
            <h5 className="headings fw-md-bold fw-bolder fs-md-3 fs-5">
              {t("Softwares")}
            </h5>

            <div className="text-black-50 fw-bold ">
              {t(
                "Level up with the best software There are so many genres to choose from"
              )}
            </div>
          </div>
          <div
            className={`d-none d-lg-block ${
              this.props.lang === "ar" ? "" : "ml-5"
            } `}
          ></div>

          <div className="py-md-4 py-2 d-grid grid-cols-md-4 grid-cols-2 gap-md-2 gap-2">
            {softwareCards?.length > 0
              ? softwareCards.map((softwareGames, index) =>
                  index < 4 ? (
                    <div className="text-center border-0">
                      <Link
                        className="active p-0 "
                        to={{
                          pathname: `/softwares/${softwareGames.id}`,
                          state: { ...softwareGames },
                        }}
                      >
                        <img
                          className="card-hover experiences-img"
                          src={softwareGames.images?.color?.large_rectangle}
                          alt={softwareGames.name}
                        />
                      </Link>
                      <div className="text-center py-2 product_title fw-bold fw-md-bold fw-bolder">
                        {this.props.lang !== "ar"
                          ? softwareGames.name
                          : softwareGames.name_arabic}
                      </div>
                      <span className='product_value'>
                   <b>
                      {softwareGames.points_in_sar}{" "}
                    </b>
                    <small>{t("Points")}</small>
                   </span>
                    </div>
                  ) : (
                    false
                  )
                )
              : false}
          </div>
        </section>
      </div>
    );
    const mobileView = (
      <section className=" d-block d-md-none">
        <section className="px-md-5 px-3 mx-auto py-md-4">
          {softwareCards && (
            <Link
              l
              className={`text-decoration-none color-skyblue fw-bold mt-2  ${
                this.props.lang !== "ar" ? "float-right" : "float-left"
              } d-inline-block p-0 pb-2`}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              to="/softwares"
            >
              {`${this.props.lang !== "ar" ? "Show All" : "ترتيب حسب"}`}{" "}
            </Link>
          )}
        </section>
        <section className="px-md-5 px-3 mx-auto py-md-4 py-1">
          <div>
            <div className="headings fw-md-bold fw-bolder fs-md-3 fs-5">
              {t("Softwares")}
            </div>
            <div className="text-black-50 fw-bold ">
              {t(
                "Level up with the best software There are so many genres to choose from"
              )}
            </div>
          </div>
          <div className={`${this.props.lang === "ar" ? "" : "ml-5"} `}></div>

          <div
            className="py-md-4 py-2 d-grid grid-cols-md-4 grid-cols-2 gap-md-3 gap-3 mb-5 "
            style={{ marginBottom: "1.2rem" }}
          >
            {softwareCards?.length > 0 &&
              softwareCards.map((softwareGames, index) =>
                index < 4 ? (
                  <div className="text-center p-0 d-grid">
                    <Link
                      className="active p-0 "
                      to={{
                        pathname: `/softwares/${softwareGames.id}`,
                        state: { ...softwareGames },
                      }}
                    >
                      <img
                        className="card-hover experiences-img"
                        src={softwareGames.images?.color?.large_rectangle}
                        alt={softwareGames.name}
                      />
                    </Link>
                    <div className="text-center product_title fw-bold fw-md-bold fw-bolder">
                      {this.props.lang !== "ar"
                        ? softwareGames.name
                        : softwareGames.name_arabic}
                    </div>
                    <span className='product_value'>
                   <b>
                      {softwareGames.points_in_sar}{" "}
                    </b>
                    <small>{t("Points")}</small>
                   </span>
                  </div>
                ) : (
                  false
                )
              )}
          </div>
        </section>
      </section>
    );
    return (
      <>
        {this.props.loading ? (
          <section>
            <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4 ">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </section>
        ) : (
          softwareCards?.length > 0 && (
            <>
              {desktopView}
              {mobileView}
            </>
          )
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    softwareCards: state.softwares.featuredCards,
    loading: state.softwares.loading,
    countrySelected: state.countries.countryId,
    currancyCode: state.countries.convertionRate.currency_code,
    fetchError: state.softwares.error,
  };
};

export default connect(mapStateToProps)(withTranslation()(SoftwareSection));
