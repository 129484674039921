import React, { Component } from "react";
import Breadcrumbs from "../shared/Breadcrumbs";
import GiftsSection from "./Sections/GiftsSection";
import OffersSection from "./Sections/OffersSection";
// import caretRight from '../../assests/img/caret-right-fill.svg'
import EMagazineSection from "./Sections/E-MagazineSection";
import AllSection from "./Sections/AllSection";
import PointsExchangeSection from "./Sections/PointsExchangeSection";
import ShopSection from "./Sections/ShopSection";
import SearchBar from "./SearchBar";
import ProductCards from "./ProductCards";
import { connect } from "react-redux";
import { fetchOrderList } from "../../Redux/myWallet/myWalletActions";
import DisplayCards from "./DisplayCards";
import { withTranslation } from "react-i18next";
import ExperienceSection from "./Sections/ExperienceSection";
import MobileTopupSection from "./Sections/MobileTopupSection";
// import { Link } from 'react-router-dom'
// import LoginModal from '../shared/Modals/LoginModal'
import { checkTokenValidity } from "../../Redux/auth/authActions";
import ReceivedGiftSection from "./Sections/ReceivedGiftsSection";
import SoftwareSection from "./Sections/SoftwareSection";
import GamesSection from "./Sections/GamesSection";
import MainLoginModel from "components/shared/Modals/MainLoginModel";
import SoftwareGamesSection from "./Sections/SoftwareGamesSection";
import TravelSection from "./Sections/TravelSection";
import PhysicalCardSection from "./Sections/PhysicalCardSection";

class MyWallet extends Component {
  state = {
    desktopSection: this.props.match.params.categoryName || "all",
    mobileSection: "",
    orders: [],
    showPopup: true,
    loadingData: false,
    commonLoading: true,
    selectFromUrl: true,
    searchFilter: "",
    myWalletSections: {
      all: {
        id: "all",
        name: "All",
        component: <AllSection lang={this.props.lang} />,
      },
      giftCards: {
        id: "giftCards",
        name: "Digital Gift cards",
        component: <GiftsSection />,
        moduleKey: "Gift Cards",
      },
      physicalGiftcards: {
        id: "physicalGiftcards",
        name: "Physical Gift cards",
        moduleKey: "Physical Giftcards",
        component: <PhysicalCardSection subSection="shipmentTracking" />,
        subSections: [
          { id: "shipmentTracking", name: "Shipment Tracking" },
          { id: "orderConfirmed", name: "Order Confirmed" },
          { id: "orderReceived", name: "Order Received in warehouse" },
          { id: "packed&ready", name: "Packed & ready for shipping" },
          { id: "outDelivery", name: "Out for Delivery" },
          { id: "delivered", name: "Delivered" },
          { id: "sentGift", name: "Sent Gift" },
        ],
        selectedSubSection: "shipmentTracking",
      },
      offers: {
        id: "offers",
        name: "Offers",
        component: <OffersSection />,
        moduleKey: "RewardsBy Offers",
      },
      merchandise: {
        id: "merchandise",
        name: "Merchandise",
        moduleKey: "RewardsBy Gifts",
        component: <ShopSection subSection="shipmentTracking" />,
        subSections: [
          { id: "shipmentTracking", name: "Shipment Tracking" },
          { id: "orderConfirmed", name: "Order Confirmed" },
          { id: "orderReceived", name: "Order Received in warehouse" },
          { id: "packed&ready", name: "Packed & ready for shipping" },
          { id: "outDelivery", name: "Out for Delivery" },
          { id: "delivered", name: "Delivered" },
          { id: "sentGift", name: "Sent Gift" },
        ],
        selectedSubSection: "shipmentTracking",
      },
      eMagazines: {
        id: "eMagazines",
        name: "E-Magazines",
        moduleKey: "E-Magazines",
        component: <EMagazineSection />,
      },
      pointsExchange: {
        id: "pointsExchange",
        name: "Points Exchange",
        moduleKey: "Points Exchange",
        component: <PointsExchangeSection />,
      },
      experience: {
        id: "experience",
        name: "Experiences",
        moduleKey: "Experiences",
        component: <ExperienceSection />,
      },
      mobileTopup: {
        id: "mobileTopup",
        name: "Mobile Topup",
        moduleKey: "Mobile TopUp",
        component: <MobileTopupSection />,
      },
      softwareGames: {
        id: "softwareGames",
        name: "Softwares & Games",
        moduleKey: "Software&Games",
        component: <SoftwareGamesSection />,
      },

      // 'software':{id:'software',name:'Software',moduleKey:'Software',component:<SoftwareSection/>},
      // 'games':{id:'games',name:'Games',moduleKey:'Games',component:<GamesSection/>},
      // 'receivedgifts':{id:'receivedgifts',name:'Received Gifts',
      // component:<ReceivedGiftSection lang={this.props.lang}/>}
      travel: {
        id: "travel",
        name: "Flights",
        moduleKey: "Travel shop",
        component: <TravelSection lang={this.props.lang} />,
        active: this.props.modulesData?.["Travel shop"],
      },
    },
  };

  setDesktopSection = (id) => {
    this.setState({
      desktopSection: id,
    });
  };
  componentDidMount() {
    setTimeout(
      () =>
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        }),
      800
    );
    this.props.checkTokenValidity();
    // if(!this.props.authLoading){
    //     this.fetchOrderListHandler()
    // }
    // if( !this.props.loading && this.props.allOrders?.length=== 0){
    //     this.props.history.replace("/404")
    // }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.authError !== "Session Timeout") {
      if (this.props.valid && !this.state.loadingData) {
        this.fetchOrderListHandler();
        this.setState({ loadingData: true });
      } else if (
        this.state.commonLoading &&
        !this.props.loading &&
        this.props.valid
      ) {
        this.setState({ commonLoading: false });
      } else if (
        !this.props.loading &&
        this.state.loadingData &&
        this.props.allOrders?.length === 0
      ) {
        this.props.history.replace({
          pathname: "/404",
          state: "No products are availabe in your wallet",
        });
      }
      if (prevState.mobileSection !== this.props.match.params.categoryName) {
        this.setState({ mobileSection: this.props.match.params.categoryName });
      }
      if (
        prevProps.countrySelected &&
        prevProps.countrySelected !== this.props.countrySelected
      ) {
        this.fetchOrderListHandler();
        this.setState({ loadingData: true, commonLoading: true });
      }
    }
  }
  shouldComponentUpdate(nextProps) {
    if (!nextProps.authLoading) return true;
  }
  setSubsection(id) {
    const { myWalletSections } = this.state;
    myWalletSections[this.state.desktopSection].selectedSubSection = id;
    this.setComponents();
  }

  setComponents() {
    const { myWalletSections } = this.state;
    myWalletSections.all.component = (
      <AllSection {...myWalletSections.all} lang={this.props.lang} />
    );
    myWalletSections.giftCards.component = (
      <GiftsSection lang={this.props.lang} {...myWalletSections.giftCards} />
    );

    // Commenting for next release

    // myWalletSections.eMagazines.component = <EMagazineSection {...myWalletSections.eMagazines}/>
    // myWalletSections.pointsExchange.component = <PointsExchangeSection {...myWalletSections.pointsExchange}/>
    // myWalletSections.hassadGifts.component = <ShopSection {...myWalletSections.hassadGifts}/>
    // myWalletSections.software.component = <SoftwareSection {...myWalletSections.software} />
    // myWalletSections.games.component = <GamesSection {...myWalletSections.games}/>
    myWalletSections.travel.component = (
      <TravelSection {...myWalletSections.travel} />
    );

    this.setState({
      myWalletSections,
      isLoaded: true,
    });
  }
  fetchOrderListHandler = () => {
    const payload = {
      orders: {
        currency: this.props.countrySelected,
      },
    };
    this.props.fetchOrderList(payload, this.props.countrySelected);
  };
  setMobileSection = (id) => {
    // this.props.history.replace(`/mywallet/${id}`)
    this.setState({ mobileSection: id });
  };
  setFilter = (val) => {
    this.setState({ searchFilter: val });
  };
  searchFilter = (list) => {
    return (
      list &&
      list.filter((em) =>
        em.brand_name.match(new RegExp(`${this.state.searchFilter}`, "ig"))
      )
    );
  };
  render() {
    const BreadcrumbLinks = [
      { name: "Home", url: "/", active: true },
      { name: "My Wallet", url: "", active: false },
    ];
    const { myWalletSections, desktopSection, mobileSection } = this.state;
    const { t, modulesData } = this.props;
    return (
      <>
        {
          this.props.authToken ? (
            this.state.commonLoading ? (
              <section>
                <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4 ">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </section>
            ) : (
              <div>
                <Breadcrumbs crumbs={BreadcrumbLinks} />
                <div className="d-md-flex d-none px-5 py-4 ">
                  <div className="align-self-start col-2 p-0 d-flex flex-column overflow-auto">
                    {Object.values(myWalletSections).map(
                      (section) =>
                        (modulesData[section.moduleKey] ||
                          section.id === "all") && (
                          <div
                            className="border-dashed flex-shrink-0 px-1 "
                            key={section.id}
                          >
                            <div
                              id={`mywallet_${section.id}`}
                              className={`fw-bold d-block py-3 cursor-pointer text-capitalize ${
                                desktopSection === section.id
                                  ? "active-link"
                                  : "text-black-50"
                              }`}
                              onClick={() => {
                                this.setDesktopSection(section.id);
                                this.props.history.replace(
                                  `/mywallet/${section.id}`
                                );
                              }}
                            >
                              {t(section.name)}
                            </div>
                          </div>
                        )
                    )}
                  </div>
                  <div className="col-md-10 col-12 p-0">
                    {!this.props.loading &&
                      myWalletSections[desktopSection].component}
                  </div>
                </div>
                <div className="d-md-none d-block px-3 no-gutters">
                  <h3 className="color-blue py-4">
                    <span
                      className="cursor-pointer"
                      onClick={() => this.setMobileSection("")}
                    >
                      {t("My Wallet")}{" "}
                    </span>
                    {mobileSection && (
                      <span className="text-black-50">
                        - {t(myWalletSections[mobileSection].name)}
                      </span>
                    )}
                  </h3>
                  {!this.props.loading && (
                    <section className="mb-5 pb-4">
                      {mobileSection ? (
                        myWalletSections[mobileSection].id !== "travel" ? (
                          <DisplayCards
                            lang={this.props.lang}
                            type={myWalletSections[mobileSection].id}
                            cards={this.props[`${mobileSection}Orders`]}
                            name={myWalletSections[mobileSection].name}
                          />
                        ) : (
                          <TravelSection
                            lang={this.props.lang}
                            type={myWalletSections.travel.id}
                          />
                        )
                      ) : (
                        <>
                          <SearchBar sectionName="" onChange={this.setFilter} />
                          {Object.values(myWalletSections).map(
                            (section) =>
                              (modulesData[section.moduleKey] ||
                                section.id === "all") &&
                              this.props[`${section.id}Orders`]?.length > 0 && (
                                <ProductCards
                                  lang={this.props.lang}
                                  showAll={() =>
                                    this.setMobileSection(section.id)
                                  }
                                  cards={this.searchFilter(
                                    this.props[`${section.id}Orders`]
                                  )}
                                  name={section.name}
                                  key={section.id}
                                />
                              )
                          )}
                          {this.props.modulesData?.["Travel shop"] &&
                            this.props.travelOrders?.length > 0 && (
                              <TravelSection
                                lang={this.props.lang}
                                type={myWalletSections.travel.id}
                                showAll={() => this.setMobileSection("travel")}
                                cards={this.props.travelOrders}
                                name={"Flights"}
                                myWalletHomePage={true}
                                disableSearchBar={true}
                              />
                            )}
                        </>
                      )}
                    </section>
                  )}
                </div>
              </div>
            )
          ) : !this.props.valid ? (
            <MainLoginModel
            toogleLang={this.props.toogleLang}
              show={this.state.showPopup}
              loginFun={() =>
                this.setState({ showPopup: !this.state.showPopup })
              }
            />
          ) : null
          // <LoginModal show={true} close={()=>this.props.history.goBack()}/>
        }
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modulesData: state.categories.moduleInfo,
    authToken: state.auth.authToken,
    authError: state.auth.error,
    loading: state.myWallet.loading,
    authLoading: state.auth.loading,
    allOrders: [
      ...state.myWallet.eCardEMagOrders,
      ...state.myWallet.merchantOrders,
    ],
    giftCardsOrders: state.myWallet.eCardEMagOrders.filter(
      (order) => order.order_type === "USER_ORDER" && order.emagazine_flag === 0
    ),
    eMagazinesOrders: state.myWallet.eCardEMagOrders.filter(
      (order) => order.emagazine_flag === 1
    ),
    pointsExchangeOrders: state.myWallet.eCardEMagOrders.filter(
      (order) => order.order_type === "POINTS_EXCHANGE"
    ),
    merchandiseOrders: state.myWallet.merchantOrders,
    physicalGiftcardsOrders: state.myWallet.physicalGiftcardsOrders,
    countrySelected: state.countries.countryId,
    currancyCode: state.countries.convertionRate.currency_code,
    offersOrders: state.myWallet.offersOrders,
    experienceOrders: state.myWallet.experienceOrders,
    mobileTopupOrders: state.myWallet.mobileTopUpOrders,
    receivedgiftsOrders: state.myWallet.receivedGifts,
    receiveOffersOrder: state.myWallet.receivedOffer,
    softwareOrders: state.myWallet.softwareOrders,
    gamesOrders: state.myWallet.gamesOrders,
    fetchError: state.myWallet.error,
    valid: state.auth.valid,
    pointsLoading: state.point.loading,
    Tokenloading: state.auth.tokenLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrderList: (payload, countrySelected) =>
      dispatch(fetchOrderList(payload, countrySelected)),
    checkTokenValidity: () => dispatch(checkTokenValidity()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(MyWallet));
