import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { hassadMallCategory } from '../../Redux/categories/categoriesAction'

class MobileCategory extends Component {
    componentDidMount(){
        if(typeof this.props.categoriesData === 'undefined'){
            this.props.fetchHassadCategories()
        }
    }
    render() {
        let catLinks = {
            "Gift Cards" : "/giftcards",
            "RewardsBy Gifts" : "/gifts",
            "Points Exchange":"/points-exchange",
            "E-Magazines" : "/e-magazines",
            "Mobile TopUp" : "/mobileTopUp",
            "Experiences" : "/experiences",
            "RewardsBy Offers": "/offers",
            "Games":"/games",
            "Software&Games":"/softwares&games"
        }
        return (
            <>
            {
                this.props.loading ? <div className="d-block d-md-none text-center px-0 container content-box"  >
                {
                    this.props.categoriesData?.map(e => 
                        e.active && <Link to={catLinks[e.module_name]}>
                        <div style={{position:'relative'}}>
                        <img style={{filter:'brightness(50%)'}} className='img-fluid my-2 w-100' src={e?.module_image?.medium_rectangle} alt="banner"/>
                        <h3 className='categoryText'>{this.props.lang !== 'ar' ? e?.module_name : e?.module_name_arabic}</h3>
                        </div>      
                    </Link>
                        )
                }
            </div> : <div className="d-flex justify-content-center pt-2 pb-2">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
            }
            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        categoriesData:state.categories.hassadCategories,
        loading :state.categories.loading,
        
    }
}


const mapDispatchToProps = dispatch => {
    return {
        fetchHassadCategories: () => dispatch(hassadMallCategory()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)((withTranslation()(MobileCategory)));
