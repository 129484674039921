/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
import thumbLogo from "../../assests/img/header/logo2.svg";
import enfactem from "../../assests/img/header/enfactemlogo.png"
import "../../assests/css/App.css";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// import searchbtnIcon from "../../assests/img/header/btn-search.png";
import searchbtnIcon from "../../assests/img/header/btn-search2.png";
// import searchIcon from "../../assests/img/header/btn-search.png";
import searchIcon from "../../assests/img/header/icon-search.svg"
import giftIcon from "../../assests/img/header/icon-gift-points.png";
import searchBanner from "../../assests/img/search-banner.png";
import {
  setCountryHandler,
  fetchConversionRate,
} from "../../Redux/country/countriesAction";
import backButton from "../../assests/img/arrow-back.svg";
import MainLoginModel from "./Modals/MainLoginModel";
import { logout } from "../../Redux/auth/authActions";
import { checkTokenValidity } from "../../Redux/auth/authActions";
import { fetchPointBalance } from "Redux/point/pointActions";
import RedemptionSuccesfull from "./Modals/RedemptionSuccesfull";
import RedemptionSuccesfullForLogged from './Modals/RedeemSuccesfulForLogged'
import axios from "axios";
import { REACT_APP_baseUrl } from "utils/app-constants";
import Loader from "./Loader";
import ResetPassword from "./Modals/ResetPassword";
import cookie from 'react-cookies';
import { logoHandler } from "utils/Theme/Logo-handler";
// import SignUpInstruction from "./Modals/SignUpInstruction";

class SearchHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchList: "",
      searchAutoCompleteList: [],
      findCountry: "",
      countryName: [],
      brandsList: [],
      categoriesList: [],
      displayAutoCompleteList: true,
      showPopup: false,
      showLogin: false,
      forgetPass: false,
      isLogin: true,
      token: '',
      redemedPop: false,
      giftCardNo: "",
      redeemValid: false,
      selectedLogin: false,
      redeemDetailsForLoggedUser:{
        showPopup:false,
        redeemSuccess:false,
        message:'',
        showSpinner:false,
        giftCardNumber:''
      },
      redeemMsg:'',
    };
  }
  componentDidMount() {
    this.searchAutoCompleteListHandler();
    // this.props.checkTokenValidity();
    if (this.props.location.pathname.includes("forgotPassword") || this.props.location.pathname.includes("auth/signup")) {
      this.setState({ forgetPass: true });
    }
    this.props.fetchPointBalance(localStorage.getItem("userToken"))


    if (this.props.location.pathname == "/redeem-card" && !this.props.valid) this.checkToken();
    else if (this.props.location.pathname == "/redeem-card" && this.props.valid){
      this.checkRedemptionPoints()
    }
  }
  
  redeemPointsForLoggedUser = (giftCardNumber) => {
    let authToken = localStorage.getItem("userToken");
    axios.post(`${REACT_APP_baseUrl}/giftcards/add_mylist_card`,
    {
        "mylist_card": {
            "card_number": giftCardNumber
        }
    },{headers: {"Authorization": `Bearer ${authToken}`}}
).then((res) => {
  if(res.data.code === 200){
    this.props.fetchPointBalance(localStorage.getItem("userToken"))
    this.setState({
      redeemDetailsForLoggedUser:{
        showPopup:true,
        redeemSuccess:true,
        showSpinner:false,
        giftCardNumber:giftCardNumber
      }
    })
  }
  else if(res.data.code === 400){
    this.setState({
      redeemDetailsForLoggedUser:{
        showPopup:true,
        redeemSuccess:false,
        showSpinner:false,
        giftCardNumber:giftCardNumber,
        responseMessage:res.data?.message
      }
    })
  }
}).catch((err) => console.err(err))
  }
  checkRedemptionPoints = () => {
    this.setState(prevState => ({
      redeemDetailsForLoggedUser: {                  
          ...prevState.redeemDetailsForLoggedUser,    
          showSpinner: true    
      }}))
  
    const redeemToken = this.props.location.search.split("?token=")?.[1];
    axios.get(`${REACT_APP_baseUrl}/giftcards/fetch_giftcard`,
                {
                    params: {
                        "token": redeemToken
                    }
                }
            )
                .then((res) => {
                    if (res.data.code === 200) {
                        if (res.data.data.giftcard.remaining_value > 0) {
                            this.redeemPointsForLoggedUser(res.data.data.giftcard.number)
                        }
                        else {
                          this.setState({
                            redeemDetailsForLoggedUser:{
                              showPopup:true,
                              redeemSuccess:false,
                              showSpinner:false,
                            }
                          })
                        }
                    }
                    else{
                      this.setState({
                        redeemDetailsForLoggedUser:{
                          showPopup:true,
                          redeemSuccess:false,
                          showSpinner:false,
                          responseMessage:res.data?.message
                        }
                      })
                    }
                })
                .catch((err) => {
                    console.error(err)
                })
  }
  checkToken = ()=>{
    const token = this.props.location.search.split("?token=")
    if (token[1]){
        this.setState({
          showLogin: true,
          isLogin: false,
          token: token[1]
        })
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      (typeof prevProps.gift === "undefined" &&
        typeof this.props.gift === "object") ||
      prevProps.offers?.length !== this.props.offers?.length ||
      prevProps.experienceCards?.length !== this.props.experienceCards?.length ||
      prevProps.games?.length !== this.props.games?.length ||
      prevProps.softwares?.length !== this.props.softwares?.length ||
      prevProps.products?.length !== this.props.products?.length
    ) {
      this.searchAutoCompleteListHandler();
    }
    if (prevProps.currancyCode !== this.props.currancyCode) {
      this.searchAutoCompleteListHandler();
    }
  };

  // redeem popup show

  redeemPopShow = (val, valid, islogin,redeemMsg)=>{
    this.setState({ redemedPop: !this.state.redemedPop, giftCardNo: val, redeemValid: valid, selectedLogin: islogin, redeemMsg : redeemMsg });
  }

  closeRedeemModal = () => {
    this.setState({redeemDetailsForLoggedUser:{
showPopup:false,
      redeemSuccess:false,
      message:'',
      showSpinner:false,
      giftCardNumber:''
    }},() => {
      this.props.history.push('/')
    })
  }



  // Login pop toggle function
  LoginPopUp = () => {
    this.setState({ showLogin: !this.state.showLogin });
  };

  loginFunClose = () => {
    this.setState({ showLogin: false });
  }

  searchAutoCompleteListHandler = async () => {
    const { modulesData } = this.props;
    const giftCardsStatus = modulesData["Gift Cards"];
    const emagStatus = modulesData["E-Magazines"];
    const pointsExchangeStatus = modulesData["Points Exchange"];
    const mobileTopupStatus = modulesData["Mobile TopUp"];
    //fetch the search autocomplete list and change the state
    let data = [];
    const productList = [];
    const brandsList = [];
    const merchandiseProducts = [];
    // const mProductsList = []
    const offerCategory = [];
    let experienceCards = [];
    let eMags = [];
    let pointsExchange = [];
    let topup = [];
    let gamesCards = [];
    let softwareCards = [];
    //giftcards search list
    // const categoryList = giftCardsOrder.map((li) => (
    //     {
    //         item_name: li.name,
    //         item_name_arabic: (li.item_name_arabic==null ||li.item_name_arabic==undefined)?"":li.item_name_arabic,
    //         id: li.category_id,
    //         type: "category"
    //     }
    // ))
    this.props.gift?.forEach((li) => {
      li.brands?.forEach((br) => {
        if (br.emagazine_flag && !br.point_exchange_flag && emagStatus) {
          eMags.push({
            item_name: br.name,
            item_name_arabic: br.name_arabic,
            id: br.id,
            type: "eMagazines",
            category_name: li.name,
          });
        } else if (
          !br.point_exchange_flag &&
          !br.is_topup &&
          !br.emagazine_flag &&
          giftCardsStatus
        ) {
          productList.push({
            item_name: br.name,
            item_name_arabic: br.name_arabic,
            id: br.id,
            type: "brand",
            category_name: li.name,
          });
        } else if (br.point_exchange_flag && pointsExchangeStatus) {
          pointsExchange.push({
            item_name: br.name,
            item_name_arabic: br.name_arabic,
            id: br.id,
            type: "pointsExchange",
            category_name: li.name,
          });
        } else if (br.is_topup && mobileTopupStatus) {
          topup.push({
            item_name: br.name,
            item_name_arabic: br.name_arabic,
            id: br.id,
            type: "MobileTopUp",
            category_name: li.name,
          });
        }
        brandsList.push(br);
      });
    });
    //products search list

    modulesData["RewardsBy Gifts"] &&
      this.props.products?.forEach((li) => {
        merchandiseProducts.push({
          item_name: li.product_name,
          item_name_arabic: li.product_name_arabic,
          id: li.id,
          type: "products",
          category_name: "",
        });
      });

    modulesData["RewardsBy Offers"] &&
      this.props.offers?.forEach((li) => {
        offerCategory.push({
          item_name: li.title,
          item_name_arabic: li.title_arabic,
          id: li.id,
          type: "offers",
          category_name: "",
        });
      });

    modulesData["Experiences"] &&
      this.props.experienceCards?.forEach((li) => {
        experienceCards.push({
          item_name: li.name,
          item_name_arabic: li.name_arabic,
          id: li.id,
          type: "experiences",
          category_name: "",
        });
      });

    modulesData["Software&Games"] &&
      this.props.games?.forEach((products) => {
        products.software_and_games?.forEach((li) => {
          if(li.type === "game"){
            gamesCards.push({
              item_name: li.name,
              item_name_arabic: li.name_arabic,
              id: li.id,
              type: "games",
              category_name: "",
            });
          }
        });
      });

    modulesData["Software&Games"] &&
      this.props.softwares?.forEach((products) => {
        products.software_and_games?.forEach((li) => {
          if(li.type === "software"){
            softwareCards.push({
              item_name: li.name,
              item_name_arabic: li.name_arabic,
              id: li.id,
              type: "softwares",
              category_name: "",
            });
          }
        });
      });

    const completeList = [
      ...productList,
      ...merchandiseProducts,
      ...offerCategory,
      ...experienceCards,
      ...eMags,
      ...pointsExchange,
      ...topup,
      ...gamesCards,
      ...softwareCards,
    ];
    completeList.sort((a, b) => a.item_name.localeCompare(b.item_name));
    this.setState({
      searchAutoCompleteList: [...completeList],
      brandsList,
      categoriesList: data,
    });
  };
  searchFilter = (e, value, showList) => {
    this.setState({
      searchList: value.replace(/[^0-9a-zA-Z\u0600-\u06FF\s]/gi, ""),
      // displayAutoCompleteList:showList
    });
  };
  searchSubmitHandler = (e, item) => {
    this.searchFilter(e, "", false);
    this.setShowPopup(false);
    //Redirect to search page
  };

  hideSearchAutoCompleteList = () => {
    this.setState({
      displayAutoCompleteList: false,
    });
  };

  logout = () => {
    this.props.logout();
  };

  handleClick = (e) => {};

  changeCountry = (e, value) => {
    this.setState({
      findCountry: value,
    });
  };
  getBrand = (id) => {
    return this.state.brandsList.find((brand) => brand.id === id);
  };
  getGetSearchRedirectUrl = (item) => {
    if (item.type === "category") {
      return `/category/${item.item_name}`;
    } else {
      switch (item.type) {
        case "brand":
          return `/selectedgiftcard/${item.id}`;
        case "products":
          return `/gift/${item.id}`;
        case "offers":
          return `/offer/${item.id}`;
        case "experiences":
          return `/experience/${item.id}`;
        case "eMagazines":
          return `/e-magazines/${item.id}`;
        case "pointsExchange":
          return `/points-exchange/${item.id}`;
        case "MobileTopUp":
          return `/mobileTopUp`;
        case "games":
          return `/games/${item.id}`;
        case "softwares":
          return `/softwares/${item.id}`;
        default:
          return `/gift/${item.id}`;
      }
    }
  };
  setShowPopup = (val) => {
    this.setState({
      showPopup: val,
    });
  };
  getSearchAutoCompleteList = () => {
    const regx = new RegExp(this.state.searchList, "i");
    let list = [];
    if (this.props.lang !== "ar") {
      list = this.state.searchAutoCompleteList.filter(
        (item) => item.item_name.search(regx) !== -1
      );
    } else {
      list = this.state.searchAutoCompleteList.filter(
        (item) => item.item_name_arabic.search(regx) !== -1
      );
    }

    list = list.length > 10 ? list.slice(0, 10) : list;
    return list.map((item) => (
      <Link
        key={item.id}
        className="w-100 list-group-item border list-group-item-action"
        to={{
          pathname: this.getGetSearchRedirectUrl(item),
          state:
            item.type === "brand"
              ? {
                  ...this.getBrand(item.id),
                  category_name: item.category_name,
                }
              : null,
        }}
        onClick={(e) => {
          this.searchSubmitHandler(e, item);
        }}
      >
        {this.getSearchAutoCompleteDisplayName(
          item.item_name,
          item.item_name_arabic,
          this.state.searchList,
          regx
        )}
      </Link>
    ));
  };
  getSearchAutoCompleteDisplayName = (
    item_name,
    item_name_arabic,
    val,
    regx
  ) => {
    const startIndex =
      this.props.lang !== "ar"
        ? item_name.search(regx)
        : item_name_arabic.search(regx);
    const endIndex = startIndex + val.length;
    const displayName = (
      <span>
        {this.props.lang !== "ar"
          ? item_name.substring(0, startIndex)
          : item_name_arabic.substring(0, startIndex)}
        <b>
          {this.props.lang !== "ar"
            ? item_name.substring(startIndex, endIndex)
            : item_name_arabic.substring(startIndex, endIndex)}
        </b>
        {this.props.lang !== "ar"
          ? item_name.substring(endIndex, item_name.length)
          : item_name_arabic.substring(endIndex, item_name.length)}
      </span>
    );
    return displayName;
  };
  numberWithCommas = (x) => {
    let num = +x;
    let y = num.toFixed(2);
    return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  render(){
    const { t, countries } = this.props;
    const { countryName, searchList, displayAutoCompleteList, forgetPass,redeemDetailsForLoggedUser } =
      this.state;

    const name = cookie.load("firstname")
    
    const desktopView = (
      <>
        <div
          className="d-none d-md-flex  align-items-center p-3 px-md-4 m-0 bg-white border-bottom "
          style={{ zIndex: "1500" }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          style={{ top: "2.4rem" }}
        >
          <Link
            className="col-2 p-0"
            to={{
              pathname: "/",
            }}
          >
            <img
              src={logoHandler()}
              href="/"
              alt="logo"
              className="img-fluid mr-md-auto"
              id="lg-fix"
            />
          </Link>
          <div
            className={`px-4 col-8`}
          >
            <div className="col px-4">
              <div className="d-flex px-1">
                <div className="flex-grow-1 px-0 text-center">
                  <input
                    type="text"
                    className="form-control p-4  rounded-0 search-input outline-color-skyblue no-outline"
                    onChange={(e) => this.searchFilter(e, e.target.value, true)}
                    onBlur={(e) => {
                      this.searchFilter(e, e.target.value, false);
                    }}
                    value={this.state.searchList}
                  />
                </div>
                <div className="px-0">
                  <button
                    className="h-100 w-100 px-3 border-0 primary-search-btn text-light"
                    onClick={(e) => this.handleClick(e)}
                  >
                    {t("Search")}
                  </button>
                </div>
                {searchList.length >= 1 && displayAutoCompleteList ? (
                  <div className="search-autocomplete-list rounded-0 pr-5 list-group w-100 position-absolute ">
                    {this.getSearchAutoCompleteList()}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/* <div
            className={`col-2 px-0 mx-n3  ${
              this.props.lang === "ar" ? "selectdiv selectdivar" : "selectdiv"
            }`}
          >
            <select
              className={`d-inline ${
                this.props.lang === "ar" ? "pr-2" : "px-auto"
              }`}
              value={this.props.countryId}
              onChange={({ target: { value } }) => {
                this.props.setCountryHandler(value);
              }}
            >
              {!this.props.countriesloading &&
                this.props.countries &&
                this.props.countries.length > 0 &&
                countries.map((country) => (
                  <option key={country.id} value={country.id}>
                    {this.props.lang === "en"
                      ? country.country_name
                      : country.country_name_arabic}
                  </option>
                ))}
            </select>
          </div> */}
          {
             <div
             className={`col-2  px-0 ${
               this.props.lang === "ar" ? "LoginBtn2" : "LoginBtn1"
             }`}
           >
             { this.props.valid && this.props.point ? 
                 <div className="text-center ">
                   {/* <h6 className="text-black-50 m-0 mt-2">{t("Available Points")}</h6>
                   <h5 className="availablePoints">
                     {this.numberWithCommas(this.props.point)}
                   </h5> */}
                   {
                this.props.authToken &&
                    <ResetPassword lang={this.props.lang} username={name}/>
            }
                 </div>
             : !this.props.valid && !this.props.loading? 
               <button
                 className="border-0 primary-search-btn text-light"
                 onClick={this.LoginPopUp}
               >
                 {t("Sign In")}
               </button> : null
             }
           </div>
          }
        
       <RedemptionSuccesfull show={this.state.redemedPop} giftCardNo={this.state.giftCardNo} redeemPopShow={this.redeemPopShow} redeemValid={this.state.redeemValid}  selectedLogin={this.state.selectedLogin} responseMessage={this.state.redeemMsg}/>
        {/* {
          this.props.SignUpMessage && <SignUpInstruction lang={this.props.lang} show={this.props.SignUpMessage}/>
        } */}
        </div>
      </>
    );

    const mobileView = (
      <>
        <div
          className="d-md-none px-3 py-1 position-sticky bg-light shadow-sm border"
          style={{ top: "2.2rem",zIndex:"1000"}}
        >
          <div className=" d-flex align-items-center justify-content-between">
            {this.props.location.pathname !== "/" && (
              // <div className="col-2 fs-3 cursor-pointer color-skyblue text-right" onClick={()=>{this.props.history.goBack()}} style={{transform:'rotate(180deg)'}}>
              //     <section style={{transform: `${this.props.lang==='ar'?"rotate(180deg)":""}`}}>➜</section>
              <div
                className=" px-3 fs-3 cursor-pointer color-skyblue text-right"
                onClick={() => {
                  this.props.history.goBack();
                }}
              >
                <section
                  style={{
                    transform: `${
                      this.props.lang === "ar" ? "rotate(180deg)" : ""
                    }`,
                  }}
                >
                  <section
                    style={{
                      marginTop: `${this.props.lang === "ar" ? "8px" : ""}`,
                    }}
                  >
                    <img src={backButton} alt="" />
                  </section>
                </section>
              </div>
            )}
            <div
              className={`flex-grow-1 p-0 d-flex ${this.props.lang === "ar" && "w-0"}`}
              onClick={() => {
                this.setShowPopup(true);
              }}
            >
              <div className="border d-inline-block flex-grow-1 p-2"></div>
              <img
                className={`img-fluid float-right ${this.props.lang === "ar" ? "width-15" : "width-12"}`}
                src={searchbtnIcon}
                alt="sarch"
              />
            </div>
            
              {
                this.props.point && this.props.valid ? 
                <div className="d-flex align-items-center justify-content-end p-0">
                {/* <img src={giftIcon} alt="giftIcon" /> &nbsp;
                <span className="fw-bold px-auto pt-1">
                {" "}
                    {this.numberWithCommas(this.props.point)} {t("Points")}
                  </span> */}
                  {
                    this.props.authToken &&
                    <ResetPassword lang={this.props.lang} username={name}/>
                  }

                </div> : 
                !this.props.valid && !this.props.loading ? 
                <div
                className={`px-0 ${
                  this.props.lang === "ar" ? "LoginBtn4" : "LoginBtn3"
                }`}
              >
                <button
              className="border-0 primary-search-btn text-light pl-2 pr-2"
              onClick={this.LoginPopUp}
              >
                {t("Login")}
              </button> 
          </div>
              : null
            }
            
            {this.state.showPopup && (
              <div
                className=" position-fixed bg-light"
                style={{
                  top: "33px",
                  left: "0",
                  height: "100vh",
                  width: "100vw",
                }}
              >
                <div className={`d-flex p-2 pb-3 shadow ${this.props.lang && "mt-2"}`}>
                  <div className="flex-grow-1 px-0 text-center">
                    <input
                      autoFocus
                      type="text"
                      className="form-control p-3  rounded-0 outline-color-skyblue border-color-skyblue no-outline"
                      onChange={(e) =>
                        this.searchFilter(e, e.target.value, true)
                      }
                      onBlur={(e) => {
                        this.searchFilter(e, e.target.value, false);
                      }}
                      value={this.state.searchList}
                    />
                  </div>
                  <div className="px-0">
                    <button
                      className="h-100 w-100 border-0 primary-btn text-light btn rounded-0 px-2 py-0"
                      onClick={(e) => this.handleClick(e)}
                    >
                      <img className="img-fluid" src={searchIcon} alt="search" />
                    </button>
                  </div>
                  <button
                    className="btn text-decoration-none color-skyblue"
                    onClick={() => {
                      this.setShowPopup(false);
                    }}
                  >
                    {t("Cancel")}
                  </button>
                </div>
                <div
                  className="d-flex position-relative align-items-center justify-content-center"
                  style={{ height: "100vh" }}
                >
                  {this.props.brandLoading ? (
                    <section>
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </section>
                  ) : (
                    <div>
                      <img
                        src={searchBanner}
                        className="w-100"
                        alt="searchbanner"
                      />
                    </div>
                  )}

                  {searchList.length >= 1 && displayAutoCompleteList && (
                    <div
                      className="search-autocomplete-list rounded-0 list-group w-100 position-absolute "
                      style={{ top: "0" }}
                    >
                      {this.getSearchAutoCompleteList()}
                    </div>
                  )}
                </div>
              </div>
            )}
                <RedemptionSuccesfull show={this.state.redemedPop} giftCardNo={this.state.giftCardNo} redeemPopShow={this.redeemPopShow} redeemValid={this.state.redeemValid}  selectedLogin={this.state.selectedLogin} responseMessage={this.state.redeemMsg}/>
        {/* {
          this.props.SignUpMessage && <SignUpInstruction lang={this.props.lang} show={this.props.SignUpMessage}/> 
        } */}
          </div>
        </div>
      </>
    );
    return (
      <>
        {!forgetPass && (
          <>
            {desktopView}
            {mobileView}
          </>
        )}
        {
          this.state.redeemDetailsForLoggedUser.showSpinner && <div style={{ height: '500px', position: 'absolute', top: '50%', left: '50%' }}>
          <Loader />
      </div>
        }
        {this.state.showLogin ? (
          <MainLoginModel
            toogleLang={this.props.toogleLang}
            show={this.state.showLogin}
            loginFun={this.LoginPopUp}
            loginFunClose={this.loginFunClose}
            lang={this.props.lang}
            isLogin={this.state.isLogin}
            token={this.state.token}
            redeemPopShow={this.redeemPopShow}
          />
        ) : null}
        <RedemptionSuccesfullForLogged show={redeemDetailsForLoggedUser.showPopup} giftCardNo={redeemDetailsForLoggedUser.giftCardNumber} redeemSuccess={redeemDetailsForLoggedUser.redeemSuccess} responseMessage={redeemDetailsForLoggedUser.responseMessage}  closeModal={this.closeRedeemModal}/>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.tokenLoading,
    countries: state.countries.countries,
    countriesloading: state.countries.loading,
    currancyCode: state.countries.convertionRate.currency_code,
    countryId: state.countries.countryId,
    point: state.point.pointBalance,
    pointsLoading: state.point.loading,
    brandLoading: state.brand.loading,
    gift: state.brand.brandData,
    valid: state.auth.valid,
    offers: state.offers.offersList,
    experienceCards: state.experiences.experienceCards,
    categories: state.merchandise.categories,
    products: state.merchandise.products,
    games:state.softwareGames.softwareGamesCards,
    softwares:state.softwareGames.softwareGamesCards,
    modulesData: state.categories.moduleInfo,
    SignUpMessage: state.auth?.SignUpMessage,
    authToken:state.auth.authToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCountryHandler: (val) => dispatch(setCountryHandler(val)),
    fetchConversionRate: (val) => dispatch(fetchConversionRate(val)),
    logout: () => dispatch(logout()),
    checkTokenValidity: () => dispatch(checkTokenValidity()),
    fetchPointBalance: (val) => dispatch(fetchPointBalance(val))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SearchHeader));
