// import axios from 'axios';
import React, { Component } from 'react';
// import { Accordion, Card } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


class AboutAndTC extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            selected:'t&c'
         }
    }
    setSelected=(val)=>{
        this.setState({
            selected:val
        })
    }


   
    render() { 
        // const experienceDetails = this.props.experienceDetails
        const {t} = this.props
        const offerDetails = this.props.prodDetails;
        return ( 
            <div>
                <div className="d-grid grid-cols-2 text-center">
                    <div className={`py-2 ${this.state.selected==='about' ? 'primary-btn text-light' :'border-color text-muted'}`}
                        onClick={()=>{this.setSelected('about')}}>
                        {t("About")}
                    </div>
                    <div className={`py-2 ${this.state.selected==='t&c' ? 'primary-btn text-light' :'border-color text-muted'}`}
                        onClick={()=>{this.setSelected('t&c')}}>
                        {t("T&Cs")}
                    </div>
                </div>
                {
                    this.state.selected==='about' &&
                    <div className='py-4'>
                                           <div className='py-3'>
                                                 {
                                                  offerDetails &&  this.props.lang !== 'ar' && offerDetails.description?.includes("/n") ? offerDetails.description?.split('\n')?.map((e,index,array)=>(
                                                        index !== array.length - 1 ? <li key={index}> {`${e}.`} </li> : ''
                                                    )) : offerDetails &&  this.props.lang !== 'ar' && offerDetails.description?.length > 0 ?
                                                    <li>{offerDetails?.description}</li> :
                                                    offerDetails.description_arabic.includes("/n") ? offerDetails.description_arabic?.split('\n')?.map((e,index,array)=>(
                                                        index !== array.length - 1 ? <li key={index}> {`${e}.`} </li> : ''
                                                    )) : <li>{offerDetails?.description_arabic}</li>
                                                    
                                                    
                                                }
                                           </div>
                        
                    </div>
                }
                {
                    this.state.selected==='t&c' &&
                    <div className='py-4'>
                        {/* <div className='fw-bold color-blue fs-5'>{t("Included in the price")}</div> */}
                        <div className='py-4'>
                        {
                            this.props.lang!=='ar'?
                            this.props.merchandiseTerms &&  this.props.merchandiseTerms.data?.terms[0]?.terms_text?.split("\n")
                            .map(terms=>
                                <div key={terms}>
                                    {terms}
                                </div>
                                )
                            :
                            this.props.merchandiseTerms && this.props.merchandiseTerms.data?.terms[1]?.terms_text?.split('\n')
                            .map(terms=>
                                <div key={terms}>
                                    {terms}
                                </div>
                                )
                        }
                        </div>
                        {/* <div className='text-danger fw-bold'>Lorem ipsum dolor sit amet consectetur adipisicing elit.</div> */}
                    </div>
                }
            </div>
         );
    }
}
const mapStateToProps = state => {
    return {
        merchandiseTerms:state.merchandise.merchandiseTerms,
        prodDetails:state.merchandise.prodDetails,
    }
}



export default connect(mapStateToProps)(withTranslation()(AboutAndTC));
