import React, { Component } from 'react'
import './Buygift.css'
import  Message from '../../../assests/img/giftflow/message.svg';
import { withTranslation } from 'react-i18next';

class Yourmessage extends Component {

    constructor(props) {
        super(props);
        this.state={
            messageFill:false,
            messageError: false,
        }
    }


    validate(val){
        if (val===""){
            this.setState({...this.state,messageFill:false},()=>(this.state.messageFill)? 
            this.props.nextStep()
            : this.setState({...this.state.messageFill, messageError:true}));
        }
        else{
            this.setState({...this.state,messageFill:true},()=>{
                this.state.messageFill? this.props.nextStep(): this.setState({...this.state.messageFill, messageError:true});
            })
        }
    }
   
    render() {
        const { message, changeFormState, GiftMessage, t, lang} = this.props;
        let textInput = React.createRef();

        const desktopView = (
            <div className="d-md-block d-none">
                <div className="d-flex align-items-center">
                    <p className={`color-blue mt-4 mainheadselect ${lang === "ar" ? "pr-4" : 'pl-4'}`}>{t("Your message")}</p>
                    {
                        (GiftMessage)?
                        <img className={`desktopmessageicon mt-2 ${lang === "ar" ? "mr-2" :"ml-2"}`} src={Message} alt="" />:null
                    }
                </div>
                <div className="px-4 py-3">
                    <textarea 
                    className="yourinput"
                    type="text" 
                    ref={textInput}
                    value={message}
                    onChange={(e)=>{
                        changeFormState('message', e.target.value);
                        this.setState({...this.state.messageFill, messageError:false})
                    }}
                    placeholder={t("You've got a my Hassad gift card")+"."+ t("Happy Birthday to you")+". "+t("This Hassad gift card is for you")+". "+t("Hope you will enjoy this!")}
                    />
                    <p className="text-danger">{(this.state.messageError)?t("Please enter the message"):""}</p>
                </div>
                <div className="w-100 text-right px-4 mb-4">
                    <button 
                        className="btn btn-primary px-5 rounded-0 mb-5"
                        onClick={()=>this.validate(textInput.current.value)}>
                        {t("Proceed")} 
                    </button>
                </div>
            </div>
        );

        const mobileView = (
            <div className="d-md-none d-block">
            <div className="d-flex align-items-center">
                <p className={`color-blue mt-4 mainheadselect ${lang === "ar" ? "pr-4" : 'pl-4'}`}>{t("Your message")}</p>
                {
                        (GiftMessage)?
                        <img className={`desktopmessageicon mt-2 ${lang === "ar" ? "mr-2" :"ml-2"}`} src={Message} alt="" />:null
                    }
            </div>
            <div className="px-3">
                <textarea 
                className="yourinput"
                type="text" 
                ref={textInput}
                value={message}
                onChange={(e)=>{
                    changeFormState('message', e.target.value);
                    this.setState({...this.state.messageFill, messageError:false})
                }}
                placeholder={t("You've got a my Hassad gift card")+"."+ t("Happy Birthday to you")+". "+t("This Hassad gift card is for you")+". "+t("Hope you will enjoy this!")}
                />
                <p className="text-danger">{(this.state.messageError)? t("Please enter the message"):""}</p>
            </div>
            <div className="w-100 text-center">
                <button 
                    className="btn btn-primary messageproceed"
                    onClick={ ()=>this.validate(textInput.current.value)}>
                    {t("Proceed")} 
                </button>
            </div>
            </div>
        );

        return (
            <>
                {desktopView}
                {mobileView}
            </>
        )
    }
}

export default (withTranslation()(Yourmessage))