import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {currancyToPoints} from "../../utils/pointsConversion" 
class ProductGiftsCard extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    numberWithCommas = (x) => {
        // let y = x.toFixed(0)
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    render() { 
        const card= this.props.card
        const {t} = this.props
        return ( 
            <div className='' key={this.props.key}>
                 <Link className='offerCardHover' to={`/gift/${card.id}`}>
                    <div style={{cursor:'pointer'}} className="border d-flex flex-column pb-1 h-100">
                <img
                    className="img-fluid w-100"
                    src={card.image && card.image.small_rectangle } alt={card.title} />
                <div className='px-2'>
                <h5 className="headings m-0 d-inline-block"
                        style={{minHeight:"75px",textOverflow:'ellipsis',fontSize:'1.1rem'}}>{this.props.lang!=='ar'?card.product_name:card.product_name_arabic}</h5>
                        {/* <div style={{minHeight:"70px"}}className="text-black-50">by {card.brand_name}</div> */}
                </div>
                <div style={{flexGrow:'1',alignItems:'flex-end'}} className="d-flex px-2">
                <div>
                <h4 className="d-inline">{this.numberWithCommas(currancyToPoints(card.total_cost))} </h4>
                <span className='py-2'>{t("Points")}</span>
                </div>
                </div>
                
            </div>
                 </Link>
            </div>
         );
    }
}
 
export default withTranslation()(ProductGiftsCard);