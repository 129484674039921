import React, { Component } from 'react';
import ModalWrapper from './ModalWrapper';
import { withTranslation } from 'react-i18next';

class TermsConditionModal extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            show:false,
            terms:'',
            isLoaded:false
         }
    }
    componentDidMount(){
        const url = "https://run.mocky.io/v3/79ee31a7-2dd2-4fb0-b09f-71cac368f6d2"
        //fetch the search autocomplete list and change the state
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            response.json().then((resp) => {
                const terms = resp.data.terms;
                this.setState({
                        terms,
                        isLoaded:true
                    })
                })
            }
        )
    }
    setModalView=()=>{
        this.setState(prevState=>({
            show:!prevState.show
        }))
    }
    render() { 
        const {t}=this.props
        return ( 
                <>
                    <div 
                        className=" cursor-pointer custom-control custom-checkbox">   
                        <input 
                            type="checkbox" 
                            className="custom-control-input" 
                            id="customCheck2"/>
                        <label 
                            className="custom-control-label pl-1" 
                            htmlFor="customCheck2">
                            {t("I have read and accepted")}
                        </label>
                        <br/>
                        <u 
                            className="color-skyblue px-1"
                            onClick={()=>{this.setModalView()}}>
                             {t("Terms & Conditions")}
                        </u>
                    </div>
                    {
                        <ModalWrapper show={this.state.show}>
                            <div className="p-4 bg-light mx-auto">
                                <h3 className="color-skyblue text-center">
                                    Terms & Conditions
                                
                                </h3>
                                <hr className="hr-line m-0 p-0 w-100"/>
                                <pre className="p-3 text-black-50">
                                    {this.state.isLoaded?
                                        this.state.terms[0].terms_text:null
                                    }
                                </pre>
                                <hr className="hr-line"/>
                                <div className="d-flex justify-content-end bg-light">
                                    <button className="btn btn-primary rounded-0 mx-auto"
                                            onClick={()=>{this.setModalView()}}>
                                        Accept
                                    </button>
                                </div>
                            </div>
                        </ModalWrapper>
                    }
                </>
            
         );
    }
}
 
export default  (withTranslation() (TermsConditionModal));