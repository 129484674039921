import React, { Component } from 'react';
// import thumbLogo from '../../../assests/img/header/thumbLogo.png'
import  cardImg from '../../../assests/img/card.svg'
import enfactem from "../../../assests/img/header/enfactemlogo.png"
import { logoHandler } from "utils/Theme/Logo-handler";
import flexxpayLogo from "../../../assests/img/header/logo2.svg";
import flexxpayLogoOnly from  '../../../assests/img/header/logo.svg'
import ModalWrapper from './ModalWrapper';
import {  Redirect, withRouter } from 'react-router-dom';
import {fetchOrderDetailsSuccess, postOrderCreation, removeOrder} from '../../../Redux/order/orderActions'
import { connect } from 'react-redux';
import {currancyToPoints, otherPointsToCurrancy, pointsToCurrancy, pointsToCurrancyCreditCountries} from '../../../utils/pointsConversion';
import axios from 'axios';
import secretKeyGenerator from '../../../utils/serectKeyGenerator';
import FailMessageModal from './FailMessageModal';
import { withTranslation } from 'react-i18next';
import MasterCard from "../../../assests/img/MasterCard.png";
import Visa from "../../../assests/img/Visa.png";
import removeIcon from '../../../assests/img/Footer/delete2.svg'
import { fetchCurrencyConversion } from '../../../Redux/conversion/conversionAction';

import { REACT_APP_baseUrl } from 'utils/app-constants';

class TopupConfirmationModal extends Component {
    constructor(props) {
        super(props);
        this.state={
            orderDetails:{
                total:this.props.orders.amount,
                payable_amount:this.props.orders.amount
            },
            redirectToPayment:false,
            checkoutBtn:true,
            paymentFail:false,
            errorMsg:" ",
            loading:false,
            convertedCurrency:this.props.orders.amount,
            selectedCurrency:"AED",
            pointsCurrancy:null,
            convertedPointsBalance:null,
        }
    }
    
    postOrderHandler(){
        const {orders,pointBalance,payload} = this.props;
        if(pointBalance || this.props.cardPayment){
            const missingPoints = orders.amount - pointBalance
        if(missingPoints>0 || this.props.cardPayment){
            payload.orders["stripe_payment_currency"] = this.state.selectedCurrency;
            payload.orders["amount"] = Number(this.state.pointsCurrancy)
            this.setState({redirectToPayment:true})
            this.setState({loading:true})
        }
        else{
            axios.post(`${REACT_APP_baseUrl}/orders/create_mobile_topup_order`,
            payload,{
                headers:{
                    "Authorization":`Bearer ${this.props.authToken}`
                },
                params:{
                    "secret_key":secretKeyGenerator(payload)
                }
            })
                .then(response  => {
                    if(response.data.code === 200){
                        const orderDetails = response.data.data.order;
                        this.props.history.push(`/payment/mobile-topup-summary/${orderDetails.id}`) 
                    }
                    else{
                    this.setState({paymentFail:true,
                        errorMsg:response.data.message
                    
                    })
                    }
                })
                .catch(error => {
                    this.setState({
                        paymentFail:true,
                        errorMsg:"Something went wrong!! Please try again!!"
                    })
                })
                this.setState({loading:true})
        }
        }
        else{
            this.setState({
                paymentFail:true,
                errorMsg:"Points Redemption Failed"
            })

        }
        
    }
    setCheckoutBtn(val){
        this.setState(old=>({
            checkoutBtn:!old.checkoutBtn
        }))
    }
    removeOrderhandler=()=>{
        this.props.remove(false)
        this.props.removeOrder()
    }
    // numberWithCommas = (x) => {
    //     let num = +x;
    //     let y = num.toFixed(0)
    //     return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // }
    numberWithCommas = (x) => {
        return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
    numberWithCom = (x) => {
        let num = +x;
        let y = num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    currencyConversion =(event)=>{
        let payload = {
            "giftcard_units": {
                "currency": event.target.value
                 }
        }
        let orginalAmount = this.props.orders.amount;

        // if(event.target.value === this.props.currancyCode){
        //     this.setState({convertedCurrency:orginalAmount,selectedCurrency:event.target.value})
        // }
        
        // else{
            this.props.fetchCurrencyConversion(payload)
            this.setState({selectedCurrency:event.target.value})

            // if(Object.values(this.props.acceptedCurrencies).some(e => e.unit_name_short === this.props.currancyCode)){
            //     this.setState({selectedCurrency:event.target.value})
            // }else{
            // }
           
        // }
    }

    componentDidMount(){

        let payload

        if(Object.values(this.props.acceptedCurrencies).some(e => e.unit_name_short === this.props.selected_currency_code)){
            payload = {
                "giftcard_units": {
                    "currency": this.props.selected_currency_code
                }
            }
            this.setState({selectedCurrency:this.props.selected_currency_code})
        }else{
            payload = {
                "giftcard_units": {
                    "currency": "AED"
                }
            }           
        }
        
        this.props.fetchCurrencyConversion(payload)
        // if(Object.values(this.props.acceptedCurrencies).some(e => e.unit_name_short === this.props.currancyCode)){
        //     this.setState({convertedCurrency:this.props.orders?.giftcard_value})
        // }else{
        //     this.setState({convertCur:true,convertCur2:false})
        // }
}


    componentDidUpdate(prevProps,prevState){
        if(prevProps.currencyConversonLoading !== this.props.currencyConversonLoading){

            let total = this.state.orderDetails.total;
            let missingPoints = (Number(total) - Number(this.props.pointBalance)).toFixed(2)
            let convertedPointsBalance = (this.props.pointBalance / this.props.convertedCurrency?.currency_exchange_rate).toFixed(2);
            if(missingPoints > 0 && !this.props.cardPayment) {
                total = missingPoints;
            }
            if(this.state.selectedCurrency === this.props.selected_currency_code){
                // For Available Currencies
                const payableAmount = pointsToCurrancyCreditCountries(total)
                if(this.props.minimum_payable_amount > payableAmount){
                    this.setState({pointsCurrancy:this.props.minimum_payable_amount,convertedPointsBalance:convertedPointsBalance})
                }else{
                    this.setState({pointsCurrancy:payableAmount,convertedPointsBalance:convertedPointsBalance})
                }
            }else{
                // For other currencies which is not avaible
                total = (missingPoints > 0 && !this.props.cardPayment) ? missingPoints : pointsToCurrancy(this.state.orderDetails.total);
                const payableAmount = (missingPoints > 0 && !this.props.cardPayment) ? pointsToCurrancyCreditCountries(total) : otherPointsToCurrancy(total)
                const markUp = (payableAmount * 0.05) + Number(payableAmount)
                if(this.props.minimum_payable_amount > markUp){
                    this.setState({pointsCurrancy:this.props.minimum_payable_amount,convertedPointsBalance:convertedPointsBalance})
                }else{
                    this.setState({pointsCurrancy:markUp.toFixed(2),convertedPointsBalance:convertedPointsBalance})
                }
            }
        }
    }
    
    render() { 
        const {t,card,orders,currancyCode,acceptedCurrencies,pickedTopupAmount,pickedTopupCurrency,defaultPrice,pointBalance,selected_currency_code} = this.props;
        const {checkoutBtn,orderDetails,paymentFail, errorMsg,loading,convertedCurrency,selectedCurrency,pointsCurrancy,convertedPointsBalance} = this.state;
        const total = orders.amount;        
        const missingPoints = (total - pointBalance).toFixed(2)
        const desktopOrderSummaryCard=(
            <div className="d-md-block d-none">
                <FailMessageModal show={paymentFail} lang={this.props.lang} Message={errorMsg}/>
                <div 
                    className="card mb-4 rounded-0"
                    style={{width:'18rem'}}>
                    <div className="card-body">
                    <div className="fs-4 fw-bold py-md-0 py-3 m-0 mt-md-0 mt-5  headings">{t("Order Summary")}:</div>
                    <div className="d-flex flex-row justify-content-between align-items-center py-3 border-bottom">
                {
                    (this.props.cardPayment|| missingPoints > 0)&&
                    <>
                        <label htmlFor="accCurrency" className='text-black-50 fw-bold col-8 m-0 p-0'>{t("Select Payment Currency")}:</label>
                    <select id='accCurrency' onChange={this.currencyConversion} className='dist border col-4 p-0 m-0 text-center form-control arrow-indicator no-outline' name="">
                    {acceptedCurrencies?.map(e => <option selected={e.unit_name_short === selected_currency_code} value={e.unit_name_short}>{e.unit_name_short}</option>)}
                    </select>
                    </>
                }   
                </div>
            

                        <div className="d-flex flex-row justify-content-between py-2">
                            <span className="text-black-50 fw-bold">{t("Subtotal")}:</span>
                            {
                                this.props.currencyConversonLoading &&
                                <div class="spinner">
                                                    <div class="bounce1"></div>
                                            <div class="bounce2"></div>
                                                     <div class="bounce3"></div>
                                                            </div>

                            }
                            {
                                this.props.cardPayment? 
                                <span className=" product_value fs-large" > 
                                    {this.props.lang!=='ar' ?<>{this.numberWithCommas(convertedCurrency)} 
                                    <small>{t("Points")}</small></>  : 
                                    <>&nbsp;{t("Points")} {this.numberWithCommas(convertedCurrency)}</>} 
                                </span> : 
                                <span className=" product_value fs-large" > 
                                    {this.props.lang!=='ar' ?<>{this.numberWithCommas(convertedCurrency)} 
                                    <small>{t("Points")}</small></>  : 
                                    <>&nbsp;{t("Points")} {this.numberWithCommas(convertedCurrency)}</>} 
                                </span>
                            }
                        </div>
                        {/* disable point functionalities */}
                        <div className="d-flex flex-row justify-content-between py-2">
                            <span className="text-black-50 fw-bold">{t("Available")}:</span>
                            {
                                this.props.lang!=='ar'?
                                <h5 className="text-danger fw-bold m-0 fs-regular">{this.numberWithCommas(pointBalance)}<small> {t("Points")}</small></h5>
                                :
                                <h5 className="text-danger fw-bold m-0 fs-regular"><small> {t("Points")}</small>&nbsp;{this.numberWithCommas(pointBalance)}</h5>

                            }
                        </div>
                        {
                            missingPoints>0 && !this.props.cardPayment && 
                            <>
                            
                            <div className="d-flex flex-row justify-content-between py-2">
                                <span className="text-black-50 fw-bold">{t("Missing Points")}:</span>
                                {
                                this.props.lang!=='ar'?
                                <h5 className="text-danger fw-bold m-0 fs-regular">{this.numberWithCommas(missingPoints)} <small>{t("Points")}</small></h5>
                                :
                                <h5 className="text-danger fw-bold m-0 fs-regular"><small>{t("Points")}</small>&nbsp;{this.numberWithCommas(missingPoints)}</h5>

                            }
                            </div>
                            {/* <div className="d-flex flex-row py-3">
                                <div className="p-0 col-2">
                                    <img src={cardImg} alt="Mobile Topup" className="img-fluid verticle-center"/>
                                </div>
                                <div className="px-1 col-10">
                                    <h5 className="color-blue fw-bold m-0">{`${this.numberWithCommas(missingPoints)} ${t("Points")} (${pointsToCurrancy(missingPoints)} ${currancyCode})`}</h5>
                                    <small className="text-muted fw-bold">
                                        {t("to be paid by  Credit Card/MADA")}
                                    </small><br/>
                                    <img src={Visa}/>
                                    <img src={MasterCard}/>
                                    <img src={Mada}/>
                                </div>
                            </div> */}
                            </>
                            
                        }

                        {
                            this.props.cardPayment && <div className="d-flex flex-row py-3">
                            <div className="p-0 col-2">
                                <img src={cardImg} alt="Mobile Topup" className="img-fluid verticle-center"/>
                            </div>
                            <div className="px-1 col-10">
                                <h5 className="primary-color fw-bold m-0">{`${this.numberWithCommas(pointsCurrancy)} ${selectedCurrency}`}</h5>
                                <small className="text-muted fw-bold">
                                    {t("to be paid by Credit Card")}
                                </small><br/>
                                <img src={MasterCard}  alt ='MasterCard'/>
                                <img src={Visa} alt ='Visa'/>
                            </div>
                        </div>
                        }
                        
                        <hr className="hr-line"/>
                        <div className="d-flex flex-row justify-content-between pt-1 pb-3">
                            <h5 className="text-dark fw-bold m-0">{t("Total")}</h5>
                            {
                                this.props.cardPayment || missingPoints>0 ? 
                                <span className=" product_value fs-large" > 
                                    {this.props.lang!=='ar' ?<>{this.numberWithCommas(pointsCurrancy)} 
                                    <small>{t(selectedCurrency)}</small></>  : 
                                    <>&nbsp;{t(selectedCurrency)} {this.numberWithCommas(pointsCurrancy)}</>} 
                                </span> : 
                                <span className=" product_value fs-large" > 
                                    {this.props.lang!=='ar' ?<>{this.numberWithCommas(convertedCurrency)} 
                                    <small>{t("Points")}</small></>  : 
                                    <>&nbsp;{t("Points")} {this.numberWithCommas(convertedCurrency)}</>} 
                                </span>
                            }
                        </div>
                        {/* <div className="d-flex gap-2">
                            <input 
                                type="checkbox"
                                className="flex-shrink-0"
                                onClick={()=>{this.setCheckoutBtn()}} />
                                <label className="" >{t("I have read and accepted the Terms & Conditions")}.</label>
                        </div> */}
                        <br/>
                        <button 
                            className="btn btn-block primary-btn text-light rounded-0 text-center" 
                            onClick={()=>{this.postOrderHandler()}} 
                            disabled={this.props.currencyConversonLoading}>
                            {/* {t("Checkout Now")} */}
                            { loading ? <span > <div class="spinner-border spinner-border-sm " role="status">
                            <span className="sr-only" >Loading...</span>
                        </div>
                        <span  className='px-2' >{t("Checking")} . . .</span>
                        </span> : <span>{(this.props.cardPayment || missingPoints>0) ? t("Buy with Credit Card") : t("Checkout Now")}</span>}
                        </button>
                    </div>
                </div>
            </div>
        )
        const mobileOrderSummaryCard=(
            <div className="d-md-none d-block flex-shrink-0">
                <FailMessageModal show={paymentFail} lang={this.props.lang} Message={errorMsg}/>
                <div className="fs-5 fw-bold text-muted py-2">{t("Order Summary")}</div>
                <div className="border px-3">
                <div className='d-flex flex-row justify-content-between align-items-center py-3 border-bottom'>
                    {
                         (this.props.cardPayment|| missingPoints > 0)&& 
                         <>
                            <label htmlFor="accCurrency" className='text-black-50 fw-bold col-8 m-0 p-0'>{t("Select Payment Currency")}:</label>
                            <select id='accCurrency' onChange={this.currencyConversion} className='dist border align-self-center col-4 p-0 m-0 text-center form-control arrow-indicator no-outline' name="">
                            {acceptedCurrencies?.map(e => <option selected={e.unit_name_short === selected_currency_code} value={e.unit_name_short}>{e.unit_name_short}</option>)}
                            </select>
                         </>
                    }
                    </div>

                <div className="">
                    <div className="d-flex flex-row justify-content-between py-3 border-bottom">
                        <span className="text-black-50 fw-bold">{t("Subtotal")}:</span>
                        {
                                this.props.currencyConversonLoading &&
                                <div class="spinner">
                                                    <div class="bounce1"></div>
                                            <div class="bounce2"></div>
                                                     <div class="bounce3"></div>
                                                            </div>

                            }
                        {
                           this.props.cardPayment? 
                           <span className=" product_value fs-large" > 
                               {this.props.lang!=='ar' ?<>{this.numberWithCommas(convertedCurrency)} 
                               <small>{t("Points")}</small></>  : 
                               <>&nbsp;{t("Points")} {this.numberWithCommas(convertedCurrency)}</>} 
                           </span> : 
                           <span className=" product_value fs-large" > 
                               {this.props.lang!=='ar' ?<>{currancyToPoints(this.numberWithCommas(convertedCurrency))} 
                               <small>{t("Points")}</small></>  : 
                               <>&nbsp;{t("Points")} {currancyToPoints(this.numberWithCommas(convertedCurrency))}</>} 
                           </span>
                        }
                    </div>
                    {/* disable point functionalities */}
                    <div className="d-flex flex-row justify-content-between py-2">
                            <span className="text-black-50 fw-bold">{t("Available")}:</span>
                            {
                                this.props.lang!=='ar'?
                                <h5 className="text-danger fw-bold m-0 fs-regular">{this.numberWithCommas(pointBalance)}<small> {t("Points")}</small></h5>
                                :
                                <h5 className="text-danger fw-bold m-0 fs-regular"><small> {t("Points")}</small>&nbsp;{this.numberWithCommas(pointBalance)}</h5>

                            }
                        </div>
                    {
                        missingPoints>0 && !this.props.cardPayment &&
                        <>
                        
                        <div className="d-flex flex-row justify-content-between  py-3 border-bottom">
                            <span className="text-black-50 fw-bold">{t("Missing Points")}:</span>
                            {
                                this.props.lang!=='ar'?
                                <div className="text-danger fw-bold m-0 fs-regula fw-bold">{this.numberWithCommas(missingPoints)} <small>{t("Points")}</small></div>
                                :
                                <div className="text-danger fw-bold m-0 fs-regula fw-bold"><small> {t("Points")}</small>&nbsp;{this.numberWithCommas(missingPoints)}</div>

                            }
                        </div>
                        {/* <div className="d-flex flex-row py-3 border-bottom">
                            <div className="p-0 col-1">
                                <img src={cardImg} alt="" className="img-fluid verticle-center"/>
                            </div>
                            <div className="px-3 col-10">
                                <div className="color-blue fw-bold m-0 fs-5 fw-bold">{`${this.numberWithCommas(missingPoints)} ${t(currancyCode)} (${pointsToCurrancy(missingPoints)} ${currancyCode})`}</div>
                                <small className="text-muted fw-bold">
                                    {t("to be paid by  Credit Card/MADA")}
                                </small><br/>
                                <img src={Visa} alt ='Visa'/>
                                <img src={MasterCard}  alt ='MasterCard'/>
                                <img src={Mada}  alt ='Mada'/>
                            </div>
                        </div> */}
                        </>
                        
                    }
                    
                    <div className="d-flex flex-row justify-content-between py-3">
                        <div className="text-dark fw-bold m-0">{t("Total")}</div>
                        {
                            this.props.cardPayment || missingPoints>0? 
                            <span className=" product_value fs-large" > 
                                {this.props.lang!=='ar' ?<>{this.numberWithCommas(pointsCurrancy)} 
                                <small>{t(selectedCurrency)}</small></>  : 
                                <>&nbsp;{t(selectedCurrency)} {this.numberWithCommas(pointsCurrancy)}</>} 
                            </span> : 
                            <span className=" product_value fs-large" > 
                                {this.props.lang!=='ar' ?<>{currancyToPoints(this.numberWithCommas(convertedCurrency))} 
                                <small>{t("Points")}</small></>  : 
                                <>&nbsp;{t("Points")} {currancyToPoints(this.numberWithCommas(convertedCurrency))}</>} 
                            </span>

                        }
                    </div>
                    {
                        this.props.cardPayment && <div className="d-flex flex-row py-3 border-bottom">
                        <div className="p-0 col-1">
                            <img src={cardImg} alt="" className="img-fluid verticle-center"/>
                        </div>
                        <div className="px-3 col-10">
                            <div className="primary-color fw-bold m-0 fs-5 fw-bold">{`${this.numberWithCommas(pointsCurrancy)} ${selectedCurrency}`}</div>
                            <small className="text-muted fw-bold">
                                {t("to be paid by Credit Card")}
                            </small><br/>
                                <img src={MasterCard}  alt ='MasterCard'/>
                            <img src={Visa} alt ='Visa'/>
                        </div>
                    </div>
                    }
                </div>
                <div className=" w-100 bg-light py-5 ">
                {/* <div className="d-flex">
                            <input 
                                type="checkbox"
                                className="flex-shrink-0"
                                onClick={()=>{this.setCheckoutBtn()}} />
                                <label className="" style={{paddingLeft: "1rem",paddingRight:"1rem"}}>{t("I have read and accepted the Terms & Conditions")}.</label>
                        </div> */}
                        <br/>
                    <button 
                        onClick={()=>{this.postOrderHandler()}}
                        disabled={this.props.currencyConversonLoading}
                        className="btn btn-block primary-btn w-100 rounded-0 p-2 text-white">
                       {/* {t("Checkout Now")} */}
                       { loading ? <span > <div class="spinner-border spinner-border-sm " role="status">
                            <span className="sr-only" >Loading...</span>
                        </div>
                        <span  className='px-2' >{t("Checking")} . . .</span>
                        </span> : <span>{t("Checkout Now")}</span>}
                    </button>
                </div>
                <div className="d-md-none d-block m-4 p-2">
                </div>
                </div>
            </div>
        )     
        return (
            <ModalWrapper show={this.props.show}>
                <section className="bg-white w-100 h-100" >
                    <header className="d-flex py-md-4 py-3 px-md-5 px-3 gap-md-4 gap-3 border shadow-sm align-items-center">
                        <div>
                            <img className="img-fluid d-md-block d-none" style={{width:'13rem'}} src={logoHandler()} alt="logo"/>
                            <img className="img-fluid d-md-none d-block mt-2" id="lg-fix" src={logoHandler()} alt="logo"/>
                        </div>
                        <div className="pt-3 fs-md-2 fs-5 fw-bold">
                            <span className="headings">{t("Order Confirmation")} </span> - <span className="text-black-50">({orders.quantity} {t("item")})</span> 
                        </div>
                    </header>
                    <div className="d-flex flex-md-row flex-column p-md-5 p-3 gap-md-5 bg-white">
                        <div className="flex-grow-1 d-flex align-self-start border-md-1 border-none p-md-3 p-0">
                            <div className="col-3 p-md-2 p-0" style={{minWidth:"8rem"}}>
                                <img  className="img-fluid" src={card.images && card.images.color?.medium_rectangle} alt="mobile topup"/>
                            </div>
                            <div className="text-black-50 px-3 flex-grow-1 ">
                                <div className="primary-color fs-md-3 fs-5 d-flex align-items-center">
                                    <h6>{this.props.lang!=='ar'?card.name: card.name_arabic}</h6>
                                     <h6 className='fw-bold'>{` - ${pickedTopupAmount} ${pickedTopupCurrency}`}</h6></div>
                                {/* <div className="fs-5 fw-bold">{this.props.lang==='en'?card.name : card.name_arabic}</div> */}
                                {/* <div className="py-2">
                                    Model Number: Pro 5500M
                                </div> */}
                                {/* <div className="pb-2">
                                    <img src="" alt=''/>
                                    Delivery to 
                                    <b className="color-skyblue"> Riyad</b>
                                </div> */}
                                <div className="pb-2 fw-bold">
                                {/* {t("Now")}:  */}
                                {
                                    this.props.lang!=='ar'?
                                    <>
                                        <div className='row'>
                                        <div className="col-lg-7 col-sm-5 col-md-12 my-1 my-md-0 tabletFontSize">
                                        <div className="d-flex justify-content-between">
                                        {
                                            this.props.cardPayment?<>
                                                
                                            <span>{t("Amount")}</span>
                                            <span className="text-blackColor fs-6"> {this.numberWithCom(defaultPrice)}  <span className="fw-normal">{t("Points")}</span></span>
                                            </>
                                            :
                                            <>
                                                
                                            <span>{t("Points")}</span>
                                            <span className="text-blackColor fs-6"> {this.numberWithCom(defaultPrice)}  <span className="fw-normal">{t("Points")}</span></span>
                                            </>
                                        }
                                        </div>
                                        <div className="d-flex justify-content-between">
                                        <span>{t("Service Charge")}</span>
                                        {
                                            this.props.cardPayment?
                                            <span className="text-blackColor fs-6"> {this.numberWithCom(total - defaultPrice)}  <span className="fw-normal">{t("Points")}</span></span>
                                            :
                                            <span className="text-blackColor fs-6"> {this.numberWithCom(total - defaultPrice)}  <span className="fw-normal">{t("Points")}</span></span>
                                        }
                                        </div>
                                        <hr />
                                        <div className="d-flex justify-content-between">
                                        <span>{t("Total")}</span>
                                        {
                                            this.props.cardPayment? 
                                            <span className=" product_value fs-large" > 
                                                {this.props.lang!=='ar' ?<>{this.numberWithCommas(convertedCurrency)} 
                                                <small>{t("Points")}</small></>  : 
                                                <>&nbsp;{t("Points")} {this.numberWithCommas(convertedCurrency)}</>} 
                                            </span> : 
                                            <span className=" product_value fs-large" > 
                                                {this.props.lang!=='ar' ?<>{this.numberWithCommas(convertedCurrency)} 
                                                <small>{t("Points")}</small></>  : 
                                                <>&nbsp;{t("Points")} {this.numberWithCommas(convertedCurrency)}</>} 
                                            </span>
                                        }
                                        </div>
                                        </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                    <div className="row">
                                        <div className="col-lg-7 col-sm-5 col-md-12 my-1 my-md-0 tabletFontSize">
                                            <div className="d-flex justify-content-between align-items-center">
                                            <span>{t("Amount")}</span>
                                            <span className="text-blackColor fs-large"><span className="fw-normal">{t("Points")}</span> {this.numberWithCom(defaultPrice)} </span>
                                            </div>
                                            <div className="d-flex justify-content-between  align-items-center">
                                            <span>{t("Service Charge")}</span>
                                            
                                            <span className="text-blackColor fs-large"><span className="fw-normal">{t("Points")}</span> {this.numberWithCom(total - defaultPrice)} &nbsp; </span>
                                            </div>
                                            <hr />
                                            <div className="d-flex">
                                            <span>{t("Total")}</span>
                                            <span className="primary-color fs-large mr-auto"><span className="fw-normal">{t("Points")}</span> {this.numberWithCom(total)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    </>

                                }
                                    {/* <b className=" btn bg-color-skyblue mx-2 py-1 px-2  fw-bold color-blue">10% off</b> */}
                                </div>
                                {/* disable points  */}
                                {/* {
                                   (missingPoints>0 && !this.props.cardPayment) && 
                                    <>
                                        <div className="pb-2 fw-bold">
                                        {t("Missing Points")}: 
                                            {
                                                this.props.lang==='en'?
                                                <span className="text-danger fs-md-3 fs-regular"> {this.numberWithCommas(missingPoints)}&nbsp;{t("Points")}</span>
                                                    :
                                                    <span className="text-danger fs-md-3 fs-regular">&nbsp; {t("Points")} {this.numberWithCommas(missingPoints)}</span>

                                            }
                                        </div>
                                        <div className="pb-2 fw-bold">
                                            <img src="" alt=''/>
                                            <span className="color-blue fs-regular"> {this.numberWithCommas(missingPoints)} {t("Points")} ({this.numberWithCom(pointsToCurrancy(missingPoints))} {currancyCode})</span>
                                        </div>
                                        <div>
                                            <b>{t("to be paid by  Credit Card/MADA")}</b> 
                                            <span className="color-skyblue">{t(" Credit Card/MADA")}</span>
                                        </div>
                                    </>
                                    
                                } */}
                               <div className="py-3 d-flex justify-content-end">
                                    <button className="btn float-right color-skyblue fw-bold" onClick={()=>this.removeOrderhandler()} >
                                    <span className="mx-1"><img className=' filter-green' src={removeIcon} alt="remove icon" cla/></span>{t("Remove")}</button>
                                </div>
                            </div>
                        </div>
                        {desktopOrderSummaryCard}
                        {mobileOrderSummaryCard}
                    </div>
                </section>
                {
                    this.state.redirectToPayment && (
                        ( this.props.cardPayment || missingPoints>0) &&
                    <Redirect
                            push to={{
                            pathname:'/payment/card-details-topup',
                            state:{
                            order:orders,orderDetails,
                            pyload:this.props.payload,
                            orderCreationURL:'orders/create_mobile_topup_order',
                            summaryPageURL:'/payment/mobile-topup-summary',
                            cardPayment:this.props.cardPayment,
                            currancyCode:selectedCurrency,
                            convertedCurrency:pointsCurrancy,
                            splitPayment: (missingPoints > 0 && !this.props.cardPayment) ? {value: Number(convertedPointsBalance) + Number(pointsCurrancy)} : false
                        }
                    }}/>

                    )
                    
                }
                
            </ModalWrapper> 
         );
    }
}
const mapStateToProps = state => {
    return {
        orderDetails:state.order.orderDetails,
        orderPosted:!state.order.loading,
        pointBalance:state.point.pointBalance,
        errorFlag:state.order.errorFlag,
        authToken:state.auth.authToken,
        currancyCode:state.countries.convertionRate.currency_code,
        convertedCurrency: state.conversion.convertedValue,
        acceptedCurrencies: state.conversion.paymentCurrencies,
        currencyConversonLoading: state.conversion.loading,
        minimum_payable_amount: state.conversion?.convertedValue?.minimum_payable_amount
    }
}

const mapDispatchToProps = dispatch => {
    return {
        postOrderCreation : (payload) => dispatch(postOrderCreation(payload)),
        removeOrder : () => dispatch(removeOrder()),
        fetchOrderDetailsSuccess : data => dispatch(fetchOrderDetailsSuccess(data)),
        fetchCurrencyConversion: (payload) => dispatch(fetchCurrencyConversion(payload))
    }
}

 
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(withTranslation()(TopupConfirmationModal)));