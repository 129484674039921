import axios from "axios"
import { FETCH_BANNERS_FAILURE, FETCH_BANNERS_REQUEST, FETCH_BANNERS_SUCCESS,
    FETCH_TAMBOLA_REQUEST,FETCH_TAMBOLA_FAILURE, FETCH_TAMBOLA_SUCCESS } from "./bannerTypes"
    import { REACT_APP_baseUrl } from 'utils/app-constants';

export const fetchBannersRequest = () => {
    return {
        type:FETCH_BANNERS_REQUEST,
    }
}

export const fetchBannersSuccess = data => {
    return {
        type:FETCH_BANNERS_SUCCESS,
        payload:data
    }
}

export const fetchBannersFailure = error =>{
    return {
        type:FETCH_BANNERS_FAILURE,
        payload:error
    }
}

const cacheHeaders = () => {
    return {
        headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Expires': '0'
        }
    }
  }
//large_rectangle
export const fetchBanners = () => {
    return (dispatch)=>{
        dispatch(fetchBannersRequest);
        axios.get(`${REACT_APP_baseUrl}/homepage_banners`,
            cacheHeaders())
            .then(response  => {
                
                let banners = response.data.data.homepage_banners;
                dispatch(fetchBannersSuccess(banners))
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
                dispatch(fetchBannersFailure("failed"))
            })
    }
}

export const fetchTambolaRequest = () => {
    return {
        type:FETCH_TAMBOLA_REQUEST,
    }
}

export const fetchTambolaSuccess = data => {
    return {
        type:FETCH_TAMBOLA_SUCCESS,
        payload:data
    }
}

export const fetchTambolaFailure = error =>{
    return {
        type:FETCH_TAMBOLA_FAILURE,
        payload:error
    }
}

export const fetchTambola = () => {
    return (dispatch)=>{
        dispatch(fetchTambolaRequest);
        axios.get(`${REACT_APP_baseUrl}/promotions/get_promotion`,
            cacheHeaders())
            .then(response  => {
                
                let Tambola = response.data.data.promotion;
                dispatch(fetchTambolaSuccess(Tambola))
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
                dispatch(fetchTambolaFailure("failed"))
            })
    }
}