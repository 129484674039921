export const TermsAndConditionEnglish = [['Please ensure to provide the passenger details as per the passport. In case you have entered some required information incorrectly, please inform the RewardsBy call centre  and submit supporting documents 14 days before the scheduled travel'],
['Valid visas and travel documents are the responsibility of the passenger.'],
['Visa  is subject for approval by immigration, in case of the rejection of application or refusal of entry, the paid amount are non-refundable'],
['Your booking cannot be modified and/or cancelled. Please ensure that you are sure with the dates and/or time of travel before making the booking on RewardsBy Marketplace'],
['RewardsBy Marketplace do not have a refund policy once the booking is paid and/or confirmed. Any redeemed Reward points will not be credited back in your account'],
['Confirmed bookings are not eligible for modifications such as promotional tickets, free gifted tickets, free gift cards etc.']]
 
export const TermsAndConditionArabic = [[".يرجى التأكد من تقديم بيانات المسافر حسب جواز السفر. في حالة إدخال بعض المعلومات المطلوبة بشكل غير صحيح ، يرجى إبلاغ مركز الاتصال RewardsBy وتقديم المستندات الداعمة قبل 14 يومًا من موعد السفر المحدد"]
,[" التأشيرات ووثائق السفر سارية المفعول هي مسؤولية الراكب."],
[" تخضع التأشيرة لموافقة دائرة الهجرة ، وفي حالة رفض الطلب أو رفض الدخول ، لا يمكن استرداد المبلغ المدفوع"],
[" لا يمكن تعديل و / أو إلغاء حجزك. يرجى التأكد من أنك متأكد من تواريخ و / أو وقت السفر قبل إجراء الحجز في مركز حصاد"],
["حصاد مول ليس لديه سياسة استرداد بمجرد دفع الحجز و / أو تأكيده. لن يتم إعادة أي نقاط حصاد المستبدلة إلى حسابك"],
[" الحجوزات المؤكدة غير مؤهلة لإجراء تعديلات مثل التذاكر الترويجية ، والتذاكر المجانية الهدايا ، وبطاقات الهدايا المجانية ، إلخ."]]

export const passengerTermsEnglish = (
    <div className="list-style-div">
        <p className="fw-bold">1. GENERAL</p>
<p>Please read these Terms and Conditions(Terms) carefully. These are the general Terms and Conditions govern the access and use of this website (Site). By continuing to use the Site and/or any of the services shown on the Site, you unconditionally agree to be bound by these Terms and Conditions set out herein and as amended periodically.<br />
The Terms "Travel Shop", "We", "Us", "Our" refer to RewardsBy Marketplace Program.</p>
<p>The Terms “You”, “Your” refer to RewardsBy Marketplace subscriber(s).</p>
<p>We may change these Terms and Conditions at any time, with or without advance notice and at Our sole discretion. Any changes to these Terms and Conditions shall become effective once posted on the Site and shall supersede all previous terms and conditions posted; however, the changes will not have any retrospective effect on contractual arrangements made through this Site prior to the changes coming into effect. </p>
<p>Your continued use of Our Services after any change means that You have irrevocably accepted the amended and/or changed Terms.</p>
<p>We reserve the right to suspend, withdraw or disable Your Account at any time, with or without notice, if in Our sole discretion You have failed to comply with any of the provisions these Terms, or for any conduct that We believe is harmful to Our business, or to any other party.</p>
<p>These Terms and Conditions and any additional terms posted on Our Site together constitute the basis of the Our relationship with You with respect to Your use of this Site.</p>

<br/>
<p className="fw-bold">2. SERVICES</p>

<p>We offer hotels, flight bookings and travel services the Services. Services are provided on the basis of facility and availability. We reserve the right (and for any reason whatsoever) to modify, change, or discontinue any aspect of the Services at any time. </p>

<br/>
<p className="fw-bold">3. TERMS AND CONDITIONS OF THE SERVICES</p>

<p className="fw-bold">
* Booking Procedures<br/>
* Cancellation &amp; Replacement Policy<br/>
* No Shows.</p> <p>No show at the accommodation establishment without prior warning shall be considered a cancellation. There shall be no refunds applicable in the case of no shows.<br/></p>

<p> <p className="fw-bold">* Services Pricing.</p> The Pricing of the Services may vary from time to time. for latest pricing You have to Visit the Site.<br />
* Method of Payment. Reward points, can be redeemed in the Travel Shop in full or partially. <br />
* Refunds.</p>

<p>
<p className="fw-bold">* No Liability.</p> The classification of all accommodation is provided to Us by the third party provider of the accommodation. We are not responsible or liable for any errata or inaccurate information provided to Us by the third-party provider including but not limited to images, descriptions, amenities/facilities, tariffs, reviews and any other information that the third party provider uses to promote and market its properties.</p>

<br/>
<p className="fw-bold">4. AVAILABILITY OF THE SERVICES</p>

<p>The reference to products and services on the Site does not imply or warrant that these products or services will be available at any time. </p>
<p>Your access to the Site may occasionally be suspended or restricted to allow for repairs, maintenance, or the introduction of new facilities or services. Although We will attempt to limit the frequency and duration of any such suspension or restriction, We will not be liable if for any reason the Site is unavailable at any time or period.</p>

<br/>
<p className="fw-bold">5. LINKS TO THIRD PARTY SITES</p>

<p>This Site may contain links to websites operated by a third party. these websites are not operated, controlled, or maintained by Us. As such, We are not responsible for their contents or other practices of such websites and we cannot guarantee the accuracy or reliability of the information, data, products or services provided on such third-party websites nor the security of any information or data You may provide.</p>
<p>Our inclusion of links to such websites, provided for Your reference only, and does not imply any endorsement of the material on such websites or any association with their operators.</p>
<p>For the avoidance of doubt, We shall have no liability to You whatsoever for any losses or damages You suffer as a result of accessing a third-party Site from our Site.</p>

<br/>
<p className="fw-bold">6. YOUR REPRESENTATIONS &amp; WARRANTIES</p>

<p>
You represent and warrant that:<br />
* You possess the legal right and ability to enter into and make the representations and warranties contained herein.<br />
* You have the ability to perform the obligations under the Terms in accordance with applicable laws.<br />
* You are in compliance with all applicable laws and have made relevant filings/ declarations and have obtained requisite approvals and registrations under applicable laws.<br />
* You will be responsible for all use of Your Account even if such use was conducted without Your authority or permission<br />
* You will not use the Site for any purpose that is unlawful or prohibited by these Terms. <br />
* You will keep Your registration and Account information up to date at all times, including but not limited to the contact details.<br />
* All information that You submit to Us is true and accurate.<br />
* Your use of the Service must be in compliance with the Terms mentioned herein and the applicable laws at all times.</p>

<br/>
<p className="fw-bold">7. YOUR RESPONSIBILITY</p>

<p>You acknowledge and agree that it is Your responsibility to safeguard Your account information and password from any unauthorized use and treat this information as confidential at all times and therefore You agree not to disclose it to any third party.</p>
<p>In no event We will be liable for the unauthorized use or misuse of Your account. Notwithstanding the foregoing, it is Your responsibility to notify Us if You know or suspect that anyone other than You becomes aware of Your account or any other detail that should be confidential to You.</p>

<br/>
<p className="fw-bold">8. PROHIBITED CONDUCT</p>
<p>
As a condition of Your use of this Site, You warrant that You will not use this Site for any purpose that is unlawful or prohibited by these Terms, and applicable laws.<br />
Such prohibited behavior and conduct will include but not limited to the following:<br />
* Any content that contains or contains links to: nudity, pornography, adult content, materials with sex or foul language.<br />
* Any content that condones, promotes, contains, or links to hackers, or other piracy related information.<br />
* Content that has been promoted through the sending of Spam or mail fraud schemes, or pages that promote or condone the sending of Spam.<br />
* Content that is grossly offensive to the community, including blatant expressions for bigotry, prejudice, racism, hatred or excessive profanity, or to post any obscene, filthy, excessively violent, harassing, or otherwise objectionable material.<br />
* Content that infringes or violates any copyright, patent, trademark, service mark, trade name, trade secret, or other intellectual property right of any third party.<br />
* Content that promotes mail fraud, multi-level marketing (pyramid) schemes or other illegal or fraudulent activities.<br />
* Content that posts or discloses any personally identifying information or private information about any third parties without their express consent.<br />
* Reverse engineering, reverse compiling or otherwise deriving the underlying source code or structure or sequence of any service, solution or technology.</p>

<br/>
<p className="fw-bold">9. TERMINATION</p>

<p>Your account may be terminated in our sole discretion, for example but not limited to the following circumstances:<br />
* Where You are in breach of any these Terms.<br />
* When we find that the Services are utilized in unregulated manner.<br />
* Any kind of spamming on the platform.<br />
* Your use of the Service disrupts our business operations or affects any other party.<br />
You may request account termination at any time by contacting our Customer Service team through email.</p>

<br/>
<p className="fw-bold">10. INDEMNIFICATION</p>

<p>As a condition of use of this Site, You agree to hold Us and Our affiliates, partners, directors, officers and employees harmless and indemnify us, immediately and on demand, from and against any and all liabilities, losses, claims, expenses (including reasonable attorney's fees) and damages whether direct or indirect arising out or in connection with claims relating to Your breach of these Terms by You.</p>

<br/>
<p className="fw-bold">11. INTELLECTUAL PROPERTY</p>

<p>The names, words, logos, slogans, images, marks, trade names, and trademarks, database used on this Site are either Our property of or the property of the third-Party Provider, all such rights are expressly reserved. You may not use, reproduce, copy or manipulate any of them in any manner without Our prior written consent.</p>

<br/>
<p className="fw-bold">12. DATA PRIVACY</p>

<p>You hereby warrant that You have all the necessary consents and authorisation from the data subject for the transfer of such personal data to Us and authorize Us to proceed with all the subsequent transfers of data in order to complete the request and booking of the Services.</p>
<p>The personal information that We may collect from the service supplier, as well as any personal data which, in the use of this system, that You may disclose to Us shall be understood as obtained, treated and transmitted with strict observation of and in total compliance with the requirements set forth in EU GDPR 2016/679. In fulfilment of that stated in the EU GDPR 2016/679, We undertake to observe professional secrecy regarding such data, even when the relationship has terminated and to ensure that the staff performing the Services abide by the aforementioned obligation.</p>
<p>If You should wish to exercise rights of access, rectification, cancellation or opposition granted by EU GDPR 2016/679, You may communicate with Us and indicate the performance requested regarding personal data and a copy of Your passport.</p>

<br/>
<p className="fw-bold">13. PRIVACY</p>

<p>Our privacy policy [https://app.rewardsby.com/privacy-policy], sets out the terms upon which We process, store and manage any personal data provided by You and/or collected by us. This Privacy Policy may be modified from time to time, and such changes should be effective from the date of posting the modified policy to the Site.<br />

<br/>
<p className="fw-bold">14. LIMITATION OF LIABILITY</p></p>

<p>To the extent permitted by Applicable law We shall, in no event, be liable for any type of punitive, incidental, special or consequential damages (including but not limited to: loss of profit, loss of opportunity, etc.) arising out of the use or inability to use this Site in any way, or with the delay or inability to use this Site, or for any information, products and services obtained through or otherwise arising out of the use of this Site, or arising in any way in connection with the Products whether based on contract, tort, strict liability or otherwise, even if We have been advised of the possibility of damages. </p>
<p>All such information, products, and services are provided as is without warranty of any kind. We disclaim all warranties and conditions that this Site, its servers or any email sent from Us are free of viruses or other harmful components. We hereby disclaim all warranties and conditions with regard to this information, products, and services, including all implied warranties and conditions of merchantability, fitness for a particular purpose, title and non-infringement.</p>
<p>We are not liable for the acts, errors, omissions, representations, warranties, breaches or negligence of any third-Party Provider as well as for any acts of any government or authority, for any personal injuries, death, property damage or any other damages or any resulting expenses. The third-Party Provider is liable in the event of breach of its obligations in providing the products and the services to You. We have no liability and will make no refund in the event of any delay, cancellation, overbooking, re-scheduling, re-booking, re-routing, strike, force majeure, financial insolvency, bankruptcy or other causes beyond Our direct control and have no responsibility for any such events as well as for any additional expenses or damages.</p>
<p>Furthermore, in no event shall We be held liable for any damages, economic or any other losses, whatsoever, as a result of providing copies of Your data files to the appropriate authorities or cooperating with law enforcement efforts to locate persons who have posted content that is illegal or promotes illegal conduct.</p>

<br/>
<p className="fw-bold">15. FORCE MAJEURE</p>

<p>Neither party shall be responsible or liable for or deemed in breach of these Terms because of any delay or failure in the performance of these Terms due to any event or circumstance beyond their control, including, without limitation acts of God; government regulations, curtailment of transportation facilities, strikes, lock-outs, terrorist attacks, haze, sabotage, riots, civil disturbances, insurrections, acts of war, etc. (a Force Majeure Event). The non-performing party shall give the other party written notice describing the particulars of the Force Majeure Event as soon as possible.</p>

<br/>
<p className="fw-bold">16. COMMUNICATIONS</p>

<p>Your use of the Site is conducted digitally, and You agree that We may communicate with You digitally for all aspects of Your use of the Site, including sending You emails. Delivery of email notices is not infallible. You agree to keep all records relating to Your use of the Site and to print or make an electronic copy of these Terms and any disclosures provided on the Site. Except as prohibited by law, You waive any law requiring different communication methods in connection with Your use of this Site. Notwithstanding the foregoing We reserves the right, at Our sole discretion, to deliver notices and disclosures by other means as well.</p>

<br/>
<p className="fw-bold">17. APPLICABLE LAW AND JURISDICTION</p>

<p>These Terms shall be are governed by and construed in accordance with the laws of the United Arab Emirates and exclusively under the jurisdiction of the DIFC Court applicable to agreement(s) made without regard to its conflict of law’s provisions or any other principles that may provide for the application of the laws of another jurisdiction.</p>

<br/>
<p className="fw-bold">18. CONTACT US</p>
<p>If you need any help or clarification please contact by email to care@rewardsby.com along with your Order, Booking Reference and Customer ID Numbers.</p>
    </div>
)

export const passengerTermsArabic = (
    <div className="list-style-div">
        <p className="fw-bold">1. عام</p>
<p> يرجى قراءة هذه الشروط والأحكام ("الشروط") بعناية. هذه هي الشروط والأحكام العامة التي تحكم الوصول إلى هذا الموقع واستخدامه ("الموقع"). من خلال الاستمرار في استخدام الموقع و / أو أي من الخدمات المعروضة على الموقع ، فإنك توافق دون قيد أو شرط على الالتزام بهذه الشروط والأحكام المنصوص عليها هنا بصيغتها المعدلة بشكل دوري.
تشير المصطلحات "متجر السفر" و "نحن" و "نحن" و "لنا" إلى برنامج RewardsBy Marketplace.</p>
<p>تشير المصطلحات "أنت" و "الخاص بك" إلى مشترك (مشتركي) RewardsBy Marketplace.</p>
{/* <p>تشير المصطلحات "أنت" و "الخاص بك" إلى مشترك (مشتركي) حصاد.</p> */}
<p>يجوز لنا تغيير هذه الشروط والأحكام في أي وقت ، بإخطار مسبق أو بدونه ووفقًا لتقديرنا الخاص. تصبح أي تغييرات تطرأ على هذه الشروط والأحكام سارية بمجرد نشرها على الموقع ويجب أن تحل محل جميع الشروط والأحكام السابقة المنشورة ؛ ومع ذلك ، لن يكون للتغييرات أي تأثير بأثر رجعي على الترتيبات التعاقدية التي تتم من خلال هذا الموقع قبل دخول التغييرات حيز التنفيذ.</p>
<p>إن استمرارك في استخدام خدماتنا بعد أي تغيير يعني أنك قبلت بشكل نهائي الشروط المعدلة و / أو المتغيرة.</p>
<p>نحن نحتفظ بالحق في تعليق حسابك أو سحبه أو تعطيله في أي وقت ، بإخطار أو بدون إشعار ، إذا لم تلتزم وفقًا تقديرنا الخاص بأي من أحكام هذه الشروط ، أو لأي سلوك نعتقد أنه ضار بنا. العمل ، أو إلى أي طرف آخر.</p>
<p>تشكل هذه الشروط والأحكام وأي شروط إضافية منشورة على موقعنا معًا أساس علاقتنا معك فيما يتعلق بـ استخدامك لهذا الموقع.</p>
<br/>


<p className="fw-bold">2. خدمات</p>

<p>نحن نقدم الفنادق وحجوزات الطيران وخدمات السفر "الخدمات". يتم تقديم الخدمات على أساس التسهيلات والتوافر. نحن نحتفظ بالحق (ولأي سبب من الأسباب) في تعديل أو تغيير أو وقف أي جانب من جوانب الخدمات في أي وقت.</p>

<br/>
<p className="fw-bold">3. شروط وأحكام الخدمات</p>

<p>
* إجراءات الحجز<br/>
* سياسة الإلغاء والاستبدال<br/>
* لا يظهر. يعتبر عدم الحضور في منشأة الإقامة دون سابق إنذار بمثابة إلغاء. لن يكون هناك استرداد مطبق في حالة عدم الحضور.<br/></p>

<p>تسعير الخدمات. قد تختلف أسعار الخدمات من وقت لآخر. لأحدث الأسعار عليك زيارة الموقع.<br/>
طريقة الدفع. يمكن استبدال نقاط حصاد في متجر السفر كليًا أو جزئيًا.<br/>
المبالغ المعادة.<br/>
</p>

<p>لا مسؤولية. يتم توفير تصنيف جميع أماكن الإقامة إلينا من قبل مزود الإقامة من الطرف الثالث. نحن لسنا مسؤولين أو مسؤولين عن أي أخطاء أو معلومات غير دقيقة مقدمة إلينا من قبل مزود الطرف الثالث بما في ذلك على سبيل المثال لا الحصر الصور والأوصاف والمرافق / المرافق والتعريفات والمراجعات وأي معلومات أخرى يستخدمها مزود الطرف الثالث للترويج و تسويق خصائصه.</p>

<br/>
<p className="fw-bold">4. توافر الخدمات</p>

<p>الإشارة إلى المنتجات والخدمات على الموقع لا تعني أو تضمن أن هذه المنتجات أو الخدمات ستكون متاحة في أي وقت.</p>
<p>قد يتم أحيانًا تعليق وصولك إلى الموقع أو تقييده للسماح بالإصلاحات أو الصيانة أو إدخال مرافق أو خدمات جديدة. على الرغم من أننا سنحاول الحد من تكرار ومدة أي تعليق أو تقييد من هذا القبيل ، إلا أننا لن نتحمل المسؤولية إذا كان الموقع غير متاح لأي سبب من الأسباب في أي وقت أو فترة.</p>

<br/>
<p className="fw-bold">5. روابط لمواقع الطرف الثالث</p>

<p>قد يحتوي هذا الموقع على روابط لمواقع إلكترونية يديرها طرف ثالث. لا يتم تشغيل هذه المواقع أو التحكم فيها أو صيانتها من قبلنا. على هذا النحو ، نحن لسنا مسؤولين عن محتوياتها أو الممارسات الأخرى لهذه المواقع ولا يمكننا ضمان دقة أو موثوقية المعلومات أو البيانات أو المنتجات أو الخدمات المقدمة على مواقع الطرف الثالث هذه أو أمان أي معلومات أو بيانات قد تكون لك. تزود.</p>
<p>إن تضميننا لروابط هذه المواقع ، شريطة أن تكون مرجعا لك فقط ، ولا يعني ضمنا أي تأييد للمواد الموجودة على هذه المواقع أو أي ارتباط مع مشغليها.</p>
<p>لتجنب الشك ، لن نتحمل أي مسؤولية تجاهك على الإطلاق عن أي خسائر أو أضرار تتكبدها نتيجة الوصول إلى موقع طرف ثالث من موقعنا.</p>

<br/>
<p className="fw-bold">6. الإقرارات والضمانات الخاصة بك</p>

<p>
أنت تقر وتضمن ما يلي:<br />
* لديك الحق القانوني والقدرة على الدخول وتقديم الإقرارات والضمانات الواردة هنا.<br />
* لديك القدرة على أداء الالتزامات بموجب الشروط وفقًا للقوانين المعمول بها.<br />
* أنت ملتزم بجميع القوانين المعمول بها وقد قدمت الإيداعات / الإعلانات ذات الصلة وحصلت على الموافقات والتسجيلات المطلوبة بموجب القوانين المعمول بها.<br />
* ستكون مسؤولاً عن جميع استخدامات حسابك حتى لو تم إجراء هذا الاستخدام بدون إذنك أو إذنك<br />
* لن تستخدم الموقع لأي غرض غير قانوني أو محظور بموجب هذه الشروط.<br />
* سوف تحافظ على معلومات التسجيل والحساب الخاصة بك محدثة في جميع الأوقات ، بما في ذلك على سبيل المثال لا الحصر تفاصيل الاتصال.<br />
* جميع المعلومات التي ترسلها إلينا صحيحة ودقيقة.<br />
* يجب أن يتوافق استخدامك للخدمة مع الشروط المذكورة هنا والقوانين المعمول بها في جميع الأوقات.</p>

<br/>
<p className="fw-bold">7. مسؤوليتك</p>

<p>أنت تقر وتوافق على أنه من مسؤوليتك حماية معلومات حسابك وكلمة المرور الخاصة بك من أي استخدام غير مصرح به والتعامل مع هذه المعلومات على أنها سرية في جميع الأوقات ، وبالتالي فإنك توافق على عدم الكشف عنها لأي طرف ثالث.</p>
<p>لن نكون مسؤولين بأي حال من الأحوال عن الاستخدام غير المصرح به أو سوء الاستخدام لحسابك. بالرغم مما سبق ذكره ، تقع على عاتقك مسؤولية إخطارنا إذا كنت تعرف أو تشك في أن أي شخص آخر غيرك أصبح على علم بحسابك أو أي تفاصيل أخرى يجب أن تكون سرية بالنسبة لك.</p>

<br/>
<p className="fw-bold">8. السلوك المحظور</p>
<p>
كشرط لاستخدامك لهذا الموقع ، فإنك تتعهد بأنك لن تستخدم هذا الموقع لأي غرض غير قانوني أو محظور بموجب هذه الشروط والقوانين المعمول بها.<br />
سيشمل هذا السلوك المحظور على سبيل المثال لا الحصر ما يلي:<br />
* أي محتوى يحتوي أو يحتوي على روابط مؤدية إلى: عُري أو مواد إباحية أو محتوى للبالغين أو مواد بها جنس أو لغة بذيئة.<br />
* أي محتوى يتغاضى عن المتسللين أو يروج لهم أو يحتوي عليهم أو يرتبط بهم أو أي معلومات أخرى متعلقة بالقرصنة.<br />
* المحتوى الذي تم الترويج له من خلال إرسال البريد العشوائي أو مخططات الاحتيال عبر البريد ، أو الصفحات التي تروج أو تتغاضى عن إرسال البريد العشوائي.<br />
* المحتوى الذي يسيء إلى المجتمع بشكل صارخ ، بما في ذلك التعبيرات الصارخة عن التعصب أو التحيز أو العنصرية أو الكراهية أو الألفاظ النابية المفرطة ، أو لنشر أي مواد فاحشة أو قذرة أو عنيفة بشكل مفرط أو مضايقة أو غير مقبولة.<br />
* المحتوى الذي ينتهك أو ينتهك أي حقوق طبع ونشر أو براءة اختراع أو علامة تجارية أو علامة خدمة أو اسم تجاري أو سر تجاري أو أي حقوق ملكية فكرية أخرى لأي طرف ثالث.<br />
* المحتوى الذي يروج للاحتيال عبر البريد أو مخططات التسويق (الهرم) متعددة المستويات أو غيرها من الأنشطة غير القانونية أو الاحتيالية.<br />
* المحتوى الذي ينشر أو يكشف عن أي معلومات تعريف شخصية أو معلومات خاصة عن أي أطراف ثالثة دون موافقتهم الصريحة.<br />
* الهندسة العكسية أو التجميع العكسي أو اشتقاق كود المصدر الأساسي أو هيكل أو تسلسل أي خدمة أو حل أو تقنية.</p>

<br/>
<p className="fw-bold">9. نهاية</p>

<p>قد يتم إنهاء حسابك وفقًا لتقديرنا الخاص ، على سبيل المثال لا الحصر في الظروف التالية:<br />
* عندما تنتهك أي من هذه الشروط.<br />
* عندما نجد أن الخدمات تُستخدم بطريقة غير منظمة.<br />
* أي نوع من البريد العشوائي على المنصة.<br />
* يؤدي استخدامك للخدمة إلى تعطيل عملياتنا التجارية أو التأثير على أي طرف آخر.<br />
يمكنك طلب إنهاء الحساب في أي وقت عن طريق الاتصال بفريق خدمة العملاء لدينا عبر البريد الإلكتروني..</p>

<br/>
<p className="fw-bold">10. التعويض</p>

<p>كشرط لاستخدام هذا الموقع ، فإنك توافق على إبعادنا نحن والشركات التابعة لنا وشركائنا والمديرين والمسؤولين والموظفين لدينا وتعويضنا ، فورًا وعند الطلب ، من وضد أي وجميع الالتزامات والخسائر والمطالبات والنفقات (بما في ذلك أتعاب المحاماة المعقولة) والأضرار سواء الناشئة بشكل مباشر أو غير مباشر أو فيما يتعلق بالمطالبات المتعلقة بخرقك لهذه الشروط من قبلك.</p>

<br/>
<p className="fw-bold">11. الملكية الفكرية</p>

<p>الأسماء والكلمات والشعارات والشعارات والصور والعلامات والأسماء التجارية والعلامات التجارية وقاعدة البيانات المستخدمة في هذا الموقع هي إما ملكنا أو ملكية موفر الطرف الثالث ، وجميع هذه الحقوق محفوظة صراحةً. لا يجوز لك استخدام أو إعادة إنتاج أو نسخ أو التلاعب بأي منها بأي طريقة دون موافقتنا الخطية المسبقة.</p>

<br/>
<p className="fw-bold">12. خصوصية البيانات</p>

<p>أنت تتعهد بموجبه بأن لديك جميع الموافقات والتفويضات اللازمة من موضوع البيانات لنقل هذه البيانات الشخصية إلينا وتفوضنا بالمضي قدمًا في جميع عمليات النقل اللاحقة للبيانات من أجل إكمال طلب الخدمات وحجزها.</p>
<p>المعلومات الشخصية التي قد نجمعها من مزود الخدمة ، وكذلك أي بيانات شخصية ، عند استخدام هذا النظام ، والتي قد تفصح عنها لنا يجب أن تُفهم على أنها تم الحصول عليها ومعالجتها ونقلها مع مراعاة صارمة وامتثال تام مع المتطلبات المنصوص عليها في اللائحة العامة لحماية البيانات للاتحاد الأوروبي 2016/679. وفاءً لما هو منصوص عليه في اللائحة العامة لحماية البيانات في الاتحاد الأوروبي 2016/679 ، نتعهد بمراعاة السرية المهنية فيما يتعلق بهذه البيانات ، حتى عند إنهاء العلاقة والتأكد من التزام الموظفين الذين يؤدون الخدمات بالالتزام المذكور أعلاه.</p>
<p>إذا كنت ترغب في ممارسة حقوق الوصول أو التصحيح أو الإلغاء أو المعارضة الممنوحة من قبل اللائحة العامة لحماية البيانات الأوروبية 2016/679 ، فيمكنك التواصل معنا والإشارة إلى الأداء المطلوب فيما يتعلق بالبيانات الشخصية ونسخة من جواز سفرك.</p>

<br/>
<p className="fw-bold">13. الإجمالية</p>

<p>تحدد سياسة الخصوصية الخاصة بنا [https://app.rewardsby.com/privacy-policy] الشروط التي بموجبها نقوم بمعالجة وتخزين وإدارة أي بيانات شخصية تقدمها أنت و / أو نجمعها. قد يتم تعديل سياسة الخصوصية هذه من وقت لآخر ، ويجب أن تكون هذه التغييرات سارية من تاريخ نشر السياسة المعدلة على الموقع.<br /></p>
<br/>

<p className="fw-bold">14. تحديد المسؤولية</p>

<p>إلى الحد الذي يسمح به القانون المعمول به ، لن نكون ، بأي حال من الأحوال ، مسؤولين عن أي نوع من الأضرار العقابية أو العرضية أو الخاصة أو التبعية (بما في ذلك على سبيل المثال لا الحصر: خسارة الأرباح ، وضياع الفرصة ، وما إلى ذلك) الناشئة عن استخدام أو عدم القدرة على استخدام هذا الموقع بأي شكل من الأشكال ، أو مع التأخير أو عدم القدرة على استخدام هذا الموقع ، أو لأي معلومات أو منتجات أو خدمات يتم الحصول عليها من خلال أو تنشأ عن استخدام هذا الموقع ، أو تنشأ بأي شكل من الأشكال فيما يتعلق مع المنتجات سواء بناءً على العقد أو المسؤولية التقصيرية أو المسؤولية الصارمة أو غير ذلك ، حتى لو تم إبلاغنا باحتمال حدوث أضرار.</p>
<p>يتم توفير جميع هذه المعلومات والمنتجات والخدمات "كما هي" دون أي ضمان من أي نوع. نحن نخلي مسؤوليتنا من جميع الضمانات والشروط بأن هذا الموقع أو خوادمه أو أي بريد إلكتروني مرسل منا خالٍ من الفيروسات أو المكونات الضارة الأخرى. نحن بموجب هذا نخلي مسؤوليتنا عن جميع الضمانات والشروط المتعلقة بهذه المعلومات والمنتجات والخدمات ، بما في ذلك جميع الضمانات الضمنية وشروط القابلية للتسويق والملاءمة لغرض معين والملكية وعدم الانتهاك.</p>
<p>نحن لسنا مسؤولين عن الأفعال أو الأخطاء أو الإغفالات أو الإقرارات أو الضمانات أو الانتهاكات أو الإهمال من جانب أي مزود تابع لجهة خارجية وكذلك عن أي أعمال من قبل أي حكومة أو سلطة ، عن أي إصابات شخصية أو وفاة أو تلف في الممتلكات أو أي أضرار أخرى أو أي نفقات ناتجة عن ذلك. يكون مزود الطرف الثالث مسؤولاً في حالة الإخلال بالتزاماته في توفير المنتجات والخدمات لك. لا نتحمل أي مسؤولية ولن نقوم برد الأموال في حالة حدوث أي تأخير أو إلغاء أو حجز زائد أو إعادة جدولة أو إعادة حجز أو إعادة توجيه أو إضراب أو قوة قاهرة أو إعسار مالي أو إفلاس أو أسباب أخرى خارجة عن سيطرتنا المباشرة ولدينا لا توجد مسؤولية عن أي أحداث من هذا القبيل وكذلك عن أي نفقات أو أضرار إضافية.</p>
<p>علاوة على ذلك ، لن نتحمل بأي حال من الأحوال المسؤولية عن أي أضرار أو خسائر اقتصادية أو أي خسائر أخرى ، على الإطلاق ، نتيجة تقديم نسخ من ملفات بياناتك إلى السلطات المختصة أو التعاون مع جهود إنفاذ القانون لتحديد الأشخاص الذين نشروا محتوى غير قانوني أو يشجع على سلوك غير قانوني.</p>

<br/>
<p className="fw-bold">15. قوة قهرية</p>

<p>لن يكون أي طرف مسؤولاً أو مسؤولاً عن أو يعتبر مخالفًا لهذه الشروط بسبب أي تأخير أو فشل في تنفيذ هذه الشروط بسبب أي حدث أو ظرف خارج عن إرادته ، بما في ذلك ، على سبيل المثال لا الحصر ، القضاء والقدر ؛ اللوائح الحكومية ، وتقليص مرافق النقل ، والإضرابات ، وحالات الإغلاق ، والهجمات الإرهابية ، والضباب ، والتخريب ، وأعمال الشغب ، والاضطرابات المدنية ، والتمرد ، وأعمال الحرب ، وما إلى ذلك ("حدث قوة قاهرة"). يجب على الطرف غير المنفذ إعطاء الطرف الآخر إخطارًا كتابيًا يصف تفاصيل حدث القوة القاهرة في أقرب وقت ممكن.</p>

<br/>
<p className="fw-bold">16. مجال الاتصالات</p>

<p>يتم استخدامك للموقع رقميًا ، وأنت توافق على أنه يجوز لنا التواصل معك رقميًا لجميع جوانب استخدامك للموقع ، بما في ذلك إرسال رسائل بريد إلكتروني إليك. تسليم إخطارات البريد الإلكتروني ليس معصوما من الخطأ. أنت توافق على الاحتفاظ بجميع السجلات المتعلقة باستخدامك للموقع وطباعة أو عمل نسخة إلكترونية من هذه الشروط وأي إفصاحات مقدمة على الموقع. باستثناء ما يحظره القانون ، أنت تتنازل عن أي قانون يتطلب طرق اتصال مختلفة فيما يتعلق باستخدامك لهذا الموقع. على الرغم مما سبق ذكره ، نحتفظ بالحق ، وفقًا لتقديرنا الخاص ، في تسليم الإخطارات والإفصاحات بوسائل أخرى أيضًا.</p>

<br/>
<p className="fw-bold">17. القانون الواجب التطبيق والاختصاص القضائي</p>

<p>تخضع هذه الشروط لقانون المملكة العربية السعودية. تتم تسوية جميع النزاعات الناشئة عن هذه الشروط أو المتعلقة بها من قبل المحاكم المختصة في المملكة العربية السعودية.</p>

<br/>
<p className="fw-bold">18. اتصل بنا</p>
<p>لأي خدمات متعلقة بمتجر السفر ، يرجى إرسال بريد إلكتروني إلى care@rewardsby.com مع طلبك ومرجع الحجز وأرقام تعريف العميل.</p>
    </div>
)


export const privacyPolicyEnglish = (
    <div>
<p>RewardsBy Marketplace Program Travel Shop, We, Us, Our is committed to protecting <br />
RewardsBy Marketplace subscriber(s) &ldquo;You&rdquo;, &ldquo;Your&rdquo; privacy of the Information which provided by You or collect by Us on this website &ldquo;Site&rdquo; </p>

<p className="fw-bold">1. OVERVIEW</p>

<p>
This Policy describes how We collects, processes, uses, shares and secures the personal information You <br />
provide. It also describes Your choices regarding use, access, and correction of Your personal information. For purposes of this Policy, personal information shall mean any information relating to an identified or identifiable natural person. <br />
This Policy applies only to information We collect, process and use through Our website &ldquo;the Site&rdquo;. This <br />
Policy does not apply to information that We collect through other channels, such as information that We collect offline, from other websites or from emails You send us. </p>

<br/>
<p className="fw-bold">2. INFORMATION WE COLLECT</p>

<p>In order to provide the best Services to You (and to help make it feasible for Us to do so), it is essential <br />
that We are able to collect and use the information as described in this Policy. This means that the data collection is largely necessary for fulfilling the relationship We have with You, and where that is not the case, We have a legitimate interest in collecting the information described below. <br /></p>

<br />
<p className="fw-bold">3. INFORMATION YOU GIVE US</p> 

<p>When You fill out online forms, provide traveler or booking information, or provide Us with other <br />
personal information actively, We collect that information for processing and use in accordance with this Policy. We may change Our offerings, products and features from time to time, the options You have to provide Us with personal information also may change, but here are few illustrations through which We collect personal information as provided by You: </p>

<p><li>When You register on the Site</li> <br />
<li>When You create an account</li>  <br />
<li>Signing up for email alerts</li>  <br />
<li>Completing a form related to one of Our products or services</li>  <br />
<li>Uploading various documents required for identification</li>  <br />
<li>Contacting Us for technical support or customer service </li> <br />
Depending on how You interact with the Site, the personal information We collect from You may vary. <br />
For example, to create an account We may ask only for an email address and password. In other circumstances, on signing up with Us as an agent, We may ask You to provide other information, which may include Your name, phone number, and/or postal address. We also collect and store certain information associated with actions You take. For example, when You perform a search on Our Site, We store such search information, information provided by You through online forms, IP addresses among others. We also store information about the bookings performed or offers availed, the time and duration of Your activity on the Site, and other information about Your behavior on the Site. <br />
when You set up an individual user account, You may create a profile (a Profile) that will include <br />
personal information you provide. At your request, We will create your Profile with information We extract from details you have entered on the Site. When you fill out and save your Profile, your saved Profile can be viewed only by the authorized Persons. <br />
If you enter credit card information on the Site in connection with a booking, that information is sent <br />
directly from your browser to the third-party service provider We use to manage credit card processing and We do not store it on Our servers. The service provider is not permitted to use the information you provide except for the sole purpose of credit card processing on Our behalf.</p>

<br />
<p className="fw-bold">4. INFORMATION WE COLLECT AUTOMATICALLY</p> 

<p> When You use the Site, Your device is automatically providing information to Us so that We can <br />
customize Our response to You. The type of information We collect by automated means generally includes technical information about Your computer, such as Your IP address or other device identifier, the type of device You use, and operating system version. The information We collect also may include usage information and statistics about Your interaction with the Site. That information may include the URLs of Our web pages that You visited, URLs of referring and exiting pages, page views, time spent on a page, number of clicks, platform type, location data (if You have enabled access to Your location on Your mobile device), and other information about how You used the Site. </p> 

<br />
<p className="fw-bold">5. AUTOMATED MEANS OF DATA COLLECTION INCLUDE THE FOLLOWING </p>

<p>Cookies And Tracking Technologies. <br />
Technologies such as cookies, locally shared objects (sometimes called flash cookies), mobile identifiers and similar technologies (Cookies) are used by Us. We use Cookies to remember users&rsquo; settings as well as for authentication and analytics. These technologies may be used in analyzing trends, administering the Site, tracking users&rsquo; movements around the Site and to gather demographic information about Our user base as a whole. You can control the use of Cookies at the individual browser level, but if You choose to disable Cookies, it may limit Your use of certain features or functions on the Site or service. For more information on Cookies, how We use them, the third parties We work with who use them, and how You can control them, please see the section entitled Cookie Policy. <br />
Log File Information. <br />
Log file information is automatically reported by Your browser or mobile application each time You access the Site. For example, when you access the Site, Our servers automatically record certain information that Your web browser sends when you visit any website. These server logs include information such as Your web request, Internet Protocol (IP) address, browser type, referring / exit pages and URLs, number of clicks, domain names, landing pages, and pages viewed. <br />
Device Information. <br />
We collect information about the device You use to access the Site, including type of device, operating system, settings, and unique device identifiers, and IP address. Whether We collect some or all of this information often depends on what type of device You&rsquo;re using and its settings. We collect the device type and any other information You choose to provide, such as username, geolocation, or email address. <br />
We also use mobile analytics software to allow Us to better understand the functionality of our mobile software on your phone. This software records information such as how often you use the application, the events that occur within the application, aggregated usage, performance data, and where the application was downloaded from. We do not link the information We store within the analytics software to any <br />
personally identifiable information You submit. </p>

<br/>
<p className="fw-bold">6. HOW WE USE INFORMATION/PURPOSES </p>

<p>We use the information We collect to provide Our services (and to help make it feasible for Us to do so), <br />
it is essential that We are able to collect and use the information as described in this Policy. It is largely <br />
necessary for fulfilling the relationship We have with You, and, where that is not the case, We have a <br />
legitimate interest in using the information We collect, including personal information, for these purposes: </p>
<li> To provide You with relevant content </li>
<li> To customise and improve the features, performance, and support of the site </li>
<li> For internal operations, including troubleshooting, data analysis, testing, research, and service improvement (this includes use of Your IP address and mobile device information to help diagnose problems with Our service and to administer the Site)</li>
<li> To communicate with You or initiate communication with You on behalf of third parties through Your account or through other means such as email, telephone (including mobile phone), or postal mail, including through the use of contact information that You provide to Us or (where it is lawful for Us to do so) </li>
<li> To analyze use of the Site and improve it </li>
<li> To create aggregate statistical data that does not identify You individually (We use mobile device data and IP addresses to gather demographic information) </li>
<li> For other purposes that You separately authorize as You interaction . </li>
<br/>
<p>When We collect any information that does not identify You as a specific natural person (Non-Personal
Information), We are permitted to use and disclose this information for any purpose, notwithstanding anything to the contrary in this Policy, except where We are required to do otherwise by applicable law. If We combine any Non-Personal Information with Your personal information (such as combining Your ZIP code with Your name), We will use and disclose such combined information as personal information in accordance with this policy. Similarly, if applicable law requires that We treat certain Non-Personal Information as personal information, We will use and disclose this information as personal information in accordance with this policy. </p>

<br />
<p className="fw-bold">7. COOKIE POLICY</p>

<p>This Cookie Policy explains: <br />
<li> what types of cookies and similar technologies are placed on Your device when You visit Our Site and </li>
<li> how We use these cookies and similar technologies. </li>
Not all information captured through the use of cookies and similar technologies will identify You. </p>

<p>What are cookies? <br />
Cookies are small text files that are sent to or accessed from Your web browser or Your device&rsquo;s <br />
memory. A cookie typically contains the name of the domain (internet location) from which the cookie originated, the &ldquo;lifetime&rdquo; of the cookie (i.e., when it expires) and a randomly generated unique number or other identifier. A cookie also may contain information about Your device, such as user settings, browsing history and activities conducted while using Our Services. We may also use other similar tracking technologies such as widgets which allow You to redirect to Our social media pages. </p>

<p>Who can drop cookies on my device? <br />
First-party cookies <br />
First-party cookies are placed on your device directly. For example, We use first-party cookies to keep <br />
You logged in to the Site, authenticate Your device and adapt Our Services to your browser&rsquo;s language and culture preferences <br />
Third- Party Cookies <br />
Third-party cookies are placed on your device by Our partners and service providers. We do not use any <br />
third-party cookies. </p>

<p>How long does a cookie stay on my device? <br />
The lifespan of the cookies We use depends on the category of cookies. There are session cookies and <br />
persistent cookies. <br />
Session cookies <br />
Session cookies only last until you close your browser. We use session cookies for a variety of reasons, including to learn more about Your use of Our Services during one single browser session and to help you to use Our Services more efficiently. <br />
Persistent cookies/ <br />
Persistent cookies have a longer lifespan and aren&rsquo;t automatically deleted when you close your browser. <br />
These types of cookies are primarily used to authenticate your device, save your preferred language, help you quickly sign-in to Our Site again and for analytical purposes.<br />
What do We use cookies (and other tracking technologies) for? <br />
Like most providers of online services, We use cookies to provide, secure and improve Our Services, <br />
including by remembering Your preferences and recognising You when You visit Our Site. To fulfil these purposes, We may also link information from cookies with other personal information We hold about You. This means that in these cases We will know that the cookie information relates to You. <br />
When You visit Our Services, essential cookies will be set on your device. Other types of cookies (as <br />
listed below) will also be set on Your device if You choose to accept them. <br />
 <br />
Essential Cookies<br />
These cookies are strictly necessary to help support the structure of Our internet sites &amp; services. They perform functions like [ensuring Our pages load properly and allowing You to return to webpages You have previously visited].<br />
The legal basis for the use of essential cookies is Our legitimate interest (Article 6(1)(f) GDPR) and necessary for the performance of Our service in accordance with Booking Terms and Conditions (Article 6(1)(b) GDPR) <br />


<table className="privacy_table">
    <tr>
    <th>Cookie name</th>
    <th>What they do</th>
    <th>Duration</th>
    </tr>
    
    <tr>
        <td>Login</td>
        <td>To keep a user logged in to the Site.</td>
        <td> Expires with session</td>
    </tr>

    <tr>
        <td>2FAVerified</td>
        <td>To mark a device as having been verified by two-factor authentication.</td>
        <td>30 days</td>
    </tr>

    <tr>
        <td>Save Cookie</td>
        <td>To flag a user's acceptance of cookies on the Website. Expires with session</td>
        <td> Expires with session</td>
    </tr>

    <tr>
        <td>culture</td>
        <td>To save a user's preferred language/culture.</td>
        <td>30 days</td>
    </tr>
</table>
 <br />

Analytics Cookies<br />

These cookies are used for analytics purposes including to help Us understand how Our Services are used and to help Us customize and improve the Services for Our visitors. They help Us to make the use of Our services easier for You, and if necessary, to make improvements.<br />
The legal basis for the use of Analytics Cookies is Your consent (Article 6(1)(a) GDPR). <br />
 <br />
Google&trade; Maps API Cookies <br />
Some features of the Site and some of Our Services rely on the use of Google&trade; Maps API Cookies. Such <br />
cookies will be stored on Your device. <br />
When browsing the Services and using the services relying on Google&trade; Maps API cookies, You consent <br />
to the storage, collection of such cookies on Your device and to the access, usage and sharing by Google of the data collected thereby. <br />
Google&trade; manages the information and Your choices pertaining to Google&trade; Maps API Cookies via an <br />
interface separate from that supplied by Your browser. For more information, please see https://www.google.com/policies/technologies/cookies/. <br /><br />
Google Analytics <br />
We use Google Analytics, which is a Google service that uses cookies and other data collection <br />
technologies to collect information about your use of the Services in order to report website trends. <br />
You can opt out of Google Analytics by visiting www.google.com/settings/ads or by downloading the <br />
Google Analytics opt-out browser add-on at https://tools.google.com/dlpage/gaoptout. </p>

<p>How can You control cookies? <br />
Tools within this Cookie Policy <br />
You can set and modify Your cookies preferences at any time, by using the Cookie Management Preference Tool available at the top of this Cookie Policy. <br />
Browser and devices controls <br />
Some web browsers provide settings that allow You to control or reject cookies or to alert You when a cookie is placed on Your computer. The procedure for managing cookies is slightly different for each internet browser. You can check the specific steps in Your particular browser help menu. <br />
You also may be able to reset device identifiers by activating the appropriate setting on Your mobile <br />
device. The procedure for managing device identifiers is slightly different for each device. You can check the specific steps in the help or settings menu of Your particular device. </p>

<p>What happens if I disable all cookies? <br />
The changes You make to Your cookie preferences may make browsing Our Services a less satisfying <br />
experience. In some cases, You may even find Yourself unable to use all or part of the Site. <br />
Opting out of advertising cookies does not mean You will not see ads &ndash; it means You won&rsquo;t see <br />
personalised advertising from the companies that participate in the opt-out programs. Also, if You delete cookies on Your device after You opted out, You will need to opt-out again. <br />
Note that if You use browser or device controls and/or if You delete or clear cookies on Your device after <br />
You have modified Your cookie preferences, the next time You visit Our Site, Our cookies banner will appear and You will need to select Your preferences again. Please therefore ensure that Your preferences contained in the Cookie Management Preference Tool are up to date.</p>

<br />
<p className="fw-bold">8. ALL CREDIT/DEBIT CARDS</p>

<p>All credit/debit cards details and personally identifiable information will NOT be stored, sold, shared, <br />
rented or leased to any third parties </p>

<br />
<p className="fw-bold">9. OPTING OUT OF COOKIES</p> 

<p>We use Cookies that are necessary for Us to provide the Services You use and You cannot opt out of <br />
these Cookies on the Site. You may be able to disable placement of some (but not all) Cookies by setting Your browser to decline cookies, though this may worsen Your user experience. Additionally, You can also control, manage and/or delete cookies via Your browser settings. </p>

<br />
<p className="fw-bold">10. DO NOT TRACK SIGNALS</p>

<p>We do not currently respond to 'do not track' signals and similar settings or mechanisms. When You use <br />
the Site, We try to provide a customized experience. </p>

<br />
<p className="fw-bold">11. OTHER IMPORTANT PRIVACY INFORMATION</p> 

<p>If You wish to access, correct, update or request Your personal information, You can do so at any time by <br />
contacting Us using the contact details provided under Contact Us section. <br />
You have the right to opt-out of marketing communications We send You at any time. You can exercise <br />
this right by clicking on the unsubscribe Or opt-out link in the marketing emails We send You. If You are an unregistered user, or to opt-out of marketing You may contact Us using the contact details provided under the Contact Us. <br />
Similarly, if We have collected and processed Your personal information with Your consent, then You <br />
can withdraw Your consent at any time. Withdrawing Your consent will not affect the lawfulness of any processing We conducted prior to Your withdrawal, nor will it affect processing of Your personal information conducted in reliance on lawful processing grounds other than consent. <br />
We respond to all requests We receive from individuals wishing to exercise their data protection rights in <br />
accordance with applicable data protection laws. Notwithstanding the foregoing, We reserve the right to keep any information in Our archives that We deem necessary to comply with Our legal obligations, resolve disputes and enforce Our agreements. </p>

<br />
<p className="fw-bold">12. CHOICES REGARDING YOUR PERSONAL INFORMATION</p> 

<p>In addition to Your rights outlined above, if You are a registered user on Our Site, You can manage Your <br />
account settings in the Account Settings page on the Site where We provide You with the opportunity to 'opt-out' of having Your personal information used for certain purposes. <br />
We will send You notifications and if, and to the extent, you have opted-in to receive promotional <br />
communications, or other messages using the contact information (e.g., your email address, your mobile device identifier) you provided to Us when you registered or when you requested information from us. You may opt-out of continuing to receive optional messages by following the instructions included in each message. Also, you can control most of the emails you receive from Us by editing your email preferences within My Account settings on the Site; provided You will need to separately opt-out of receiving Our various emails by unsubscribing to each email that You have originally subscribed to. <br />
We will send You service-related announcements when We believe it is necessary to do so. In some <br />
cases, You can stop or limit the information We collect by automated means. To learn more about how You may be able to stop or limit Our receipt of that information please review the section entitled Cookie Policy. </p>

<br />
<p className="fw-bold">13. CLOSURE OF ACCOUNT</p> 

<p>If You'd like to close your account, You can do so within Your Account Settings on the Site. When You <br />
close Your account, You will no longer have full access to its features, but We reserve the right to keep <br />
any information in a closed account in Our archives that We deem necessary to comply with Our legal or <br />
regulatory obligations, resolve disputes and enforce Our agreements. </p> 

<br />
<p className="fw-bold">14. HOW LONG WE KEEP YOUR PERSONAL INFORMATION</p> 

<p> We keep Your personal information only so long as We need it to fulfill the purposes described in this <br />
Policy. This is also the case for anyone that We share Your personal information with and who carries out services on Our behalf. Retention periods can vary significantly based on the type of information and how it is used. Our retention periods are based on criteria that include legally mandated retention periods, pending or potential litigation, Our intellectual property or ownership rights, contract requirements, operational directives or needs, and historical archiving. When We no longer need to use Your personal information and there is no need for Us to keep it to comply with Our legal or regulatory obligations, resolve disputes and enforce Our agreements, we&rsquo;ll either remove it from Our systems or depersonalize it so that We can't identify You. </p> 

<br />
<p className="fw-bold">15. SECURITY SAFEGUARDS</p> 

<p> We employ physical, electronic, and managerial measures to safeguard the information We collect online. <br />
However, no company can fully eliminate security risks, so We cannot make guarantees about any part of Our services. You are responsible for keeping Your username and password secret. Once You have registered with Us, We will never ask You for Your password. Please create a unique password for your account and do not use it for any other web services or applications. Do not share Your password with anyone else. </p> 

<br />
<p className="fw-bold">16. YOU MAY REACH US HERE</p> 

<p> If you have an unresolved privacy or data use concern that We have not addressed satisfactorily, please <br />
contact Us at [care@rewardsby.com]</p> 

<br />
<p className="fw-bold">17. UPDATES TO OUR PRIVACY POLICY</p> 

<p>We may revise this Policy from time to time by posting an updated version on the Site. This version of <br />
the Policy will be effective for You as described at the beginning of the Policy. Further revisions of this Policy will become effective as follows: The revised Policy will be effective immediately for unregistered users and users registering accounts or otherwise acknowledging the Policy on or after the revision date. For other users who registered accounts before the revision date, it will also become effective immediately. However, they can object to the new Policy within thirty (30) days after the revision date. If We make a change that We believe materially reduces Your rights or increases Your responsibilities, We will notify You by email (sent to the email address specified in Your account) or by means of a notice on this Site prior to the change becoming effective. We may provide notice of changes in other circumstances as well. We encourage You to periodically review this page for the latest information on Our privacy practices. Your continued use of the Site is subject to the most current effective version of this Policy. </p>

<br />
<p className="fw-bold">18. CONTACT US</p> 
<p>If you need any help or clarification please contact by email to care@rewardsby.com along with your Order, Booking Reference and Customer ID Numbers.</p>
    </div>
)

export const privacyPolicyArabic = (
    <div>
<p>يلتزم برنامج RewardsBy Marketplace"ترافيل شوب" ، "نحن" ، "نا" ، "لدينا" بحماية مشترك (مشتركي) RewardsBy Marketplace "أنت" ، وخصوصيتك "الخاصة بك" للمعلومات التي تقدمها أو نجمعها من قبلنا على هذا الموقع "الموقع"</p>

<p className="fw-bold">1. نظرة عامة</p>

<p>توضح هذه السياسة كيف نقوم بجمع ومعالجة واستخدام ومشاركة وتأمين المعلومات الشخصية التي تقدمها. كما يصف اختياراتك فيما يتعلق باستخدام معلوماتك الشخصية والوصول إليها وتصحيحها. لأغراض هذه السياسة ، تعني المعلومات الشخصية أي معلومات تتعلق بشخص طبيعي محدد أو يمكن التعرف عليه.</p>
<p>تنطبق هذه السياسة فقط على المعلومات التي نجمعها ونعالجها ونستخدمها من خلال موقعنا "الموقع". لا تنطبق هذه السياسة على المعلومات التي نجمعها من خلال قنوات أخرى ، مثل المعلومات التي نجمعها في وضع عدم الاتصال ، من مواقع الويب الأخرى أو من رسائل البريد الإلكتروني التي ترسلها إلينا.</p>
<br/>

<p className="fw-bold">2. المعلومات التي نجمعها</p>

<p>من أجل تقديم أفضل الخدمات لك (وللمساعدة في جعل الأمر ممكنًا بالنسبة لنا) ، من الضروري أن نكون قادرين على جمع واستخدام المعلومات كما هو موضح في هذه السياسة. هذا يعني أن جمع البيانات ضروري إلى حد كبير للوفاء بالعلاقة التي تربطنا بك ، وحيثما لم يكن الأمر كذلك ، فلدينا مصلحة مشروعة في جمع المعلومات الموضحة أدناه.</p>

<br />
<p className="fw-bold">3. المعلومات التي تقدمها لنا</p> 

<p>عندما تملأ النماذج عبر الإنترنت ، أو تزودنا بمعلومات المسافر أو الحجز ، أو تزودنا بمعلومات شخصية أخرى بنشاط ، فإننا نجمع تلك المعلومات للمعالجة والاستخدام وفقًا لهذه السياسة. قد نقوم بتغيير عروضنا ومنتجاتنا وميزاتنا من وقت لآخر ، وقد تتغير أيضًا الخيارات التي يتعين عليك تزويدنا بها بالمعلومات الشخصية ، ولكن فيما يلي بعض الرسوم التوضيحية التي نجمع من خلالها المعلومات الشخصية على النحو الذي قدمته أنت:</p>


<p>
<li>عندما تقوم بالتسجيل في الموقع</li> <br />
<li>عندما تقوم بإنشاء حساب</li>  <br />
<li>الاشتراك في تنبيهات البريد الإلكتروني</li>  <br />
<li>استكمال نموذج متعلق بأحد منتجاتنا أو خدماتنا</li>  <br />
<li>تحميل المستندات المختلفة المطلوبة للتعريف</li>  <br />
<li>الاتصال بنا للحصول على الدعم الفني أو خدمة العملاء </li> <br />
</p>
<p>اعتمادًا على كيفية تفاعلك مع الموقع ، قد تختلف المعلومات الشخصية التي نجمعها منك. على سبيل المثال ، لإنشاء حساب قد نطلب فقط عنوان بريد إلكتروني وكلمة مرور. في ظروف أخرى ، عند التسجيل معنا كوكيل ، قد نطلب منك تقديم معلومات أخرى ، والتي قد تشمل اسمك ورقم هاتفك و / أو عنوانك البريدي. نقوم أيضًا بجمع وتخزين بعض المعلومات المرتبطة بالإجراءات التي تتخذها. على سبيل المثال ، عند إجراء بحث على موقعنا ، نقوم بتخزين معلومات البحث هذه والمعلومات التي تقدمها من خلال النماذج عبر الإنترنت وعناوين IP وغيرها. نقوم أيضًا بتخزين معلومات حول الحجوزات التي تم إجراؤها أو العروض المتاحة ، ووقت ومدة نشاطك على الموقع ، وغيرها من المعلومات حول سلوكك على الموقع.</p>
<p>عندما تقوم بإعداد حساب مستخدم فردي ، يمكنك إنشاء ملف تعريف ("ملف تعريف") يتضمن المعلومات الشخصية التي تقدمها. بناءً على طلبك ، سننشئ ملفك الشخصي بالمعلومات التي نستخرجها من التفاصيل التي أدخلتها على الموقع. عندما تقوم بملء وحفظ ملف التعريف الخاص بك ، يمكن فقط عرض ملف التعريف المحفوظ الخاص بك من قبل الأشخاص المصرح لهم.</p>
<p>إذا قمت بإدخال معلومات بطاقة الائتمان على الموقع فيما يتعلق بالحجز ، يتم إرسال هذه المعلومات مباشرة من متصفحك إلى مزود خدمة الطرف الثالث الذي نستخدمه لإدارة معالجة بطاقة الائتمان ولا نقوم بتخزينها على خوادمنا. لا يُسمح لمزود الخدمة باستخدام المعلومات التي تقدمها إلا لغرض وحيد هو معالجة بطاقة الائتمان نيابة عنا.</p>

<br />
<p className="fw-bold">4. المعلومات التي نجمعها تلقائيًا</p> 

<p> عندما تستخدم الموقع ، يقوم جهازك تلقائيًا بتوفير المعلومات لنا حتى نتمكن من تخصيص استجابتنا لك. يشتمل نوع المعلومات التي نجمعها بالوسائل الآلية بشكل عام على معلومات فنية حول جهاز الكمبيوتر الخاص بك ، مثل عنوان IP الخاص بك أو معرّف الجهاز الآخر ، ونوع الجهاز الذي تستخدمه ، وإصدار نظام التشغيل. قد تتضمن المعلومات التي نجمعها أيضًا معلومات الاستخدام وإحصاءات حول تفاعلك مع الموقع. قد تتضمن هذه المعلومات عناوين URL لصفحات الويب التي قمت بزيارتها ، وعناوين URL لصفحات الإحالة والخروج ، ومشاهدات الصفحة ، والوقت الذي تقضيه على الصفحة ، وعدد النقرات ، ونوع النظام الأساسي ، وبيانات الموقع (إذا قمت بتمكين الوصول إلى موقعك على جهاز محمول) ، ومعلومات أخرى حول كيفية استخدامك للموقع. </p> 

<br />
<p className="fw-bold">5. تشمل الوسائل الآلية لجمع البيانات ما يلي </p>

<p className="fw-bold">ملفات تعريف الارتباط وتقنيات التتبع.</p>
<p>
نحن نستخدم تقنيات مثل ملفات تعريف الارتباط ، والكائنات المشتركة محليًا (تسمى أحيانًا "ملفات تعريف الارتباط بالفلاش") ، ومعرفات الجوال والتقنيات المماثلة ("ملفات تعريف الارتباط"). نستخدم ملفات تعريف الارتباط لتذكر إعدادات المستخدمين وكذلك للمصادقة والتحليلات. يمكن استخدام هذه التقنيات في تحليل الاتجاهات وإدارة الموقع وتتبع تحركات المستخدمين حول الموقع وجمع المعلومات الديموغرافية حول قاعدة مستخدمينا ككل. يمكنك التحكم في استخدام ملفات تعريف الارتباط على مستوى المتصفح الفردي ، ولكن إذا اخترت تعطيل ملفات تعريف الارتباط ، فقد يحد ذلك من استخدامك لميزات أو وظائف معينة على الموقع أو الخدمة. لمزيد من المعلومات حول ملفات تعريف الارتباط ، وكيف نستخدمها ، والأطراف الثالثة التي نتعامل معها ، وكيف يمكنك التحكم بها ، يرجى الاطلاع على القسم المعنون سياسة ملفات تعريف الارتباط. </p>
<br/>

<p className="fw-bold">معلومات ملف السجل.</p>
<p>يتم الإبلاغ عن معلومات ملف السجل تلقائيًا بواسطة متصفحك أو تطبيق الهاتف المحمول في كل مرة تدخل فيها إلى الموقع. على سبيل المثال ، عند الوصول إلى الموقع ، تقوم خوادمنا تلقائيًا بتسجيل بعض المعلومات التي يرسلها متصفح الويب الخاص بك عند زيارة أي موقع ويب. تتضمن سجلات الخادم هذه معلومات مثل طلب الويب وعنوان بروتوكول الإنترنت ("IP") ونوع المتصفح وصفحات الإحالة / الخروج وعناوين URL وعدد النقرات وأسماء المجال والصفحات المقصودة والصفحات التي تم عرضها.</p>
<br/>

<p className="fw-bold">معلومات الجهاز.</p>
<p>نقوم بجمع معلومات حول الجهاز الذي تستخدمه للوصول إلى الموقع ، بما في ذلك نوع الجهاز ونظام التشغيل والإعدادات ومعرفات الجهاز الفريدة وعنوان IP. غالبًا ما يعتمد ما إذا كنا نجمع بعض هذه المعلومات أو كلها على نوع الجهاز الذي تستخدمه وإعداداته. نجمع نوع الجهاز وأي معلومات أخرى تختار تقديمها ، مثل اسم المستخدم أو الموقع الجغرافي أو عنوان البريد الإلكتروني.نستخدم أيضًا برنامج تحليلات الهاتف المحمول للسماح لنا بفهم أفضل لوظائف برنامج الهاتف المحمول الخاص بنا على هاتفك. يسجل هذا البرنامج معلومات مثل عدد مرات استخدامك للتطبيق ، والأحداث التي تحدث داخل التطبيق ، والاستخدام المجمع ، وبيانات الأداء ، والمكان الذي تم تنزيل التطبيق منه. نحن لا نربط المعلومات التي نخزنها في برنامج التحليلات بأي معلومات تعريف شخصية تقدمها.</p>
<br/>

<p className="fw-bold">6. كيف نستخدم المعلومات / الأغراض </p>

<p>نحن نستخدم المعلومات التي نجمعها لتقديم خدماتنا (وللمساعدة في جعل ذلك ممكنا بالنسبة لنا) ، فمن الضروري أن نكون قادرين على جمع واستخدام المعلومات كما هو موضح في هذه السياسة. إنه ضروري إلى حد كبير للوفاء بالعلاقة التي نتمتع بها معك ، وحيثما لم يكن الأمر كذلك ، فلدينا مصلحة مشروعة في استخدام المعلومات التي نجمعها ، بما في ذلك المعلومات الشخصية ، لهذه الأغراض:</p>

<li> لتزويدك بالمحتوى ذي الصلة</li>
<li> لتخصيص وتحسين ميزات وأداء ودعم الموقع</li>
<li> للعمليات الداخلية ، بما في ذلك استكشاف الأخطاء وإصلاحها وتحليل البيانات والاختبار والبحث وتحسين الخدمة (وهذا يشمل استخدام عنوان IP الخاص بك ومعلومات الجهاز المحمول للمساعدة في تشخيص المشاكل مع خدمتنا وإدارة الموقع)</li>
<li> للتواصل معك أو بدء الاتصال بك نيابة عن أطراف ثالثة من خلال حسابك أو من خلال وسائل أخرى مثل البريد الإلكتروني أو الهاتف (بما في ذلك الهاتف المحمول) أو البريد العادي ، بما في ذلك من خلال استخدام معلومات الاتصال التي تقدمها لنا أو ( حيث يجوز لنا القيام بذلك)</li>
<li> لتحليل استخدام الموقع وتحسينه </li>
<li> لإنشاء بيانات إحصائية مجمعة لا تحدد هويتك بشكل فردي (نستخدم بيانات الجهاز المحمول وعناوين IP لجمع المعلومات الديموغرافية)</li>
<li> للأغراض الأخرى التي تصرح بها بشكل منفصل عند تفاعلك. </li>
<br/>

<p>عندما نجمع أي معلومات لا تحدد هويتك كشخص طبيعي معين ("المعلومات غير الشخصية") ، يُسمح لنا باستخدام هذه المعلومات والكشف عنها لأي غرض ، بغض النظر عن أي شيء يتعارض مع هذه السياسة ، باستثناء الحالات التي نكون فيها مطلوبًا للقيام بخلاف ذلك بموجب القانون المعمول به. إذا قمنا بدمج أي معلومات غير شخصية مع معلوماتك الشخصية (مثل دمج الرمز البريدي الخاص بك مع اسمك) ، فسنستخدم هذه المعلومات المجمعة ونفصح عنها كمعلومات شخصية وفقًا لهذه السياسة. وبالمثل ، إذا كان القانون المعمول به يتطلب أن نتعامل مع بعض المعلومات غير الشخصية على أنها معلومات شخصية ، فسنستخدم هذه المعلومات ونكشف عنها كمعلومات شخصية وفقًا لهذه السياسة.
</p>

<br />
<p className="fw-bold">7. سياسة ملفات الارتباط</p>

<p>توضح سياسة ملفات تعريف الارتباط هذه: <br />
<li> ما أنواع ملفات تعريف الارتباط والتقنيات المماثلة التي يتم وضعها على جهازك عند زيارة موقعنا و </li>
<li>كيف نستخدم ملفات تعريف الارتباط هذه والتقنيات المماثلة.</li>
لن تحدد هويتك جميع المعلومات التي يتم الحصول عليها من خلال استخدام ملفات تعريف الارتباط والتقنيات المماثلة.</p>

<p>ما هي ملفات تعريف الارتباط؟ <br />
ملفات تعريف الارتباط هي ملفات نصية صغيرة يتم إرسالها أو الوصول إليها من متصفح الويب أو ذاكرة جهازك. يحتوي ملف تعريف الارتباط عادةً على اسم المجال (موقع الإنترنت) الذي نشأ منه ملف تعريف الارتباط ، و "عمر" ملف تعريف الارتباط (أي عند انتهاء صلاحيته) ورقم فريد أو معرف آخر يتم إنشاؤه عشوائيًا. قد يحتوي ملف تعريف الارتباط أيضًا على معلومات حول جهازك ، مثل إعدادات المستخدم وسجل التصفح والأنشطة التي يتم إجراؤها أثناء استخدام خدماتنا. قد نستخدم أيضًا تقنيات تتبع أخرى مماثلة مثل الأدوات التي تسمح لك بإعادة التوجيه إلى صفحات وسائل التواصل الاجتماعي الخاصة بنا.</p>

<p>من يمكنه إسقاط ملفات تعريف الارتباط على جهازي؟ <br />
ملفات تعريف ارتباط الطرف الأول <br />
يتم وضع ملفات تعريف الارتباط الخاصة بالطرف الأول على جهازك مباشرة. على سبيل المثال ، نستخدم ملفات تعريف الارتباط الخاصة بالطرف الأول للاحتفاظ بتسجيل الدخول إلى الموقع ، ومصادقة جهازك وتكييف خدماتنا مع تفضيلات اللغة والثقافة في متصفحك <br />
ملفات تعريف ارتباط الطرف الثالث<br />
يتم وضع ملفات تعريف الارتباط للجهات الخارجية على جهازك بواسطة شركائنا ومقدمي الخدمة. نحن لا نستخدم أي ملفات تعريف ارتباط لطرف ثالث.</p>

<p>ما هي مدة بقاء ملف تعريف الارتباط على جهازي؟ <br />
يعتمد عمر ملفات تعريف الارتباط التي نستخدمها على فئة ملفات تعريف الارتباط. هناك ملفات تعريف الارتباط للجلسة وملفات تعريف الارتباط الدائمة.<br />
ملفات تعريف الارتباط للجلسة<br />
تستمر ملفات تعريف الارتباط للجلسة فقط حتى تغلق المستعرض الخاص بك. نحن نستخدم ملفات تعريف الارتباط للجلسة لعدة أسباب ، بما في ذلك معرفة المزيد حول استخدامك لخدماتنا خلال جلسة متصفح واحدة ولمساعدتك على استخدام خدماتنا بشكل أكثر كفاءة. <br />
ملفات تعريف الارتباط الدائمة /<br/>
تتمتع ملفات تعريف الارتباط الدائمة بعمر أطول ولا يتم حذفها تلقائيًا عند إغلاق المتصفح. تُستخدم هذه الأنواع من ملفات تعريف الارتباط بشكل أساسي لمصادقة / توثيق جهازك ، وحفظ لغتك المفضلة ، ومساعدتك على تسجيل الدخول بسرعة إلى موقعنا مرة أخرى ولأغراض تحليلية.
<br />
ما الغرض من استخدام ملفات تعريف الارتباط (وتقنيات التتبع الأخرى)؟<br />
مثل معظم مزودي الخدمات عبر الإنترنت ، نستخدم ملفات تعريف الارتباط لتوفير خدماتنا وتأمينها وتحسينها ، بما في ذلك من خلال تذكر تفضيلاتك والتعرف عليك عندما تزور موقعنا. لتحقيق هذه الأغراض ، قد نقوم أيضًا بربط المعلومات من ملفات تعريف الارتباط بالمعلومات الشخصية الأخرى التي نحتفظ بها عنك. هذا يعني أنه في هذه الحالات سنعرف أن معلومات ملفات تعريف الارتباط تتعلق بك.
 <br />
 ملفات تعريف الارتباط الأساسية<br />
 تعد ملفات تعريف الارتباط هذه ضرورية للغاية للمساعدة في دعم بنية مواقع وخدمات الإنترنت الخاصة بنا. إنهم يؤدون وظائف مثل [ضمان تحميل صفحاتنا بشكل صحيح والسماح لك بالعودة إلى صفحات الويب التي قمت بزيارتها سابقًا].<br />
 الأساس القانوني لاستخدام ملفات تعريف الارتباط الأساسية هو مصلحتنا المشروعة (المادة 6 (1) (و) من القانون العام لحماية البيانات) وضروري لأداء خدمتنا وفقًا لشروط وأحكام الحجز (المادة 6 (1) (ب) القانون العام لحماية البيانات)
 <br />
 </p>

<table className="privacy_table">
    <tr>
    <th>اسم ملف تعريف الارتباط</th>
    <th>ماذا يعملون؟</th>
    <th>مدة</th>
    </tr>
    
    <tr>
        <td>تسجيل الدخول</td>
        <td>للحفاظ على تسجيل دخول المستخدم إلى الموقع </td>
        <td>تنتهي مع الجلسة</td>
    </tr>

    <tr>
        <td>تم التحقق من مصادقة عاملين</td>
        <td>لوضع علامة على جهاز على أنه تم التحقق منه بواسطة المصادقة ذات العاملين.</td>
        <td>30 يوما</td>
    </tr>

    <tr>
        <td>حفظ ملف تعريف الارتباط</td>
        <td>للإبلاغ عن قبول المستخدم لملفات تعريف الارتباط على موقع الويب.</td>
        <td>تنتهي مع الجلسة</td>
    </tr>

    <tr>
        <td>حضاره</td>
        <td>لحفظ اللغة / الثقافة المفضلة للمستخدم.</td>
        <td>30 يوما</td>
    </tr>
</table>
 <br />

 ملفات تعريف الارتباط التحليلية<br />

 تُستخدم ملفات تعريف الارتباط هذه لأغراض التحليل بما في ذلك مساعدتنا على فهم كيفية استخدام خدماتنا ومساعدتنا على تخصيص الخدمات وتحسينها لزوارنا. إنها تساعدنا على جعل استخدام خدماتنا أسهل بالنسبة لك ، وإذا لزم الأمر ، لإجراء تحسينات.<br />
 الأساس القانوني لاستخدام التحليلات ملفات تعريف الارتباط هي موافقتك (المادة 6 (1) (أ) القانون العام لحماية البيانات).<br />
 <br />
 ملفات تعريف الارتباط لـ Google ™ Maps API<br />
 تعتمد بعض ميزات الموقع وبعض خدماتنا على استخدام ملفات تعريف الارتباط لـ Google ™ Maps API. سيتم تخزين ملفات تعريف الارتباط هذه على جهازك.
<br />
عند تصفح الخدمات واستخدام الخدمات التي تعتمد على ملفات تعريف الارتباط الخاصة بواجهة برمجة تطبيقات خرائط Google ™ ، فإنك توافق على تخزين وجمع ملفات تعريف الارتباط هذه على جهازك وعلى وصول واستخدام ومشاركة Google للبيانات التي تم جمعها بذلك.
 <br />
 تدير Google ™ المعلومات وخياراتك المتعلقة بملفات تعريف الارتباط لـ Google ™ Maps API عبر واجهة منفصلة عن تلك التي يوفرها متصفحك. لمزيد من المعلومات ، يرجى الاطلاع على https://www.google.com/policies/technologies/cookies/.
<br />

تحليلات كوكل<br />
نحن نستخدم Google Analytics ، وهي خدمة من خدمات Google تستخدم ملفات تعريف الارتباط وتقنيات جمع البيانات الأخرى لجمع معلومات حول استخدامك للخدمات من أجل الإبلاغ عن اتجاهات موقع الويب.<br />
يمكنك إلغاء الاشتراك في Google Analytics عن طريق زيارة www.google.com/settings/ads أو عن طريق تنزيل وظيفة المتصفح الإضافية لتعطيل التتبع في Google Analytics على https://tools.google.com/dlpage/gaoptout.<br />

<p>كيف يمكنك التحكم في ملفات تعريف الارتباط؟<br />
أدوات ضمن سياسة ملفات تعريف الارتباط هذه<br />
يمكنك تعيين وتعديل تفضيلات ملفات تعريف الارتباط الخاصة بك في أي وقت ، باستخدام أداة تفضيلات إدارة ملفات تعريف الارتباط المتوفرة في الجزء العلوي من سياسة ملفات تعريف الارتباط هذه.<br />

أدوات التحكم في المتصفح والأجهزة <br />
توفر بعض متصفحات الويب إعدادات تسمح لك بالتحكم في ملفات تعريف الارتباط أو رفضها أو لتنبيهك عند وضع ملف تعريف ارتباط على جهاز الكمبيوتر الخاص بك. يختلف إجراء إدارة ملفات تعريف الارتباط اختلافًا طفيفًا لكل متصفح إنترنت. يمكنك التحقق من الخطوات المحددة في قائمة تعليمات المتصفح الخاص بك.<br />
قد تتمكن أيضًا من إعادة تعيين معرفات الجهاز عن طريق تنشيط الإعداد المناسب على جهازك المحمول. يختلف إجراء إدارة معرّفات الأجهزة اختلافًا طفيفًا لكل جهاز. يمكنك التحقق من الخطوات المحددة في قائمة المساعدة أو الإعدادات الخاصة بجهازك المحدد.</p>

<p>ماذا يحدث إذا قمت بتعطيل جميع ملفات تعريف الارتباط؟<br />
التغييرات التي تجريها على تفضيلات ملفات تعريف الارتباط قد تجعل تصفح خدماتنا تجربة أقل إرضاءً. في بعض الحالات ، قد تجد نفسك غير قادر على استخدام كل أو جزء من الموقع.<br />
لا يعني إلغاء الاشتراك في ملفات تعريف الارتباط الإعلانية أنك لن ترى إعلانات - فهذا يعني أنك لن ترى إعلانات مخصصة من الشركات التي تشارك في برامج إلغاء الاشتراك. أيضًا ، إذا قمت بحذف ملفات تعريف الارتباط على جهازك بعد إلغاء الاشتراك ، فستحتاج إلى إلغاء الاشتراك مرة أخرى.<br />
لاحظ أنه إذا كنت تستخدم عناصر تحكم في المتصفح أو الجهاز و / أو إذا قمت بحذف أو مسح ملفات تعريف الارتباط على جهازك بعد تعديل تفضيلات ملفات تعريف الارتباط الخاصة بك ، في المرة التالية التي تزور فيها موقعنا ، سيظهر شعار ملفات تعريف الارتباط الخاص بنا وستحتاج إلى تحديد تفضيلاتك تكرارا. لذلك يرجى التأكد من تحديث تفضيلاتك الواردة في أداة تفضيلات إدارة ملفات تعريف الارتباط.</p>
<br />

<p className="fw-bold">8. جميع بطاقات الائتمان / الخصم</p>

<p>لن يتم تخزين جميع تفاصيل بطاقات الائتمان / الخصم ومعلومات التعريف الشخصية أو بيعها أو مشاركتها أو تأجيرها أو تأجيرها لأي طرف ثالث</p>
<br />

<p className="fw-bold">9. الخروج من ملفات تعريف الارتباط</p> 

<p>نحن نستخدم ملفات تعريف الارتباط الضرورية بالنسبة لنا لتقديم الخدمات التي تستخدمها ولا يمكنك إلغاء الاشتراك في ملفات تعريف الارتباط هذه على الموقع. قد تتمكن من تعطيل وضع بعض (وليس كل) ملفات تعريف الارتباط عن طريق ضبط متصفحك على رفض ملفات تعريف الارتباط ، على الرغم من أن هذا قد يؤدي إلى تفاقم تجربة المستخدم الخاصة بك. بالإضافة إلى ذلك ، يمكنك أيضًا التحكم في ملفات تعريف الارتباط و / أو إدارتها و / أو حذفها عبر إعدادات متصفحك.</p>
<br />

<p className="fw-bold">10. لا تتبع الإشارات</p>

<p>لا نستجيب حاليًا لإشارات "عدم التتبع" والإعدادات أو الآليات المماثلة. عندما تستخدم الموقع ، نحاول تقديم تجربة مخصصة.</p>
<br />

<p className="fw-bold">11. معلومات الخصوصية المهمة الأخرى</p> 

<p>إذا كنت ترغب في الوصول إلى معلوماتك الشخصية أو تصحيحها أو تحديثها أو طلبها ، يمكنك القيام بذلك في أي وقت عن طريق الاتصال بنا باستخدام تفاصيل الاتصال الواردة في قسم "اتصل بنا".<br />
يحق لك إلغاء الاشتراك في الاتصالات التسويقية التي نرسلها إليك في أي وقت. يمكنك ممارسة هذا الحق من خلال النقر على رابط "إلغاء الاشتراك" أو "إلغاء الاشتراك" في رسائل البريد الإلكتروني التسويقية التي نرسلها إليك. إذا كنت مستخدمًا غير مسجل ، أو إذا كنت ترغب في إلغاء الاشتراك في التسويق ، فيمكنك الاتصال بنا باستخدام تفاصيل الاتصال الواردة ضمن "اتصل بنا".<br />
وبالمثل ، إذا قمنا بجمع معلوماتك الشخصية ومعالجتها بموافقتك ، فيمكنك سحب موافقتك في أي وقت. لن يؤثر سحب موافقتك على قانونية أي معالجة أجريناها قبل انسحابك ، ولن يؤثر على معالجة معلوماتك الشخصية التي تتم بناءً على أسس معالجة قانونية بخلاف الموافقة.<br />
نحن نستجيب لجميع الطلبات التي نتلقاها من الأفراد الذين يرغبون في ممارسة حقوق حماية البيانات الخاصة بهم وفقًا لقوانين حماية البيانات المعمول بها. بالرغم مما سبق ذكره ، نحتفظ بالحق في الاحتفاظ بأي معلومات في أرشيفاتنا نراها ضرورية للامتثال لالتزاماتنا القانونية وحل النزاعات وإنفاذ اتفاقياتنا.</p>
<br />

<p className="fw-bold">12. اختيارات بخصوص معلوماتك الشخصية</p> 

<p>بالإضافة إلى حقوقك الموضحة أعلاه ، إذا كنت مستخدمًا مسجلاً على موقعنا ، فيمكنك إدارة إعدادات حسابك في صفحة "إعدادات الحساب" على الموقع حيث نوفر لك فرصة "إلغاء الاشتراك" في الحصول على المعلومات الشخصية المستخدمة لأغراض معينة.<br />
سنرسل إليك إشعارات ، وإذا اخترت ، إلى حد ما ، تلقي اتصالات ترويجية أو رسائل أخرى باستخدام معلومات الاتصال (على سبيل المثال ، عنوان بريدك الإلكتروني ومعرف جهازك المحمول) التي قدمتها لنا عند التسجيل أو عندما طلبت معلومات منا. يمكنك إلغاء الاشتراك في الاستمرار في تلقي الرسائل الاختيارية باتباع الإرشادات المضمنة في كل رسالة. أيضًا ، يمكنك التحكم في معظم رسائل البريد الإلكتروني التي تتلقاها منا عن طريق تحرير تفضيلات البريد الإلكتروني الخاصة بك ضمن إعدادات "حسابي" على الموقع ؛ بشرط أنك ستحتاج إلى الانسحاب بشكل منفصل من تلقي رسائل البريد الإلكتروني المختلفة لدينا عن طريق إلغاء الاشتراك في كل بريد إلكتروني قمت بالاشتراك فيه في الأصل.<br />
سنرسل إليك إعلانات متعلقة بالخدمة عندما نعتقد أنه من الضروري القيام بذلك. في بعض الحالات ، يمكنك إيقاف أو تقييد المعلومات التي نجمعها بالوسائل الآلية. لمعرفة المزيد حول كيف يمكنك إيقاف أو تقييد استلامنا لتلك المعلومات ، يرجى مراجعة القسم المعنون سياسة ملفات تعريف الارتباط.
</p>

<br />
<p className="fw-bold">13. إغلاق الحساب</p> 

<p>إذا كنت ترغب في إغلاق حسابك ، يمكنك القيام بذلك من خلال إعدادات حسابك على الموقع. عند إغلاق حسابك ، لن يكون لديك حق الوصول الكامل إلى ميزاته ، لكننا نحتفظ بالحق في الاحتفاظ بأي معلومات في حساب مغلق في أرشيفاتنا التي نراها ضرورية للامتثال لالتزاماتنا القانونية أو التنظيمية وحل النزاعات والتنفيذ اتفاقياتنا.</p> 

<br />
<p className="fw-bold">14. كم من الوقت نحتفظ بمعلوماتك الشخصية</p> 

<p> نحتفظ بمعلوماتك الشخصية فقط طالما أننا بحاجة إليها لتحقيق الأغراض الموضحة في هذه السياسة. هذا هو الحال أيضًا مع أي شخص نشارك معه معلوماتك الشخصية ويقوم بتنفيذ الخدمات نيابة عنا. يمكن أن تختلف فترات الاحتفاظ بشكل كبير بناءً على نوع المعلومات وكيفية استخدامها. تستند فترات الاحتفاظ لدينا إلى معايير تشمل فترات الاحتفاظ الإلزامية قانونًا ، والتقاضي المعلق أو المحتمل ، وحقوق الملكية الفكرية أو حقوق الملكية الخاصة بنا ، ومتطلبات العقد ، والتوجيهات أو الاحتياجات التشغيلية ، والأرشفة التاريخية. عندما لم نعد بحاجة إلى استخدام معلوماتك الشخصية ولم تعد هناك حاجة لنا للاحتفاظ بها للامتثال لالتزاماتنا القانونية أو التنظيمية ، وحل النزاعات وإنفاذ اتفاقياتنا ، فسنقوم إما بإزالتها من أنظمتنا أو نزع الطابع الشخصي عنها بحيث لا يمكننا تحديد هويتك.</p> 
<br />

<p className="fw-bold">15. الضمانات الأمنية</p> 

<p> نحن نستخدم تدابير مادية وإلكترونية وإدارية لحماية المعلومات التي نجمعها عبر الإنترنت. ومع ذلك ، لا يمكن لأي شركة القضاء على المخاطر الأمنية بالكامل ، لذلك لا يمكننا تقديم ضمانات بشأن أي جزء من خدماتنا. أنت مسؤول عن الحفاظ على سرية اسم المستخدم وكلمة المرور. بمجرد التسجيل معنا ، لن نطلب منك كلمة المرور الخاصة بك أبدًا. الرجاء إنشاء كلمة مرور فريدة لحسابك وعدم استخدامها لأي خدمات أو تطبيقات ويب أخرى. لا تشارك كلمة مرورك مع أي شخص آخر.</p> 
<br />

<p className="fw-bold">16. يمكنك الوصول إلينا هنا</p> 

<p>إذا كانت لديك مخاوف تتعلق بالخصوصية أو استخدام البيانات لم يتم حلها ولم نعالجها بشكل مرض ، فيرجى الاتصال بنا على care@rewardsby.com</p> 

<br />
<p className="fw-bold">17. تحديثات سياسة الخصوصية الخاصة بنا</p> 

<p>قد نقوم بمراجعة هذه السياسة من وقت لآخر عن طريق نشر نسخة محدثة على الموقع. سيكون هذا الإصدار من السياسة ساريًا بالنسبة لك كما هو موضح في بداية السياسة. ستصبح المراجعات الإضافية لهذه السياسة سارية المفعول على النحو التالي: ستصبح السياسة المعدلة سارية على الفور للمستخدمين غير المسجلين والمستخدمين الذين يسجلون الحسابات أو يعترفون بالسياسة في تاريخ المراجعة أو بعده. بالنسبة للمستخدمين الآخرين الذين سجلوا حسابات قبل تاريخ المراجعة ، سيصبح ساريًا أيضًا على الفور. ومع ذلك ، يمكنهم الاعتراض على السياسة الجديدة في غضون ثلاثين (30) يومًا من تاريخ المراجعة. إذا قمنا بإجراء تغيير نعتقد أنه يقلل ماديًا من حقوقك أو يزيد من مسؤولياتك ، فسنخطرك عبر البريد الإلكتروني (المرسل إلى عنوان البريد الإلكتروني المحدد في حسابك) أو عن طريق إشعار على هذا الموقع قبل أن يصبح التغيير ساريًا. قد نقدم إشعارًا بالتغييرات في الظروف الأخرى أيضًا. نحن نشجعك على مراجعة هذه الصفحة بشكل دوري للحصول على أحدث المعلومات حول ممارسات الخصوصية لدينا. يخضع استمرار استخدامك للموقع لأحدث نسخة فعالة من هذه السياسة.</p>

<br />
<p className="fw-bold">18. اتصل بنا</p> 
<p>لأي خدمات متعلقة بمتجر السفر ، يرجى إرسال بريد إلكتروني إلى care@rewardsby.com مع طلبك ومرجع الحجز وأرقام تعريف العميل.</p>
    </div>
)