import React, { Component } from 'react';
import ModalWrapper from './ModalWrapper';
import sad from "../../../assests/img/sadd.svg";
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

class StripeFailureModal extends Component {
    setModalView=()=>{
        this.props.changeStripeStatus()
    }
    render() {
        const {t}=this.props
        return ( 
            <ModalWrapper show={this.props.show}>
                <div 
                    className="p-4 bg-light mx-auto text-center py-md-5 py-4" 
                    style={{width:'35rem'}}>
                    <img 
                        className="p-md-0 p-3"
                        height="100"
                        src={sad} alt="" /> 
                    <h3 className='text-muted m-0 py-md-3 py-0'>
                    {t("Payment Failed")}
                    </h3> 
                    <p>{t(this.props.Message)}</p>
                
                    <Link 
                    to={{state:this.props.location?.state, pathname:window.location.pathname}}
                    className="btn  border-color-blue mt-3 mx-2"
                    onClick={()=>{this.setModalView()}}>
                    <span className="fw-bold color-blue">{t("Close")}</span>
                    </Link>      
                </div>
            </ModalWrapper>
         );
    }
}
const mapDispatchToProps = dispatch => {
    return {    

    }
}

export default connect(null,mapDispatchToProps) (withTranslation()  (StripeFailureModal));