import React, { Component } from 'react'
import { Redirect } from 'react-router';


import '../../assests/css/App.css';
import '../../assests/css/Giftsection.css';
import TermsConditionModal from '../shared/Modals/TermsConditionModal';
import Breadcrumbs from '../shared/Breadcrumbs';

class PartnersList extends Component {

    constructor(props) {
        super(props);
        if (props.location.state !== null && props.location.state !== undefined) {
            this.referrer = props.location.state.referrer
        }

        this.state = {
            information: this.referrer,
            value:this.referrer.min,
            min_value:this.referrer.min,
            max_value:this.referrer.max,
            showLink:this.referrer.name
        }
    }

    handleclick(e,val){
        this.setState({
            value:val
        })
    }
        render() {
            if (this.state.information === undefined || null) {
                return (
                    <Redirect push to={{
                        pathname: '/'
                    }} />
                )   
            }
            const {information} = this.state
            const breadcrumbs =[
                {name:'Home',url:'/',active:true},
                {name:'Hassad Partners',url:`/hassadpartnerslist`,active:true},
                {name:information.name,url:'',active:false}
            ] 
            return (
                <div>
                    
                    
                    <Breadcrumbs crumbs={breadcrumbs}/>
                    <div className="container-fluid px-5 py-5">
                        <div className="d-flex">
                            <div className="col-3 p-0">
                                <img className=" img-fluid p-0" src={information.images?.color?.medium_rectangle} href="#" alt=''/>
                            </div>
                                <div className="px-5 flex-grow-1">
                                    <h2 className="color-blue m-0">{information.name}</h2>
                                    <h5 className='text-black-50 m-0 py-1'>
                                        by {information.name}
                                    </h5>
                                    <h5 className="m-0 py-4 text-dark">
                                        <span style={{color:'#bbb'}}>Value:</span> {this.state.value*10}
                                        <small className='fs-6 pl-2'>Points</small>
                                    </h5>
                                    <hr className="hr-line my-2"/>
                                    <h5 className="m-0 pt-4 pb-2 text-dark">
                                        <span style={{color:'#bbb'}}>Card Value:</span> {this.state.value*10}
                                    </h5>
                                <div className="d-flex mr-5 pr-5 ">
                                {
                                    [100,200,300,500,1000].map(rate=>(

                                                <button
                                                    className={`btn mr-3 px-4 rounded-0 ${this.state.value===rate?'bg-color-skyblue text-light':'text-muted card-rate'}`}
                                                    onClick={(e)=>this.handleclick(e,rate)}
                                                    >
                                                    {rate}
                                                </button>

                                    ))
                                }
                                
                            </div>
                            {this.state.showLink && this.state.showLink === "Hassad" ? <div className="row">
                                <div className="col-12 pt-4"> 
                                <h5 className="color-blue m-0 py-3">Highlights</h5>
                                    <h6 className="text-black-50 d-inline">- 's Hassad Gift Card </h6> 
                                    <a href="https://testapimeritmarketplace.meritincentives.com/hassad-partners">
                                        <u className="color-skyblue">with exclusive partners ( See List).</u>
                                    </a>
                                  </div>
                            </div> : null }

                        </div>
                        <div className="text-muted">
                                <div className="card mb-4 rounded-0">
                                    <div className="card-body">
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                                <label className="custom-control-label pl-2" htmlFor="customCheck1">Buying as a Gift</label>
                                        </div>
                                            <p className="mt-3"> Quantity </p>
                                        <p>
                                            <select className="arrows dist">
                                                <option selected value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                            </select>
                                        </p>
                                        <div>
                                            <TermsConditionModal show={false}/>
                                        </div>
                                        <br/>
                                        <button type="button" className="btn btn-block btn-primary rounded-0">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                            <hr></hr>

                        </div>
                        </div>
                    </div>
                    
                )
        }

}

export default PartnersList;