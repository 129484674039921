import React, { Component } from 'react';
import thumbLogo from '../../../assests/img/header/thumbLogo.png'
import  cardImg from '../../../assests/img/card.svg'
import enfactem from "../../../assests/img/header/enfactemlogo.png"
import { logoHandler } from "utils/Theme/Logo-handler";
import hassadLogo from '../../../assests/img/header/hassad-logo.png'
import ModalWrapper from './ModalWrapper';
import { Redirect, withRouter } from 'react-router-dom';
import {fetchOrderDetailsSuccess, postOrderCreation, removeOrder} from '../../../Redux/order/orderActions'
import { connect } from 'react-redux';
import {currancyToPoints, pointsToCurrancy} from '../../../utils/pointsConversion';
import axios from 'axios';
import secretKeyGenerator from '../../../utils/serectKeyGenerator';
import FailMessageModal from './FailMessageModal';
import { withTranslation } from 'react-i18next';
import removeIcon from '../../../assests/img/Footer/icon-bin-remove.svg'

import { REACT_APP_baseUrl } from 'utils/app-constants';

 
class PointsConfirmationModal extends Component {
    constructor(props) {
        super(props);
        this.state={
            orderDetails:{
                total:this.props.orders.amount,
                payable_amount:this.props.orders.amount
            },
            redirectToPayment:false,
            checkoutBtn:true,
            paymentFail:false,
            loading:false,
            errorMsg:" ",
        }
    }
    componentDidMount(){
    }
    postOrderHandler(){
        const {orders,pointBalance,data} = this.props;
        const missingPoints = currancyToPoints(orders.amount) - pointBalance
        if(missingPoints>0){
            this.setState({redirectToPayment:true})
        }
        else{
            const payload ={"orders":data}
            axios.post(`${REACT_APP_baseUrl}/orders/create_points_exchange_order`,
            payload,{
                headers:{
                    "Authorization":`Bearer ${this.props.authToken}`
                },
                params:{
                    "secret_key":secretKeyGenerator(payload)
                }
            })
                .then(response  => {
               
                    if(response.data.code === 200){
                        const orderDetails = response.data.data.order;
                        this.props.history.push(`/payment/points-orders-summary/${orderDetails.id}`)
                    }
                    else{
                    this.setState({paymentFail:true})
                    }
                })
                .catch(error => {
                    this.setState({paymentFail:true,
                        errorMsg:"Something went wrong!! Please try again!!"
                    })
                })
                this.setState({loading:true})
        }
        
    }
    setCheckoutBtn(val){
        this.setState(old=>({
            checkoutBtn:!old.checkoutBtn
        }))
    }
    removeOrderhandler=()=>{
        this.props.remove(false)
        this.props.removeOrder()
    }
    numberWithCommas = (x) => {
        let num = +x;
        let y = num.toFixed(0)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    render() { 
        const {t,card,orders,pointBalance,currancyCode} = this.props;
        const {checkoutBtn,orderDetails,paymentFail,loading,errorMsg} = this.state;
        const total = currancyToPoints(orders.amount);
        const missingPoints = total - pointBalance
        const desktopOrderSummaryCard=(
            <div className="d-md-block d-none">
                <FailMessageModal show={paymentFail} lang={this.props.lang} Message={errorMsg}/>
                <div 
                    className="card mb-4 rounded-0"
                    style={{width:'17rem'}}>
                    <div className="card-body">
                    <div className="fs-4 fw-bold py-md-0 py-3 m-0 mt-md-0 mt-5  color-blue">{t("Order Summary")}:</div>
                        <div className="d-flex flex-row justify-content-between py-2">
                            <span className="text-black-50 fw-bold">{t("Subtotal")}:</span>
                            {
                    this.props.lang!=='ar'?
                    <h5 className="text-dark fw-bold m-0">{this.numberWithCommas(total)}<small>{t("Points")}</small></h5>
                    :
                    <h5 className="text-dark fw-bold m-0"><small>{t("Points")}</small>&nbsp;{this.numberWithCommas(total)}</h5>

                }
                        </div>
                        <div className="d-flex flex-row justify-content-between py-2">
                            <span className="text-black-50 fw-bold">{t("Available")}:</span>
                            {
                                this.props.lang!=='ar'?
                                <h5 className="text-danger fw-bold m-0">{this.numberWithCommas(pointBalance)}<small> {t("Points")}</small></h5>
                                :
                                <h5 className="text-danger fw-bold m-0"><small> {t("Points")}</small>&nbsp;{this.numberWithCommas(pointBalance)}</h5>

                            }
                        </div>
                        {
                            missingPoints>0 &&
                            <>
                            
                            <div className="d-flex flex-row justify-content-between py-2">
                                <span className="text-black-50 fw-bold">{t("Missing Points")}:</span>
                                <h5 className="text-danger fw-bold m-0">{this.numberWithCommas(missingPoints)}<small>{t("Points")}</small></h5>
                            </div>
                            <div className="d-flex flex-row py-3">
                                <div className="p-0 col-2">
                                    <img src={cardImg} alt="" className="img-fluid verticle-center"/>
                                </div>
                                <div className="px-1 col-10">
                                    <h5 className="color-blue fw-bold m-0">{`${this.numberWithCommas(missingPoints)} points (${pointsToCurrancy(missingPoints)} ${currancyCode})`}</h5>
                                    <small className="text-muted fw-bold">
                                        {t("to be paid by  Credit Card")}
                                    </small>
                                </div>
                            </div>
                            </>
                            
                        }
                        
                        <hr className="hr-line"/>
                        <div className="d-flex flex-row justify-content-between pt-1 pb-3">
                            <h5 className="text-dark fw-bold m-0">{t("Total")}</h5>
                            <h5 className="text-dark fw-bold m-0">{this.numberWithCommas(total)}<small>{t("Points")}</small></h5>
                        </div>
                        <div className="d-flex gap-2">
                            <input 
                                type="checkbox"
                                className="flex-shrink-0"
                                onClick={()=>{this.setCheckoutBtn()}} />
                                <label className="" >{t("I have read and accepted the Terms & Conditions")}.</label>
                        </div>
                        <br/>
                        <button 
                            className="btn btn-block primary-btn text-light rounded-0 text-center" 
                            onClick={()=>{this.postOrderHandler()}} 
                            disabled={checkoutBtn}>
                            {/* {t("Checkout Now")} */}
                            { loading ? <span > <div class="spinner-border spinner-border-sm " role="status">
                            <span className="sr-only" >Loading...</span>
                        </div>
                        <span  className='px-2' >{t("Checking")} . . .</span>
                        </span> : <span>{t("Checkout Now")}</span>}
                        </button>
                        
                    </div>
                </div>
            </div>
        )
        const mobileOrderSummaryCard=(
            <div className="d-md-none d-block flex-shrink-0">
                <FailMessageModal show={paymentFail} lang={this.props.lang} Message={errorMsg}/>
                <div className="fs-5 fw-bold text-muted py-2">{t("Order Summary")}</div>
                <div className="border px-3">
                    <div className="d-flex flex-row justify-content-between py-3 border-bottom">
                        <span className="text-black-50 fw-bold">{t("Subtotal")}:</span>
                       {
                    this.props.lang!=='ar'?
                    <div className="text-dark fw-bold m-0 fs-5 fw-bold">{this.numberWithCommas(total)}<small>{t("Points")}</small></div>
                    :
                    <div className="text-dark fw-bold m-0 fs-5 fw-bold"><small>{t("Points")}</small>&nbsp;{this.numberWithCommas(total)}</div>

                }
                    </div>
                    {
                        missingPoints>0 &&
                        <>
                        
                        <div className="d-flex flex-row justify-content-between  py-3 border-bottom">
                            <span className="text-black-50 fw-bold">{t("Missing Points")}:</span>
                            <div className="text-danger fw-bold m-0 fs-5 fw-bold">{this.numberWithCommas(missingPoints)}<small> Points</small></div>
                        </div>
                        <div className="d-flex flex-row py-3 border-bottom">
                            <div className="p-0 col-1">
                                <img src={cardImg} alt="" className="img-fluid verticle-center"/>
                            </div>
                            <div className="px-3 col-10">
                                <div className="color-blue fw-bold m-0 fs-5 fw-bold">{`${this.numberWithCommas(missingPoints)} points (${pointsToCurrancy(missingPoints)} ${currancyCode})`}</div>
                                <small className="text-muted fw-bold">
                                    {t("to be paid by  Credit Card")}
                                </small>
                            </div>
                        </div>
                        </>
                        
                    }
                    
                    <div className="d-flex flex-row justify-content-between py-3">
                        <div className="text-dark fw-bold m-0">{t("Total")}</div>
                    {
                        this.props.lang!=='ar'?
                        <div className="text-dark fw-bold m-0 fs-5 fw-bold">{this.numberWithCommas(total)}<small>{t("Points")}</small></div>
                        :
                        <div className="text-dark fw-bold m-0 fs-5 fw-bold"><small>{t("Points")}</small>&nbsp;{this.numberWithCommas(total)}</div>

                    }
                    </div>
                </div>
                <div className=" w-100 bg-light py-5 ">
                <div className="d-flex">
                            <input 
                                type="checkbox"
                                className="flex-shrink-0"
                                onClick={()=>{this.setCheckoutBtn()}} />
                                <label className="" style={{paddingLeft: "1rem",paddingRight:"1rem"}}>{t("I have read and accepted the Terms & Conditions")}.</label>
                        </div>
                        <br/>
                    <button 
                        onClick={()=>{this.postOrderHandler()}}
                        disabled={checkoutBtn}
                        className="btn primary-btn w-100 rounded-0 p-2 text-white">
                       {/* {t(" Checkout Now")} */}
                       { loading ? <span > <div class="spinner-border spinner-border-sm " role="status">
                            <span className="sr-only" >Loading...</span>
                        </div>
                        <span  className='px-2' >{t("Checking")} . . .</span>
                        </span> : <span>{t("Checkout Now")}</span>}
                    </button>
                </div>
                <div className="d-md-none d-block m-4 p-2">
                </div>
            </div>
        )
        
        return (
            <ModalWrapper show={this.props.show}>
                <section className="bg-white w-100 h-100" >
                    <header className="d-flex py-md-4 py-3 px-md-5 px-3 gap-md-4 gap-3 border shadow-sm align-items-center">
                        <div>
                            <img className="img-fluid d-md-block d-none" style={{width:'13rem'}} src={logoHandler()} alt="logo"/>
                            <img className="img-fluid d-md-none d-block" id="lg-fix" src={logoHandler()} alt="logo"/>
                        </div>
                        <div className="pt-3 fs-md-2 fs-5 fw-bold">
                            <span className="color-blue">{t("Order Confirmation")} </span> - <span className="text-black-50">({orders.quantity} {t("item")})</span> 
                        </div>
                    </header>
                    <div className="d-flex flex-md-row flex-column p-md-5 p-3 gap-md-5 bg-white">
                        <div className="flex-grow-1 d-flex align-self-start border-md-1 border-none p-md-3 p-0">
                            <div className="col-3 p-md-2 p-0" style={{minWidth:"8rem"}}>
                                <img  className="img-fluid" src={card.images && card.images?.color?.medium_rectangle} alt="giftCard"/>
                            </div>
                            <div className="text-black-50 px-3 flex-grow-1 ">
                            
                                <div className="color-blue fs-md-3 fs-5 fw-bold">{this.props.lang!=='ar'?card.name: card.name_arabic}</div>
                                {/* <div className="fs-5 fw-bold">{card.name}</div> */}
                                {/* <div className="py-2">
                                    Model Number: Pro 5500M
                                </div> */}
                                {/* <div className="pb-2">
                                    <img src="" alt=''/>
                                    Delivery to 
                                    <b className="color-skyblue"> Riyad</b>
                                </div> */}
                                <div className="pb-2 fw-bold">
                                {t("Now")}: 
                                    <span className="color-blue fs-5"> {this.numberWithCommas(total)} {t("Points")}</span>
                                    {/* <b className=" btn bg-color-skyblue mx-2 py-1 px-2  fw-bold color-blue">10% off</b> */}
                                </div>
                                {
                                    missingPoints >0 && 
                                    <>
                                        <div className="pb-2 fw-bold">
                                        {t("Missing Points")}:
                                            <span className="text-danger fs-md-3 fs-5"> {this.numberWithCommas(missingPoints)} Points</span>
                                        </div>
                                        <div className="pb-2 fw-bold">
                                            <img src="" alt=''/>
                                            <span className="color-blue fs-5"> {this.numberWithCommas(missingPoints)} Points({pointsToCurrancy(missingPoints)} {currancyCode})</span>
                                        </div>
                                        <div>
                                            <b>to be paid by </b>
                                            <span className="color-skyblue">{t("Credit/Debit Cards")}</span>
                                        </div>
                                    </>
                                    
                                }
                                <div className="py-3 d-flex justify-content-end">
                                    <button className="btn float-right color-skyblue fw-bold" onClick={()=>this.removeOrderhandler()} >
                                    <span className="mx-1"><img src={removeIcon} alt="remove icon"/></span>{t("Remove")}</button>
                                </div>
                            </div>
                        </div>
                        {desktopOrderSummaryCard}
                        {mobileOrderSummaryCard}
                    </div>
                </section>
                {
                    this.state.redirectToPayment && (
                    missingPoints>0 &&
                    <Redirect
                            push to={{
                            pathname:'/payment/card-details',
                            state:{order:orders,orderDetails}
                    }}/>

                    )
                    
                }
                
            </ModalWrapper> 
         );
    }
}
const mapStateToProps = state => {
    return {
        orderDetails:state.order.orderDetails,
        orderPosted:!state.order.loading,
        pointBalance:state.point.pointBalance,
        errorFlag:state.order.errorFlag,
        authToken:state.auth.authToken,
        currancyCode:state.countries.convertionRate.currency_code
    }
}

const mapDispatchToProps = dispatch => {
    return {
        postOrderCreation : (payload) => dispatch(postOrderCreation(payload)),
        removeOrder : () => dispatch(removeOrder()),
        fetchOrderDetailsSuccess : data => dispatch(fetchOrderDetailsSuccess(data))
    }
}

 
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(withTranslation()(PointsConfirmationModal)));