import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import Breadcrumbs from '../shared/Breadcrumbs';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import brand1 from "./../../assests/img/categories/brand1.png"
import brand2 from "./../../assests/img/categories/brand2.png"
import brand3 from "./../../assests/img/categories/brand3.png"
import brand4 from "./../../assests/img/categories/brand4.png"
import brand5 from "./../../assests/img/categories/brand5.png"
import brand6 from "./../../assests/img/categories/brand6.png"

// import filterIcon from "./../../assests/img/header/icon-filter-categories.png"


import ShopBrand from './ShopBrand';
import ProductGiftsCard from '../shared/ProductGiftsCard';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { Dropdown } from 'react-bootstrap';
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';

class ProductsPage extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        categoryName:props.match.params.subCategory,
        category:props.match.params.categoryName,
        brands:["Apple","Beats","Bose","Canon","Dell","HP","Sony","LG",],
        sidebar:[{
            name:"eCards",
        },{
            name:"Discount & Offer",
            
        }],
        color:["black","orange","blue","brown"],
        Brands:[brand1,brand2,brand3,brand4,brand5,brand6],
        memory:["10 GB","16 GB","32 GB"],
        data:this.props.products,
        letter:"Default",
        letters:["A-Z","Z-A"],
        mobileFilters:["A-Z","Z-A","Discount & Offer","Apple","Beats","Bose","Canon","Dell","HP","Sony","LG"]
      }
    }
    
    componentDidUpdate(prevProps,prevState){
        // let href = this.props.location.pathname.split('/');
        if((prevProps.currancyCode !== this.props.currancyCode) ){

        }
        // if(this.props.products.length===0 ){    
        //  this.props.history.replace("/404")
        // }
        if(prevProps.products !== this.props.products){
            this.setState({
                categories: this.props.categories,
                data: this.props.products?.map(val => val.products)
            })
        }
        if( !this.props.loading && this.props.products &&  this.props.products.length=== 0){
            this.props.history.replace("/404")        
        }
        
        // if(href.length>2 && typeof href.split('/')[2] != 'number'){
        // }
    }

  render() {
    const breadcrumbs =[
        {name:'Home',url:'/',active:true},
        {name:this.state.category ,url:`/category/${this.state.category}`,active:true},
        {name: this.state.categoryName && this.state.categoryName,url:'',active:false}
    ] 
    let data = this.props.products;
    const desktopView = (
        <div className="d-md-block d-none container-fluid p-0 pb-5">
            <div className="d-flex">
                <div className="w-100">
            
            
            <Breadcrumbs crumbs={breadcrumbs}/>
            <div className="d-flex p-5 gap-5">
                <div className='col-2 p-0'>
                    <div className='border-bottom fw-bold '>
                        <span className='fs-5 btnTxt fw-bold'>Filter</span>
                        <div className='text-muted d-flex flex-column gap-2 py-3'>
                                {
                                    this.state.sidebar.map((val,ind)=>{
                                        return(
                                            <div className='d-flex'>
                                               <input type="checkbox" name="" id="" />
                                               <span className={`${this.props.lang === "ar" ? "mr-1" : "ml-1"}`}>{val.name}</span>
                                            </div>
                                        )
                                    })
                                }
                      
                        </div>
                    </div>
                    <div className='border-bottom fw-bold py-4'>
                        <span className='fs-5 btnTxt fw-bold'>Category</span>
                        <div className='text-muted d-flex flex-column gap-2 py-3'>
                            <span className='color-skyblue fw-bold cursor-pointer'>{this.state.category}</span>
                            <p className='medium mb-0'>{this.state.categoryName} (200)</p>
                        </div>
                    </div>
                    <div className='border-bottom fw-bold py-4'>
                        <span className='fs-5 btnTxt fw-bold'>Brands</span>
                        <div className='text-muted d-flex flex-column gap-2 py-3'>
                            {
                                this.state.brands.map((val)=>{
                                    return(
                                        <div className='d-flex'>
                                               <input type="checkbox" name="" id="" />
                                               <span className={`${this.props.lang === "ar" ? "mr-1" : "ml-1"}`}>{val}</span>
                                            </div>
                                    )
                                })
                            }
                        </div>
                
                    </div>
                    <div className='border-bottom fw-bold py-4'>
                        <span className='fs-5 btnTxt fw-bold'>Price (Sar)</span>
                        <div className='text-muted d-flex  gap-2 py-3'>
                        <input type="input" name="" id="" className='w-50' />
                                               <span className='ml-1'>to</span>
                                               <input type="input" name="" id="" className='w-50' />
                                               <span className='btnTxt'>Go</span>
                        </div>
                
                    </div>
                    <div className='border-bottom fw-bold py-4'>
                        <span className='fs-5 btnTxt fw-bold'>Color</span>
                        <div className='text-muted d-grid grid-cols-md-2 grid-cols-2 gap-md-3 gap-2 py-3'>
                            {
                              this.state.color.map((val,ind)=>{
                                  return(
                                      <div key={ind}>
                                        <div className={`color-box ${val}  ${this.props.lang === "ar" ? "ml-2" : "mr-2"}`}></div>
                                        <span className='text-capitalize'>{val}</span>
                                      </div>
                                  )
                              })
                            }
                        </div>
                
                    </div>
                    <div className='border-bottom fw-bold py-4'>
                        <span className='fs-5 btnTxt fw-bold'>Internal Memory</span>
                        <div className='text-muted d-flex flex-column gap-2 py-3'>
                            {
                                this.state.memory.map((val)=>{
                                    return(
                                        <div className='d-flex'>
                                               <input type="checkbox" name="" id="" />
                                               <span className={`${this.props.lang === "ar" ? "mr-1" : "ml-1"}`}>{val}</span>
                                            </div>
                                    )
                                })
                            }
                        </div>
                
                    </div>
                </div>
                <div>
                    <div style={{minWidth:'70vw'}} className='d-flex justify-content-between'>
                        <div className=''>
                            <span className='fs-3 btnTxt fw-bold'>{this.state.categoryName}</span> <span className='fs-3'>- All</span>
                        </div>
                        <div className={`d-flex w-19`}>
                                <span className={`${this.props.lang === "ar" ? "ml-3" : 'mr-4'} mt-2`}>Sort by</span> 
                            <div >  
                                <Dropdown>
                                    <DropdownToggle style={{width:'8rem'}} className={`${this.props.lang === 'ar'?"float-left":""} text-light rounded-0  px-0 bg-white no-outline`}>
                                        <span className='second-color fw-bold float-left px-3'>{this.state.letter}</span>
                                        <span className='arrow-down color-skyblue float-right'></span>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {
                                                this.state.letters.map((e)=>(
                                                    <DropdownItem >
                                                        {e}
                                                    </DropdownItem>
                                                ))
                                            }
                                    </DropdownMenu>
                                </Dropdown>    
                            </div>
                            
                        </div>
                    </div>
                    <div className='my-5'>
                        <span className='fs-3 btnTxt fw-bold'>
                            Choose from the top brands
                        </span>
                        <div className="d-grid grid-cols-md-6 grid-cols-2 gap-md-3 gap-2 py-3">
                                {
                                    this.state.Brands  &&  this.state.Brands.map( (card,id)=>(
                                        <ShopBrand  card={card} key={id} />
                                        ))
                                    }
                        </div>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div>
                            <span className='fs-3 btnTxt fw-bold'>Trending Discounts</span>
                            <p className='small'>Save BIG on mobiles</p>
                        </div>
                        <div>
                            <span className='color-skyblue fw-bold'>Show All</span>
                        </div>
                    </div>
                    <div className="d-grid grid-cols-md-4 grid-cols-2 gap-md-3 gap-2 py-3">
                            {
                              data  &&  data.map( (card,id)=>(
                                
                                    <ProductGiftsCard  card={card} key={id} lang={this.props.lang}/>
                                ))
                            }
                       </div>
                </div>
            </div>
                </div>
          </div>
                </div>
    )
    const mobileView = (
        <div className='d-block d-md-none p-0'>
            <Breadcrumbs crumbs={breadcrumbs}/>
            <div className='d-flex justify-content-around mt-3'>
                        <div className='w-50'>
                            <span className='fs-4 btnTxt fw-bold'>{this.state.categoryName}</span> <span className='fs-4'>- All</span>
                        </div>
                        {/* <div className={`d-flex`}> */}
                            {/* <span className={`${this.props.lang === "ar" ? "ml-2" : 'mr-2'} mt-1`}>Sort by</span>  */}
                            <div >  
                                <Dropdown>
                                    <DropdownToggle style={{width:'8rem'}} className={`${this.props.lang === 'ar'?"float-left":""} text-light rounded-0  px-0 bg-white no-outline`}>
                                        <span className='second-color fw-bold float-left px-3'>{this.state.letter}</span>
                                        <span className='arrow-down color-skyblue float-right'></span>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {
                                                this.state.mobileFilters.map((e)=>(
                                                    <DropdownItem >
                                                        {e}
                                                    </DropdownItem>
                                                ))
                                            }
                                    </DropdownMenu>
                                </Dropdown>    
                            </div> 
                        {/* </div> */}
            </div>
                    <div className='my-5'>
                        <span className={`fs-3 btnTxt fw-bold ${this.props.lang === "ar" ? "mr-3" : "ml-3"}`}>
                            Choose from the top brands
                        </span>
                        <div className="d-grid grid-cols-md-6 grid-cols-2 gap-md-3 gap-2 py-3">
                                {
                                    this.state.Brands  &&  this.state.Brands.map( (card,id)=>(
                                        <ShopBrand  card={card} key={id} device="mobile"/>
                                        ))
                                    }
                        </div>
                    </div>
                    <div className='d-flex justify-content-around'>
                        <div>
                            <span className='fs-3 btnTxt fw-bold'>Trending Discounts</span>
                            <p className='small'>Save BIG on mobiles</p>
                        </div>
                        <div>
                            <span className='color-skyblue fw-bold'>Show All</span>
                        </div>
                    </div>
                    <div className="d-grid grid-cols-md-4 grid-cols-2 gap-md-3 gap-2 py-3">
                            {
                              data  &&  data.map( (card,id)=>(
                                
                                    <ProductGiftsCard  card={card} key={id} lang={this.props.lang}/>
                                ))
                            }
                       </div>
        </div>
    )
    return <div>
        {desktopView}
        {mobileView}
    </div>

  }
}
const mapStateToProps = state => {
    return {
        products:state.merchandise.products
    }
}
export default connect(mapStateToProps)(withTranslation()(ProductsPage));
