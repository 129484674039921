import React, { Component } from 'react';
// import ProductGiftsCard from '../shared/ProductOffersCard'
// import { Dropdown } from 'react-bootstrap';
// import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';
// import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
// import DropdownItem from 'react-bootstrap/esm/DropdownItem';
// import Breadcrumbs from '../shared/Breadcrumbs';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
// import { fetchProductsList,sortMerchandiseCards } from '../../Redux/Merchandise/merchandiseActions';
import { fetchProductsList } from '../../Redux/Merchandise/merchandiseActions';
import { connect } from 'react-redux';
import { currancyToPoints } from 'utils/pointsConversion';
// import { pointsToCurrancy } from '../../utils/pointsConversion';
// import { currancyToPoints } from '../../utils/pointsConversion';
// import { withTranslation } from 'react-i18next';

class GiftsPage extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            categories:[],
            confirmOrder:false,
            filteredCards:false,
            orders:{},
            card:{},
            data:this.props.products,
            fromPoint:null,
            toPoint:null,
            pointsFilteredCard:{},
            pointsOnlyFilter:false,
            categoryName:false,
            brands:['Al Baik','Al Tazaj','Hardes','Harfy','Kudu'],
            establisdhments:['Casual Dining','Food Court'],
         }
    }
    numberWithCommas = (x) => {
        let y = x.toFixed(0)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    

  
    
    render() { 
        // const {currentCategory,brands,establisdhments} = this.state
        const{t}=this.props     
        return ( 
            this.props.featuredProducts.length>0 && <div className='  px-md-5 px-3 pt-3   mx-auto bg-white' style={{overflow:'hidden'}}>
            <div className="">
                <div className='headings m-0 d-inline-block fs-md-3 fs-5 fw-bold'>{t("Merchandise")}</div>
                         {
                                 <Link className={`text-decoration-none color-skyblue fw-bold  ${this.props.lang !== 'ar' ? "float-right": "float-left"} d-inline-block p-0 pb-2`}
                                 onClick={()=>{window.scrollTo(0, 0)}}
                                 to='/gifts'>{`${this.props.lang !== 'ar' ? "Show All" :  "ترتيب حسب"}`}</Link>
                             }
                <div className="text-black-50 fw-bold  ">{t("")}&nbsp;</div>
            </div>
            <div className="d-grid grid-cols-md-2 grid-cols-2 gap-md-3 gap-0 overflow-auto">
           </div>            
            <div className="d-grid grid-cols-md-4 grid-cols-2 gap-md-3 gap-2 pt-3 pb-3 pb-md-2">
            { this.props.featuredProducts?.map((card,index)=>(
                index<4 ?
            <Link className='offerCardHover' to={`/gift/${card.id}`}>
                <div style={{cursor:'pointer'}} className="border p-0">
                <div className="text-center">
                <img
                    className="img-fluid w-80" 
                    src={card.image && card.image.small_square } alt={card.title} />
                </div>
            <div className="p-md-3 p-2">
                    <h5 className="product_title fw-bold m-0 d-inline-block"
                        style={{height:'70px',textOverflow:'ellipsis',overflow:'hidden',fontSize:'1.1rem'}}>{ this.props.lang !== 'ar' ?card.product_name:card.product_name_arabic }</h5>
                    <div style={{minHeight:"35px"}}className="text-black-50">{ this.props.lang !== 'ar' ?card.brand_name:card.brand_name_arabic }</div>
                    <div className='product_value'>
                        {/* <h5 className="d-inline">{Number(card.total_cost)}</h5><small> {t("SAR")}</small> */}
                        <h5 className="d-inline">{currancyToPoints(Number((card.total_cost)))}</h5> <small>{t("Points")}</small>     
                    </div>  
                </div>
         </div>
             </Link>: false
            ))}

            </div>
     </div>
         );



        

                }
}
const mapStateToProps = state => {
    return {
        loading:state.merchandise.loading,
        sortBy:state.merchandise.sortBy,
        filteredCards: state.merchandise.filteredCards,
        countrySelected:state.countries.countryId,
        categoryName:state.merchandise.categoryName,
        products:state.merchandise.products,
       data:state.merchandise.categories,
        currancyCode:state.countries.convertionRate.currency_code,
        featuredProducts:state.merchandise.featuredProducts
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchProductsList : (payload) => dispatch(fetchProductsList(payload)),
        
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GiftsPage));