import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { fetchCountries, setCountryHandler, fetchConversionRate, setLanguagesHandler } from '../../Redux/country/countriesAction';
import { getBrandData } from '../../Redux/brand/brandActions';
import { fetchOffers } from '../../Redux/offers/offerActions';
import { fetchExperienceCards } from '../../Redux/experience/experienceAction'
import { fetchProductsList } from '../../Redux/Merchandise/merchandiseActions';
import { fetchSoftwares } from '../../Redux/Softwares/SoftwareAction'
import { fetchGames } from '../../Redux/Games/GamesAction';
import { fetchCurrencies } from '../../Redux/conversion/conversionAction';
import ResetPassword from "../shared/Modals/ResetPassword"
import Location from "../../assests/img/Login/Location.png"
import { fetchSoftwareGames } from 'Redux/Software&Games/SoftwaresAction';


class TopHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.lang,
            countryName: [],
        }
    }
    componentDidMount() {
        const { modulesData } = this.props
        this.props.fetchCountries()
        this.props.fetchConversionRate(this.props.countryId)
        this.fetchBrands(this.props.countryId)
        this.props.fetchCurrencies()
        modulesData["RewardsBy Offers"] && this.fetchOrdersList(this.props.countryId)
        modulesData["Experiences"] && this.fetchExperienceCards(this.props.countryId)
        modulesData["RewardsBy Gifts"] && this.fetchProductHandler(this.props.countryId)
        // modulesData["Games"] && this.fetchGames(this.props.countryId)
        // modulesData["Software"] && this.fetchSoftware(this.props.countryId)
        modulesData["Software&Games"] && this.fetchSoftwareGames(this.props.countryId)

    }
    componentDidUpdate = (prevProps, prevState) => {

        if ((prevProps.countryId && (prevProps.countryId !== this.props.countryId))) {
            const { modulesData } = this.props
            this.fetchBrands(this.props.countryId)
            modulesData["RewardsBy Offers"] && this.fetchOrdersList(this.props.countryId)
            modulesData["Experiences"] && this.fetchExperienceCards(this.props.countryId)
            modulesData["RewardsBy Gifts"] && this.fetchProductHandler(this.props.countryId)
            // modulesData["Games"] && this.fetchGames(this.props.countryId)
            // modulesData["Software"] && this.fetchSoftware(this.props.countryId)
            modulesData["Software&Games"] && this.fetchSoftwareGames(this.props.countryId)
        }
    }
    fetchBrands = (countrySelected) => {
        let userCountry = localStorage.getItem("selectedCountry")
        if(userCountry) userCountry = JSON.parse(userCountry)
        const payload = {
            "brands":
                {
                    "program_id":1, 
                    "currency":countrySelected,
                }, 
        }
        this.props.getBrands(payload);
    }
    fetchOrdersList(countrySelected) {
        const payload = {
            "offer_campaign": {
                "currency": countrySelected
            }
        }
        this.props.fetchOffers(payload)
    }
    fetchExperienceCards(countrySelected) {
        const payload = {
            "experiences": {
                "currency": countrySelected
            }
        }
        this.props.fetchExperienceCards(payload)
    }
    fetchSoftwareGames(countrySelected) {
        const payload = {
            "software_and_game": {
                "currency": countrySelected
            }
        }
        this.props.fetchSoftwareGames(payload)
    }

    fetchSoftware(countrySelected) {
        const payload = {
            "software_and_game": {
                "currency": countrySelected,
                "campaign_type": "SOFTWARE"
            }
        }
        this.props.fetchSoftwares(payload)
    }

    fetchGames(countrySelected) {
        const payload = {
            "software_and_game": {
                "currency": countrySelected,
                "campaign_type": "GAME"
            }
        }
        this.props.fetchGames(payload)
    }

    fetchProductHandler = (countrySelected) => {
        const payload = {
            "merchant_product": {
                "currency": countrySelected
            }
        }

        this.props.fetchProductsList(payload)
    }
    toogleLang = (e) => {
       this.props.toogleLang(e)
    }

    numberWithCommas = (x) => {
        let num = +x;
        let y = num.toFixed(2);
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    checkLanguage = (language) => {
        let country = this.props.countries?.filter(ite => ite?.currencies?.[0]?.id == language)
        let selectedCountry = {
            countryName: country?.[0]?.name,
            countryId: country?.[0]?.id,
            currencyId: country?.[0]?.currencies?.[0]?.id,
            countryCode: country?.[0]?.code,
            currencyCode: country?.[0]?.currencies?.[0]?.code,
        };
        let payload = {
            languages: country?.[0]?.languages,
            selectedCountry: selectedCountry
        }
        localStorage.setItem("selectedCountry", JSON.stringify(selectedCountry))
        this.props.setLanguagesHandler(payload)
        this.toogleLang(country?.[0]?.languages?.[0]?.code)
    }

    render() {
        const { t, countries, languages, countriesloading, countryId } = this.props;
        return (
            <div className={`header_footer  px-md-4 d-flex justify-content-end position-sticky z-index-top ${this.props.authToken && "height-25"} ${this.props.lang !== "ar" ? "pr-3" : "pl-3"}`} style={{ top: '0', backgroundColor: " #230871" }}>
                <div className='d-md-block d-none my-auto'>
                    <img src={Location} alt="location" />
                </div>
                <div className="dropdown">
                    <select
                        className={`btn border-0 text-light dropdown-toggle ${this.props.lang !== "ar" ? "text-left" : "text-right"}`}
                        type="button" style={{ width: '13rem' }}
                        value={countryId}
                        onChange={({ target: { value } }) => {
                            this.props.setCountryHandler(value)
                            this.checkLanguage(value)
                        }}>
                        {!countriesloading &&
                            countries?.map(country => {
                                return <option style={{ backgroundColor: "#fff", color: "#000" }}
                                    key={country.id}
                                    onClick={(e) => {
                                        this.checkLanguage(country?.languages)
                                        this.setCountryHandler(e, country.name)
                                    }
                                    } value={country?.currencies?.[0]?.id}>{country.name} </option>

                            })}
                    </select>
                </div>
                {
                    this.props.valid && this.props.point &&
                    <div className={`w-100 text-light my-auto ${this.props.lang !== "ar" ? "pr-2 text-right border-right" : "pl-2 text-left border-left"}`}>
                        <h6 className="m-0">{t("Available Points")}: <span>{this.numberWithCommas(this.props.point)}</span></h6>
                    </div>
                }
                {/* <div className={`text-light my-auto ${this.props.lang === "en" ? "pl-2" : "pr-2"}`} onClick={() => { this.toogleLang() }} style={{ cursor: "pointer" }} >
                    {this.props.lang === "en" ? 'عربى' : 'English'}
                </div> */}

                <div className="">
                    <select
                        className={`btn border-0 text-light dropdown-toggle ${this.props.lang !== "ar" ? "text-left" : "text-right"}`}
                        type="button" style={{ width: '13rem' }}
                        onChange={(e) => { this.toogleLang(e.target.value) }}
                    >
                        {!countriesloading &&
                            languages?.map(language => {
                                return <option style={{ backgroundColor: "#fff", color: "#000" }}
                                    key={language.code}
                                    selected={this.props.lang === language.code}
                                    value={language.code}>{language.name}
                                </option>

                            })}
                    </select>
                </div>

                {/*            
            {
                this.props.authToken &&
                    <ResetPassword lang={this.props.lang}/>
            } */}
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        countries: state.countries.countries,
        languages: state.countries.languages,
        selectedCountry: state.countries.selectedCountry,
        countriesloading: state.countries.loading,
        convertionRate: state.countries.convertionRate,
        countryId: state.countries.countryId,
        point: state.point.pointBalance,
        authToken: state.auth.authToken,
        modulesData: state.categories.moduleInfo,
        valid: state.auth.valid,
    }
}


const mapDispatchToProps = dispatch => {
    return {
        fetchCountries: () => dispatch(fetchCountries()),
        setCountryHandler: (val) => dispatch(setCountryHandler(val)),
        setLanguagesHandler: (payload) => dispatch(setLanguagesHandler(payload)),
        fetchConversionRate: (val) => dispatch(fetchConversionRate(val)),
        getBrands: (payload) => dispatch(getBrandData(payload)),
        fetchOffers: (payload) => dispatch(fetchOffers(payload)),
        fetchExperienceCards: (payload) => dispatch(fetchExperienceCards(payload)),
        fetchProductsList: (payload) => dispatch(fetchProductsList(payload)),
        fetchSoftwareGames: (payload) => dispatch(fetchSoftwareGames(payload)),
        fetchSoftwares: (payload) => dispatch(fetchSoftwares(payload)),
        fetchGames: (payload) => dispatch(fetchGames(payload)),
        fetchCurrencies: () => dispatch(fetchCurrencies())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TopHeader)); 