import React, { Component } from 'react';
import Breadcrumbs from '../shared/Breadcrumbs';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
// import { currancyToPoints } from '../../utils/pointsConversion';
import { Dropdown } from 'react-bootstrap';
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
// import { REACT_APP_baseUrl } from 'utils/app-constants';

class Experiences extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            sortByLetter:'A-Z',
            sortByLetterMob:'A-Z',
            data:undefined,
        }
    }

    componentDidMount(){
        if( !this.props.loading && this.props.experienceCards && this.props.experienceCards.length === 0){
            this.props.history.push({
                pathname:'/404',
                redirectionURL:'/experiences'
            })
        }
    }
    componentDidUpdate(){
        if(typeof this.state.data === 'undefined' && !this.props.loading){
            this.setState({data:this.props.experienceCards}) 
        }
        if( !this.props.loading && this.props.experienceCards && this.props.experienceCards.length === 0){
            this.props.history.push({
                pathname:'/404',
                redirectionURL:'/experiences'
            })
        }
    }
    checkCheckBox(index){
        let checkbox = document.getElementsByClassName('check')
    
        if(index === 0){
            checkbox[0].checked = true
            checkbox[1].checked = false
        }else {
            checkbox[0].checked = false
            checkbox[1].checked = true
        }
    }
    numberWithCommas = (x) => {
            return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      };
      getFirstKeyInPrice = (experience)=>{
        const {t} = this.props;
        if(experience.price_per_users && Object.keys(experience.price_per_users)){
            return <span>{t("From")} <b>{this.numberWithCommas((Object.values(experience.price_per_users)[0]))}</b> {Object.keys(experience.price_per_users)[0] === "1" ? t('Price Per Adult') : `${t('Price for')} ${Object.keys(experience.price_per_users)[0]} ${t('Person')} `} </span>
        }
    }
    render() { 
        const breadcrumbs =[
            {name:'Home',url:'/',active:true},
            {name:'Experiences',url:``,active:false},
        ] 
        const {t} = this.props
        const letters = ["A-Z","Z-A"];
        const mobletters = ["Online Experiences","Offline Experiences","A-Z","Z-A"];
        const filters = ["Online Experiences","Offline Experiences"]
        let experienceCard = this.props.experienceCards
        const sortBy = (letter,index) => {
            if(letter!== this.state.sortByLetter && letter === "A-Z"){
                experienceCard.sort(function(a, b){
                    return a.name.toLowerCase() === b.name.toLowerCase() ? 0 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
                })
                this.setState({sortByLetter:'A-Z',sortByLetterMob:'A-Z'})
            }
            else if(letter!== this.state.sortByLetter && letter === 'Z-A'){
                experienceCard.sort(function(b, a){
                    return a.name.toLowerCase() === b.name.toLowerCase() ? 0 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
                })
                this.setState({sortByLetter:'Z-A',sortByLetterMob:'Z-A'})
            }
            // commenting Points 
            // else if(letter!== this.state.sortByLetter && letter === 'Points'){
                
            //     experienceCard.sort((a,b) => a.price_per_users["1"] - b.price_per_users["1"])
            //     this.setState({sortByLetter:'Points',sortByLetterMob:'Points'})
            // }
            else if(letter === 'Online Experiences'){
                this.checkCheckBox(index)
               let filteredData = this.props.experienceCards?.filter(item => item.type === 'ONLINE')
               this.setState({data:filteredData,sortByLetter:'A-Z',sortByLetterMob:'Online'})
                
            }
            else if(letter === 'Offline Experiences'){
                this.checkCheckBox(index)
                let filteredData = this.props.experienceCards?.filter(item => item.type === 'OFFLINE')
                this.setState({data:filteredData,sortByLetter:'A-Z',sortByLetterMob:'Offline'})

            }
            else if(this.state.sortByLetter !== this.state.sortByLetterMob){
                this.setState({sortByLetterMob:this.state.sortByLetter})
            }
        }
        if(!!this.state.data){
            experienceCard = this.state.data
        }
        const desktopView  =(
            <div  className="d-none d-lg-flex row gx-5 p-5 w-100 m-0">
        <div  className="col-2 category-nav p-0">
        <div className="sub-Categories-nav">
                <h5
                    className =" btnTxt ml-0 pb-1 fw-bold"
                >{t("Filters")}</h5>
                {
                  filters &&  filters.map((cat,index)=>(
                        <div className='d-flex mb-3' key={index}>
                            <input onClick={()=>sortBy(cat,index)} className={this.props.lang !== 'ar' ? "inline mr-2 check" : "inline ml-2 check"} type="checkbox" value={index}/>
                            <span>{t(cat)}</span>
                        </div>
                    ))
                }
                <hr className="hr-line"/>
            </div>
                </div>
                <section className="col-10 px-md-5 px-3 py-md-4 py-1">
                {
                     experienceCard.length>0 && 
                    <div className='d-flex justify-content-between'>
                    <div>
                    <div className="headings fw-md-bold fw-bolder fs-md-3 fs-5">{t("Experiences")}</div>
                    <div className="text-black-50 fw-bold ">{t('Explore Best in Experiences 2022')}</div>
                    </div>
                    <div className={`d-none d-lg-block ${this.props.lang === 'ar'?"":"ml-5"} `} >
                    <Dropdown className='d-inline '>
                        <DropdownToggle style={{width:'8rem'}} className={`${this.props.lang === 'ar'?"float-left":""} text-light rounded-0 mt-1 px-0 bg-white no-outline`}>
                                        <span className='second-color fw-bold float-left px-3'>{`${t(this.state.sortByLetter)}`}</span>
                                        <span className='arrow-down second-color float-right'></span>
                                    </DropdownToggle>
                                   <DropdownMenu>
                                    {
                                            letters?.map((e)=>(
                                                <DropdownItem key={e} onClick={()=>sortBy(e)} >{t(e)}</DropdownItem>
                                            ))
                                        }
                                    </DropdownMenu>
                    </Dropdown>
                    </div>
                    </div>
                }
                    <div className="py-md-4 py-2 d-grid grid-cols-md-4 grid-cols-2 gap-md-3 gap-2">
                    
                        {experienceCard.length>0 && experienceCard.map(experience => (
                            <div className="text-center border-0 d-grid" key={experience.id}>
                                    <Link className="active p-0 "
                                        to={{
                                            pathname: `/experience/${experience.id}`,
                                            state: {...experience }
                                        }}>
                                        <img className="card-hover experiences-img" src={experience.images?.large_rectangle} alt={experience.name} />
                                    </Link>
                                    <div className='text-center py-2 product_title fw-md-bold fw-bold'>
                                        {this.props.lang !== 'ar' ? experience.name : experience.name_arabic}
                                    </div>
                                    <div style={{alignItems:'flex-end',justifySelf:'center'}}className="d-flex flex-row text-black-50">
                                    {this.getFirstKeyInPrice(experience)}
                                        </div>
                                </div>
                            )
                        )}
                    </div>
                    {
                        experienceCard.length===0 && 
                        <div className='text-center'>
                            <h4>{t("There are no items currently available. Please check again later")}</h4>
                        </div>
                    }
                </section>
                </div>

        )
        const mobileView = (
            <section className=" d-md-block d-lg-none px-md-5 px-3 py-md-4 py-1">
                
              
                <div className='d-flex justify-content-between'>
                    <div>
                    <div className="headings fw-md-bold fw-bolder fs-md-3 fs-5">{t("Experiences")}</div>
                    <div className="text-black-50 fw-bold ">{t('Explore Best in Experiences 2022')}</div>
                    </div>
                    <div className={`${this.props?.lang === 'ar'?"":"ml-5"} `} >
                    <Dropdown className='d-inline '>
                        <DropdownToggle style={{width:'8rem'}} className={`${this.props.lang === 'ar'?"float-left":""} text-light rounded-0 mt-1 px-0 bg-white no-outline`}>
                                        <span className='second-color fw-bold float-left px-3'>{`${t(this.state.sortByLetterMob)}`}</span>
                                        <span className='arrow-down color-skyblue float-right'></span>
                                    </DropdownToggle>
                                   <DropdownMenu>
                                    {
                                            mobletters?.map((e,index)=>(
                                                <DropdownItem key={index} onClick={()=>sortBy(e,index)} >{t(e)}</DropdownItem>
                                            ))
                                        }
                                    </DropdownMenu>
                    </Dropdown>
                    </div>
                    </div>                           
                    <div className="py-md-4 pt-3 d-grid grid-cols-md-4 grid-cols-2 gap-md-3 gap-3 content-box">
                    
                        {experienceCard.length>0 && experienceCard?.map(experience => (
                                <div  className="text-center p-0 d-grid" key={experience.id}>
                                    <Link className="active p-0 "
                                        to={{
                                            pathname: `/experience/${experience.id}`,
                                            state: {...experience }
                                        }}>
                                        <img className="card-hover experiences-img" src={experience.images?.large_rectangle} alt={experience.name} />
                                    </Link>
                                    <div className='text-center product_title  fw-md-bold fw-bold'>
                                        {this.props.lang !== 'ar' ? experience.name : experience.name_arabic}
                                    </div>
                                    <div style={{alignItems:'flex-end',justifySelf:'center'}} className="d-flex flex-row product_value">
                                    {this.getFirstKeyInPrice(experience)}
                                        </div> 
                                </div>
                            )
                        )}
                    </div>
                    {
                        experienceCard.length===0 && 
                        <div className='text-center'>
                            <h4>{t("There are no items currently available. Please check again later")}</h4>
                        </div>
                    }
                    <div className='mt-3'></div>
                </section>
        )
        return ( 
        <>
            {
                this.props.loading ? <section>
                <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4 ">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
        </section> : <>
        <Breadcrumbs crumbs={breadcrumbs}/>
        
        {desktopView}
        {mobileView}      
        </>
            }
        </>
         );
    }
}
 

const mapStateToProps = state => {
    return {
        experienceCards:state.experiences.experienceCards,
        loading:state.experiences.loading,
        countrySelected:state.countries.countryId,
        currancyCode:state.countries.convertionRate.currency_code,
        fetchError:state.experiences.error
    }
}

export default connect(mapStateToProps)(withTranslation()(Experiences));

