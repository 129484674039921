import { combineReducers } from "redux";
import eMagazinesReducer from "./eMagazines/eMagazinesReducer";
import giftCardReducer from "./giftCard/giftCardReducer";
import authReducer from "./auth/authReducer";
import orderReducer from "./order/orderReducer";
import pointReducer from './point/pointReducer'
import bannersReducer from "./banners/bannersReducer";
import listOfCountriesReducers from "./country/countriesReducers";
import pointExchangeReducer from './pointExchange/pointExchangeReducer';
import featureCardsReducer from "./featureCards/featureCardsReducer";
import TermsReducer from "./TermsandCond/TermsReducer";
import CountryCodeReducer from "./CountryCode/CountryCodeReducer";
import brandReducer from "./brand/brandReducers";
import mobileReducer from "./mobileTopUp/mobileReducer";
import myWalletReducer from "./myWallet/myWalletReducer"
import offerReducer from './offers/offerReducer'
import categoryReducer from './categories/categoriesReducer'
import experienceReducer from './experience/experienceReducer'
import merchandiseReducer from './Merchandise/merchandiseReducer'
import softwareGamesReducer from './Software&Games/SoftwaresReducer'
import softwareReducer from './Softwares/SoftwareReducer'
import gamesReducer from './Games/GamesReducer'
import conversionReducer from './conversion/conversionReducer'
import giftFlowReducer from './GiftFlow/giftFlowReducer'
import giftOcassionImages from './GiftFlowOcassion/giftOcassionReducer'
import ResetReducer from "./PasswordReset/ResetReducer"
import ForgetPassReducer from "./ForgetPassword/ForgetPassReducer"
import ConfirmAccount from './ConfirmAcc/confirmAccReducer'
import travelReducer from './travelShop/TravelReducer'
import goalReducer from './goalItems/goalItemsReducer'

const rootReducer = combineReducers({
    countries:listOfCountriesReducers,
    giftCards:giftCardReducer,
    eMagazines:eMagazinesReducer,
    pointExchangeCards:pointExchangeReducer,
    auth:authReducer,
    order: orderReducer,
    point:pointReducer,
    banners:bannersReducer,
    featureCards:featureCardsReducer,
    termsConds:TermsReducer,
    countryCode:CountryCodeReducer,
    PhoneFormat:CountryCodeReducer,
    brand:brandReducer,
    mobileCountry:mobileReducer,
    myWallet:myWalletReducer,
    offers:offerReducer,
    categories:categoryReducer,
    experiences:experienceReducer,
    merchandise:merchandiseReducer,
    softwareGames:softwareGamesReducer,
    softwares:softwareReducer,
    games:gamesReducer,
    conversion:conversionReducer,
    giftFormDetails: giftFlowReducer,
    giftOcassion: giftOcassionImages,
    ResetReducer:ResetReducer,
    ForgetPassReducer:ForgetPassReducer,
    ConfirmAccount:ConfirmAccount,
    travel:travelReducer,
    goalItem:goalReducer
})

export default rootReducer;