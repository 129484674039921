import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';

class Breadcumb extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }
    

    render() {
        const {step, second, third, t, lang,previousStep} = this.props;
        
        const desktopView = (
            <div className="maincheck px-4 mt-3 d-md-flex d-none justify-content-between">
                            <div className={`check1`}
                                onClick={()=>previousStep(1)}
                            >
                                <div className={(step<=2?"bigcheck":"maininput normalcheck")}></div>
                                <p className={`mb-auto fs-regular ${lang === "ar" ? "checkname2" :"checkname"}`}>{t("Design")}</p>
                            </div>
                            <div className='steps'></div>                           
                            <div className={`check1 checkwidth`}
                                onClick={()=>previousStep(3)}
                            >
                                <div className={second}></div>
                                <p className={`mb-auto fs-regular ${lang === "ar" ? "checkname2" :"checkname"}`}>{t("Message")}</p>
                            </div>
                            <div className='steps'></div>
                            <div className="check1 last-check"
                                onClick={()=>previousStep(4)}
                            >
                                <div className={third}></div>
                                <p className={`mb-auto fs-regular ${lang === "ar" ? "checkname2" :"checkname"}`}>{t("Delivery Details")}</p>
                            </div>
                    </div>
        );

        const mobileView = (
            <div className="maincheck mt-3 d-md-none d-flex">
                            <div className={`${lang === "ar" ? "check2" : "check"}`}>
                                
                                <div className={(step<=2?"bigcheck":"maininput normalcheck")}></div>
                                <p className={`mb-auto fs-regular ${lang === "ar" ? "checkname2" :"checkname"}`}>{t("Design")}</p>
                            </div>
                            <div className='m-steps'></div>
                            
                            <div className={`${lang === "ar" ? "check2" : "check"} checkwidth`}>
                                <div className={second}></div>
                                <p className={`mb-auto fs-regular ${lang === "ar" ? "checkname2" :"checkname"}`}>{t("Message")}</p>
                            </div>
                            <div className='m-steps'></div>
                            <div className="check1 ">
                                <div className={third}></div>
                                <p className={`mb-auto fs-regular ${lang === "ar" ? "checkname2" :"checkname"}`}>{t("Delivery Details")}</p>
                            </div>
                    </div>
        );

        return (
            <>
                     {desktopView}
                     {mobileView}
            </>
        )
    }
}

export default (withTranslation()(Breadcumb))