import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchTambola } from '../../Redux/banners/bannersAction';
import '../../assests/css/App.css';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

class Tambola extends Component{

constructor(props){
    super(props)
    this.state={
    }
}

componentDidMount(){
    this.props.fetchTambola();
}

render(){
    const {t}= this.props
    // let touchstartX = 0;
    // let touchendX = 0;

    // function handleGesture() {
    //     if (touchendX < touchstartX) {document.getElementsByName('BannerCardNextTambola')[0].click()} ;
    //     if (touchendX > touchstartX) {document.getElementsByName('BannerCardPreTambola')[0].click()} ;
    //     }
    const {tambola} = this.props
    const desktopView = (tambola && <div className="d-md-block d-none container-fluid">
    <div className="row pt-0">
                <div className="col-12 p-0 pt-0">
                    <div  className=" pt-0 mt-0" >
                                <div className='tambola px-2'>
                                   {
                                     tambola && tambola?.active &&
                                     <>
                                       <img className="d-block w-100" src={this.props.lang !=='ar'? tambola?.banner?.large_rectangle: tambola?.banner_arabic?.large_rectangle } alt='tambol' />
                                      {
                                      tambola && tambola?.display_order_count &&
                                    <div>
                                       {
                                       tambola && tambola?.orders_count_to_win !=='0' &&
                                         <>
                                       {/* <div className='position-absolute tambola-number'>
                                  
                                      <div  >{tambola.orders_count_to_win}</div>
                                    
                                     </div>
                                     <div className='position-absolute tambola-text align-content-center text-center' style={{fontSize:"1.2rem"}}>
                                      <div>  {t("Order Yet to Win")}</div>
                                     </div> */}
                                     <div className='promotion'>
                                     <div className='fs-extra-large fw-bold' >{tambola.orders_count_to_win}</div>
                                     <div className='fs-medium'>  {t("Order Yet to Win")}</div>
                                     </div>
                                       </>
                                     }
                               
                                   </div>
                                    }
                                     </>
                                   }
                                   {
                                     tambola && tambola?.targetted_orderid && tambola.orders_count_to_win === null &&
                                     <>
                                        <img className="d-block w-100" src={this.props.lang !=='ar'? tambola?.banner?.large_rectangle: tambola?.banner_arabic?.large_rectangle } alt='tambol' />
                                {/* <div className='position-absolute fs-medium tambola-number'>
                                  <span  >{t("Congrats")}</span>
                                </div>
                                <div className='position-absolute tambola-text-success'>
                             
                               {t("The Winner is Order ID")} : 
                               <b> {tambola?.targetted_orderid}</b>
                                 </div> */}
                                 <div class="promotion-win">
                                        <b className='congrats fs-extra-large'>{t("Congrats")}</b>
                                        <br />
                                        <div className='fs-regular'>{t("The Winner is Order ID")} : <b> {tambola?.targetted_orderid}</b> </div>
                                        
                                  </div>
                                </>
                                   }
                               <Link  data-toggle="modal" data-target="#terms-modal" className={`${this.props.lang ==='en'? ``:  ``} terms terms-link mx-3 `}>
                               {/* terms-mobile-arabic */}
                                {t("Terms")}
                                </Link>
                                <div class="modal fade" id="terms-modal" tabindex="-1" role="dialog" aria-labelledby="terms-modalTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        {/* <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5> */}
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
     
      <div class="modal-body">
      <div> {this.props.lang !== 'ar' ? tambola?.terms?.split('\n')?.map((e)=> ( <div> {`${e}.`} </div>)) : tambola?.terms_arabic?.split('\n')?.map((e)=> ( <div> {`${e}.`} </div>))}</div>
      </div>
    </div>
  </div>
</div>
                            </div>
                            {/* ) } */}
                             
                        {/* </div> */}
                        {/* <div className="d-md-block d-none">
                            <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a>
                        </div> */}
                        
                    </div>
                </div>
            </div>
</div>)


const mobileView = (
    tambola && 
    <div className="d-block d-md-none container-fluid bg-light pb-1">
    <div  className="row">
                <div  className="col-12 p-0">
                    <div>
                        <div className='tambola px-2 '>
                                {/* key={index} className={`${index===0 && `active`} carousel-item `} */}
                              
                                    <div>
                                     {
                                       tambola  && tambola?.active &&
                                       <>
                                         <img className="d-block w-100" src={this.props.lang !=='ar'? tambola?.banner?.large_rectangle: tambola?.banner_arabic?.large_rectangle } alt='tambol'  style={{height:"1.9rem"}} />
                                         {
                                tambola && tambola?.display_order_count &&

                                <div>
                                   {
                                   tambola && tambola?.orders_count_to_win !=='0' &&
                                //    <div>
                                //     <div className='position-absolute  tambola-number-mobile2'>
                                //   <div  >{tambola.orders_count_to_win}</div>
                                //   <span  >587</span>
                                // </div>
                                // <div className='position-absolute tambola-text-mobile mt-2'>
                                // <div>  {t("Order Yet to Win")}</div>
                                // </div>
                                //    </div>
                                <div className='promotion-mobile'>
                                {/* <div className='fs-extra-large fw-bold' >{tambola.orders_count_to_win}</div> */}
                                <div style={{fontSize:".6rem"}} className=' fw-bold' >{tambola.orders_count_to_win}</div>
                                <div style={{fontSize:".5rem"}}>  {t("Order Yet to Win")}</div>
                                </div>
                               }
                                  
                             
                                </div>
                              }
                                       </>
                                     }   
                             {
                                     tambola && tambola?.targetted_orderid && tambola.orders_count_to_win === null &&
                                     <>
                                         <img className="d-block w-100" src={this.props.lang !=='ar'? tambola?.banner?.large_rectangle: tambola?.banner_arabic?.large_rectangle } alt='tambol'  style={{height:"1.9rem"}} />
                                        {/* <div className='position-absolute tambola-number-mobile 'style={{marginTop:"2px"}}>
                                  <span  >{t("Congrats")}</span>
                                </div>
                                <div className='position-absolute tambola-text-mobile-success'>
                                   {t("The Winner is Order ID")} :
                                </div>
                                 <div className='position-absolute tambola-id-mobile mb-1'>                          
                                   <b>{t(tambola?.targetted_orderid)}</b>
                                 </div> */}
                                 <div  className={`${this.props.lang !=='ar'? `promotion-win-mobile`:  `promotion-win-mobile-arabic`}  no-outline `}>
                                 <div  className='congrats fw-bold cong-i-4' >{t("Congrats")}</div>
                                        <div className={`${this.props.lang !=='ar'? `win-en`:  `win-ar`}  `} >{t("The Winner is Order ID")} :  </div>
                                        <div className={`${this.props.lang !=='ar'? `id-eng`:  `id-arabic`} fw-bold `} >  {tambola?.targetted_orderid} </div>
                                 </div>
            
                                </>
                                   }
                                    </div>
                                    {/* <> 
                                    <div className='position-absolute tambola-number-mobile  tambola-cong-mobile  mt-1'>
                                  <span  >{t("Congratulations")}</span>
                                </div>
                                <div className='position-absolute tambola-text-mobile-success'>
                                   {t("The Winner Order ID")} :
                                </div>
                                 <div className='position-absolute tambola-id-mobile mb-1'>                          
                                   <b>{tambola?.targetted_orderid}</b>
                                 </div>
                                   </> */}
                                <Link  data-toggle="modal" data-target="#exampleModalCenter" className={`${this.props.lang !=='ar'? `terms-mobile`:  `terms-mobile-arabic`} terms-link ml-1 terms-link no-outline`}>
                                {/* terms-mobile-arabic */}
                                {t("Terms")}
                                </Link>
                                <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered " role="document">
    <div class="modal-content">
      <div class="modal-header">
        {/* <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5> */}
        <button type="button" class="close d-flex justify-content-end position-relative" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
     
      <div class="modal-body">
      {this.props.lang !== 'ar' ? tambola?.terms?.split('\n')?.map((e)=> ( <div> {`${e}.`} </div>)) : tambola?.terms_arabic?.split('\n')?.map((e)=> ( <div> {`${e}.`} </div>))}
      </div>
    </div>
  </div>
</div>
                            </div>
                        {/* <div className="carousel-inner px-2">
                        {this.props.tambola && this.props.tambola?.length > 0 && tambola?.map((banner,index) =>
                                <div key={index} className={`${index===0 && `active`} carousel-item `}>
                                <img className="d-block w-100 mb-2" src={`${this.props.lang === 'en' ? banner?.banner?.large_rectangle : banner?.banner_arabic?.large_rectangle}`} alt='' />
                            </div>) }
                             
                        </div> */}
                        {/* <div  className="d-md-block d-none">
                            <a  className="carousel-control-prev" name='BannerCardPrev' href="#carouselMobExampleIndicators" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a  className="carousel-control-next" name='BannerCardNext' href="#carouselMobExampleIndicators" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a>
                        </div> */}
                        
                    </div>
                </div>
            </div>
</div>
)







    return(
        <>
            {this.props.loading ? <section>
                    <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
            </section> : <>
                {desktopView}
                {mobileView}
            </>
        }
        </>
    )
}

}

const mapStateToProps = state => {
    return {
        tambola:state.banners.tambola,
        loading:state.banners.loading
    }
}


const mapDispatchToProps = dispatch => {
    return {
        fetchTambola: () => dispatch(fetchTambola())
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (withTranslation()(Tambola));