import React, { Component } from 'react';
// import TermsConditionModal from '../shared/Modals/TermsConditionModal';
import { withTranslation } from 'react-i18next';



class BuyNowCardForPointExchange extends Component {
    constructor(props) {
        super(props);
       
        this.state = { 
            byNowButton:true
         }
    }
    setByNowButton(val){
        this.setState(old=>({
            byNowButton:!old.byNowButton
        }))
    }
    
    render() { 
        const {t} = this.props
        const desktopView=(
               <>
            <div className="d-md-block d-none">
               <div className="d-flex gap-2 d-none">
                    <input 
                        type="checkbox" 
                        onClick={()=>{this.setByNowButton()}} />&nbsp;
                        <label className="" >{t("I have read and accepted the Terms & Conditions")}.</label>
                </div>
               
                <div >
                    <button type="button" className="btn btn-block primary-btn rounded-0 text-center"   onClick={()=>{this.props.onSubmit(true)}} disabled={this.state.byNowButton}>{t("Exchange Now")}</button>       
                </div>
               </div>
               </>
        )
        const mobileView = (
            <>
            <div className='d-md-none d-block mb-5'>
            <div className="d-flex">
                 <input 
                     type="checkbox" 
                     onClick={()=>{this.setByNowButton()}} /> &nbsp; &nbsp;
                     <label className="" style={{paddingLeft: "1rem",paddingRight:"1rem"}}>{t("I have read and accepted the Terms & Conditions")}.</label>
             </div>
            
             <div >
                 <button type="button" className="btn btn-block primary-btn rounded-0 text-center"   onClick={()=>{this.props.onSubmit(true)}} disabled={this.state.byNowButton}>{t("Exchange Now")}</button>       
             </div>
            </div>
            </>
        )
        return ( 
            <>
                {desktopView}
                {mobileView}
            </>
         );
    }
}
 
export default (withTranslation() (BuyNowCardForPointExchange));