import isDev from './utils';

const TEST_BASE_API_URL = "https://testapimeritmarketplace.meritincentives.com/api/v1";

export const REACT_APP_baseUrl = isDev() ?TEST_BASE_API_URL:`https://${window.location.hostname}/api/v1`;
export const REACT_APP_baseUrl_V3 = isDev() ?TEST_BASE_API_URL:`https://${window.location.hostname}/api/v3`;

export const REACT_APP_baseUrl_V2 = isDev() ?TEST_BASE_API_URL:`https://${window.location.hostname}/api/v2`;

export const REACT_APP_stripePublishableKey = `${window._env_.REACT_APP_stripePublishableKey}`;
export const REACT_APP_RECAPTCHA_SITE_KEY = `${window._env_.REACT_APP_RECAPTCHA_SITE_KEY}`;

export const PointsExchangeID = {  
    'AlFursan':window._env_.REACT_APP_AlFursanId,
    'Neqaty': window._env_.REACT_APP_NeqatyId
}

export const DEBUG_PARTY_TOWN = process?.env?.NODE_ENV === "development" ? true : false