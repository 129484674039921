import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import caretRight from '../../assests/img/caret-right-fill.svg'

class Breadcrumbs extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        const {t} = this.props
        return ( 
            <div className="d-md-flex d-none border px-5 py-2 fw-bold text-black-50">
                {
                    this.props.crumbs.map((crumb,ind)=>(
                        crumb.active ?
                                 <React.Fragment key={ind}>
                            
                            <Link 
                                className={crumb.active ? "active-link":""} 
                                to={{
                                    pathname:crumb.url,
                                }}
                            >{t(crumb.name)}
                            
                            </Link>
                            <span className="px-4">
                                <img className='img-fluid icon-gray' style={{height:'0.7rem'}} src={caretRight} alt=''/>
                            </span>
                        </React.Fragment>:
                        <span key={ind}>{t(crumb.name)}</span>
                        
                        
                    ))
                }
        </div>
         );
    }
}
export default withTranslation()(Breadcrumbs);
