import React, { Component } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { connect } from 'react-redux';
import { fetchocassionimage } from '../../../Redux/GiftFlowOcassion/giftOcassionActions';


// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import './Buygift.css'
import { withTranslation } from 'react-i18next';


import SwiperCore, {
    EffectCoverflow, Pagination
} from 'swiper';

SwiperCore.use([EffectCoverflow, Pagination]);

class Birthdaycard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disable: (this.props.GiftParticularCardSelect!==undefined)?false:true,
            selected:{
                id: this.props.GiftParticularCardSelect
            },
            sliderIndex: this.props.GiftParticularSliderIndex
        }
    }

    componentDidMount() {
        if (this.props.cardSelected !== this.props.ocassionimage.id)this.props.fetchocassionimage(this.props.cardSelected) 
    }

    disableHandler = (name, index) => {
        this.setState({ 
            disable: false ,
            selected:{
                id: name,
            },
            sliderIndex: index
        })
    }



    render() {
        const { nextStep,particularCardSelectHandler, t } = this.props;
        const  GiftOccassionName  = this.props.ocassionimage.occasion_message;
        const GiftOccassionNameArabic = this.props.ocassionimage.occasion_message_in_arabic;
        const  data  = this.props.ocassionimage?.occasion_images?.data;
        const desktopView = (
            <div className="d-md-block d-none flex-shrink-0 mt-4 mb-5 px-3">
                <p className="color-blue mt-4 pl-1 mainheadselect">{(this.props.lang === 'ar')?GiftOccassionNameArabic:GiftOccassionName}</p>
                <Swiper
                    slidesPerView={5}
                    spaceBetween={50}
                    pagination={{
                        "clickable": true
                    }}
                    onSwiper={(swiper) => {
                        if (this.state.selected.id!== undefined) {
                            swiper.activeIndex = this.state.sliderIndex - 2;
                            swiper.slideNext();
                        }
                    }}
                    // onSlideChange={(swiper) => {
                    //     changeFormState('url', (cardSelected == 0) ? Birthdaycard[swiper.activeIndex] : Aniverserycard[swiper.activeIndex]);
                    // }}
                    className="mySwiper">
                    <div className="hi">
                        {
                            data?.map((image, index) => {
                                console.log(image.id, this.state.selected.id);
                                return <SwiperSlide onClick={()=>{
                                    this.disableHandler(image.id, index);
                                }}><img className={(this.state.selected.id === image.id)?"selectedborder hoverpointer":"hoverpointer"} src={image?.occasion_image?.medium_rectangle} alt=""/>
                                </SwiperSlide>
                            }
                            )}
                    </div>
                </Swiper>

                <div className="w-100 text-center mt-5">
                    <button
                        className="btn btn-primary w-50"
                        disabled={this.state.disable}
                        onClick={()=>{
                            particularCardSelectHandler(this.state.selected.id, this.state.sliderIndex);
                            nextStep();
                        }}>
                        {t("Proceed")}
                    </button>
                </div>
            </div>
        );

        const mobileView = (
            <div className="Selectmain d-md-none d-block">
                <p className="color-blue mt-4 pl-3 mainheadselect">{GiftOccassionName}</p>

                <Swiper effect={'coverflow'} grabCursor={true} centeredSlides={true} slidesPerView={'2'} coverflowEffect={{
                    "rotate": 50,
                    "stretch": 0,
                    "depth": 100,
                    "modifier": 1,
                    "slideShadows": true
                }}
                onSwiper={(swiper) => {
                    console.log(this.state.selected.id);
                    if (this.state.selected.id!== undefined) {
                        swiper.activeIndex = this.state.sliderIndex - 2;
                        swiper.slideNext();
                    }
                }}
                    // onSlideChange={(swiper) => {
                    //     changeFormState('url', (cardSelected == 0) ? Birthdaycard[swiper.activeIndex] : Aniverserycard[swiper.activeIndex]);
                    // }}
                    pagination={true} className="mySwiper mt-4">
                    <div className="hi">
                    {
                            data?.map((image, index) => {
                                return <SwiperSlide onClick={()=>{
                                        this.disableHandler(image.id, index)
                                    }
                                    }>
                                    <img className={(this.state.selected.id === image.id)?"selectedborder hoverpointer":"hoverpointer"} src={image?.occasion_image?.medium_rectangle} alt=""/>
                                </SwiperSlide>
                            }
                            )}
                    </div>
                </Swiper>


                <div className="w-100 text-center pro">
                    <button
                        className="btn btn-primary"
                        disabled={this.state.disable}
                        onClick={()=>{
                            particularCardSelectHandler(this.state.selected.id, this.state.sliderIndex);
                            nextStep();
                        }}>
                        {t("Proceed")}
                    </button>
                </div>
            </div>
        );

        return (
            <>
                {this.props.loading ? <section>
                    <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </section> : <>
                    {desktopView}
                    {mobileView}
                </>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ocassionimage: state.giftOcassion.ocassionimage,
        loading: state.giftOcassion.loading,
    };
};


const mapDispatchToProps = dispatch => {
    return {
        fetchocassionimage: (cardselected) => dispatch(fetchocassionimage(cardselected)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(Birthdaycard));








