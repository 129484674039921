import React, { Component } from 'react'
import './Buygift.css'
import countryCodes from '../../../utils/countrycodes/countryCodes.json';
import { isValidPhoneNumber } from 'libphonenumber-js'
import { withTranslation } from 'react-i18next';

class Giftform extends Component {

    constructor(props) {
        super(props);
        this.state={
            hide:{
                email: true,
                sms: false
            },
            error:{
                toError: "",
                fromError: "",
                emailError: "",
                mobileError: ""
            },
            countryCodeInput: '+966',
            mobileNumberInput: '',
        }
    };

    componentDidMount(){
        if (this.props.GiftFormat === "both"){
            this.setState({
                hide:{
                    email: true,
                    sms: true
                }
            })
        }
        else if (this.props.GiftFormat === "email"){
            this.setState({
                hide:{
                    email: true
                }
            })
        }
        else{
            this.setState({
                hide:{
                    sms: true
                }
            })
        }
        
        if(this.props.GiftMobile){
            if(this.props.GiftEmail) this.hideHandler('both')
            else this.hideHandler('sms')
        }
        else this.hideHandler('email')
    }

    hideHandler(a){
        (a==="both")?
        this.setState({
            hide:{
                email: true,
                sms: true
            },
            ...this.state.error
        })
        : 
        this.setState({
            hide:{
                [a]: true
            }
        })
    }


    validate(to, from, email) {
        const { t } = this.props;
        // eslint-disable-next-line no-useless-escape
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!to) this.setState({ error:{ ...this.state.error,  toError: t("Please enter recipient name")} })

        else if (!from) this.setState({ error:{ ...this.state.error,  fromError: t("Please enter your name")} }) 

        else if (this.state.hide.email && this.state.hide.sms){
            if (!email){
                this.setState({ error:{ ...this.state.error,  emailError: t("Please enter recipient email")} })
            }
            else if(!email.match(mailformat)){
                this.setState({ error:{ ...this.state.error,  emailError: t("Please Enter the correct email")} })
            }
            else if(this.state.error.mobileError !== ""){
                this.setState({ error:{ ...this.state.error,  mobileError: t("Please Enter correct mobile number")} })
            }
            else if(this.state.mobileNumberInput==="" && this.props.GiftMobile===""){
                this.setState({ error:{ ...this.state.error,  mobileError: t("Please Enter mobile number")} })
            }
            else{
                this.props.nextStep();
            }
        }

        else if(this.state.hide.email){
            if (!email){
                this.setState({ error:{ ...this.state.error,  emailError: t("Please enter recipient email")} })
            }
            else if(!email.match(mailformat)){
                this.setState({ error:{ ...this.state.error,  emailError: t("Please Enter the correct email")} })
            }
            else{
                this.props.nextStep();
            }
            
        }
        else if(this.state.hide.sms){
            if(this.state.error.mobileError !== ""){
                this.setState({ error:{ ...this.state.error,  mobileError: t("Please Enter correct mobile number")} })
            }
            else if(this.state.mobileNumberInput==="" && this.props.GiftMobile===""){
                this.setState({ error:{ ...this.state.error,  mobileError: "Please Enter mobile number"} })
            }
            else{
                this.props.nextStep();
            }
        }

        else{
            if (!email){
                this.setState({ error:{ ...this.state.error,  emailError: t("Please enter recipient email")} })
            }
            else if(!email.match(mailformat)){
                this.setState({ error:{ ...this.state.error,  emailError: t("Please Enter the correct email")} })
            }
            else if(this.state.error.mobileError !== ""){
                this.setState({ error:{ ...this.state.error,  mobileError: t("Please Enter correct mobile number")} })
            }
            else{
                this.props.nextStep();
            }
        }
        

    }



    validateMobile(mobileNumber, countryCode){
        const { t } = this.props;
        if(isValidPhoneNumber(countryCode+ mobileNumber)){
            this.setState({ error:{ ...this.state.error,  mobileError: ""} })
        }
        else{
            this.setState({ error:{ ...this.state.error,  mobileError: t("Please Enter correct mobile number")} })
        }
    }



 
    render() {
        const {changeFormState, to, from, email, mobile, countryCode, t, lang} = this.props;
        const { toError, fromError, emailError, mobileError} = this.state.error;
        let toInput = React.createRef();
        let fromInput = React.createRef();
        let emailInput = React.createRef();


        const desktopView = (
            <div className="pb-5 d-md-block d-none">
            <p className={`op mt-5 mainheadselect ${lang === "ar" ? "mr-4" :"ml-4"}`}> <span className="text-black-50">{t("Delivery")}:</span>{t("Email & SMS")}</p>
            <div className="d-flex w-100 justify-content-start">
                <div onClick={()=>{
                    this.hideHandler('email');
                    changeFormState('format', 'email')
                }} className={this.state.hide.email && !this.state.hide.sms?'messactive mx-4':'mx-4 formmessagetype'}>
                    {t("Email")}
                </div>
                <div onClick={()=>{
                    this.hideHandler('sms');
                    changeFormState('format', 'mobile')
                }} className={this.state.hide.sms && !this.state.hide.email? 'messactive mx-4':'mx-4 formmessagetype'}>
                    {t("SMS")}
                </div> 
                <div onClick={()=>{
                    this.hideHandler('both')
                    changeFormState('format', "both")
                }} className={this.state.hide.sms && this.state.hide.email?'messactive mx-4':'mx-4 formmessagetype'}>
                    {t("Both")}
                </div>
                
            </div>
            <div className="container-fluid mt-3">
                <div className="row px-2">
                    <div className="col-6 text-black-50">
                    {t("To")}
                    </div>
                    <div className="col-6 text-black-50">
                    {t("From")}
                    </div>
                </div>
                <div className="row mt-1 px-2">
                    <div className="col-6">
                        <input ref={toInput} className="w-100 py-2" type="text" placeholder={t("Please enter recipient name")} value={to} onChange={(e)=>{
                            changeFormState('to', e.target.value);
                            this.setState({ error:{ ...this.state.error,  toError: ""}});
                        }
                        } />
                        <p className={(toError)?"text-danger fs-small fw-bold":"text-black-50 fs-small fw-bold"}>{(this.state.error.toError)? t("Please enter recipient name"):t("Recipient's name")+'. ' + t("This could be a nick name")}</p>
                    </div>
                    <div className="col-6">
                        <input ref={fromInput} className="w-100 py-2" type="text" placeholder={t("Please enter your name")} value={from} onChange={(e)=>{
                            changeFormState('from', e.target.value)
                            this.setState({ error:{ ...this.state.error,  fromError: ""}})
                            }} />
                        <p className={(fromError)?"text-danger fs-small fw-bold":"text-black-50 fs-small fw-bold"}>{(fromError)?fromError:`${t("Sender's name")}. ${t("This could be a nick name")}`}</p>
                    </div>
                </div>
            </div>

            <div className="container-fluid mt-3">
                    <div className="row px-2">
                        {
                            (this.state.hide.email)?
                            <div className="col-6 text-black-50">
                            {t("Recipient's email address")}
                            </div>: null
                        }
                        {
                            (this.state.hide.sms)?
                            <div className="col-6 text-black-50">
                            {t("Recipient's mobile number")}
                            </div>: null
                        }
                    </div>
                    <div className="row mt-1 px-2">
                        {
                            (this.state.hide.email)?
                            <div className="col-6">
                            <input ref={emailInput} className="w-100 py-2" type="text" placeholder={t("Please enter recipient email")} value={email} onChange={(e)=>{
                                changeFormState('email', e.target.value);
                                this.setState({ error:{ ...this.state.error,  emailError: ""}})
                                }} />
                            <p className={(this.state.error.emailError)?"text-danger fs-small fw-bold":"text-black-50 fs-small fw-bold"}>{(emailError)?emailError:t("Will be delivered to this id via Email")}</p>
                            </div>: null
                           
                        }
                        {
                            (this.state.hide.sms)?
                            <div className="col-6 ">
                                <div className="d-flex">
                                    <select value={countryCode} onChange={(e)=>{
                                        this.setState({ countryCodeInput:e.target.value});
                                        changeFormState('countryCode', e.target.value);
                                        this.validateMobile(this.state.mobileNumberInput, e.target.value);
                                    }} className="px-3" name="" id="">
                                        {
                                            countryCodes.map((countryCode) =>{
                                                return <option value={countryCode.value}>{countryCode.value}</option>
                                            })
                                        }
                                    </select>
                                    <input className="w-100 py-2" type="text" value={mobile} 
                                    onChange={(e)=>{
                                        changeFormState('mobile', e.target.value);
                                        this.setState({ mobileNumberInput: e.target.value});
                                        this.validateMobile(e.target.value, this.state.countryCodeInput)
                                    }}/>
                                </div>
                                <p className={(mobileError)?"text-danger fs-small fw-bold":"text-black-50 fs-small fw-bold"}>{(mobileError)?mobileError:t("Will be delivered to this mobile number via SMS")}</p>
                            </div>: null
                        }
                    </div>
            </div>

            <div className="mb-5 mt-3 pb-5">

                        <div className="w-100 text-right px-4">
                            <button 
                                className="btn btn-primary px-5 rounded-0"
                                onClick={
                                    ()=>{
                                        if(this.state.hide.email) this.validate(toInput.current.value,fromInput.current.value,emailInput.current.value);
                                        else this.validate(toInput.current.value,fromInput.current.value);
                                    }
                                }
                                >
                                {t("Proceed")} 
                            </button>
                        </div>
                
            </div>
            </div>
        );

        const mobileView = (
            <div className="pb-5 d-md-none d-block">
            <p className={`op mt-5 mainheadselect ${lang === "ar" ? "mr-3" :"ml-3"}`}> <span className="text-black-50">{t("Delivery")}:</span>{t("Email & SMS")}</p>
            <div className="d-flex w-100 justify-content-around">
                <div onClick={()=>{
                    this.hideHandler('email')
                    changeFormState('format', 'email')
                }} className={this.state.hide.email && !this.state.hide.sms?'messactive':'formmessagetype'}>
                    {t("Email")}
                </div>
                <div onClick={()=>{
                    this.hideHandler('sms')
                    changeFormState('format', 'mobile')
                }} className={this.state.hide.sms && !this.state.hide.email? 'messactive':'formmessagetype'}>
                    {t("SMS")}
                </div> 
                <div onClick={()=>{
                    this.hideHandler('both')
                    changeFormState('format', 'both')
                }} className={this.state.hide.sms && this.state.hide.email?'messactive':'formmessagetype'}>
                     {t("Both")}
                </div>
                
            </div>
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col text-black-50">
                    {t("To")}
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col">
                        <input ref={toInput} className="w-100 py-2" type="text" placeholder={t("Please enter recipient name")} value={to} onChange={(e)=>{
                            changeFormState('to', e.target.value);
                            this.setState({ error:{ ...this.state.error,  toError: ""}})
                        }
                        } />
                        <p className={(toError)?"text-danger fs-small fw-bold":"text-black-50 fs-small fw-bold"}>{(this.state.error.toError)?t("Please enter your name"):t("Recipient's name") + '. ' + t("This could be a nick name")}</p>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col text-black-50">
                    {t("From")}
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col">
                        <input ref={fromInput} className="w-100 py-2" type="text" placeholder={t("Please enter recipient name")} value={from} onChange={(e)=>{
                            changeFormState('from', e.target.value)
                            this.setState({ error:{ ...this.state.error,  fromError: ""}})
                            }} />
                        <p className={(fromError)?"text-danger fs-small fw-bold":"text-black-50 fs-small fw-bold"}>{(fromError)?fromError: `${t("Sender's name")}. ${t("This could be a nick name")}`}</p>
                    </div>
                </div>
            </div>

            {
            (this.state.hide.email)? 
            <div className="container-fluid mt-3">
            <div className="row">
                <div className="col text-black-50">
                {t("Recipient's email address")}
                </div>
            </div>
            <div className="row mt-1">
                            <div className="col">
                               <input ref={emailInput} className="w-100 py-2" type="text" placeholder={t("Please enter recipient email")} value={email} onChange={(e)=>{
                                   changeFormState('email', e.target.value);
                                   this.setState({ error:{ ...this.state.error,  emailError: ""}  })
                                   }} />
                               <p className={(this.state.error.emailError)?"text-danger fs-small fw-bold":"text-black-50 fs-small fw-bold"}>{(emailError)?emailError:t("Will be delivered to this id via Email")}</p>
                           </div>
                       </div>
                </div>
            :
            ``
        }
        {
            (this.state.hide.sms)?
            <div className="container-fluid mt-3 ">
            <div className="row">
                <div className="col text-black-50">
                {t("Recipient's mobile number")}
                </div>
            </div>
            <div className="row mt-1">
                 <div className="col">
                 <div className="d-flex">
                                    <select value={countryCode} onChange={(e)=>{
                                        this.setState({ countryCodeInput:e.target.value});
                                        this.validateMobile(this.state.mobileNumberInput, e.target.value);
                                    }} className="px-3" name="" id="">
                                        {
                                            countryCodes.map((countryCode) =>{
                                                return <option value={countryCode.value}>{countryCode.value}</option>
                                            })
                                        }
                                    </select>
                                    <input className="w-100 py-2" type="text" value={mobile}  
                                    onChange={(e)=>{
                                        changeFormState('mobile', e.target.value);
                                        this.setState({ mobileNumberInput: e.target.value})
                                        this.validateMobile(e.target.value, this.state.countryCodeInput)
                                    }}/>
                                </div>
                    <p className={(mobileError)?"text-danger fs-small fw-bold":"text-black-50 fs-small fw-bold"}>{(mobileError)?mobileError:t("Will be delivered to this mobile number via SMS")}</p>
                </div>
            </div> 
            </div>
            :
            ``
            
        }
            <div className="mb-5 mt-3 pb-5">
                        <div className="w-100 text-center">
                            <button 
                                className="pro1 btn btn-primary"
                                onClick={
                                    ()=>{
                                        if(this.state.hide.email) this.validate(toInput.current.value,fromInput.current.value,emailInput.current.value);
                                        else this.validate(toInput.current.value,fromInput.current.value);
                                    }
                                }
                                >
                                {t("Proceed")} 
                            </button>
                        </div>
            </div>
            </div>
        );


        return (
            <>
                {desktopView}
                {mobileView}
            </>
        )
    }
}


export default (withTranslation()(Giftform))
