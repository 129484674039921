import axios from "axios";
// import store from "../store"
import {
  FETCH_POINT_BALANCE_FAILURE,
  FETCH_POINT_BALANCE_REQUEST,
  FETCH_POINT_BALANCE_SUCCESS,
} from "./pointTypes";
import { REACT_APP_baseUrl } from "utils/app-constants";

export const fetchPointBalanceRequest = () => {
  return {
    type: FETCH_POINT_BALANCE_REQUEST,
  };
};

export const fetchPointBalanceSuccess = (data) => {
  return {
    type: FETCH_POINT_BALANCE_SUCCESS,
    payload: data,
  };
};

export const fetchPointBalanceFailure = (error) => {
  return {
    type: FETCH_POINT_BALANCE_FAILURE,
    payload: error,
  };
};

export const fetchPointBalance = (token) => {
  return (dispatch) => {
    dispatch(fetchPointBalanceRequest);
    
    let tkn = localStorage.getItem("userToken")
    axios.get(
        `${REACT_APP_baseUrl}/users/get_point_balance`,
        {
          headers: {
            Authorization: `Bearer ${token || tkn}`,
          },
        }
      )
      .then((response) => {
        const pointBalance = response.data.data.point_balance;
        dispatch(fetchPointBalanceSuccess(pointBalance));
      })
      .catch((error) => {
        const errorMsg = error;
        console.log(errorMsg);
        dispatch(fetchPointBalanceFailure("failed"));
      });
  };
};
