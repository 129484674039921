import React, { Component } from 'react'
import './Buygift.css'
import {Link } from 'react-router-dom'
import { fetchGiftFlowData } from '../../../Redux/GiftFlow/giftFlowActions.js';
import {connect} from 'react-redux'
import { withTranslation } from 'react-i18next';


class Customdesign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hide:{
                theme:true,
                upload: false,
            },
            url:"",
            productPath: ''
        }
    }

    componentDidMount() {
        const type = localStorage.getItem('type');
        if (type === 'eMagazines') this.setState({productPath: 'e-magazines'})
        if (type === 'giftCards') this.setState({productPath: 'selectedgiftcard'})
        if (type === 'offers') this.setState({productPath: 'offer'})

        const data = this.props.ocassionimage?.occasion_images?.data;
        for (let i=0; i<data.length; i++) {
            if (this.props.particularCardSelect === data[i].id){
                this.setState({
                    url : data[i].occasion_image?.medium_rectangle
                })
            }
        }
    }

    handleFile = (e) => {
        const content = e.target.result;
        this.setState({
            url:content
        })
      }
      

    handleChangeFile = (file) => {
        let fileData = new FileReader();
        fileData.onloadend = this.handleFile;
        fileData.readAsDataURL(file);
    }

    fetchGiftFlowDataHandler = (message, to, from, email, mobile, url, format, countryCode, cardSelect, sliderIndex, view, GiftSliderIndex, particularSliderIndex, particularCardSelect) => {
        
        console.log('index', GiftSliderIndex, sliderIndex)
        if (view === "desktop"){
            if (sliderIndex < 5){
                sliderIndex= 1
            }
            else{
                sliderIndex = sliderIndex - 3
            }
            if (particularSliderIndex < 5){
                particularSliderIndex= 1
            }
            else{
                particularSliderIndex = particularSliderIndex - 3
            }
        }
        else{
            sliderIndex += 1; 
            particularSliderIndex += 1; 
        }
        
        if (format === 'email'){
            mobile = ''
        }
        else if (format === 'mobile'){
            email = ''
        }
        const payload = {
            "message":message,
            "to": to,
            "from": from,
            "email": email,
            "mobile": mobile,
            "url": this.state.url,
            "giftCountryCode": countryCode,
            "GiftCardSelect": cardSelect,
            "GiftSliderIndex": sliderIndex,
            "GiftParticularCardSelect": particularCardSelect,
            "GiftParticularSliderIndex": particularSliderIndex,
            "GiftFormat": format
        }
        this.props.fetchGiftFlowData(payload);
    }



    render() {
        const {url, id, message, to, from, email, mobile, format, countryCode, cardSelect, sliderIndex,GiftSliderIndex,particularSliderIndex,particularCardSelect, t} = this.props;
        const desktopView = (
            <div className="d-md-block d-none">
                <div className="main d-flex mt-5 pl-5 justify-content-start border-bottom pb-3">
                    <div className={(this.state.hide.theme?"static border px-4 mx-4 py-2 customtab customtabactive":"static border px-4 py-2 mx-4 customtab")}
                        onClick={()=> this.setState({hide:{upload:false, theme: true}})}
                    >
                        {t("Theme")}
                    </div>
        
                    {/* <div className={(this.state.hide.theme?"static border px-4 mx-4 py-2 customtab":"static border px-4 py-2 customtab mx-4 customtabactive")}
                        onClick={()=> this.setState({hide:{upload:true, theme: false}})}
                    >
                        {t("Upload Photo")}
                    </div> */}
                </div>
                <div className="w-100 text-center">
                    {(this.state.hide.theme?
                    <>
                    <img className="mt-3" style={{width: '20%', height: 'auto'}} src={this.state.url} alt="" />
                    <div className="d-flex justify-content-end mt-3 mb-5 pr-5">
                        <div className="px-3">
                            <Link 
                            onClick={()=> this.fetchGiftFlowDataHandler(message, to, from, email, mobile, url,format, countryCode, cardSelect, sliderIndex, 'desktop', GiftSliderIndex, particularSliderIndex, particularCardSelect)}
                            to={{
                                pathname: `/${this.state.productPath}/${id}`,
                                state:{
                                  comingFrom: 'giftflow'
                                }
                              }}
                            className="btn btn-primary px-3 text-white hoverCustom">{t("Proceed")}
                            </Link>
                        </div>
                    </div>
                    </>
                                            :
                        <>
                           <div className="d-flex justify-content-around mt-5">
                            <div className="upload-file">
                                <div role="button" className="upload-box ">
                                        <i className="fas fa-cloud-upload-alt"></i>
                                        <span className="upload-name">{t("Upload a photo or drag file here")}</span>
                                        <input onChange={e =>this.handleChangeFile(e.target.files[0])} type="file" className="uploadInput" accept="image/*,video/mp4" name="upload"/>
                                            <p></p>
                                    </div>
                                    <div className="d-flex justify-content-between pb-2">
                                        <div className="d-flex flex-column">
                                            <small className="float-right">{t("Image Size")}<strong> 1060px x 300px</strong>
                                        </small>
                                        </div>
                                    </div>
                                </div>

                                <img style={{width: 'auto', height: '300px'}} src={this.state.url} alt="" />
                           </div>
                      
                <div className="d-flex justify-content-end mt-3 mb-5 pr-5">
                    <div className="px-3">
                        <Link 
                        onClick={()=> this.fetchGiftFlowDataHandler(message, to, from, email, mobile, this.state.url,format, countryCode, cardSelect, sliderIndex, 'desktop', particularSliderIndex, particularCardSelect)}
                        to={{
                            pathname: `/${this.state.productPath}/${id}`,
                            state:{
                              comingFrom: 'giftflow'
                            }
                          }}
                        className="btn btn-primary px-3 text-white hoverCustom">{t("Proceed")}
                        </Link>
                    </div>
                </div>
                </>
                    )}
            </div>

            </div>
        );
        const mobileView = (
            <div className="d-md-none d-block">
                <div className="main d-flex mt-4 justify-content-around border-bottom pb-3">
                    <div className={(this.state.hide.theme?"static border px-4 py-2 customtab customtabactive":"static border px-4 py-2 customtab")}
                        onClick={()=> this.setState({hide:{upload:false, theme: true}})}
                    >
                        {t("Theme")}
                    </div>
        
                    {/* <div className={(this.state.hide.theme?"static border px-4 py-2 customtab":"static border px-4 py-2 customtab customtabactive")}
                        onClick={()=> this.setState({hide:{upload:true, theme: false}})}
                    >
                        {t("Upload Photo")}
                    </div> */}
                </div>
                <div className="w-100 text-center">
                    {(this.state.hide.theme?
                    <>
                        <img style={{width: '80%', height: 'auto'}} src={this.state.url} alt="" />
                            <div className="d-flex justify-content-around mt-3" style={{paddingBottom:'80px'}}>
                                    <div>
                                        <Link
                                        onClick={()=> this.fetchGiftFlowDataHandler(message, to, from, email, mobile, url, format, countryCode, cardSelect, sliderIndex, 'mnobile', GiftSliderIndex, particularSliderIndex,particularCardSelect)}
                                        to={{
                                            pathname: `/${this.state.productPath}/${id}`,
                                            state:{
                                              comingFrom: 'giftflow'
                                            }
                                          }}
                                        className="btn btn-primary text-white hoverCustom messageproceed">{t("Proceed")}
                                        </Link>
                                    </div>
                            </div>
                        </>

                        :
                        <>
                        <div className="d-flex flex-column align-items-center mt-5">
                            {!this.state.url?
                                <div style={{width: '80% !important'}} className="upload-file upload-file1">
                                <div role="button" className="upload-box">
                                        <i className="fas fa-cloud-upload-alt"></i>
                                        <span className="upload-name">{t("Upload a photo or drag file here")}</span>
                                        <input onChange={e =>this.handleChangeFile(e.target.files[0])} type="file" className="uploadInput" accept="image/*,video/mp4" name="upload"/>
                                            <p></p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex flex-column">
                                            <small className="float-right">{t("Image Size")}<strong> 1060px x 300px</strong>
                                        </small>
                                        </div>
                                    </div>
                                </div>:null}
                                <div className="pt-4 ">
                                    <div class="btn position-relative" >
                                        <img style={{width: '90%', height: 'auto'}} src={this.state.url} alt="" />
                                        <span onClick={()=> this.setState({url:""})} class="position-absolute top-0 start-90 translate-middle badge bg-light rounded-pill">X</span>
                                    </div>
                                </div>
                           </div>
                           {this.state.url?
                                <div className="d-flex justify-content-around mt-3" style={{paddingBottom:'80px'}}>
                                    <div>
                                        <Link
                                        onClick={()=> this.fetchGiftFlowDataHandler(message, to, from, email, mobile, this.state.url, format, countryCode, cardSelect, sliderIndex, 'mobile', GiftSliderIndex, particularSliderIndex, particularCardSelect)}
                                        to={{
                                            pathname: `/${this.state.productPath}/${id}`,
                                            state:{
                                              comingFrom: 'giftflow'
                                            }
                                          }}
                                        className="btn btn-primary text-white hoverCustom">{t("Proceed")}
                                        </Link>
                                    </div>
                                </div>:null}
                        </>
                    )}
                </div>
              
            </div>
        );

        return (
            <>
                {desktopView}
                {mobileView}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ocassionimage: state.giftOcassion.ocassionimage,
    };
  };

const mapDispatchToProps = dispatch => {
    return {
        fetchGiftFlowData : (payload) => dispatch(fetchGiftFlowData(payload)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(Customdesign));