import React,{Component} from 'react'
import { fetchOffers } from '../../.././Redux/offers/offerActions';
import { connect } from 'react-redux';
import { sortOfferCards } from '../../.././Redux/offers/offerActions';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { currancyToPoints } from 'utils/pointsConversion';
// import { currancyToPoints } from '../../../utils/pointsConversion';


class DiscountOffersSection extends Component {
    constructor(props) {
        super(props);
        this.state = { 
           
         }
    }

    render() { 
        const {t}=this.props
       const numberWithCommas = (x) => {
            let y = x.toFixed(0)
            return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
         return ( 
            this.props.featuredOffers?.length>0 && <div className='  px-md-5 px-3 py-3  mx-auto bg-white' >
            <div >
                <div className='headings m-0 d-inline-block fs-md-3 fs-5 fw-bold'>{t("Discounts & Offers")}</div>
                         {
                                 <Link className={`text-decoration-none color-skyblue fw-bold  ${this.props.lang !== 'ar' ? "float-right": "float-left"} d-inline-block p-0 pb-2`}
                                 onClick={()=>{window.scrollTo(0, 0)}}
                                 to='/offers'>{`${this.props.lang !== 'ar' ? "Show All" :  "ترتيب حسب"}`}</Link>
                             }
                <div className="text-black-50 fw-bold  ">{t("Top Brands-Unbelievable Savings")}</div>
            </div>
            <div className="d-grid grid-cols-md-2 grid-cols-2 gap-md-3 gap-0 overflow-auto ">
           </div>            
            <div className="d-grid grid-cols-md-4 grid-cols-2 gap-md-3 gap-2 pt-3 ">
            { this.props.featuredOffers?.map((card,index)=>(
                index < 4 ?
            <Link className='offerCardHover' to={`/offer/${card.id}`}>
                <div style={{cursor:'pointer'}} className="border p-0">
            <img
                className="img-fluid w-100" 
                src={card.image && card.image.small_square } alt={card.title} />
            <div className="p-md-3 p-2">
                <h5 className="product_title fw-bold m-0 d-inline-block justify-content-center"
                    style={{minHeight:"75px",textOverflow:'ellipsis',overflow:'hidden',fontSize:'1.1rem'}}>{this.props.lang!=='ar'?card.title:card.title_arabic}</h5>
                {/* <div style={{minHeight:"70px"}}className="text-black-50">by {card.brand_name}</div> */}
                <div className='pt-1 pb-3 product_value'>
                    <h4 className="d-inline">{this.props.convertionRate && currancyToPoints(numberWithCommas((card.hassad_offer_value)))}</h4> <small>{t("Points")}</small>                     
              </div>
                {/* <div className="text-danger">{`Valid Until: ${date.slice(3)}`}</div> */}
                {
                        new Date() < new Date(card.effective_to_date) ? <div className="text-danger" style={{minHeight:"42px"}}>{t("Valid Until")}: {card.effective_to_date}</div> : <div className="text-danger" style={{minHeight:"42px"}}>{t("Sold Out")}...... {t("Coming Soon")}</div>
                    }
                
            </div>
         </div>
             </Link>: false
            ))}
            </div>
     </div>
         );
    }
}
const mapStateToProps = state => {
    return {
        offers:state.offers.offersList,
        category:state.offers.category,
        loading:state.offers.loading,
        sortBy:state.offers.sortBy,
        filteredCards: state.offers.filteredCards,
        offerCard: state.brand.brand,
        countrySelected:state.countries.countryId,
        currancyCode:state.countries.convertionRate.currency_code,
        categoryName:state.offers.categoryName,
        convertionRate:state.countries.convertionRate.currency_exchange_rate,
        featuredOffers: state.offers.featuredOffers
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchOffers : (payload) => dispatch(fetchOffers(payload)),
        sortOfferCards:(data,sortBy,categoryName,onlyCategory,onlyPointsFilter) => dispatch(sortOfferCards(data,sortBy,categoryName,onlyCategory,onlyPointsFilter))
    }
}

export default  connect(mapStateToProps,mapDispatchToProps) (withTranslation()(DiscountOffersSection));