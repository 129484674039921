import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';



class ProductCards extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
        setTimeout(() =>
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            }), 800);
    }
    
    render() { 
        let {cards,name, t} = this.props
        let lang = this.props.i18n.language
        const linkCards = (type,row) =>{
            if(row.giftcard_token){
                type = 'receivedGiftcards'
            }
            else if(row.voucher_url){
                type = 'receivedOffers'
            } 
            if(type === 'Gift Cards' || type === 'E-Magazines'){
                return (

                   <>
                    {
                         row.isgift === false &&  row.giftcard_tokens.map((token,index)=>{
                            return(
                              <>
                             
                              <Link
                                target='_blank'
                              to={{
                              pathname:`/giftcard/${token}/details?lang=${lang}`,
                               state: {...row }
                             }}
                 
                            >      
                                        <td className="px-1 py-2 align-bottom  "><button className='bg-light-gray border-0 px-3  fw-bold view-button' ><p className='m-0'  style={{fontSize:"15px"}}>0{index+1}</p>
                                        <h6 className='m-0 fw-bold primary-color'  style={{fontSize:"11px"}}>{t("View")}</h6>
                                </button> </td>
                                 </Link>

                              </>
                               )
                            })
                      } 
                   </>           
                )
            }
            else if(type === 'Offers'){
                if(row.vouchers === null){
                    return 
                }
                return (
                    <>
                    {
                       row.is_gift === false &&  row.vouchers.map((element,index)=>{
                        return(
                         <a style={{textDecoration:"none"}}
                         href= {element.offer_url}
                         target="_blank"
                         rel="noreferrer"
                         >
                                     <td className="px-1 py-2 align-bottom  "><button className='bg-light-gray border-0 px-3  fw-bold view-button' ><p className='m-0'  style={{fontSize:"15px"}}>0{index+1}</p>
                                     <h6 className='m-0 fw-bold primary-color'  style={{fontSize:"11px"}}>{t("View")}</h6>
                             </button> </td>
                         </a>
                        )
                          })
                    }     
                     </>
                )
            }
            else if(type === "RewardsBy Gifts"){
                return(
                   row.shipment_tracking_url && <Link
                    to={{pathname:row.shipment_tracking_url}}
                    target="_blank" rel="noopener noreferrer"
                    >
                    <td className="px-1 py-2 align-bottom  "><button className='bg-light-gray border-0 px-3  fw-bold view-button' ><p className='m-0'  style={{fontSize:"15px"}}>01</p>
                                     <h6 className='m-0 fw-bold primary-color'  style={{fontSize:"11px"}}>{t("View")}</h6>
                             </button> </td>
                    </Link>
                    
                )
            }

            else if(type === 'Experiences'){
                
                return (
                    row.experience_url && 
                    <Link
                    to={{pathname:row.experience_url}}
                    target="_blank" rel="noopener noreferrer"
                    >
                    <td className="px-1 py-2 align-bottom  "><button className='bg-light-gray border-0 px-3  fw-bold view-button' >
                    <p className='m-0'  style={{fontSize:"15px"}}>01</p>
                    <h6 className='m-0 fw-bold mb-1 primary-color'  style={{fontSize:"12px"}}>{t("View")}</h6>
                             </button> </td>
                    </Link>
                )
            }
            else if(type === 'Software' || type === 'Games'){
                
                return (
                    <Link
                    to={{pathname:`/softwareGames/${row.id}/details?lang=${lang}`,
                    state: {data:row}
                }}
                    target="_blank" rel="noopener noreferrer"
                    >
                    <td className="px-1 py-2 align-bottom  "><button className='bg-light-gray border-0 px-3  fw-bold view-button' >
                    <p className='m-0'  style={{fontSize:"15px"}}>01</p>
                    <h6 className='m-0 fw-bold mb-1 primary-color'  style={{fontSize:"12px"}}>{t("View")}</h6>
                             </button> </td>
                    </Link>
                )
            }
            else if(type === 'receivedGiftcards'){
                
                return (
                    <>
                              <div className="py-1 fs-7">
                         <span>{t("Gift Card Number")}: </span><span className="color-skyblue fw-bold">{row.giftcard_number}</span>
                         </div>  
                                 {
                                  row.gift_message &&     row.gift_message !== null?
                                  
                                      <button className="bg-light-gray border-0 px-3 py-2 fw-bold text-muted text-capitalize" style={{fontSize:"12px"}}>
                                      {row.gift_message}
                                  </button>:
                                  false
                                  }
                    <Link
                    target='_blank'
                    to={{
                        pathname:`/giftcard/${row.giftcard_token}/details?lang=${lang}`,
                         state: {...row }
                       }}
                    >
                    <td className="px-1 py-2 align-bottom  "><button className='bg-light-gray border-0 px-3  fw-bold view-button' >
                    <p className='m-0'  style={{fontSize:"15px"}}>01</p>
                    <h6 className='m-0 fw-bold my-1 primary-color'  style={{fontSize:"12px"}}>{t("View")}</h6>
                             </button> </td>
                    </Link>
                    </>
                )
            }
               else if(type === 'receivedOffers'){
                
                return (
                    <>
                          <div className="py-1 fs-7">
                         <span>{t("Voucher Number")}: </span><span className="color-skyblue fw-bold">{row.voucher_number}</span>
                         </div>  
                      {
                                  row.gift_message &&     row.gift_message !== null?
                                  
                                      <button className="bg-light-gray border-0 px-3 py-2 fw-bold text-muted text-capitalize" style={{fontSize:"12px"}}>
                                      {row.gift_message}
                                  </button>:
                                  false
                                  }
                    <a style={{textDecoration:"none"}}
                             href= { row.voucher_url}
                             target="_blank"
                             rel="noreferrer"
                             >     
                                     <td className="px-1 py-4 align-bottom  "><button className='bg-light-gray border-0 px-3  fw-bold view-button' ><p className='m-0'  style={{fontSize:"15px"}}>01</p>
                                     <h6 className='m-0 fw-bold mb-1 primary-color'  style={{fontSize:"11px"}}>{t("View")}</h6>
                             </button> </td>
                                
                             </a>
                    </>
                )
            }
        }
        const carouselItems = []
        const displayCard=(card)=>{
            
            return (
                <div key={card.id} className="w-50 d-inline-block p-1border mx-1 no-gutters border"  style={{width:"calc(50% - 0.5rem)"}}>
                <div className=" bg-color-gray d-flex align-items-center justify-content-center">
                    <img 
                        className="d-block img-fluid p-3 w-100"
                        style={{}}
                        src={card.images?.small_rectangle || card.image?.small_rectangle ||card.card_images?.small_rectangle} alt="" />
                </div>
                <div className="p-3  overflow-hidden">
                    <div className="product_title m-0 pb-1 fw-bold fs-5 overflow-hidden" >{
                        this.props.name === 'RewardsBy Gifts' ? (this.props.lang !== 'ar'? card?.product_name : card?.product_name_arabic) : (this.props.lang !== 'ar'? card?.brand_name : card?.brand_name_arabic)
                    }</div>
                    <div   className="text-muted fw-bold">{t("by")} {this.props.lang !== 'ar'? card?.brand_name : card?.brand_name_arabic}</div>
                    {
                               this.props.name === "Received Gifts" ?
                               <div className="bg-light-gray rounded-0 fw-bold text-muted text-capitalize my-2 fs-6 d-inline-block px-2 py-2">
                               {t("RECEIVED")}
                           </div>
                               :
                               <div className="bg-light-gray rounded-0 fw-bold text-muted text-capitalize my-2 fs-6 d-inline-block px-2 py-2">
                               {t(card.order_status || card.status)}
                           </div>
                           }
                           
                    <div className="py-1 fs-7">
                    {
                       this.props.name === "Received Gifts" ?
                                       
                                         <div className='my-3'><span>{t("Expiry Date")}: </span><span className="text-dark"><b>{card?.expiration_date ||card.effective_to_date}</b></span></div>
                                       
                                         :
                                         <>
                                          <div className='my-3'><span>{t("Date")}: </span><span className="text-dark"><b>{new Date(card.date).toDateString() || new Date(card.expiration_date).toDateString() }</b></span></div>
                                          </>

                                    }                      
                       {  this.props.name !== "Received Gifts" ? 
                      <>
                         <span>{t("Order ID")}: </span><span className="color-skyblue fw-bold">{card.orderid}</span>
                         <div><span>{t("Product Value")}: </span><span className="color-skyblue fw-bold">{card.order_total}{" "}{card.unit_name || card.currency_name}</span></div>
                         </>
                         : false
                          
                       }
                        {
                           ( card?.isgift || card.is_gift) && (card.order_status=== 'FULFILLED' || card.order_status === 'CONFIRMED')  ?
                                        <>
                                        {card.recipient_email && <div><span>{`${t("Recipient Email")}:`} </span><span className="color-skyblue text-break">{card.recipient_email}</span></div>}
                                       {card.recipient_phone && <div><span>{`${t("Recipient Mobile")}:`} </span><span className="color-skyblue text-wrap">{card.recipient_phone}</span></div>}
                                        <div className='justify-content-start d-flex over'>
                                        <button className="bg-light-gray border-0 px-3 py-2 mt-2  fw-bold text-muted text-capitalize"  style={{color:"#00008b"}}>
                                         {t("Gifted")}
                                        </button>
                                         </div>
                                        </>
                                         :
                                          false
                                    }
                        </div>
                      
                        {
                                   card.order_status ==="FULFILLED" || card.order_status ==="SHIPPED" || card.status === 'CONFIRMED' ? 
                                  linkCards(name,card)
                                  : false
                               }
                    </div>
                </div>
            )
        }
      cards &&  cards.forEach( (card,ind) => {
            ind++;
            if(ind === cards.length && cards.length%2!==0){
                carouselItems.push(displayCard(card))
            }
            else if(ind%2!==0 && ind !== cards.length){
                carouselItems.push(
                    <div key={ind} className="d-flex align-items-top">
                        {displayCard(card)}
                        {displayCard(cards[ind])}
                    </div>)
            }
            
        });
        
        return ( 
            <div className="mb-5">
                <div class="w-100 text-left fs-5 fw-bold rounded-0 color-blue py-3 d-flex justify-content-between align-items-center" type="button" data-toggle="collapse" data-target={`#collapse${name}`} aria-expanded="false" aria-controls={`collapse${name}`}>
                    {t(name)}
                    <button onClick={this.props.showAll} className="color-skyblue fs-6 btn">
                        {t("Show All")} ({ cards && cards.length})
                    </button>
                </div>
                
                <Carousel 
                  prevIcon={null} 
                  nextIcon={null}
                  interval={100000000000}
                  indicators={false}
                  >
                    {
                        carouselItems.map(card=>(
                            <Carousel.Item indicators={false}>
                                {card}
                            </Carousel.Item>
                        ))
                    }
                    
                </Carousel>
            </div>
            
         );
    }
}
 
export default withTranslation() (ProductCards);