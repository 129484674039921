import React, { Component } from 'react';
import { withTranslation } from 'react-i18next/';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import electronicIcon from '../../assests/img/categories/icon-bordered-electronics-n-communication.png'
import fashionIcon from '../../assests/img/categories/icon-bordered-fashion-n-lifestyle.png'
import fitnessIcon from '../../assests/img/categories/icon-bordered-fitness-health-beauty.png'
import globalIcon from '../../assests/img/categories/icon-bordered-global-stores.png'
import homeIcon from '../../assests/img/categories/icon-bordered-home-n-furniture.png'
import hypermarketIcon from '../../assests/img/categories/icon-bordered-hypermarket.png'
import jewelleryIcon from '../../assests/img/categories/icon-bordered-jewellery-n-accessories.png'
import kidsIcon from '../../assests/img/categories/icon-bordered-kids-babies-toys.png'
import leisureIcon from '../../assests/img/categories/icon-bordered-leisure-n-entertainment.png'
import onlineIcon from '../../assests/img/categories/icon-bordered-online-shopping.png'
import restaurantsIcon from '../../assests/img/categories/icon-bordered-restaurants-n-cafes.png'
import stationaryIcon from '../../assests/img/categories/icon-bordered-stationery-n-education.png'
import transportIcon from '../../assests/img/categories/icon-bordered-transportation-n-services.png'
import travelIcon from '../../assests/img/categories/icon-bordered-travel-n-hotels.png'
import { fetchCategoriesList } from '../../Redux/categories/categoriesAction';

class MobileCategorySection extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            category:undefined
         }
    }
    categories=[
                    {name:'Electronics & Communication',id:'',imgSrc:electronicIcon,url:""},
                    {name:'Stationery & Education',id:'',imgSrc:stationaryIcon,url:""},
                    {name:'Kids, Babies & Toys',id:'',imgSrc:kidsIcon,url:""},
                    {name:'Travel & Hotels',id:'',imgSrc:travelIcon,url:""},
                    {name:'Transportation & Services',id:'',imgSrc:transportIcon,url:""},
                    {name:'Restaurants & Cafes',id:'',imgSrc:restaurantsIcon,url:""},
                    {name:'Home & Furniture',id:'',imgSrc:homeIcon,url:""},
                    {name:'Hypermarket',id:'',imgSrc:hypermarketIcon,url:""},
                    {name:'Jewellery & Accessories',id:'',imgSrc:jewelleryIcon,url:""},
                    {name:'Online Shopping',id:'',imgSrc:onlineIcon,url:""},
                    {name:'Leisure & Entertainment',id:'',imgSrc:leisureIcon,url:""},
                    {name:'Fashion & Lifestyle',id:'',imgSrc:fashionIcon,url:""},
                    {name:'Fitness, Health & Beauty',id:'',imgSrc:fitnessIcon,url:""},
                    {name:'Global Stores',id:'',imgSrc:globalIcon,url:""}
                ]
    componentDidMount(){
    //    this.props.fetchCategoriesList()
    }

    render() { 
        const {t} = this.props
        
        return ( 
            <div className="d-md-none d-block px-4 bg-light headings mb-2 py-3">
                <h5>{t("Shop By Category")}</h5>
                <div style={{overflowY:'hidden',overflowX:'auto'}} className="d-flex mx-n1">
                    {this.props.category && this.props.category.map((category,index)=>(
                       <Link to={{pathname:`/category/${category.name}`,state:category.id}} key={index}>
                         <div className="px-1 text-center">
                            <img style={{width:'86px',height:'86px'}} src={category?.image?.thumb_square} alt={category.name} />
                            <small className="fw-bold text-dark d-block" style={{lineHeight:'1rem'}}>
                                {this.props.lang !== 'ar' ? category.name : category.name_arabic}
                            </small>
                        </div>
                       </Link>
                        
                    ))}
                </div>
            </div>
         );
    }
}
 
const mapStateToProps = state => {
    return {
       category:state.categories.allCategories
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchCategoriesList: () => dispatch(fetchCategoriesList())
        
    }
}
export default connect(mapStateToProps,mapDispatchToProps)((withTranslation()(MobileCategorySection)));
